import React from 'react';
import {
  Group,
  Input,
  NumberInput,
  RangeSlider,
  Slider,
  Stack,
  Radio,
} from '@mantine/core';

import { ScoreContactFilterState } from './types';
import FilterPill from './FilterPill';

const MIN_SCORE = 0;
const MAX_SCORE = 100;

interface ComponentProps {
  onClear: () => void;
  onClose: () => void;
  scoreContactFilterState: ScoreContactFilterState;
}

export default function ScoreContactFilter({
  onClear,
  onClose,
  scoreContactFilterState: {
    minScore,
    maxScore,
    selectionMode,
    onChangeMinScore,
    onChangeMaxScore,
    onChangeSelectionMode,
  },
}: ComponentProps) {
  const filterLabel = (() => {
    switch (selectionMode) {
      case 'less':
        return `< ${maxScore}%`;
      case 'greater':
        return `> ${minScore}%`;
      case 'between':
        return `${minScore}% - ${maxScore}%`;
    }
  })();

  const handleChangeSelectionMode = (value: string) => {
    const mode = value as 'less' | 'greater' | 'between';
    if (mode === 'less') {
      onChangeMinScore(MIN_SCORE);
      if (!maxScore) onChangeMaxScore(50);
    } else if (mode === 'greater') {
      if (!minScore) onChangeMinScore(50);
      onChangeMaxScore(MAX_SCORE);
    }
    onChangeSelectionMode(mode);
  };

  return (
    <FilterPill
      title='Contact Score'
      label={filterLabel}
      onClear={onClear}
      onClose={onClose}
    >
      <Stack gap='xs' p='sm'>
        <Radio.Group value={selectionMode} onChange={handleChangeSelectionMode}>
          <Group>
            <Radio
              label='Less than'
              value='less'
              style={{ cursor: 'pointer' }}
            />
            <Radio
              label='Between'
              value='between'
              style={{ cursor: 'pointer' }}
            />
            <Radio
              label='Greater than'
              value='greater'
              style={{ cursor: 'pointer' }}
            />
          </Group>
        </Radio.Group>

        {selectionMode === 'between' ? (
          <>
            <Group justify='space-between'>
              <Input.Label>Min Score (%)</Input.Label>
              <NumberInput
                w='50%'
                min={MIN_SCORE}
                max={maxScore}
                value={minScore}
                onChange={(val) => onChangeMinScore(Number(val))}
              />
            </Group>
            <Group justify='space-between'>
              <Input.Label>Max Score (%)</Input.Label>
              <NumberInput
                w='50%'
                min={minScore}
                max={MAX_SCORE}
                value={maxScore}
                onChange={(val) => onChangeMaxScore(Number(val))}
              />
            </Group>
            <RangeSlider
              mb='lg'
              min={MIN_SCORE}
              max={MAX_SCORE}
              marks={[
                { value: 25, label: '25%' },
                { value: 50, label: '50%' },
                { value: 75, label: '75%' },
              ]}
              value={[minScore, maxScore] as [number, number]}
              onChange={([min, max]) => {
                onChangeMinScore(min);
                onChangeMaxScore(max);
              }}
            />
          </>
        ) : (
          <>
            {selectionMode === 'greater' ? (
              <>
                <Group justify='space-between'>
                  <Input.Label>Min Score (%)</Input.Label>
                  <NumberInput
                    w='50%'
                    min={MIN_SCORE}
                    max={MAX_SCORE}
                    value={minScore}
                    onChange={(val) => onChangeMinScore(Number(val))}
                  />
                </Group>
                <Slider
                  mb='lg'
                  min={MIN_SCORE}
                  max={MAX_SCORE}
                  marks={[
                    { value: 25, label: '25%' },
                    { value: 50, label: '50%' },
                    { value: 75, label: '75%' },
                  ]}
                  value={minScore}
                  onChange={(val) => onChangeMinScore(val)}
                  inverted
                />
              </>
            ) : (
              <>
                <Group justify='space-between'>
                  <Input.Label>
                    {selectionMode === 'less'
                      ? 'Max Score (%)'
                      : 'Min Score (%)'}
                  </Input.Label>
                  <NumberInput
                    w='50%'
                    min={MIN_SCORE}
                    max={MAX_SCORE}
                    value={selectionMode === 'less' ? maxScore : minScore}
                    onChange={(val) =>
                      selectionMode === 'less'
                        ? onChangeMaxScore(Number(val))
                        : onChangeMinScore(Number(val))
                    }
                  />
                </Group>
                <Slider
                  mb='lg'
                  min={MIN_SCORE}
                  max={MAX_SCORE}
                  marks={[
                    { value: 25, label: '25%' },
                    { value: 50, label: '50%' },
                    { value: 75, label: '75%' },
                  ]}
                  value={selectionMode === 'less' ? maxScore : minScore}
                  onChange={(val) =>
                    selectionMode === 'less'
                      ? onChangeMaxScore(val)
                      : onChangeMinScore(val)
                  }
                />
              </>
            )}
          </>
        )}
      </Stack>
    </FilterPill>
  );
}
