import { LoadingOverlay } from '@mantine/core';
import TranscriptWrapper from 'components/transcript/TranscriptWrapper';
import { useGetTranscriptAccess } from 'hooks/useGetTranscriptAccess';
import React from 'react';
import { useParams, Navigate, useSearchParams } from 'react-router-dom';

const NoLoginCampaignTranscript = () => {
  const { campaignId } = useParams();
  const [searchParams] = useSearchParams();
  const candidateId = searchParams.get('candidateId') || '';
  const { transcriptAccess, isFetchingTranscriptAccess } =
    useGetTranscriptAccess({ campaignId, candidateId });

  if (isFetchingTranscriptAccess) {
    return <LoadingOverlay visible={true} loaderProps={{ display: 'none' }} />;
  }

  // if access is restricted
  if (
    candidateId &&
    !transcriptAccess.hasAccess &&
    !isFetchingTranscriptAccess
  ) {
    console.error(
      'access is restricted, redirecting to restricted-access',
      candidateId,
      transcriptAccess
    );
    return <Navigate to='/restricted-access' />;
  }

  return (
    <div
      className='page-container-common'
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <TranscriptWrapper
        showNavigationShortcut={false}
        isFetchingCalls={false}
        onClose={() => {}}
      />
    </div>
  );
};

export default NoLoginCampaignTranscript;
