import React, { useCallback } from 'react';
import {
  Switch,
  Flex,
  Stack,
  Text,
  Tooltip,
  Title,
  Slider,
  NumberInput,
} from '@mantine/core';
import { IconHelp } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import { usePermissions } from 'hooks/usePermissions';

import { getOutroFromSchedule } from './Outro';

const FollowUpStep = ({ scriptInfo }) => {
  const { canEditCampaign } = usePermissions();
  const handleScheduleFollowUpChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.currentTarget.checked;

      if (
        newValue &&
        (!localStorage.getItem('calAPIKey') ||
          !localStorage.getItem('calEventTypeID'))
      ) {
        notifications.show({
          id: 'calendar-integration-required',
          title: 'Calendar Integration Required',
          message:
            'Please set up your calendar integration to use the "Schedule follow up" option.',
          color: 'red',
        });
        return;
      }

      scriptInfo.setFieldValue('scheduleFollowUp', newValue);

      const defaultCallOutro = getOutroFromSchedule(newValue).actualValue;
      scriptInfo.setFieldValue('callOutro', defaultCallOutro);
    },
    [scriptInfo]
  );

  return (
    <>
      <Stack h='100%'>
        <Flex justify='space-between' align='center'>
          <Title order={5}>Follow-Up & Scheduling Settings</Title>
          <div
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              paddingTop: '10px',
            }}
          ></div>
          <div style={{ paddingRight: '5px', paddingTop: '10px' }}>
            <Switch
              checked={scriptInfo.values.scheduleFollowUp}
              onChange={handleScheduleFollowUpChange}
              label='Schedule follow up'
            />
          </div>
        </Flex>

        <Flex
          align='flex-center'
          style={{ marginTop: '15px', marginBottom: '15px' }}
        >
          <Text fz='sm' c='dimmed'>
            This message will be used at the end of the call to debrief the
            candidate and inform them of next steps. <br />
            {scriptInfo.values.scheduleFollowUp
              ? '\n\n\nIf the candidate scores above the passing score, they will be scheduled for a follow up call.'
              : '  '}
          </Text>

          <Flex
            style={{
              opacity: scriptInfo.values.scheduleFollowUp ? 1.0 : 0.08,
              pointerEvents: scriptInfo.values.scheduleFollowUp
                ? 'all'
                : 'none',
              paddingLeft: '25px',
              marginLeft: 'auto',
            }}
          >
            <div style={{ width: '130px' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Text size='sm' style={{ paddingBottom: '6px' }}>
                  Passing Score{' '}
                  <Tooltip label='Threshold score past which a follow up call will be scheduled'>
                    <IconHelp
                      size={16}
                      style={{
                        marginLeft: '4px',
                        position: 'relative',
                        top: '2px',
                        // color: '#868e96',
                      }}
                    />
                  </Tooltip>
                </Text>
              </div>

              <Slider
                size='sm'
                disabled={!canEditCampaign}
                color={
                  scriptInfo.values.scheduleFollowUp ? '#228be6e8' : 'gray'
                }
                {...scriptInfo.getInputProps('passingScore')}
                // value={scriptInfo.values.passingScore}
              />
            </div>
            <div
              style={{
                width: '60px',
                paddingLeft: '14px',
                paddingTop: '6px',
              }}
            >
              <NumberInput
                {...scriptInfo.getInputProps('passingScore')}
                min={0}
                max={100}
                size='xs'
                hideControls
                disabled={!canEditCampaign}
              />
            </div>
          </Flex>
        </Flex>
        {/* {scriptInfo.values.scheduleFollowUp && (
                <Text fz='sm' c='dimmed'>
                  If the candidate's score is above the passing score, they will
                  be scheduled for a follow up call.
                </Text>
              )} */}
        <div
          style={{
            whiteSpace: 'pre-line',
            border: '1px solid #DCDCDC',
            borderRadius: '4px',
            fontSize: '14px',
            padding: '8px',
            paddingLeft: '14px',
            cursor: 'not-allowed',
            // minHeight: '100px', do not set this, causes issues
            opacity: 0.94,
            backgroundColor: '#F8F8F8',
            pointerEvents: 'none',
          }}
        >
          {
            getOutroFromSchedule(scriptInfo.values.scheduleFollowUp)
              .selectionPageDisplay
          }
        </div>
      </Stack>
    </>
  );
};

export default FollowUpStep;
