import { useStytchIsAuthorized } from '@stytch/react/b2b';

// Resource and action strings taken directly from Stytch

// Add actions here a user can take on a resource
type CampaignActions = 'edit';
type AdminActions = 'edit' | 'view';

export const RESOURCES = {
  CAMPAIGNS: 'salv.campaigns',
  ADMIN: 'salv.admin',
} as const;

// Add to map here to ensure compile time checks pass in hasPermission
interface ResourceActionMap {
  'salv.admin': AdminActions;
  'salv.campaigns': CampaignActions;
}

export const usePermissions = () => {
  // Typesafe wrapper around useStytchIsAuthorized
  function useHasPermission<
    R extends keyof ResourceActionMap,
    A extends ResourceActionMap[R],
  >(resource: R, action: A) {
    // NOTE: to investigate later, fromCache is false but the call is super fast, sub-millisecond, (0.06ms on average)
    // So not a performance bottleneck, but a bit disconcerting / confusing
    const { isAuthorized } = useStytchIsAuthorized(resource, action);
    return isAuthorized;
  }

  return {
    canAccessAdmin: useHasPermission(RESOURCES.ADMIN, 'view'),
    canEditCampaign: useHasPermission(RESOURCES.CAMPAIGNS, 'edit'),
  } as const;
};
