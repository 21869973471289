import React, { useMemo, useState } from 'react';
import {
  Stack,
  Text,
  Textarea,
  Title,
  Modal,
  Divider,
  Alert,
  Button,
} from '@mantine/core';
import { IconAlertTriangle } from '@tabler/icons-react';
import { usePermissions } from 'hooks/usePermissions';
import Template, {
  SequenceSettingsTemplate,
} from 'components/template/Template';
import axios from 'api/axiosConfig';
import env from 'env';
import AddScriptGenerationTemplateToJobModal from 'components/template/AddScriptGenerationTemplateToJobModal';

import ImportJobButton from './ImportJobButton';

const GenerateStep = ({
  scriptInfo,
  orgSettings,
  generatingScript,
  scriptGenerationGuidelineEdits,
  setScriptGenerationGuidelineEdits,
  orgScriptGenerationTemplates,
  setOrgScriptGenerationTemplates,
}) => {
  const [showModalConfirmGuidelineEdit, setShowModalConfirmGuidelineEdit] =
    useState(false);
  const [editingGuidelines, setEditingGuidelines] = useState(false);
  const { canEditCampaign } = usePermissions();
  const [openAddTemplateModal, setOpenAddTemplateModal] = useState(false);

  const jobTemplatesIds = scriptInfo.getInputProps(
    'scriptGenerationTemplateIds'
  ).value;

  const handleRemoveTemplate = (id: string) => {
    const updatedTemplates = jobTemplatesIds.filter(
      (templateId: string) => templateId !== id
    );

    scriptInfo.setValues({ scriptGenerationTemplateIds: updatedTemplates });
  };

  const { jobsInTemplate } = useMemo(() => {
    const jobTemplatesIdsSet = new Set(jobTemplatesIds);

    const sortTemplates = (templates) =>
      templates.sort((a, b) => {
        if (a.is_default && !b.is_default) return -1;
        if (!a.is_default && b.is_default) return 1;
        return 0;
      });

    const jobsInTemplate = sortTemplates(
      orgScriptGenerationTemplates.filter((template) =>
        jobTemplatesIdsSet.has(template.id)
      )
    );

    return { jobsInTemplate };
  }, [jobTemplatesIds, orgScriptGenerationTemplates]);

  const handleEditTemplate = async (template: SequenceSettingsTemplate) => {
    try {
      await axios.patch(
        `${env.REACT_APP_SERVER_URL}/script_generation_templates/${template.id}/edit`,
        {
          orgId: template.org_id,
          title: template.title,
          instructions: template.instructions,
        }
      );
      setOrgScriptGenerationTemplates(
        (templates: SequenceSettingsTemplate[]) => {
          return templates.map((t) => {
            if (t.id === template.id) {
              return template;
            }
            return t;
          });
        }
      );
    } catch (error) {
      throw new Error(String(error));
    }
  };

  const handleJobSelect = (job) => {
    const filteredJob = {};

    // List of specific fields to include
    const fieldsToInclude = [
      'name',
      'description',
      'weekly_hours',
      'employment_type',
      'category',
      'department',
      'experience_level',
      'remote_work_status',
      'location',
    ];

    // Copy only fields that exist
    fieldsToInclude.forEach((field) => {
      if (job[field] != null) {
        filteredJob[field] = job[field];
      }
    });

    // Handle any salary_* fields
    Object.keys(job).forEach((key) => {
      if (key.startsWith('salary_') && job[key] != null) {
        filteredJob[key] = job[key];
      }
    });

    scriptInfo.setFieldValue(
      'jobDescription',
      JSON.stringify(filteredJob, null, 2)
    );
  };

  return (
    <>
      <Modal.Root
        opened={showModalConfirmGuidelineEdit}
        onClose={() => {
          setShowModalConfirmGuidelineEdit(false);
        }}
        size='lg'
      >
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title style={{ fontWeight: 'bold' }}>
              Script Guidelines Edit Warning Confirmation
            </Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Divider />
          <Modal.Body>
            <br />
            <Alert
              color='yellow'
              icon={<IconAlertTriangle size={16} />}
              style={{
                padding: '16px',
                backgroundColor: 'var(--salv-warning-yellow)',
              }}
            >
              <div style={{ fontSize: '16px' }}>
                <b>Warning</b>: Changes to additional guidelines will affect all
                scripts and sequences
              </div>
            </Alert>
            <br />
            <div>
              Modifications to additional guidelines will be applied to{' '}
              <b>all jobs, scripts, and campaign sequences</b> in your
              organization.
            </div>
            <br />
            <br />
            <Divider />
            <div
              style={{
                display: 'flex',
                marginTop: '16px',
                gap: '20px',
              }}
            >
              <Button
                variant='outline'
                style={{ marginLeft: 'auto' }}
                onClick={() => {
                  setShowModalConfirmGuidelineEdit(false);
                  setEditingGuidelines(false);
                }}
              >
                Cancel
              </Button>
              <Button
                variant='filled'
                color={'var(--salv-warning-yellow)'}
                style={{
                  marginRight: 'auto',
                  '--button-color': 'black',
                }}
                onClick={() => {
                  setShowModalConfirmGuidelineEdit(false);
                  setEditingGuidelines(true);
                }}
              >
                <IconAlertTriangle size={16} style={{ marginRight: '8px' }} />
                Acknowledge and Edit
              </Button>
            </div>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
      <Modal.Root
        opened={editingGuidelines}
        onClose={() => {
          setEditingGuidelines(false);
          setShowModalConfirmGuidelineEdit(false);
        }}
        size='lg'
      >
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title style={{ fontWeight: 'bold' }}>
              Edit Additional Guidelines
            </Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Divider />
          <Modal.Body>
            <br />
            <Alert
              color='yellow'
              icon={<IconAlertTriangle size={16} />}
              style={{
                padding: '16px',
                backgroundColor: 'var(--salv-warning-yellow)',
              }}
            >
              <div style={{ fontSize: '16px' }}>
                <b>Warning</b>: Changes to additional guidelines will affect all
                scripts and sequences
              </div>
            </Alert>
            <br />
            <div>
              Modifications to additional guidelines will be applied to{' '}
              <b>all jobs, scripts, and campaign sequences</b> in your
              organization.
            </div>
            <br />
            <Textarea
              className='fullHeightTextarea'
              placeholder='Additional guidelines'
              value={scriptGenerationGuidelineEdits}
              onChange={(e) => {
                setScriptGenerationGuidelineEdits(e.target.value);
              }}
              style={{ marginTop: '16px', height: '200px' }}
            />
            <br />
            <Divider />
            <div
              style={{
                display: 'flex',
                marginTop: '16px',
                gap: '20px',
              }}
            >
              <Button
                variant='outline'
                style={{ marginLeft: 'auto' }}
                onClick={() => {
                  setEditingGuidelines(false);
                  setScriptGenerationGuidelineEdits(
                    orgSettings.values.scriptGenerationGuidelines
                  );
                }}
              >
                Discard
              </Button>
              <Button
                variant='light'
                style={{
                  marginRight: 'auto',
                  backgroundColor: 'var(--salv-warning-yellow)',
                  color: 'black',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  '--button-color': 'black',
                }}
                onClick={() => {
                  setEditingGuidelines(false);
                  orgSettings.setValues({
                    scriptGenerationGuidelines: scriptGenerationGuidelineEdits,
                  });
                }}
              >
                Save For All Jobs
              </Button>
            </div>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
      <Stack style={{ minHeight: '300px', height: '50vh' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div>
            <Title order={5}>Job Description (Optional)</Title>
            <Text fz='sm' c='dimmed'>
              Add a job description which can be used to generate a call script
              and requirements
            </Text>
          </div>
          {true && <ImportJobButton onJobSelect={handleJobSelect} />}
        </div>
        <Textarea
          className='fullHeightTextarea'
          placeholder='Paste in complete job description...'
          {...scriptInfo.getInputProps('jobDescription')}
          disabled={generatingScript}
          classNames={{
            input: !canEditCampaign ? 'view-only' : '',
          }}
        />
      </Stack>
      <Stack>
        <Title order={5}>Script Generation Templates (Optional)</Title>
        <Text size='sm' c='dimmed'>
          Add common script generation instructions for your organization to
          customzie the AI-Generated interview sequence.
        </Text>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            marginTop: '16px',
          }}
        >
          {jobsInTemplate.map((template: SequenceSettingsTemplate) => {
            return (
              <Template
                template={template}
                onEditTemplate={handleEditTemplate}
                handleRemoveTemplate={() => handleRemoveTemplate(template.id)}
              />
            );
          })}
          {canEditCampaign && (
            <Button
              color='blue'
              onClick={() => setOpenAddTemplateModal(true)}
              style={{ alignSelf: 'center' }}
              variant='outline'
            >
              Add Template to Job
            </Button>
          )}
        </div>
        <AddScriptGenerationTemplateToJobModal
          open={openAddTemplateModal}
          onClose={() => setOpenAddTemplateModal(false)}
          scriptInfo={scriptInfo}
          setOrgScriptGenerationTemplates={setOrgScriptGenerationTemplates}
          orgScriptGenerationTemplates={orgScriptGenerationTemplates}
        />
      </Stack>
    </>
  );
};

export default GenerateStep;
