// CalendarModal.tsx
import React, { useState } from 'react';
import { Modal, TextInput, Button, Group } from '@mantine/core';
import axios from 'api/axiosConfig';

interface CalendarModalProps {
  opened: boolean;
  onClose: () => void;
}

const CalendarModal: React.FC<CalendarModalProps> = ({ opened, onClose }) => {
  const [calAPIKey, setCalAPIKey] = useState(
    localStorage.getItem('calAPIKey') || ''
  );
  const [calEventTypeID, setCalEventTypeID] = useState(
    localStorage.getItem('calEventTypeID') || ''
  );
  const [savingCalSettings, setSavingCalSettings] = useState(false);

  const handleSaveCal = async () => {
    setSavingCalSettings(true);
    const payload = {
      calApiKey: calAPIKey,
      calEventTypeID: calEventTypeID,
    };

    try {
      await axios.post('/cal_integration', payload);
      localStorage.setItem('calAPIKey', calAPIKey);
      localStorage.setItem('calEventTypeID', calEventTypeID);
      onClose();
    } catch (error) {
      alert(
        'Failed to save calendar settings. Please check your API Key and Event Type ID. If the issue persists, contact support@salv.ai'
      );
    }
    setSavingCalSettings(false);
  };

  return (
    <Modal
      title='Calendar Integration'
      centered
      opened={opened}
      onClose={() => {
        onClose();
        setCalAPIKey(localStorage.getItem('calAPIKey') || '');
        setCalEventTypeID(localStorage.getItem('calEventTypeID') || '');
      }}
      zIndex={999999}
    >
      <TextInput
        label='Cal.com API Key'
        placeholder='cal_live_b16653451d4c0568832e74e03d'
        value={calAPIKey}
        onChange={(e) => setCalAPIKey(e.target.value)}
        required
      />
      <TextInput
        label='Cal.com Event Type ID'
        mt='md'
        placeholder='567346'
        value={calEventTypeID}
        onChange={(e) => setCalEventTypeID(e.target.value)}
        required
      />
      <Group justify='center' mt='xl'>
        {savingCalSettings ? (
          <Button disabled>Saving...</Button>
        ) : (
          <Button onClick={handleSaveCal}>Save and Close</Button>
        )}
      </Group>
    </Modal>
  );
};

export default CalendarModal;
