import React, {
  useEffect,
  useState,
  useCallback,
  Fragment,
  useRef,
} from 'react';
import {
  Button,
  Table,
  ActionIcon,
  Menu,
  rem,
  Center,
  Loader,
  Badge,
  Text,
  Progress,
  Skeleton,
} from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';
import {
  IconDotsVertical,
  IconTrash,
  IconEdit,
  IconCopy,
  IconClipboardList,
  IconVideo,
  IconPhone,
  IconFolderSymlink,
  IconPlus,
  IconFolderFilled,
} from '@tabler/icons-react';
import { notifications, showNotification } from '@mantine/notifications';
import { deleteCampaign, duplicateScript } from 'pages/scripts/actions';
import { v4 as uuidv4 } from 'uuid';
import 'css/common.css';
import env from 'env';
import axios from 'api/axiosConfig';
import { formatToLocalTime } from 'utils/dateUtils';
import { convertUpperSnakeToTitle } from 'utils/formatUtils';
import './ScriptTabsPage.css';
import DeleteCampaignFolderModal from 'components/campaign-folders/DeleteCampaignModal';
import MoveToFolderModal from 'components/campaign-folders/MoveToFolderModal';
import RenameFolderModal from 'components/campaign-folders/RenameFolderModal';
import NewFolderModal from 'components/campaign-folders/NewFolderModal';
import FolderBreadcrumbs from 'components/campaign-folders/FolderBreadcrumbs';
import { isAxiosError } from 'axios';
import ATSSyncButton from 'components/campaign/ATSSyncButton';
import DemoSelectModal from 'pages/scripts/DemoSelectModal';
import { CampaignReviewIndicator } from 'components/transcript/ReviewIndicator';

export type CampaignResponse = {
  campaign_id: string;
  campaign_name: string;
  user: {
    email: string;
  };
  status: string;
  created: Date;
  needs_review: boolean;
  web_call?: boolean;
  schedule_follow_up?: boolean;
  folder_id?: string;
  has_unanswered_questions: boolean;
};

type CampaginsProgressResponse = {
  campaign_id: string;
  total_candidates: number;
  done_candidates: number;
};

export type CampaignFolder = {
  folder_id: string;
  folder_name: string;
  user: {
    email: string;
  };
  created_at: Date;
  breadcrumbs: [
    {
      folder_id: string;
      folder_name: string;
    },
  ];
  parent_folder_id: string;
};

const COLLAPSE_THRESHOLD = 1100; // Note: threshold here AND css file

const ScriptsPage = () => {
  const navigate = useNavigate();

  const [campaignsData, setCampaignsData] = useState<CampaignResponse[]>([]);
  const [folderData, setFolderData] = useState<CampaignFolder[]>([]);
  const [campaignsProgress, setCampaignsProgress] = useState<
    CampaginsProgressResponse[]
  >([]);
  const [campaignsProgressLoading, setCampaignProgressLoading] = useState(true);
  const [campaignsLoading, setCampaignsLoading] = useState(true);
  const [openNewFolderModal, setOpenNewFolderModal] = useState(false);
  const [openMoveToFolderModal, setOpenMoveToFolderModal] = useState(false);
  const [openRenameFolderModal, setOpenRenameFolderModal] = useState(false);
  const [openDeleteFolderModal, setOpenDeleteFolderModal] = useState(false);
  const [itemToMove, setItemToMove] = useState<
    CampaignResponse | CampaignFolder | null
  >(null);
  const [selectedFolder, setSelectedFolder] = useState<CampaignFolder | null>(
    null
  );
  const email = localStorage.getItem('email');
  const { folderId: paramsFolderId } = useParams();
  const [currentFolder, setCurrentFolder] = useState<CampaignFolder | null>(
    null
  );
  const abortControllerRef = useRef<AbortController | null>(null);

  const orgId = localStorage.getItem('orgId');
  const { folderId } = useParams();

  const resetState = () => {
    setCampaignsLoading(true);
    setCampaignsData([]);
    setFolderData([]);
    setCampaignsProgress([]);
    setCampaignProgressLoading(true);
  };

  const fetchCampaigns = useCallback(async () => {
    if (!orgId) {
      return;
    }

    resetState();
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const controller = new AbortController();
    abortControllerRef.current = controller;
    try {
      const response = await axios.get(
        `${env.REACT_APP_SERVER_URL}/campaigns/org/${orgId}`,
        {
          params: {
            folderId,
          },
          signal: controller.signal,
        }
      );

      const { campaigns, folders, current_folder } = response.data.campaigns;
      const sortedData = campaigns.sort(
        (a, b) =>
          new Date(b.created as string).getTime() -
          new Date(a.created as string).getTime()
      ); // Sort by created time, most recent first
      setCampaignsData(sortedData);
      setFolderData(folders);
      setCurrentFolder(current_folder);
      setCampaignsLoading(false);
    } catch (error) {
      if (isAxiosError(error) && error.code === 'ERR_CANCELED') {
        return;
      }
      console.error('Error fetching campaigns:', error);
      setCampaignsLoading(false);
    }
  }, [folderId, orgId]); // Add orgId as a dependency

  const fetchCampaignProgress = useCallback(async () => {
    if (!orgId) {
      return;
    }
    setCampaignProgressLoading(true);
    try {
      const response = await axios.get(
        `${env.REACT_APP_SERVER_URL}/campaigns/org/${orgId}/stats`
      );
      setCampaignsProgress(response.data.campaigns);
      setCampaignProgressLoading(false);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    } finally {
      setCampaignProgressLoading(false);
    }
  }, [orgId]); // Add orgId as a dependency

  useEffect(() => {
    // Select all matching elements
    const progressSections = document.querySelectorAll(
      'div.mantine-Progress-section'
    );

    if (progressSections.length > 0) {
      progressSections.forEach((section) => {
        if (!env.IS_LOCAL) {
          section.classList.add('custom-progress-style');
        } else {
          section.classList.remove('custom-progress-style');
        }
      });
    }
  }, [campaignsData]); // Add dependencies if necessary

  const [isCollapsed, setIsCollapsed] = useState(
    window.innerWidth <= COLLAPSE_THRESHOLD
  );

  useEffect(() => {
    const handleResize = () => {
      setIsCollapsed(window.innerWidth <= COLLAPSE_THRESHOLD);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial state based on window width

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    fetchCampaigns();
    fetchCampaignProgress();
  }, [fetchCampaigns, fetchCampaignProgress]);

  const campaginProgressFromId = (
    campaignId: string
  ): CampaginsProgressResponse | undefined => {
    if (campaignsProgressLoading) return undefined;
    const progress = campaignsProgress.find(
      (c) => c.campaign_id === campaignId
    );
    return progress;
  };

  // TODO: be less lazy
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const didCampaignStart = (campaignId: string) => {
    const progress = campaginProgressFromId(campaignId);
    if (!progress) return false;
    return progress.total_candidates > 0;
  };

  const navigateToScriptEditor = useCallback(
    (
      c: CampaignResponse | undefined,
      tab = '',
      screenType: 'phone' | 'web' = 'phone'
    ) => {
      const campaignId = c?.campaign_id || '';
      const folder_Id = c?.folder_id || paramsFolderId || '';
      let url = '';

      if (folder_Id) {
        url = `/scripts/folders/${folder_Id}/script-editor/${campaignId}`;
      } else {
        url = `/scripts/script-editor/${campaignId}`;
      }

      if (!campaignId) {
        const newId = uuidv4();
        if (folder_Id) {
          url = `/scripts/folders/${folder_Id}/script-editor/new-${screenType}/${newId}`;
        } else {
          url = `/scripts/script-editor/new-${screenType}/${newId}`;
        }
      }

      if (tab) {
        url = url + `?tab=${tab}`;
      } else if (c && didCampaignStart(c.campaign_id)) {
        url = url + '?tab=contacts';
      }
      navigate(url);
    },
    [didCampaignStart, navigate, paramsFolderId]
  );

  const handleDuplicateScript = (campaignId: string) => {
    duplicateScript(campaignId)
      .then(() => {
        showNotification({
          title: 'Success',
          message: 'Script duplicated successfully!',
          color: 'green',
        });
        fetchCampaigns();
        fetchCampaignProgress();
      })
      .catch((error) => {
        showNotification({
          title: 'There was an error duplicating the script',
          message: error instanceof Error ? error.message : '',
          color: 'red',
        });
      });
  };

  const handleDeleteCampaign = (campaignId: string) => {
    deleteCampaign(campaignId)
      .then(() => {
        fetchCampaigns();
        showNotification({
          title: 'Success',
          message: 'Campaign deleted successfully!',
          color: 'green',
        });
      })
      .catch((error) => {
        showNotification({
          title: 'There was an error deleting the campaign',
          message: error instanceof Error ? error.message : '',
          color: 'red',
        });
      });
  };

  const [selectedcampaignIdx, setSelectedcampaignIdx] = useState(-1);

  const renderProgress = (campaignId: string) => {
    const progress = campaginProgressFromId(campaignId);
    if (!progress) {
      return <Skeleton height={6} style={{ width: '33%' }} />;
    }
    return (
      <div style={{ display: 'flex' }}>
        {!isCollapsed && (
          <Progress
            style={{
              width: '50px',
              alignSelf: 'center',
              marginRight: '12px',
            }}
            // color='#aeb0b5'
            color='var(--salv-dark-1)'
            // color='var(--mantine-color-blue-1)'
            radius='lg'
            value={(100 * progress.done_candidates) / progress.total_candidates}
            // styles={{
            //   // Using 'filled' to target the progress bar's filled area
            //   filled: {
            //     background:
            //       'linear-gradient(90deg, var(--salv-dark-2), var(--salv-light-1))',
            //   },
            // }}
          />
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: 'auto',
          }}
        >
          <Text size='xs'>
            {progress.done_candidates} / {progress.total_candidates}
          </Text>
        </div>
      </div>
    );
  };

  const handleClickMoveItem = (item: CampaignResponse | CampaignFolder) => {
    setOpenMoveToFolderModal(true);
    setItemToMove(item);
  };

  const handleCloseMoveFolderModal = () => {
    setOpenMoveToFolderModal(false);
    setItemToMove(null);
  };

  const handleClickRenameFolder = (folder) => {
    setOpenRenameFolderModal(true);
    setSelectedFolder(folder);
  };

  const handleCloseRenameFolderModal = () => {
    setOpenRenameFolderModal(false);
    setSelectedFolder(null);
  };

  const handleClickDeleteFolder = (folder) => {
    setOpenDeleteFolderModal(true);
    setSelectedFolder(folder);
  };

  const handleCloseDeleteFolderModal = () => {
    setOpenDeleteFolderModal(false);
    setSelectedFolder(null);
  };

  const handleMoveItemToFolder = async (moveToFolderId: string | null) => {
    if (!itemToMove) {
      return;
    }

    try {
      if ('campaign_id' in itemToMove) {
        // Move campaign
        await axios.post(
          `${env.REACT_APP_SERVER_URL}/move_campaign_to_folder`,
          {
            campaignId: itemToMove.campaign_id,
            folderId: moveToFolderId,
          }
        );
      } else if ('folder_name' in itemToMove) {
        // Move folder
        await axios.patch(
          `${env.REACT_APP_SERVER_URL}/campaign_folders/${itemToMove.folder_id}/move`,
          {
            parentFolderId: moveToFolderId,
          }
        );
      }

      fetchCampaigns();
    } catch (error) {
      console.error('Error moving item:', error);
      notifications.show({
        title: 'Error',
        message: `Failed to move ${
          'campaign_id' in itemToMove ? 'campaign' : 'folder'
        }`,
        color: 'red',
      });
    }
  };

  const handleAddFolder = (folder: CampaignFolder) => {
    setFolderData((prev) => [folder, ...prev]);
  };

  const handleDeleteFolder = (folderId: string) => {
    setFolderData((prev) => prev.filter((f) => f.folder_id !== folderId));
  };

  const handleRenameFolder = (folder: CampaignFolder) => {
    setFolderData((prev) =>
      prev.map((f) => (f.folder_id === folder.folder_id ? folder : f))
    );
  };

  const handleNavigateBreadCrumbs = (
    breadcrumb: Partial<CampaignFolder> | null
  ) => {
    if (!breadcrumb?.folder_id) {
      setCurrentFolder(null);
      navigate('/scripts');
      return;
    }
    setCurrentFolder(breadcrumb as CampaignFolder);
    navigate(`/scripts/folders/${breadcrumb.folder_id}`);
  };

  // TODO: make the headers sticky. Follow this https://ui.mantine.dev/category/tables/
  return (
    <div className='page-container-common'>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '8px',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <IconClipboardList size={20} color='#4a564d' />
          <FolderBreadcrumbs
            currentFolder={currentFolder}
            onNavigate={handleNavigateBreadCrumbs}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <ATSSyncButton />
          <Menu shadow='md' width={200} position='bottom-end'>
            <Menu.Target>
              <Button
                variant='light'
                size='sm'
                leftSection={<IconPlus size={18} />}
              >
                New
              </Button>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                leftSection={
                  <IconFolderFilled
                    size={14}
                    color='var(--salv-folder-color)'
                  />
                }
                onClick={() => setOpenNewFolderModal(true)}
              >
                New Folder
              </Menu.Item>
              <Menu.Item
                leftSection={
                  <IconPhone size={14} color='var(--salv-phone-color)' />
                }
                onClick={() => navigateToScriptEditor(undefined, '', 'phone')}
              >
                Phone Screen
              </Menu.Item>
              <Menu.Item
                leftSection={
                  <IconVideo size={14} color='var(--salv-video-color)' />
                }
                onClick={() => navigateToScriptEditor(undefined, '', 'web')}
              >
                Video Call
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </div>
      </div>

      <div className='common-table-wrapper'>
        <Table style={{ tableLayout: 'fixed', width: '100%' }}>
          <Table.Thead style={{ position: 'relative' }}>
            <Table.Tr>
              <Table.Th
                style={{
                  width: '28%',
                }}
              >
                Name
              </Table.Th>
              <Table.Th
                style={{
                  width: '20%',
                }}
              >
                Status
              </Table.Th>
              <Table.Th
                style={{
                  width: '20%',
                }}
              >
                Progress
              </Table.Th>
              <Table.Th
                style={{
                  width: '22%',
                }}
              >
                Owner
              </Table.Th>
              <Table.Th
                style={{
                  width: '22%',
                }}
              >
                Created
              </Table.Th>
              <Table.Th
                style={{
                  width: isCollapsed ? '8%' : '5%',
                }}
              >
                {''}
              </Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {folderData.map((folder, index) => (
              <FolderRow
                key={index}
                folder={folder}
                onClickRenameFolder={handleClickRenameFolder}
                onClickDeleteFolder={handleClickDeleteFolder}
                onClickMoveFolder={handleClickMoveItem}
                setCurrentFolder={setCurrentFolder}
              />
            ))}
            {campaignsData.map((campaign, index) => (
              <Table.Tr
                key={index}
                style={{
                  cursor: 'pointer',
                  background:
                    selectedcampaignIdx === index ? 'var(--salv-dark-0)' : '',
                }}
                onClick={() => navigateToScriptEditor(campaign)}
              >
                <Table.Td
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '12px',
                  }}
                >
                  <CampaignReviewIndicator
                    needsReview={!!campaign.needs_review}
                    hasUnansweredQuestions={!!campaign.has_unanswered_questions}
                  />
                  <div
                    style={{
                      flexShrink: 0,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {renderCampaignTypeIcon(campaign)}
                  </div>
                  {campaign.campaign_name}
                </Table.Td>
                <Table.Td>
                  <Badge autoContrast className='status-badge'>
                    {convertUpperSnakeToTitle(campaign.status)}
                  </Badge>
                </Table.Td>
                <Table.Td>
                  {/* <Text size='sm'>
                    {campaign.total_calls - campaign.pending_calls} /{' '}
                    {campaign.total_calls}
                  </Text> */}
                  {/* Bar concept */}
                  {renderProgress(campaign.campaign_id)}
                </Table.Td>
                <Table.Td style={{ color: '#4a4e56' }}>
                  {campaign.user.email}
                </Table.Td>
                <Table.Td style={{ color: '#4a4e56' }}>
                  {campaign.created ? formatToLocalTime(campaign.created) : ''}
                </Table.Td>
                <Table.Td
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  className='no-ellipses'
                >
                  <Menu
                    shadow='md'
                    width={200}
                    onChange={(e) => {
                      setSelectedcampaignIdx(e ? index : -1);
                    }}
                  >
                    <Menu.Target>
                      <ActionIcon variant='subtle'>
                        <IconDotsVertical
                          size={20}
                          onClick={() => {
                            // e.stopPropagation();
                            // e.preventDefault();
                          }}
                        />
                      </ActionIcon>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Label>{campaign.campaign_name}</Menu.Label>
                      <Menu.Item
                        leftSection={
                          <IconEdit
                            style={{ width: rem(14), height: rem(14) }}
                          />
                        }
                        onClick={() =>
                          navigateToScriptEditor(campaign, 'sequence')
                        }
                      >
                        Edit
                      </Menu.Item>
                      <Menu.Item
                        leftSection={
                          <IconCopy
                            style={{ width: rem(14), height: rem(14) }}
                          />
                        }
                        onClick={() =>
                          handleDuplicateScript(campaign.campaign_id)
                        }
                      >
                        Duplicate
                      </Menu.Item>
                      <Menu.Item
                        leftSection={
                          <IconFolderSymlink
                            style={{ width: rem(14), height: rem(14) }}
                          />
                        }
                        onClick={() => handleClickMoveItem(campaign)}
                      >
                        {`${campaign?.folder_id ? 'Move' : 'Move to Folder'}`}
                      </Menu.Item>
                      <Menu.Divider />
                      <Menu.Item
                        color='red'
                        leftSection={
                          <IconTrash
                            style={{ width: rem(14), height: rem(14) }}
                          />
                        }
                        onClick={() =>
                          handleDeleteCampaign(campaign.campaign_id)
                        }
                      >
                        Delete
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                </Table.Td>
              </Table.Tr>
            ))}
            {campaignsLoading && (
              <tr>
                <td colSpan={7}>
                  <Center p='lg'>
                    <Loader size='sm' type='dots' />
                  </Center>
                </td>
              </tr>
            )}
            {campaignsData.length === 0 &&
              folderData.length === 0 &&
              !campaignsLoading && (
                <tr>
                  <td colSpan={12}>
                    <Center p='lg' style={{ color: 'var(--salv-dark-4)' }}>
                      {folderId
                        ? 'No jobs found in this folder'
                        : 'No jobs found'}
                    </Center>
                  </td>
                </tr>
              )}
          </Table.Tbody>
        </Table>
      </div>
      {email === 'demo@salv.ai' && (
        <DemoSelectModal
          opened={true}
          onClose={() => {
            /* No need for state change */
          }}
          onSubmit={() => {}}
          campaignsData={campaignsData}
          campaignsLoading={campaignsLoading}
        />
      )}

      <NewFolderModal
        open={openNewFolderModal}
        onClose={() => {
          setOpenNewFolderModal(false);
        }}
        onAddFolder={handleAddFolder}
        folderId={paramsFolderId}
      />
      {itemToMove && (
        <MoveToFolderModal
          open={openMoveToFolderModal}
          onClose={handleCloseMoveFolderModal}
          itemToMove={itemToMove}
          onMoveItemToFolder={handleMoveItemToFolder}
          onAddFolder={handleAddFolder}
        />
      )}
      <RenameFolderModal
        open={openRenameFolderModal}
        onClose={handleCloseRenameFolderModal}
        folder={selectedFolder}
        onRenameFolder={handleRenameFolder}
      />
      <DeleteCampaignFolderModal
        open={openDeleteFolderModal}
        onClose={handleCloseDeleteFolderModal}
        folder={selectedFolder}
        onDeleteFolder={handleDeleteFolder}
      />
    </div>
  );
};

const FolderRow = ({
  folder,
  onClickRenameFolder,
  onClickDeleteFolder,
  onClickMoveFolder,
  setCurrentFolder,
}) => {
  const navigate = useNavigate();
  const navigateToFolder = () => {
    setCurrentFolder(folder);
    navigate(`/scripts/folders/${folder.folder_id}`);
  };
  return (
    <Fragment>
      <Table.Tr onClick={navigateToFolder} style={{ cursor: 'pointer' }}>
        <Table.Td>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '12px',
            }}
          >
            <IconFolderFilled
              size={16}
              style={{ color: 'var(--salv-dark-4)' }}
            />
            {folder.folder_name}
          </div>
        </Table.Td>
        <Table.Td></Table.Td>
        <Table.Td></Table.Td>
        <Table.Td style={{ color: '#4a4e56' }}>{folder.user.email}</Table.Td>
        <Table.Td style={{ color: '#4a4e56' }}>
          {folder.created_at ? formatToLocalTime(folder.created_at) : ''}
        </Table.Td>
        <Table.Td
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          className='no-ellipses'
        >
          <Menu
            shadow='md'
            width={200}
            // onChange={(e) => {
            //   setSelectedcampaignIdx(e ? index : -1);
            // }}
          >
            <Menu.Target>
              <ActionIcon variant='subtle'>
                <IconDotsVertical size={20} />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                leftSection={
                  <IconEdit style={{ width: rem(14), height: rem(14) }} />
                }
                onClick={() => onClickRenameFolder(folder)}
              >
                Rename Folder
              </Menu.Item>
              <Menu.Item
                leftSection={
                  <IconFolderSymlink
                    style={{ width: rem(14), height: rem(14) }}
                  />
                }
                onClick={() => onClickMoveFolder(folder)}
              >
                Move Folder
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item
                color='red'
                leftSection={
                  <IconTrash style={{ width: rem(14), height: rem(14) }} />
                }
                onClick={() => onClickDeleteFolder(folder)}
              >
                Delete Folder
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Table.Td>
      </Table.Tr>
    </Fragment>
  );
};

export const renderCampaignTypeIcon = (campaign: CampaignResponse) => {
  if (campaign.web_call) {
    return <IconVideo size={16} style={{ color: 'var(--salv-video-color)' }} />;
  }
  return <IconPhone size={16} style={{ color: 'var(--salv-phone-color)' }} />;
};

export default ScriptsPage;
