import { useState } from 'react';

interface FeedbackFilterState {
  selectedFeedback: string[];
  onChangeSelectedFeedback: (feedback: string[]) => void;
  clear: () => void;
  isEmpty: boolean;
}

const FEEDBACK_MAP: Record<string, string> = {
  Positive: 'thumbs_up',
  Negative: 'thumbs_down',
  Neutral: 'no_feedback',
};

export default function useFeedbackFilterState(): FeedbackFilterState {
  const [selectedFeedback, setSelectedFeedback] = useState<string[]>([]);

  const onChangeSelectedFeedback = (feedback: string[]) => {
    const mappedFeedback = feedback.map((f) => FEEDBACK_MAP[f] || f);
    setSelectedFeedback(mappedFeedback);
  };

  const clear = () => {
    setSelectedFeedback([]);
  };

  const isEmpty = selectedFeedback.length === 0;

  return {
    selectedFeedback,
    onChangeSelectedFeedback,
    clear,
    isEmpty,
  };
}
