import React from 'react';
import { Button } from '@mantine/core';
import { IconFileDownload } from '@tabler/icons-react';
import { IconPhone, IconMail } from '@tabler/icons-react';

import { formatPhoneNumber } from '../utils/phoneUtils';
import PDFViewer from './PDFViewer';
import { CopyableText } from './CopyableText';

interface CandidateInfoDisplayProps {
  candidate: {
    fullName: string;
    phoneNumber: string;
    email: string;
    city?: string;
    state?: string;
    milesFromJob?: number;
    resumeUrl?: string;
    resumeText?: string;
    qualificationsMap?: Record<string, { value: string; thinking: string }>;
    jobApplications?: Array<{
      job?: {
        title?: string;
        city?: string;
        state?: string;
      };
      date?: Date;
    }>;
  };
  showQualifications?: boolean;
  formatDate?: (date: Date) => string;
  monsterIdentity?: {
    md5EmailAddress: string;
    textResumeID: string;
  };
  onMonsterDownload?: (identity: {
    md5EmailAddress: string;
    textResumeID: string;
  }) => void;
  downloadingMonster?: boolean;
}

export const CandidateInfoDisplay: React.FC<CandidateInfoDisplayProps> = ({
  candidate,
  showQualifications = false,
  formatDate,
  monsterIdentity,
  onMonsterDownload,
  downloadingMonster,
}) => {
  const capitalizeWords = (str: string) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const capitalizeName = (str: string) => {
    return str
      .toLocaleLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  return (
    <div style={{ position: 'relative', height: '100%', fontSize: '14px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <div style={{ display: 'flex' }}>
            <h3 style={{ marginBottom: '4px' }}>
              <CopyableText text={candidate.fullName}>
                {capitalizeName(candidate.fullName)}
              </CopyableText>
            </h3>
          </div>
          <div
            style={{
              display: 'flex',
              marginBottom: '6px',
              alignItems: 'center',
            }}
          >
            <div style={{ display: 'flex', marginRight: '12px' }}>
              <CopyableText text={candidate.phoneNumber}>
                <IconPhone style={{ opacity: 0.7, marginRight: '2px' }} />
                {formatPhoneNumber(candidate.phoneNumber)}
              </CopyableText>
            </div>
            <div style={{ display: 'flex', marginRight: '12px' }}>
              <CopyableText text={candidate.email}>
                <IconMail style={{ opacity: 0.7, marginRight: '4px' }} />
                {candidate.email}
              </CopyableText>
            </div>
            {monsterIdentity && onMonsterDownload && (
              <Button
                size='sm'
                variant='light'
                disabled={downloadingMonster}
                onClick={() => onMonsterDownload(monsterIdentity)}
              >
                Redo Purchase{' '}
                <IconFileDownload style={{ marginLeft: '12px' }} />
              </Button>
            )}
          </div>

          {candidate.jobApplications &&
            candidate.jobApplications.length > 0 && (
              <div style={{ marginRight: '12px' }}>
                <h4 style={{ margin: '0px' }}>Applied to</h4>
                {candidate.jobApplications.map((jobApp, idx) => (
                  <div key={idx} style={{ paddingLeft: '12px' }}>
                    <span style={{ marginRight: '2px' }}>
                      {jobApp?.job?.title}
                    </span>{' '}
                    <span style={{ marginRight: '2px' }}>
                      {jobApp?.job?.city}, {jobApp?.job?.state}
                    </span>{' '}
                    {jobApp?.date && formatDate && (
                      <span style={{ color: 'gray' }}>
                        {'on ' + formatDate(jobApp.date)}
                      </span>
                    )}
                  </div>
                ))}
              </div>
            )}

          {showQualifications && candidate.qualificationsMap && (
            <div style={{ width: '100%' }}>
              {Object.keys(candidate.qualificationsMap)
                .filter(
                  (key) => candidate.qualificationsMap?.[key]?.value === 'yes'
                )
                .map((kw) => (
                  <span
                    key={kw}
                    style={{ marginRight: '10px', fontWeight: 'bold' }}
                  >
                    {capitalizeWords(kw)}
                  </span>
                ))}
              <br />
              <span>Missing: </span>
              {Object.keys(candidate.qualificationsMap)
                .filter(
                  (key) =>
                    candidate.qualificationsMap?.[key]?.value === 'unknown'
                )
                .map((kw) => (
                  <span
                    key={kw}
                    style={{
                      marginRight: '10px',
                      fontWeight: 'bold',
                      color: '#c71212',
                    }}
                  >
                    {capitalizeWords(kw)}
                  </span>
                ))}
            </div>
          )}
        </div>

        {candidate?.resumeUrl && showQualifications && (
          <a
            href={candidate.resumeUrl}
            style={{
              color: 'black',
              display: 'flex',
              alignItems: 'center',
              gap: '2px',
              textDecoration: 'none',
              alignSelf: 'center',
            }}
            className='default-download-btn'
          >
            Resume <IconFileDownload size={35} stroke={1} />
          </a>
        )}
      </div>

      <div style={{ paddingTop: '6px' }}>
        {candidate.resumeUrl && (
          <div style={{ overflowY: 'scroll', maxHeight: 'calc(100% - 120px)' }}>
            <PDFViewer
              fileUrl={candidate.resumeUrl}
              backupText={candidate.resumeText || ''}
            />
          </div>
        )}
      </div>
    </div>
  );
};
