import React from 'react';
import { Checkbox, Menu } from '@mantine/core';

import { FeedbackFilterState } from './types';
import FilterPill from './FilterPill';

const FEEDBACK_OPTIONS = ['Positive', 'Neutral', 'Negative'];

const FEEDBACK_MAP_REVERSE: Record<string, string> = {
  thumbs_up: 'Positive',
  thumbs_down: 'Negative',
  no_feedback: 'Neutral',
};

interface ComponentProps {
  onClear: () => void;
  onClose: () => void;
  feedbackFilterState: FeedbackFilterState;
}

export default function FeedbackFilter({
  onClear,
  onClose,
  feedbackFilterState: { selectedFeedback, onChangeSelectedFeedback },
}: ComponentProps) {
  const filterLabel = selectedFeedback.length
    ? String(selectedFeedback.length)
    : undefined;

  const displaySelectedFeedback = selectedFeedback.map(
    (f) => FEEDBACK_MAP_REVERSE[f] || f
  );

  const handleItemClick = (feedback: string) => () => {
    if (displaySelectedFeedback.includes(feedback)) {
      onChangeSelectedFeedback(
        displaySelectedFeedback.filter((f) => f !== feedback)
      );
    } else {
      onChangeSelectedFeedback([...displaySelectedFeedback, feedback]);
    }
  };

  const handleSelectAll = () => {
    if (displaySelectedFeedback.length === 0) {
      onChangeSelectedFeedback(FEEDBACK_OPTIONS);
    } else {
      onChangeSelectedFeedback([]);
    }
  };

  return (
    <FilterPill
      title='Feedback'
      label={filterLabel}
      onClear={onClear}
      onClose={onClose}
    >
      <Menu.Item
        leftSection={
          <Checkbox
            size='xs'
            checked={displaySelectedFeedback.length > 0}
            indeterminate={
              displaySelectedFeedback.length > 0 &&
              FEEDBACK_OPTIONS.length !== displaySelectedFeedback.length
            }
            readOnly
          />
        }
        onClick={handleSelectAll}
      >
        Select all
      </Menu.Item>
      <Menu.Divider />
      {FEEDBACK_OPTIONS.map((feedback) => (
        <Menu.Item
          key={feedback}
          leftSection={
            <Checkbox
              size='xs'
              checked={displaySelectedFeedback.includes(feedback)}
              readOnly
            />
          }
          onClick={handleItemClick(feedback)}
        >
          {feedback}
        </Menu.Item>
      ))}
    </FilterPill>
  );
}
