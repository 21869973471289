import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import React, { useEffect, useState } from 'react';
import { fileTypeFromBuffer } from 'file-type';
import { Modal, Button } from '@mantine/core';
import { IconArrowsMaximize } from '@tabler/icons-react';

import '@react-pdf-viewer/core/lib/styles/index.css';
import FilePreview from './FilePreview.jsx';

import './FilePreview.css';

const PDFViewer = ({ fileUrl, backupText }) => {
  const [loadState, setLoadState] = useState('');
  const [fileType, setFileType] = useState('');
  const [fullscreenOpen, setFullscreenOpen] = useState(false);

  useEffect(() => {
    const fetchAndConvertFile = async () => {
      if (fileUrl) {
        try {
          const response = await fetch(fileUrl);
          const arrayBuffer = await response.arrayBuffer();
          const fileType = await fileTypeFromBuffer(arrayBuffer);
          if (fileType) {
            setFileType(fileType.ext);
          }
          setLoadState('success');
        } catch (error) {
          console.error('Error reading file type', error);
          setLoadState('failure');
        }
      }
    };

    fetchAndConvertFile();
  }, [fileUrl]);

  const renderPreview = () => {
    return (
      <div
        style={{
          fontSize: '0.9vw',
          background: 'white',
          padding: '10px',
          borderRadius: '12px',
        }}
        className='file-preview'
      >
        <FilePreview fileUrl={fileUrl} backupText={backupText} />
      </div>
    );
  };

  const renderContent = () => (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: fullscreenOpen ? '100vh' : '100%',
        marginTop: fullscreenOpen ? 0 : '5px',
      }}
    >
      {fileType === 'pdf' || !loadState ? (
        <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js'>
          <Viewer fileUrl={fileUrl} renderError={renderPreview} />
        </Worker>
      ) : (
        <div>{renderPreview()}</div>
      )}
    </div>
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const pageLayer = document.querySelector('.rpv-core__page-layer');
      const button = document.getElementById('fullscreen-button');
      if (pageLayer && button) {
        button.style.display = 'block';
        pageLayer.appendChild(button);
        clearInterval(interval);
      }
    }, 100);

    return () => clearInterval(interval);
  }, [fileUrl, loadState]);

  return (
    <>
      <div style={{ position: 'relative' }}>
        <Button
          variant='transparent'
          size='sm'
          onClick={(e) => {
            e.stopPropagation();
            setFullscreenOpen(true);
          }}
          style={{
            position: 'absolute',
            top: '7px',
            right: '4px',
            zIndex: 100,
            display: 'none',
          }}
          id='fullscreen-button'
        >
          <IconArrowsMaximize size={16} color='var(--mantine-color-gray-6)' />
        </Button>
        {renderContent()}
      </div>

      <Modal
        opened={fullscreenOpen}
        onClose={(e) => {
          if (e) e.stopPropagation();
          setFullscreenOpen(false);
        }}
        size='80%'
        padding={0}
        withCloseButton={true}
        onClick={(e) => e.stopPropagation()}
        styles={{
          inner: {
            padding: '0px',
          },
          content: {
            height: '97vh',
            maxHeight: '97vh',
            marginTop: '2vh',
          },
          header: {
            margin: '0px',
            padding: '10px',
          },
          body: {
            height: 'calc(95vh - 160px)',
            padding: '0px',
            margin: '0px',
          },
        }}
      >
        {renderContent()}
      </Modal>
    </>
  );
};

export default PDFViewer;
