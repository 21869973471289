import { useState, useEffect, useMemo } from 'react';

//
// Custom hook to read and react to changes in localStorage "email"
//
export function useEmail() {
  // Read initial value once (won't re-read on every render)
  const getEmail = () => {
    try {
      return localStorage.getItem('email') || '';
    } catch {
      return '';
    }
  };

  const [email, setEmail] = useState<string>(getEmail);

  useEffect(() => {
    // Define a storage event handler to update the state only if value changes
    const handleStorage = (e: StorageEvent) => {
      if (e.key === 'email') {
        const newEmail = e.newValue || '';
        setEmail((prev) => (prev !== newEmail ? newEmail : prev));
      }
    };

    window.addEventListener('storage', handleStorage);
    return () => {
      window.removeEventListener('storage', handleStorage);
    };
  }, []);

  return [email, setEmail] as const;
}

export function useIsDemoAdmin(): boolean {
  const [email] = useEmail();
  return useMemo(() => email.toLowerCase() === 'demo-admin@salv.ai', [email]);
}

export function useIsInternal(): boolean {
  const [email] = useEmail();
  return useMemo(
    () =>
      email.toLowerCase().endsWith('@salv.ai') ||
      email.toLowerCase().endsWith('@tenzo.ai'),
    [email]
  );
}
