import { CompletionFilterState } from 'pages/job-posting-tips/components/types';
import { useState } from 'react';

const DEFAULT_MIN_COMPLETION = 0;
const DEFAULT_MAX_COMPLETION = 100;

export type CompletionSelectionMode = 'less' | 'greater' | 'between';

export default function useCompletionFilterState(): CompletionFilterState {
  const [minCompletion, setMinCompletion] = useState(DEFAULT_MIN_COMPLETION);
  const [maxCompletion, setMaxCompletion] = useState(DEFAULT_MAX_COMPLETION);
  const [selectionMode, setSelectionMode] =
    useState<CompletionSelectionMode>('between');

  const clear = () => {
    setMinCompletion(DEFAULT_MIN_COMPLETION);
    setMaxCompletion(DEFAULT_MAX_COMPLETION);
    setSelectionMode('between');
  };

  const isEmpty =
    minCompletion === DEFAULT_MIN_COMPLETION &&
    maxCompletion === DEFAULT_MAX_COMPLETION &&
    selectionMode === 'between';

  return {
    minCompletion,
    maxCompletion,
    selectionMode,
    onChangeMinCompletion: setMinCompletion,
    onChangeMaxCompletion: setMaxCompletion,
    onChangeSelectionMode: setSelectionMode,
    clear,
    isEmpty,
  };
}
