import React, { useState, useEffect, useRef } from 'react';
import './AudioPlayer.css';
import env from 'env';
import axios from 'api/axiosConfig';
import { Loader } from '@mantine/core';
import { useAtom } from 'jotai';
import { transcriptionCacheAtom } from 'pages/editorv2/atoms';
import { useParams, useSearchParams } from 'react-router-dom';

interface VideoPlayerProps {
  callId?: string;
  seekTimestamp: number | null;
  webAudio?: boolean;
  onTimeUpdate?: (time: number) => void;
  recordingURL?: string;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  callId,
  seekTimestamp,
  webAudio,
  onTimeUpdate,
  recordingURL,
}) => {
  const { campaignId } = useParams();
  const [searchParams] = useSearchParams();
  const candidateId = searchParams.get('candidateId') || '';
  const transcriptionCacheKey = `${candidateId}_${campaignId}`;
  const [, setTranscriptionCache] = useAtom(transcriptionCacheAtom);

  const [isLoading, setIsLoading] = useState(!recordingURL);
  const [videoUrl, setVideoUrl] = useState(recordingURL || '');
  const [isRenderingVideo, setIsRenderingVideo] = useState(true);
  const [renderDelayPassed, setRenderDelayPassed] = useState(false);

  const videoRef = useRef<HTMLVideoElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const playerInitialized = useRef(false);
  const pollingIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

  const RENDER_DELAY = 150;
  const POLLING_INTERVAL = 3000;

  useEffect(() => {
    if (recordingURL && !playerInitialized.current) {
      setVideoUrl(recordingURL);
      setIsLoading(false);
      playerInitialized.current = true;
    }
  }, [recordingURL]);

  useEffect(() => {
    if (seekTimestamp !== null && videoRef.current) {
      videoRef.current.currentTime = seekTimestamp;

      // Only attempt to play if video is actually loaded
      if (videoRef.current.readyState >= 2) {
        videoRef.current.play().catch((err) => {
          console.warn('Could not autoplay video:', err);
        });
      }
    }
  }, [seekTimestamp]);

  useEffect(() => {
    if (!recordingURL && !playerInitialized.current) {
      setVideoUrl('');
    }
  }, [callId, webAudio, recordingURL]);

  useEffect(() => {
    if (recordingURL || !callId || playerInitialized.current) {
      return;
    }

    let isMounted = true;

    const checkAvailability = async () => {
      try {
        const endpoint = webAudio
          ? `/check_web_audio_recording/${callId}`
          : `/check_phone_recording/${callId}`;

        const response = await axios.get(
          `${env.REACT_APP_SERVER_URL}${endpoint}`
        );

        if (response.status === 200 && response.data.available) {
          if (isMounted) {
            // Stop polling
            if (pollingIntervalRef.current) {
              clearInterval(pollingIntervalRef.current);
              pollingIntervalRef.current = null;
            }

            if (isMounted) {
              setVideoUrl(response.data.video_url);
              setIsLoading(false);
              playerInitialized.current = true;
            }

            setTranscriptionCache({
              data: {
                [transcriptionCacheKey]: {
                  recordingURL: response.data.video_url,
                },
              },
              timestamp: Date.now(),
            });
          }
        } else {
          throw new Error('Video not available');
        }
      } catch (error) {
        // If it's the initial request and failed, start polling
        if (!pollingIntervalRef.current && isMounted) {
          pollingIntervalRef.current = setInterval(
            checkAvailability,
            POLLING_INTERVAL
          );
        }
      }
    };

    // Perform the initial check immediately
    checkAvailability();

    // Cleanup function
    return () => {
      isMounted = false;
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
        pollingIntervalRef.current = null;
      }
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = null;
      }
    };
  }, [
    callId,
    webAudio,
    recordingURL,
    setTranscriptionCache,
    transcriptionCacheKey,
  ]);

  // Handle video loading completion
  useEffect(() => {
    if (!videoUrl) {
      return;
    }

    const video = videoRef.current;
    if (!video) {
      return;
    }

    const handleLoadedData = () => {
      setIsRenderingVideo(false);
    };

    video.addEventListener('loadeddata', handleLoadedData);

    // Check if already loaded
    if (video.readyState >= 2) {
      handleLoadedData();
    }

    // Add error handling
    const handleError = () => {
      console.error('Video loading error');
      setIsLoading(false);
    };

    video.addEventListener('error', handleError);

    return () => {
      video.removeEventListener('loadeddata', handleLoadedData);
      video.removeEventListener('error', handleError);
    };
  }, [videoUrl]);

  // Initial render delay to ensure UI stability
  useEffect(() => {
    const timer = setTimeout(() => {
      setRenderDelayPassed(true);
    }, RENDER_DELAY);

    return () => clearTimeout(timer);
  }, []);

  const videoStyles: React.CSSProperties = {
    borderRadius: '16px',
    position: 'relative',
    width: '80%',
    height: '60px',
    marginBottom: '6px',
    display: isRenderingVideo ? 'none' : 'block',
  };

  return (
    <div
      ref={containerRef}
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        position: 'relative',
        height: videoUrl && !isRenderingVideo ? '60px' : 'auto',
      }}
    >
      {(isLoading || isRenderingVideo) && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            position: 'relative',
            width: '60%',
            flexDirection: 'column',
            borderRadius: '16px',
            border: '1px solid var(--salv-dark-2)',
            aspectRatio: 5.5,
            opacity: '.7',
          }}
        >
          <h4 style={{ marginBottom: '0px', marginTop: '8px' }}>
            {webAudio ? 'Loading Call Audio' : 'Loading Phone Call'}
          </h4>
          <Loader type='dots' size='sm' />
        </div>
      )}
      {videoUrl && renderDelayPassed && (
        <video
          key={videoUrl}
          onTimeUpdate={(e) => onTimeUpdate?.(e.currentTarget.currentTime)}
          controls
          ref={videoRef}
          style={videoStyles}
          onLoadedData={() => setIsRenderingVideo(false)}
        >
          <source src={videoUrl} type='video/mp4' />
          Your browser does not support the video tag.
        </video>
      )}
    </div>
  );
};

const PhoneCallRecordingPlayer: React.FC<VideoPlayerProps> = ({
  callId,
  seekTimestamp,
  webAudio,
  onTimeUpdate,
  recordingURL,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        fontSize: '14px',
        width: '100%',
        maxWidth: '800px',
        margin: '0 auto',
      }}
    >
      {(!!callId || !!recordingURL) && (
        <VideoPlayer
          callId={callId}
          seekTimestamp={seekTimestamp}
          webAudio={webAudio}
          onTimeUpdate={onTimeUpdate}
          recordingURL={recordingURL}
        />
      )}
    </div>
  );
};

export default PhoneCallRecordingPlayer;
