import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Avatar,
  Group,
  Menu,
  rem,
  ScrollArea,
  Text,
  Tooltip,
} from '@mantine/core';
import {
  IconCalendar,
  IconChartBar,
  IconClipboardList,
  IconDatabaseSearch,
  IconFileDescription,
  IconLogout,
  IconReceipt2,
  IconSelector,
  IconSettings,
  IconMenu2,
} from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import { useStytchB2BClient } from '@stytch/react/b2b';
import { useLocation, useNavigate } from 'react-router-dom';
import { clearLocalStorage } from 'utils/userInfoUtils';
import { useIsMobile } from 'pages/web-call/components/useIsMobile';
import { useIsInternal } from 'utils/useUserSession';

import { usePermissions } from '../../hooks/usePermissions';
import CalendarModal from './components/CalendarModal';
import classes from './SideNavBar.module.css';

const COLLAPSE_THRESHOLD = 1400; // Note: threshold here AND css file

export default function SideNavBar() {
  const [active, setActive] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const firstName = localStorage.getItem('firstName');
  const lastName = localStorage.getItem('lastName');
  const email = localStorage.getItem('email') || '';
  const isMobile = useIsMobile();

  const { canAccessAdmin } = usePermissions();

  const [isCollapsed, setIsCollapsed] = useState(
    window.innerWidth <= COLLAPSE_THRESHOLD
  );

  const isInternal = useIsInternal();

  useEffect(() => {
    const handleResize = () => {
      setIsCollapsed(window.innerWidth <= COLLAPSE_THRESHOLD);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial state based on window width

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const data = useMemo(() => {
    const baseData = [
      { path: '/scripts', label: 'Jobs', icon: IconClipboardList },
    ];

    if (isInternal) {
      baseData.push(
        {
          path: '/analytics',
          label: 'Analytics',
          icon: IconChartBar,
        },
        { path: '/sourcing', label: 'Sourcing', icon: IconDatabaseSearch },
        { path: '/job-posting-tips', label: 'Job Tips', icon: IconReceipt2 },
        {
          path: '/resume-editor',
          label: 'Resume',
          icon: IconFileDescription,
        }
      );
    }
    if (canAccessAdmin) {
      baseData.push({
        path: '/settings',
        label: 'Admin',
        icon: IconSettings,
      });
    }

    return baseData;
  }, [isInternal, canAccessAdmin]);

  // Set active based on the current route
  useEffect(() => {
    const currentPath = location.pathname;
    const currentItem = data.find((item) => currentPath.startsWith(item.path));
    if (currentItem) {
      setActive(currentItem.label);
    } else if (currentPath === '/') {
      setActive('Jobs');
    }
  }, [location.pathname, data]);

  const [
    calendarModalOpened,
    { open: openCalendarModal, close: closeCalendarModal },
  ] = useDisclosure(false);

  const links = data.map((item) => {
    const linkContent = (
      <div
        className={classes.link}
        data-active={item.label === active || undefined}
        key={item.label}
        onClick={() => {
          setActive(item.label);
          navigate(item.path);
        }}
      >
        <item.icon className={classes.linkIcon} stroke={1.5} />
        <span>{item.label}</span>
      </div>
    );

    return isCollapsed ? (
      <Tooltip
        label={item.label}
        position='right'
        withArrow
        transitionProps={{ duration: 0 }}
        key={item.label}
        offset={10}
      >
        {linkContent}
      </Tooltip>
    ) : (
      linkContent
    );
  });

  const stytch = useStytchB2BClient();
  const logout = useCallback(async () => {
    await stytch.session.revoke();
    clearLocalStorage();
  }, [stytch]);

  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };

  return (
    <div style={{ display: 'flex' }}>
      {isMobile ? (
        <Menu
          shadow='md'
          width={200}
          zIndex={99999}
          position='bottom-start'
          offset={0}
        >
          <Menu.Target>
            {/* <Group gap='xs' className={classes['user-menu']}>
              <div
                style={{
                  display: 'flex',
                  gap: '10px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Avatar
                  color='blue'
                  size='sm'
                  name={firstName + ' ' + lastName}
                />
                <div className={classes.email}>
                  <Text size='xs' style={{ whiteSpace: 'nowrap' }}>
                    {email && email.length > 13
                      ? `${email.slice(0, 13)}...`
                      : email}
                  </Text>
                </div>

                <IconSelector
                  color='var(--salv-dark-5)'
                  stroke={1.2}
                  style={{ paddingRight: '6px', marginLeft: 'auto' }}
                  className={classes.icon_selector}
                />
              </div>
            </Group> */}
            <IconMenu2 style={{ marginTop: '10px', marginLeft: '10px' }} />
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Label>Pages</Menu.Label>
            {data.map((item) => (
              <Menu.Item
                leftSection={<item.icon />}
                key={item.label}
                onClick={() => {
                  setActive(item.label);
                  navigate(item.path);
                }}
              >
                {item.label}
              </Menu.Item>
            ))}
            <Menu.Label>Account</Menu.Label>
            <Menu.Item
              leftSection={<IconCalendar />}
              onClick={openCalendarModal}
            >
              Calendar Integration
            </Menu.Item>

            <Menu.Item
              color='red'
              leftSection={<IconLogout />}
              onClick={handleLogout}
            >
              Logout
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      ) : (
        <nav className={classes.navbar}>
          <div className={classes.navbarMain}>
            <Group
              className={classes.header}
              justify='space-between'
              style={{ textAlign: 'center' }}
            >
              <div className={classes.sideNavBarHeader}>
                <img
                  className={classes.full_logo} // Use CSS module class here
                  src='/imgs/salv_full_logo_further.png'
                  alt='Salv Full Logo'
                />
                <img
                  className={classes.small_logo} // Use CSS module class here
                  src='/imgs/salv_logo_just_squares.png'
                  alt='Salv Logo Just Squares'
                />
              </div>
            </Group>
            <ScrollArea className={classes.links}>{links}</ScrollArea>
          </div>

          <div className={classes.footer}>
            <Menu width={200} position='top-start' offset={18}>
              <Menu.Target>
                <Group gap='xs' className={classes['user-menu']}>
                  <div
                    style={{
                      display: 'flex',
                      gap: '10px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <Avatar
                      color='blue'
                      size='sm'
                      name={firstName + ' ' + lastName}
                    />
                    <div className={classes.email}>
                      <Text size='xs'>
                        {email && email.length > 13
                          ? `${email.slice(0, 13)}...`
                          : email}
                      </Text>
                    </div>

                    <IconSelector
                      color='var(--salv-dark-5)'
                      stroke={1.2}
                      style={{ paddingRight: '6px', marginLeft: 'auto' }}
                      className={classes.icon_selector}
                    />
                  </div>
                </Group>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Label>Account</Menu.Label>
                <Menu.Item
                  leftSection={
                    <IconCalendar style={{ width: rem(14), height: rem(14) }} />
                  }
                  onClick={openCalendarModal}
                >
                  Calendar Integration
                </Menu.Item>

                <Menu.Item
                  color='red'
                  leftSection={
                    <IconLogout style={{ width: rem(14), height: rem(14) }} />
                  }
                  onClick={handleLogout}
                >
                  Logout
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </div>
        </nav>
      )}
      <CalendarModal
        opened={calendarModalOpened}
        onClose={closeCalendarModal}
      />
    </div>
  );
}
