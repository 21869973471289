import React from 'react';
import { Checkbox, Menu } from '@mantine/core';

import { UnreadStatusFilterState } from './types';
import FilterPill from './FilterPill';

const UNREAD_STATUSES = ['UNREAD_CALLS', 'UNREAD_SMS', 'UNREAD_EMAIL'];

const UNREAD_STATUS_LABELS: Record<string, string> = {
  UNREAD_CALLS: 'Unread Calls',
  UNREAD_SMS: 'Unread SMS',
  UNREAD_EMAIL: 'Unread Email',
};

interface ComponentProps {
  onClear: () => void;
  onClose: () => void;
  unreadStatusFilterState: UnreadStatusFilterState;
}

export default function UnreadStatusFilter({
  onClear,
  onClose,
  unreadStatusFilterState,
}: ComponentProps) {
  // Defensive check to prevent runtime errors
  if (!unreadStatusFilterState) {
    console.error('unreadStatusFilterState is undefined');
    return null;
  }

  const { selectedStatuses, onChangeSelectedStatuses } =
    unreadStatusFilterState;

  const filterLabel = selectedStatuses.length
    ? String(selectedStatuses.length)
    : undefined;

  const handleItemClick = (status: string) => () => {
    if (selectedStatuses.includes(status)) {
      onChangeSelectedStatuses(selectedStatuses.filter((s) => s !== status));
    } else {
      onChangeSelectedStatuses([...selectedStatuses, status]);
    }
  };

  const handleSelectAll = () => {
    if (selectedStatuses.length === 0) {
      onChangeSelectedStatuses(UNREAD_STATUSES);
    } else {
      onChangeSelectedStatuses([]);
    }
  };

  return (
    <FilterPill
      title='Unread Status'
      label={filterLabel}
      onClear={onClear}
      onClose={onClose}
    >
      <Menu.Item
        leftSection={
          <Checkbox
            size='xs'
            checked={selectedStatuses.length > 0}
            indeterminate={
              selectedStatuses.length > 0 &&
              UNREAD_STATUSES.length !== selectedStatuses.length
            }
            readOnly
          />
        }
        onClick={handleSelectAll}
      >
        Select all
      </Menu.Item>
      <Menu.Divider />
      {UNREAD_STATUSES.map((status) => (
        <Menu.Item
          key={status}
          leftSection={
            <Checkbox
              size='xs'
              checked={selectedStatuses.includes(status)}
              readOnly
            />
          }
          onClick={handleItemClick(status)}
        >
          {UNREAD_STATUS_LABELS[status]}
        </Menu.Item>
      ))}
    </FilterPill>
  );
}
