import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Text, Skeleton, Popover } from '@mantine/core';
import './CampaignDetailStatsRow.css';
import env from 'env';
import { convertSecondsToHoursMinutes } from 'utils/dateUtils';
import { IconHelp } from '@tabler/icons-react';
import { useDebouncedCallback } from '@mantine/hooks';

interface CandidateStats {
  phone_number: string;
  calls_attempted: number;
  sum_call_length_sec: number;
  sms_count: number;
  email_count: number;
}

interface CampaignDetailStats {
  by_candidate: Record<string, CandidateStats>;
  summary: {
    total_candidates: number;
    total_sms_sent: number;
    total_emails_sent: number;
  };
}

interface CampaignDetailStatsRowProps {
  campaignId: string;
  isWebCall: boolean;
  searchValue?: string;
  candidateIds?: string[]; // Optional array of candidate IDs to filter by
  activeFilters?: any[] | undefined;
}

const SECONDS_SAVED_PER_CALL_ATTEMPT = 60;
const SECONDS_PER_MSG_SENT = 30;
const SECONDS_PER_EMAIL_SENT = 30;

const getTimeSaved = (s: any) => {
  const call_attempt_seconds =
    s.calls_attempted * SECONDS_SAVED_PER_CALL_ATTEMPT;
  const msgs_seconds = s.total_sms_sent * SECONDS_PER_MSG_SENT;
  const call_time = s.sum_call_length_sec;
  const emails_seconds = s.total_emails_sent * SECONDS_PER_EMAIL_SENT;
  // TODO: number of emails
  const total_seconds =
    call_attempt_seconds + msgs_seconds + call_time + emails_seconds;

  return convertSecondsToHoursMinutes(total_seconds);
};

type StatItemProps = {
  value: number;
  label: string;
  explanation?: React.ReactNode; // Make explanation optional
};

const StatItem: React.FC<StatItemProps> = ({ value, label, explanation }) => {
  return (
    <div className='stat-item'>
      <Text fw={600} color='var(--mantine-color-blue-6)' size='md'>
        {value}
      </Text>
      <Text
        size='sm'
        fw={500}
        className='stat-item-text'
        c='var(--salv-dark-9)'
      >
        {label}
      </Text>
      {explanation && <>{explanation}</>}
    </div>
  );
};

const CampaignDetailStatsRow: React.FC<CampaignDetailStatsRowProps> = ({
  campaignId,
  isWebCall,
  candidateIds,
  activeFilters,
}) => {
  const [stats, setStats] = useState<CampaignDetailStats | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const isFiltered = activeFilters && activeFilters?.length > 0;

  const debouncedFetchStats = useDebouncedCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${env.REACT_APP_SERVER_URL}/campaigns/campaign/${campaignId}/stats`,
        {
          orgId: localStorage.getItem('orgId'),
          userId: localStorage.getItem('userId'),
          sessionId: localStorage.getItem('sessionId'),
        }
      );
      setStats(response.data.campaign_stats);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  }, 500);

  useEffect(() => {
    if (campaignId) {
      debouncedFetchStats();
    }
  }, [campaignId, debouncedFetchStats]);

  if (isLoading || !stats) {
    // TODO: make an outer container with a fixed height
    return (
      <div
        className='stat-row-container'
        style={{ paddingTop: '26px', paddingBottom: '25px', width: '220px' }}
      >
        <Skeleton height={8} radius='xl' width='100%' />
      </div>
    );
  }

  // Calculate totals from the per-candidate data
  const calculateTotals = () => {
    const byCandidate = stats?.by_candidate || {};

    // Determine which candidate IDs to include (filter if provided, otherwise use all)
    let candidateIdsToInclude = Object.keys(byCandidate);
    if (candidateIds && candidateIds.length > 0) {
      // Filter to only the requested candidate IDs that exist in our data
      candidateIdsToInclude = candidateIdsToInclude.filter((id) =>
        candidateIds.includes(id)
      );
    }

    // Sum up calls attempted and call length for the filtered candidates
    const totalCallsAttempted = candidateIdsToInclude.reduce(
      (sum, id) => sum + (byCandidate[id]?.calls_attempted || 0),
      0
    );

    const totalCallLengthSec = candidateIdsToInclude.reduce(
      (sum, id) => sum + (byCandidate[id]?.sum_call_length_sec || 0),
      0
    );

    // Sum up SMS counts for the filtered candidates
    const totalSmsSent = candidateIdsToInclude.reduce(
      (sum, id) => sum + (byCandidate[id]?.sms_count || 0),
      0
    );

    const totalEmailsSent = candidateIdsToInclude.reduce(
      (sum, id) => sum + (byCandidate[id]?.email_count || 0),
      0
    );

    // For total candidates, use either the filtered count or the summary value
    const totalCandidates =
      candidateIds && candidateIds.length > 0
        ? candidateIdsToInclude.length
        : stats?.summary.total_candidates || 0;

    return {
      calls_attempted: totalCallsAttempted,
      sum_call_length_sec: totalCallLengthSec,
      total_sms_sent: totalSmsSent,
      total_emails_sent: totalEmailsSent,
      total_candidates: totalCandidates,
    };
  };

  const totals = calculateTotals();

  function AlignedText({ leftContent, rightContent }) {
    return (
      <div className='aligned-text-container'>
        <b className='aligned-text-left'>{leftContent}</b>
        <span className='aligned-text-right'>{rightContent}</span>
      </div>
    );
  }

  const renderForumla = () => {
    return (
      <Popover width={200} position='bottom' shadow='md'>
        <Popover.Target>
          <IconHelp
            size={16}
            style={{
              marginLeft: '-3px',
              position: 'relative',
              top: '0px',
            }}
            className='help-icon'
            color='var(--salv-dark-6)'
          />
        </Popover.Target>
        <Popover.Dropdown
          style={{ width: 'calc(10.5rem * var(--mantine-scale))' }}
        >
          <AlignedText
            leftContent={convertSecondsToHoursMinutes(
              totals.sum_call_length_sec
            )}
            rightContent='Calling'
          />
          {!isWebCall && (
            <AlignedText
              leftContent={convertSecondsToHoursMinutes(
                totals.total_sms_sent * SECONDS_PER_MSG_SENT
              )}
              rightContent='Messaging'
            />
          )}
          {isWebCall && (
            <AlignedText
              leftContent={convertSecondsToHoursMinutes(
                totals.total_emails_sent * SECONDS_PER_EMAIL_SENT
              )}
              rightContent='Emailing'
            />
          )}
          {!isWebCall && (
            <AlignedText
              leftContent={convertSecondsToHoursMinutes(
                totals.calls_attempted * SECONDS_SAVED_PER_CALL_ATTEMPT
              )}
              rightContent='Call Attempts'
            />
          )}
        </Popover.Dropdown>
      </Popover>
    );
  };

  return (
    <div className='stat-row-container'>
      <div className='campaign-detail-stats-row'>
        <StatItem
          value={totals.total_candidates}
          label={totals.total_candidates === 1 ? 'Candidate' : 'Candidates'}
        />
        <StatItem
          value={totals.calls_attempted}
          label={totals.calls_attempted === 1 ? 'Call' : 'Calls'}
        />
        {!isFiltered && !isWebCall && (
          <StatItem
            value={totals.total_sms_sent}
            label={totals.total_sms_sent === 1 ? 'Message' : 'Messages'}
          />
        )}
        {!isFiltered && isWebCall && (
          <StatItem
            value={totals.total_emails_sent}
            label={totals.total_emails_sent === 1 ? 'Email' : 'Emails'}
          />
        )}
        {!isFiltered && (
          <StatItem
            value={getTimeSaved(totals)}
            label='Saved'
            explanation={renderForumla()}
          />
        )}
      </div>
    </div>
  );
};

export default CampaignDetailStatsRow;
