import { CallLengthFilterState } from 'pages/job-posting-tips/components/types';
import { useState } from 'react';

const DEFAULT_MIN_LENGTH = 0;
const DEFAULT_MAX_LENGTH = 3600; // 1 hour in seconds

export type CallLengthSelectionMode = 'less' | 'greater' | 'between';

export default function useCallLengthFilterState(): CallLengthFilterState {
  const [minLength, setMinLength] = useState(DEFAULT_MIN_LENGTH);
  const [maxLength, setMaxLength] = useState(DEFAULT_MAX_LENGTH);
  const [selectionMode, setSelectionMode] =
    useState<CallLengthSelectionMode>('between');

  const clear = () => {
    setMinLength(DEFAULT_MIN_LENGTH);
    setMaxLength(DEFAULT_MAX_LENGTH);
    setSelectionMode('between');
  };

  const isEmpty =
    minLength === DEFAULT_MIN_LENGTH &&
    maxLength === DEFAULT_MAX_LENGTH &&
    selectionMode === 'between';

  return {
    minLength,
    maxLength,
    selectionMode,
    onChangeMinLength: setMinLength,
    onChangeMaxLength: setMaxLength,
    onChangeSelectionMode: setSelectionMode,
    clear,
    isEmpty,
  };
}
