import React from 'react';
import {
  Group,
  Input,
  NumberInput,
  RangeSlider,
  Slider,
  Stack,
  Radio,
} from '@mantine/core';

import { NumberOfCallsFilterState } from './types';
import FilterPill from './FilterPill';

const MIN_CALLS = 0;
const MAX_CALLS = 20;

interface ComponentProps {
  onClear: () => void;
  onClose: () => void;
  numberOfCallsFilterState: NumberOfCallsFilterState;
}

export default function NumberOfCallsFilter({
  onClear,
  onClose,
  numberOfCallsFilterState: {
    minCalls,
    maxCalls,
    selectionMode,
    onChangeMinCalls,
    onChangeMaxCalls,
    onChangeSelectionMode,
  },
}: ComponentProps) {
  const filterLabel = (() => {
    switch (selectionMode) {
      case 'less':
        return `< ${maxCalls} calls`;
      case 'greater':
        return `> ${minCalls} calls`;
      case 'between':
        return `${minCalls} - ${maxCalls} calls`;
    }
  })();

  const handleChangeSelectionMode = (value: string) => {
    const mode = value as 'less' | 'greater' | 'between';
    if (mode === 'less') {
      onChangeMinCalls(MIN_CALLS);
      if (!maxCalls) onChangeMaxCalls(5);
    } else if (mode === 'greater') {
      if (!minCalls) onChangeMinCalls(5);
      onChangeMaxCalls(MAX_CALLS);
    }
    onChangeSelectionMode(mode);
  };

  return (
    <FilterPill
      title='Number of Calls'
      label={filterLabel}
      onClear={onClear}
      onClose={onClose}
    >
      <Stack gap='xs' p='sm'>
        <Radio.Group value={selectionMode} onChange={handleChangeSelectionMode}>
          <Group>
            <Radio
              label='Less than'
              value='less'
              style={{ cursor: 'pointer' }}
            />
            <Radio
              label='Between'
              value='between'
              style={{ cursor: 'pointer' }}
            />
            <Radio
              label='Greater than'
              value='greater'
              style={{ cursor: 'pointer' }}
            />
          </Group>
        </Radio.Group>

        {selectionMode === 'between' ? (
          <>
            <Group justify='space-between'>
              <Input.Label>Min Calls</Input.Label>
              <NumberInput
                w='50%'
                min={MIN_CALLS}
                max={maxCalls}
                value={minCalls}
                onChange={(val) => onChangeMinCalls(Number(val))}
              />
            </Group>
            <Group justify='space-between'>
              <Input.Label>Max Calls</Input.Label>
              <NumberInput
                w='50%'
                min={minCalls}
                max={MAX_CALLS}
                value={maxCalls}
                onChange={(val) => onChangeMaxCalls(Number(val))}
              />
            </Group>
            <RangeSlider
              mb='lg'
              min={MIN_CALLS}
              max={MAX_CALLS}
              marks={[
                { value: 5, label: '5' },
                { value: 10, label: '10' },
                { value: 15, label: '15' },
              ]}
              value={[minCalls, maxCalls] as [number, number]}
              onChange={([min, max]) => {
                onChangeMinCalls(min);
                onChangeMaxCalls(max);
              }}
            />
          </>
        ) : (
          <>
            <Group justify='space-between'>
              <Input.Label>
                {selectionMode === 'less' ? 'Max Calls' : 'Min Calls'}
              </Input.Label>
              <NumberInput
                w='50%'
                min={MIN_CALLS}
                max={MAX_CALLS}
                value={selectionMode === 'less' ? maxCalls : minCalls}
                onChange={(val) =>
                  selectionMode === 'less'
                    ? onChangeMaxCalls(Number(val))
                    : onChangeMinCalls(Number(val))
                }
              />
            </Group>
            <Slider
              mb='lg'
              min={MIN_CALLS}
              max={MAX_CALLS}
              marks={[
                { value: 5, label: '5' },
                { value: 10, label: '10' },
                { value: 15, label: '15' },
              ]}
              value={selectionMode === 'less' ? maxCalls : minCalls}
              onChange={(val) =>
                selectionMode === 'less'
                  ? onChangeMaxCalls(val)
                  : onChangeMinCalls(val)
              }
              inverted={selectionMode === 'greater'}
            />
          </>
        )}
      </Stack>
    </FilterPill>
  );
}
