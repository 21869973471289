import {
  ActionIcon,
  Box,
  Button,
  Loader,
  Modal,
  ScrollArea,
  Tabs,
  Text,
  Textarea,
  TextInput,
  Tooltip,
  Switch,
} from '@mantine/core';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { RequirementGrade } from 'hooks/useGetTranscriptData';
import WebCallRecordingPlayer from 'components/transcript/WebCallRecordingPlayer';
import PhoneCallRecordingPlayer from 'components/transcript/PhoneCallRecordingPlayerV2';
import { MIN_VISIBLE_TRANSCRIPT_HEIGHT } from 'components/transcript/constants';
import TranscriptLines from 'components/transcript/TranscriptLines';
import { formatToLocalTime } from 'utils/dateUtils';
import { TranscriptMessage } from 'components/transcript/types';
import env from 'env';
import axios from 'api/axiosConfig';
import { notifications } from '@mantine/notifications';
import AutoScrollButton from 'components/transcript/AutoScrollButton';
import { useParams } from 'react-router-dom';
import { IconEdit } from '@tabler/icons-react';
import { useIsMobile } from 'pages/web-call/components/useIsMobile';
import TranscriptSummary from 'components/transcript/TranscriptSummary';

export const TabOptions = {
  CALL: 'Call',
  SUMMARY: 'Summary',
} as const;

export type Tab = (typeof TabOptions)[keyof typeof TabOptions];
const isTab = (value: any): value is Tab => {
  return Object.values(TabOptions).includes(value);
};

const SubmittalReportsPage = ({ isLoggedIn }) => {
  const isMobile = useIsMobile();
  const [activeTab, setActiveTab] = useState<Tab>(
    isMobile ? TabOptions.SUMMARY : TabOptions.CALL
  );
  const [isLoading, setIsLoading] = useState(true);
  const [candidate, setCandidate] = useState<any>({});
  const [call, setCall] = useState<any>({});
  const [campaignOrgId, setCampaignOrgId] = useState<string | null>(null);
  const [transcript, setTranscript] = useState<TranscriptMessage[]>([]);
  const [requirementGradesList, setRequirementGradesList] = useState<
    RequirementGrade[]
  >([]);
  const [summary, setSummary] = useState<string>('');
  const [seekTimestamp, setSeekTimestamp] = useState<number | null>(null);
  const [userScrolling, setUserScrolling] = useState(false);
  const [requirementToEdit, setRequirementToEdit] = useState<number | null>(
    null
  );
  const [openEditRequirementModal, setOpenEditRequirementModal] =
    useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [showTranslated, setShowTranslated] = useState(false);
  const [needsTranslation, setNeedsTranslation] = useState(false);
  const [isTranslating, setIsTranslating] = useState(false);
  const [translatedTranscript, setTranslatedTranscript] = useState<
    TranscriptMessage[] | string
  >([]);
  const [
    translatedRequirementsGradesList,
    setTranslatedRequirementsGradesList,
  ] = useState<RequirementGrade[]>([]);
  const [showPreview, setShowPreview] = useState(false);
  const orgId = localStorage.getItem('orgId') || '';

  const showActions = isLoggedIn && campaignOrgId === orgId;

  const scrollingProgrammatically = useRef(false);

  const { candidateId, campaignId, callId } = useParams();

  const [columnHeight, setColumnHeight] = useState(0);
  const outerContainerRef = useRef<HTMLDivElement>(null);
  const [videoHeight, setVideoHeight] = useState(0);
  const [currentTimeMs, setCurrentTimeMs] = useState(0);
  const onTimestampClick = (timestamp: number | null) => {
    if (timestamp !== null) {
      setSeekTimestamp(timestamp);
      // Force programmatic scroll & highlight
      setUserScrolling(false);
    }
  };

  useEffect(() => {
    const fetchReportData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `${env.REACT_APP_SERVER_URL}/candidate/${candidateId}/campaign/${campaignId}/call/${callId}/submittal_report`
        );
        const {
          transcript,
          requirement_grades_list,
          candidate,
          call,
          org_id,
          summary,
          needs_translation,
        } = response.data;
        setTranscript(transcript);
        setRequirementGradesList(requirement_grades_list);
        setCandidate(candidate);
        setCall(call);
        setCampaignOrgId(org_id);
        setSummary(summary);
        setNeedsTranslation(needs_translation);
      } catch (error) {
        console.error('Error fetching report data:', error);
        notifications.show({
          title: 'There was an error fetching the report data',
          message: error instanceof Error ? error.message : '',
          color: 'red',
        });
      } finally {
        setIsLoading(false);
      }
    };
    fetchReportData();
  }, [callId, campaignId, candidateId]);

  const videoContainerObserverRef = useRef<ResizeObserver | null>(null);
  const videoContainerCallbackRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (videoContainerObserverRef.current) {
        videoContainerObserverRef.current.disconnect();
        videoContainerObserverRef.current = null;
      }
      if (node) {
        const observer = new ResizeObserver(([entry]) => {
          setVideoHeight(entry.contentRect.height);
        });
        observer.observe(node);
        setVideoHeight(node.getBoundingClientRect().height);
        videoContainerObserverRef.current = observer;
      }
    },
    []
  );

  const handleSetTranslatedData = ({
    translatedTranscript,
    translatedRequirementsGradesList,
  }) => {
    setTranslatedTranscript(translatedTranscript);
    setTranslatedRequirementsGradesList(translatedRequirementsGradesList);
    setShowTranslated(true);
  };

  const handleTranslateTranscript = async (e) => {
    e.stopPropagation();
    if (isTranslating) {
      return;
    }

    if (showTranslated) {
      setShowTranslated(false);
      return;
    }

    try {
      setIsTranslating(true);
      const response = await axios.post(
        `${env.REACT_APP_SERVER_URL}/translate_transcript`,
        {
          transcript,
          requirementGradesList,
          candidateQuestions: [],
        }
      );

      const { translated_transcript, translated_requirement_grades_list } =
        response.data;

      handleSetTranslatedData({
        translatedTranscript: translated_transcript,
        translatedRequirementsGradesList: translated_requirement_grades_list,
      });
    } catch (error) {
      console.error('Error translating transcript:', error);
      notifications.show({
        title: 'There was an error translating the transcript',
        message: error instanceof Error ? error.message : '',
        color: 'red',
      });
    } finally {
      setIsTranslating(false);
    }
  };
  const transcriptToUse = showTranslated ? translatedTranscript : transcript;
  const requirementGradesListToUse = showTranslated
    ? translatedRequirementsGradesList
    : requirementGradesList;

  const handleTimeUpdate = (currentTime: number) => {
    setCurrentTimeMs(currentTime * 1000);
  };

  const scrollableElementRef = useRef<HTMLDivElement | null>(null);
  const scrollableCallbackRef = useCallback((node: HTMLDivElement | null) => {
    const onUserScrollStart = () => {
      setUserScrolling(true);
    };
    if (scrollableElementRef.current) {
      const oldNode = scrollableElementRef.current;
      oldNode.removeEventListener('wheel', onUserScrollStart);
      oldNode.removeEventListener('touchstart', onUserScrollStart);
      oldNode.removeEventListener('touchmove', onUserScrollStart);
      oldNode.removeEventListener('keydown', onUserScrollStart);
    }
    if (node) {
      node.addEventListener('wheel', onUserScrollStart, { passive: true });
      node.addEventListener('touchstart', onUserScrollStart, { passive: true });
      node.addEventListener('touchmove', onUserScrollStart, { passive: true });
      node.addEventListener('keydown', onUserScrollStart);
      scrollableElementRef.current = node;
    } else {
      scrollableElementRef.current = null;
    }
  }, []);

  const resizeObserverRef = useRef<ResizeObserver | null>(null);
  const measuredRef = useCallback((node: HTMLDivElement | null) => {
    if (resizeObserverRef.current) {
      resizeObserverRef.current.disconnect();
      resizeObserverRef.current = null;
    }
    if (node) {
      const observer = new ResizeObserver(([entry]) => {
        setColumnHeight(entry.contentRect.height);
      });
      observer.observe(node);
      // measure once
      setColumnHeight(node.getBoundingClientRect().height);
      resizeObserverRef.current = observer;
    }
  }, []);

  const onResumeAutoScroll = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setUserScrolling(false);
    },
    []
  );

  const handleOpenEditModal = (index: number) => {
    setRequirementToEdit(index);
    setOpenEditRequirementModal(true);
  };

  const handleCloseEditModal = () => {
    setRequirementToEdit(null);
    setOpenEditRequirementModal(false);
  };

  const handleEditRequirement = async (
    editedRequirement: RequirementGrade,
    index: number
  ) => {
    try {
      setIsEditing(true);
      await axios.patch(
        `${env.REACT_APP_SERVER_URL}/candidate/${candidateId}/campaign/${campaignId}/call/${callId}/submittal_report/edit`,
        {
          requirement_grades_list: requirementGradesList.map(
            (requirement, i) => {
              if (i === index) {
                return editedRequirement;
              }
              return requirement;
            }
          ),
        }
      );

      setRequirementGradesList((prev) => {
        const newRequirements = [...prev];
        newRequirements[index] = editedRequirement;
        return newRequirements;
      });
      handleCloseEditModal();
    } catch (e) {
      console.error('Error editing requirement:', e);
      notifications.show({
        title: 'There was an error editing the requirement',
        message: e instanceof Error ? e.message : '',
        color: 'red',
      });
    } finally {
      setIsEditing(false);
    }
  };

  const webCall = call?.web_call || false;
  const lastCalled = call.last_updated || '';

  const handleTogglePreview = () => {
    setShowPreview(!showPreview);
  };

  if (isLoading) {
    return (
      <div
        className='page-container-common'
        style={{
          display: 'flex',
        }}
      >
        <div
          style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Loader size='md' type='dots' />
        </div>
      </div>
    );
  }
  return (
    <>
      {showPreview && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            // background: 'var(--salv-dark-0)',
            background: 'white',
          }}
        />
      )}
      <div
        className='page-container-common'
        style={{
          display: 'flex',
          width: isMobile ? 'unset' : '100%',
          zIndex: 2 /* Ensure it's above the preview overlay */,
        }}
      >
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: isMobile ? '100%' : '55%',
            height: '90vh',
            padding: '10px 10px 0px 10px',
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          <Tabs
            variant='pills'
            value={activeTab}
            onChange={(value) => {
              if (isTab(value)) setActiveTab(value);
            }}
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              position: 'relative',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingTop: '6px',
                height: '100%',
                position: 'relative',
              }}
            >
              <Tabs.List>
                {isMobile && (
                  <Tabs.Tab
                    value={TabOptions.SUMMARY}
                    classNames={{
                      tab: 'header-tab',
                    }}
                  >
                    Summary
                  </Tabs.Tab>
                )}
                <Tabs.Tab
                  value={TabOptions.CALL}
                  classNames={{
                    tab: 'header-tab',
                  }}
                >
                  {!webCall ? 'Phone Call' : 'Video Call'}
                </Tabs.Tab>
              </Tabs.List>
              <Tabs.Panel
                value={TabOptions.CALL}
                style={{ height: 'calc(100% - 40px)', padding: 0, margin: 0 }}
              >
                <div
                  style={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '12px',
                    padding: '10px',
                    paddingTop: '2px',
                    width: '100%',
                    height: webCall ? 'calc(100%)' : 'calc(100%)',
                  }}
                >
                  <div
                    className={
                      columnHeight < MIN_VISIBLE_TRANSCRIPT_HEIGHT
                        ? 'scrollable-container overflow-y-auto'
                        : 'scrollable-container'
                    }
                    ref={outerContainerRef}
                  >
                    <div
                      ref={videoContainerCallbackRef}
                      className={
                        webCall && columnHeight > MIN_VISIBLE_TRANSCRIPT_HEIGHT
                          ? 'video-container-sticky'
                          : ''
                      }
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: isMobile ? 'column' : 'row',
                          alignItems: isMobile ? 'flex-start' : 'center',
                          justifyContent: 'space-between',
                          marginTop: '6px',
                          height: '100%',
                        }}
                      >
                        {lastCalled && (
                          <div style={{ padding: '8px', fontSize: '12px' }}>
                            {`Call from ${formatToLocalTime(lastCalled, true)}`}
                          </div>
                        )}
                        {needsTranslation && (
                          <Fragment>
                            {isTranslating ? (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'baseline',
                                  gap: '2px',
                                }}
                              >
                                <Text c='blue' size='xs' fw={600}>
                                  Translating
                                </Text>
                                <Loader
                                  size='xs'
                                  style={{ marginRight: '8px' }}
                                  type='dots'
                                  color='blue'
                                />
                              </div>
                            ) : (
                              <Button
                                size='xs'
                                variant='subtle'
                                onClick={handleTranslateTranscript}
                              >
                                {showTranslated
                                  ? 'Translated - Show Original'
                                  : 'Translate to English'}
                              </Button>
                            )}
                          </Fragment>
                        )}
                      </div>
                      {webCall ? (
                        <WebCallRecordingPlayer
                          callId={callId}
                          seekTimestamp={seekTimestamp}
                          testCall={false}
                          onTimeUpdate={handleTimeUpdate}
                          recordingURL={''}
                        />
                      ) : (
                        <PhoneCallRecordingPlayer
                          callId={callId}
                          seekTimestamp={seekTimestamp}
                          webAudio={false}
                          onTimeUpdate={handleTimeUpdate}
                        />
                      )}
                    </div>

                    <div
                      className={
                        columnHeight > MIN_VISIBLE_TRANSCRIPT_HEIGHT
                          ? 'transcript-box overflow-y-auto'
                          : 'transcript-box'
                      }
                      style={{
                        height:
                          columnHeight > MIN_VISIBLE_TRANSCRIPT_HEIGHT
                            ? `calc(100% - ${videoHeight}px)`
                            : 'auto',
                        position: 'absolute',
                      }}
                      ref={scrollableCallbackRef}
                    >
                      <TranscriptLines
                        transcript={transcriptToUse}
                        sms={false}
                        onTimestampClick={onTimestampClick}
                        clickable={true}
                        currentTimeMs={currentTimeMs}
                        userScrolling={userScrolling}
                        scrollingProgrammatically={scrollingProgrammatically}
                        columnHeight={columnHeight}
                        outerContainer={outerContainerRef}
                        autoScroll={true}
                        isTranslating={isTranslating}
                      />
                    </div>

                    {/* Invisible measured box */}
                    <div
                      className={'transcript-box overflow-y-auto'}
                      style={{
                        height: `calc(100% - ${videoHeight}px)`,
                        position: 'absolute',
                        visibility: 'hidden',
                      }}
                      ref={measuredRef}
                    >
                      <TranscriptLines
                        transcript={transcript}
                        sms={false}
                        onTimestampClick={onTimestampClick}
                        clickable={true}
                        currentTimeMs={currentTimeMs}
                        userScrolling={userScrolling}
                        scrollingProgrammatically={scrollingProgrammatically}
                        columnHeight={columnHeight}
                        outerContainer={outerContainerRef}
                        autoScroll={false} // doesn't matter, it's hidden anyway
                        isTranslating={isTranslating}
                      />
                    </div>
                  </div>
                  <AutoScrollButton
                    userScrolling={userScrolling}
                    onResumeAutoScroll={onResumeAutoScroll}
                    styleOverride={{
                      zIndex: 10000000,
                      bottom: '0px',
                    }}
                  />
                  <div
                    className='scroll-fade-bottom'
                    style={{ height: '45px' }}
                  ></div>
                </div>
              </Tabs.Panel>
              {isMobile && (
                <Tabs.Panel
                  value={TabOptions.SUMMARY}
                  style={{ height: 'calc(100% - 40px)', padding: 0, margin: 0 }}
                >
                  <SummarySection
                    candidate={candidate}
                    requirementGradesList={requirementGradesListToUse}
                    showActions={showActions}
                    isTranslating={isTranslating}
                    handleOpenEditModal={handleOpenEditModal}
                    showPreview={showPreview}
                    onTimestampClick={onTimestampClick}
                    candidateId={candidateId}
                    campaignId={campaignId}
                    callId={callId}
                    summary={summary}
                    handleTogglePreview={handleTogglePreview}
                  />
                </Tabs.Panel>
              )}
            </div>
          </Tabs>
        </Box>
        {!isMobile && (
          <SummarySection
            candidate={candidate}
            requirementGradesList={requirementGradesListToUse}
            showActions={showActions}
            isTranslating={isTranslating}
            handleOpenEditModal={handleOpenEditModal}
            showPreview={showPreview}
            onTimestampClick={onTimestampClick}
            candidateId={candidateId}
            campaignId={campaignId}
            callId={callId}
            summary={summary}
            handleTogglePreview={handleTogglePreview}
          />
        )}
        {requirementToEdit !== null && (
          <EditRequirementGradesBoxModal
            openModal={openEditRequirementModal}
            requirement={requirementGradesListToUse[requirementToEdit]}
            requirementIndex={requirementToEdit}
            onClose={handleCloseEditModal}
            onEditRequirement={handleEditRequirement}
            isEditing={isEditing}
          />
        )}
      </div>
    </>
  );
};

const EditRequirementGradesBoxModal = ({
  openModal,
  requirement,
  requirementIndex,
  onClose,
  onEditRequirement,
  isEditing,
}) => {
  const [formRequirement, setRequirement] = useState(requirement);
  const isMobile = useIsMobile();

  const handleChange = (e) => {
    setRequirement({
      ...formRequirement,
      [e.target.name]: e.target.value,
    });
  };

  const handleSaveEditRequirement = async () => {
    await onEditRequirement(
      {
        ...requirement,
        ...formRequirement,
      },
      requirementIndex
    );
  };

  return (
    <Modal
      title='Edit Requirement Grade'
      opened={openModal}
      onClose={onClose}
      size={isMobile ? 'xs' : 'md'}
      centered
      zIndex={99999}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
        }}
      >
        <TextInput
          label='Grade'
          name='grade'
          value={formRequirement.grade}
          onChange={handleChange}
          styles={{
            label: {
              fontSize: isMobile ? '12px' : '14px',
            },
            input: {
              fontSize: isMobile ? '12px' : '14px',
            },
          }}
        />
        <Textarea
          label='Question'
          name='question'
          value={formRequirement.question}
          onChange={handleChange}
          styles={{
            label: {
              fontSize: isMobile ? '12px' : '14px',
            },
            input: {
              fontSize: isMobile ? '12px' : '14px',
            },
          }}
          autosize
          minRows={3}
          maxRows={4}
        />
        <Textarea
          label='Answer'
          name='answer'
          value={formRequirement.answer}
          onChange={handleChange}
          styles={{
            label: {
              fontSize: isMobile ? '12px' : '14px',
            },
            input: {
              fontSize: isMobile ? '12px' : '14px',
            },
          }}
          autosize
          minRows={3}
          maxRows={4}
        />
        <div
          style={{
            display: 'flex',
            gap: '10px',
            alignSelf: 'flex-end',
          }}
        >
          <Button
            size={isMobile ? 'xs' : 'sm'}
            variant='outline'
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            size={isMobile ? 'xs' : 'sm'}
            onClick={handleSaveEditRequirement}
            loading={isEditing}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const SummarySection = ({
  candidate,
  requirementGradesList,
  showActions,
  isTranslating,
  handleOpenEditModal,
  showPreview,
  onTimestampClick,
  candidateId,
  campaignId,
  callId,
  summary,
  handleTogglePreview,
}) => {
  const isMobile = useIsMobile();
  return (
    <div
      style={{
        width: isMobile ? '100%' : '48%',
        height: '100%',
        padding: '10px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          gap: '20px',
        }}
      >
        <div
          style={{
            display: 'flex',
            gap: '17px',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <h4
            className='candidate-name'
            style={{ marginTop: 0, marginBottom: 0 }}
          >
            {candidate.fullName}
          </h4>
          {showActions && (
            <Tooltip
              label='Toggle to show preview of submittal report with no edit functionality'
              position='bottom'
            >
              <Switch
                checked={showPreview}
                onChange={handleTogglePreview}
                size='sm'
                label='Preview Report'
              />
            </Tooltip>
          )}
        </div>
        <TranscriptSummary
          candidateId={candidateId || ''}
          campaignId={campaignId}
          callId={callId}
          summary={summary}
          showEditButton={showActions && !showPreview}
        />
        <ScrollArea
          className='scrollable-content'
          type='scroll'
          style={{
            height: 'calc(95vh)',
          }}
        >
          <div className='scroll-fade-top'></div>
          <div className='inside'>
            {requirementGradesList.map(
              ({ question, answer, grade, ts, title }, index) => (
                <div
                  key={index}
                  className={'qa-item' + (ts ? ' clickable-qa-item' : '')}
                  onClick={() => {
                    if (ts) {
                      onTimestampClick(ts);
                    }
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div style={{ display: 'flex', gap: '10px' }}>
                      <div
                        style={{
                          fontSize: isMobile ? '12px' : '16px',
                          fontWeight: 600,
                          marginRight: '12px',
                          color: isTranslating
                            ? 'var(--mantine-color-dimmed)'
                            : 'var(--mantine-color-blue-5)',
                        }}
                      >
                        {grade}
                      </div>
                      <div
                        style={{
                          fontSize: isMobile ? '12px' : '16px',
                          fontWeight: 700,
                          marginTop: '2px',
                          color: isTranslating
                            ? 'var(--mantine-color-dimmed)'
                            : '',
                        }}
                      >
                        {title || question}
                      </div>
                    </div>
                    {showActions && !showPreview && (
                      <ActionIcon
                        variant='subtle'
                        onClick={() => handleOpenEditModal(index)}
                      >
                        <IconEdit size={16} />
                      </ActionIcon>
                    )}
                  </div>
                  <div
                    style={{
                      fontSize: isMobile ? '12px' : '14px',
                      padding: '8px',
                      paddingBottom: '4px',
                      color: isTranslating ? 'var(--mantine-color-dimmed)' : '',
                    }}
                  >
                    {answer}
                  </div>
                </div>
              )
            )}
          </div>
        </ScrollArea>
      </div>
    </div>
  );
};

export default SubmittalReportsPage;
