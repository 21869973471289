import { Button, Modal, TextInput } from '@mantine/core';
import React, { useState } from 'react';
import env from 'env';
import axios from 'api/axiosConfig';
import { notifications } from '@mantine/notifications';

const NewFolderModal = ({ open, onClose, onAddFolder, folderId }) => {
  const [folderName, setFolderName] = useState('');
  const [isCreatingFolder, setIsCreatingFolder] = useState(false);

  const orgId = localStorage.getItem('orgId');
  const userId = localStorage.getItem('userId');

  const handleCreateFolder = async (folderName: string) => {
    try {
      setIsCreatingFolder(true);
      const response = await axios.post(
        `${env.REACT_APP_SERVER_URL}/create_campaign_folder`,
        {
          orgId,
          folderName,
          userId,
          parentFolderId: folderId,
        }
      );

      const { folder } = response.data;

      onAddFolder(folder);
      onClose();
      setFolderName('');
    } catch (error) {
      console.error('Error creating folder:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to create folder',
        color: 'red',
      });
    } finally {
      setIsCreatingFolder(false);
    }
  };

  const handleChangeFolderName = (event) => {
    setFolderName(event.currentTarget.value);
  };
  return (
    <Modal title='Create New Folder' opened={open} onClose={onClose} size='sm'>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <TextInput
          label='Folder Name'
          onChange={handleChangeFolderName}
          value={folderName}
        />
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}
        >
          <Button variant='outline' onClick={onClose}>
            Cancel
          </Button>
          <Button
            color='blue'
            onClick={(e) => handleCreateFolder(folderName)}
            disabled={!folderName}
            loading={isCreatingFolder}
          >
            Create
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default NewFolderModal;
