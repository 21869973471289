import React, { useState, useEffect, useCallback } from 'react';
import { Button, Switch, Tabs, Loader, Text } from '@mantine/core';
import axios from 'axios';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import {
  IconAlertCircle,
  IconClipboardList,
  IconRocket,
} from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import AddContactsModal from 'components/campaign/AddContactsModal';
import { useAtom, useAtomValue } from 'jotai';
import {
  atsIntegrationJobIdAtom,
  campaignCacheAtom,
  webCallAtom,
} from 'pages/editorv2/atoms';
import { usePermissions } from 'hooks/usePermissions';
import FolderBreadcrumbs from 'components/campaign-folders/FolderBreadcrumbs';
import ATSSyncButton from 'components/campaign/ATSSyncButton';
import QuestionsPage from 'pages/questions/QuestionsPage';
import env from 'env';

import CampaignDetailsPage from '../campaign/details/CampaignDetailsPage';
import EditorPageV2 from '../editorv2/EditorPageV2';
import CampaignMenuDropdown from './components/CampaignMenuDropdown';
import { pauseCampaign, startCampaign } from './actions';
import './ScriptTabsPage.css';
import 'css/common.css';
import { CampaignFolder } from './ScriptsPage';

const TabOptions = {
  SEQUENCE: 'sequence',
  CONTACTS: 'contacts',
  LOADER: 'loader',
  QUESTIONS: 'questions',
} as const;

type Tab = (typeof TabOptions)[keyof typeof TabOptions];

const isTab = (value: any): value is Tab => {
  return Object.values(TabOptions).includes(value);
};

const ScriptTabsPage = () => {
  const [campaignDetailsKey, setCampaignDetailsKey] = useState(0);
  const [canSubmitCampaign, setCanSubmitCampaign] = useState<boolean>(false);
  const userId = localStorage.getItem('userId');

  const { canEditCampaign } = usePermissions();

  const handleContactsRefresh = () => {
    setCampaignDetailsKey((prevKey) => prevKey + 1);
  };

  const navigate = useNavigate();

  const location = useLocation();
  const match = location.pathname.match(
    /\/scripts\/script-editor\/(new(?:-phone|-web)?\/)?([^/]+)/
  );
  const { campaignId: paramCampaignId, folderId: paramFolderId } = useParams();
  const campaignId = match ? match[2] : paramCampaignId || '';
  const isNewWeb = match?.[1]?.includes('web');

  const queryParams = new URLSearchParams(location.search);
  const initialTab: Tab = isTab(queryParams.get('tab') as Tab)
    ? (queryParams.get('tab') as Tab)
    : TabOptions.SEQUENCE;

  const [activeTab, setActiveTab] = useState<Tab>(TabOptions.LOADER);
  const [isCampaignActive, setIsCampaignActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [contactsModalOpen, setContactsModalOpen] = useState(false);
  const [jobTitle, setJobTitle] = useState<string>('');
  const [scriptFetched, setScriptFetched] = useState<boolean>(false);
  const [isWebCall, setIsWebCall] = useState<boolean>(!!isNewWeb);
  const [, setCampaignCache] = useAtom(campaignCacheAtom);
  const atsIntegrationJobId = useAtomValue(atsIntegrationJobIdAtom);
  const [currentFolder, setCurrentFolder] = useState<CampaignFolder | null>(
    null
  );

  const email = localStorage.getItem('email');
  const isInternal = email?.endsWith('@salv.ai');

  const fetchCampaign = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${env.REACT_APP_SERVER_URL}/campaigns/${campaignId}`,
        {
          params: {
            userId,
          },
        }
      );
      setIsCampaignActive(
        response.data.status === 'ACTIVE' || response.data.status === 'PENDING'
      );
      setCampaignCache((prev) => ({
        ...prev,
        [campaignId]: {
          ...prev[campaignId],
          candidatesNeedingReview: new Set(
            response.data.candidates_needing_review
          ),
        },
      }));
    } catch (error) {
      console.error('Failed to fetch campaign status', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to fetch campaign status',
        color: 'red',
        icon: <IconAlertCircle size={18} />,
      });
    } finally {
      setIsLoading(false);
    }
  }, [campaignId, setCampaignCache, userId]);

  const webCallAtomValue = useAtomValue(webCallAtom); // Subscribes to atom updates

  useEffect(() => {
    setIsWebCall(webCallAtomValue);
  }, [webCallAtomValue]); // Reacts to changes in the atom

  useEffect(() => {
    if (scriptFetched) {
      setActiveTab(initialTab);
    }
  }, [scriptFetched, initialTab]);

  const fetchFolder = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${env.REACT_APP_SERVER_URL}/campaign-folders/${paramFolderId}`
      );
      const { folder } = response.data;
      setCurrentFolder(folder);
    } catch (error) {
      console.error('Failed to fetch folder', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to fetch folder',
        color: 'red',
        icon: <IconAlertCircle size={18} />,
      });
    } finally {
      setIsLoading(false);
    }
  }, [paramFolderId]);

  useEffect(() => {
    if (!paramFolderId) {
      return;
    }

    fetchFolder();
  }, [fetchFolder, paramFolderId]);

  const handleCampaignToggle = async () => {
    try {
      setIsLoading(true);
      if (isCampaignActive) {
        await pauseCampaign(campaignId);
        setIsCampaignActive(false);
        notifications.show({
          title: 'Campaign Paused',
          message: 'The campaign has been paused successfully.',
          color: 'yellow',
        });
      } else {
        await startCampaign(campaignId);
        setIsCampaignActive(true);
        notifications.show({
          title: 'Campaign Resumed',
          message: 'The campaign has been resumed successfully.',
          color: 'green',
        });
      }
    } catch (error) {
      console.error('Failed to toggle campaign status', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to toggle campaign status',
        color: 'red',
        icon: <IconAlertCircle size={18} />,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (activeTab !== TabOptions.LOADER) {
      const queryParams = new URLSearchParams(location.search);
      queryParams.set('tab', activeTab);
      navigate(`${location.pathname}?${queryParams.toString()}`, {
        replace: true,
      });
    }
  }, [activeTab, navigate, location.pathname, location.search]);

  useEffect(() => {
    // TODO: we fetch the whole campaign settings obj here now, so we can probably get rid of a few other redundant fetches
    fetchCampaign();
  }, [fetchCampaign]);

  const handleNavigateBreadCrumbs = (
    breadcrumb: Partial<CampaignFolder> | null
  ) => {
    if (!breadcrumb?.folder_id) {
      setCurrentFolder(null);
      navigate('/scripts');
      return;
    }
    setCurrentFolder(breadcrumb as CampaignFolder);
    navigate(`/scripts/folders/${breadcrumb.folder_id}`);
  };

  return (
    <div
      className='page-container-common'
      style={{
        display: 'flex',
        flexDirection: 'column',
        // backgroundColor: '#f0f0f0',
      }}
    >
      <Tabs
        color='gray'
        variant='pills'
        value={activeTab}
        onChange={(value) => {
          if (isTab(value)) {
            setActiveTab(value);
          }
        }}
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <div
          style={{
            flex: 1,
            overflow: 'auto',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '5px',
              visibility: scriptFetched ? 'visible' : 'hidden',
            }}
          >
            <IconClipboardList size={20} color='#4a564d' />
            <FolderBreadcrumbs
              currentFolder={currentFolder}
              onNavigate={handleNavigateBreadCrumbs}
              includeLastArrow
              size='sm'
            />
            <Text size='sm' style={{ marginBottom: '0px', marginTop: '0px' }}>
              {jobTitle ? jobTitle : 'Untitled'}
            </Text>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingTop: '14px',
              visibility: scriptFetched ? 'visible' : 'hidden',
            }}
          >
            <Tabs.List>
              <Tabs.Tab
                value='sequence'
                classNames={{
                  tab: 'header-tab', // Add a custom class name for this tab
                }}
              >
                Sequence
              </Tabs.Tab>
              <Tabs.Tab
                value='contacts'
                classNames={{
                  tab: 'header-tab', // Add a custom class name for this tab
                }}
              >
                Contacts
              </Tabs.Tab>
              {isInternal && false && (
                <Tabs.Tab
                  value='questions'
                  classNames={{
                    tab: 'header-tab', // Add a custom class name for this tab
                  }}
                >
                  Questions
                </Tabs.Tab>
              )}
            </Tabs.List>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CampaignMenuDropdown campaignId={campaignId} />
              {atsIntegrationJobId && (
                <ATSSyncButton
                  size='xs'
                  variant='light'
                  radius='md'
                  style={{ marginLeft: '14px', borderRadius: '24px' }}
                />
              )}
              {canEditCampaign && (
                <Button
                  size='xs'
                  variant='light'
                  style={{
                    marginLeft: '14px',
                    borderRadius: '24px',
                  }}
                  onClick={() => setContactsModalOpen(true)}
                  leftSection={<IconRocket size={20} />}
                  disabled={!canSubmitCampaign}
                >
                  Add Contacts
                </Button>
              )}
              {canEditCampaign && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: '14px',
                    position: 'relative',
                  }}
                >
                  {(() => {
                    // Get current time in EST
                    const now = new Date();
                    const estTime = new Date(
                      now.toLocaleString('en-US', {
                        timeZone: 'America/New_York',
                      })
                    );
                    const hours = estTime.getHours();
                    // Only display between 11am and 5pm EST
                    return (hours < 11 || hours > 17) && isCampaignActive ? (
                      <div
                        style={{
                          position: 'absolute',
                          top: '-34px',
                          right: '0',
                          fontSize: '12px',
                          color: 'var(--mantine-color-gray-6)',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        Not initiating new calls outside of business hours
                      </div>
                    ) : null;
                  })()}
                  <Switch
                    checked={isCampaignActive}
                    onChange={handleCampaignToggle}
                    disabled={
                      isLoading || (!isCampaignActive && !canSubmitCampaign)
                    }
                    size='md'
                  />
                  <span
                    style={{
                      marginLeft: '10px',
                      color: isCampaignActive
                        ? 'var(--mantine-color-blue-4)'
                        : 'var(--mantine-color-gray-4)',
                      fontWeight: '600',
                      fontSize: '14px',
                    }}
                  >
                    {isCampaignActive ? 'Active' : 'Paused'}
                  </span>
                </div>
              )}
            </div>
          </div>
          {/* When i set to 50px, buttons look better BUT then becomes scrollable. TODO: fix */}
          <Tabs.Panel value='sequence' style={{ height: 'calc(100% - 90px)' }}>
            <EditorPageV2
              setScriptTitle={setJobTitle}
              scriptFetched={scriptFetched}
              setScriptFetched={setScriptFetched}
              isCampaignActive={isCampaignActive}
              setIsCampaignActive={setIsCampaignActive}
              handleContactsRefresh={handleContactsRefresh}
              canSubmitCampaign={canSubmitCampaign}
              setCanSubmitCampaign={setCanSubmitCampaign}
            />
          </Tabs.Panel>

          <Tabs.Panel
            value='contacts'
            style={{ height: 'calc(100% - 90px)', overflow: 'hidden' }} // Change from 'scroll' to 'hidden'
          >
            <CampaignDetailsPage
              key={campaignDetailsKey}
              isWebCall={isWebCall}
            />
          </Tabs.Panel>

          <Tabs.Panel
            value='loader'
            style={{ height: 'calc(100% - 90px)', overflow: 'scroll' }}
          >
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Loader type='dots' style={{ marginTop: '0%' }} />
            </div>
          </Tabs.Panel>
          <Tabs.Panel value='questions' style={{ height: 'calc(100% - 90px)' }}>
            <QuestionsPage setScriptActiveTab={setActiveTab} />
          </Tabs.Panel>
        </div>
      </Tabs>
      <AddContactsModal
        campaignModalOpen={contactsModalOpen}
        setCampaignModalOpen={setContactsModalOpen}
        campaignId={campaignId}
        isCampaignActive={isCampaignActive}
        setIsCampaignActive={setIsCampaignActive}
        handleContactsRefresh={handleContactsRefresh}
        isWebCall={isWebCall}
      />
    </div>
  );
};

export default ScriptTabsPage;
