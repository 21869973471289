import React, { Fragment, useEffect, useRef, useState } from 'react';
import { notifications } from '@mantine/notifications';
import { ActionIcon, Badge, Card, Text, Tooltip } from '@mantine/core';
import { IconPencil, IconTrash, IconX } from '@tabler/icons-react';
import { useHover } from '@mantine/hooks';

import EditTemplateConfirmationModal from './EditTemplateConfirmationModal';
import EditTemplateModal from './EditTemplateModal';
import DeleteTemplateConfirmationModal from './DeleteTemplateConfirmationModal';
import './Template.css';
import { ORG_BACKGROUND_INFO_PLACEHOLDER } from './constants';

export interface SequenceSettingsTemplate {
  id: string;
  title: string;
  instructions: string;
  is_default?: boolean;
  org_id?: string;
}

interface TemplateProps {
  template: SequenceSettingsTemplate;
  onEditTemplate?: (template: SequenceSettingsTemplate) => Promise<void>;
  onDeleteTemplate?: (templateId: string) => Promise<void>;
  handleRemoveTemplate?: () => void;
  isAdmin?: boolean;
  showActionButtons?: boolean;
  isSelectable?: boolean;
  isSelected?: boolean;
  onTemplateSelect?: (templateId: string) => void;
}

const Template = ({
  template,
  onEditTemplate,
  onDeleteTemplate,
  handleRemoveTemplate,
  isAdmin = false,
  showActionButtons = true,
  isSelectable,
  isSelected,
  onTemplateSelect,
}: TemplateProps) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openConfirmEditModal, setOpenConfirmEditModal] = useState(false);
  const [openDeleteTemplateModal, setOpenDeleteTemplateModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const isDefault = !!template.is_default;
  const [isExpanded, setIsExpanded] = useState(false);
  const [isContentLongEnough, setIsContentLongEnough] = useState(false);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const maxLines = isAdmin ? 4 : 2;
  const { hovered, ref } = useHover();

  useEffect(() => {
    if (!contentRef.current) {
      return;
    }

    const element = contentRef.current;
    let observer;

    // Function to measure content when visible
    const measureContent = () => {
      const lineHeight = parseFloat(
        window.getComputedStyle(element).lineHeight
      );
      const scrollHeight = element.scrollHeight;
      const tolerance = 0.5 * maxLines;

      if (scrollHeight > maxLines * lineHeight + tolerance) {
        setIsContentLongEnough(true);
      }
    };

    // Create and configure IntersectionObserver
    observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setTimeout(measureContent, 20);
          observer.disconnect();
        }
      },
      {
        root: null,
        threshold: 0.1,
      }
    );

    // Start observing
    observer.observe(element);

    // Cleanup
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [maxLines, template.instructions]);

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setOpenConfirmEditModal(false);
  };

  const handleConfirmEdit = () => {
    setOpenEditModal(true);
    setOpenConfirmEditModal(false);
  };

  const handleDeleteTemplate = async () => {
    if (!onDeleteTemplate) {
      return;
    }

    try {
      setIsDeleting(true);
      await onDeleteTemplate(template.id);
      setOpenDeleteTemplateModal(false);
      notifications.show({
        title: 'Success',
        message: 'Template deleted successfully',
        color: 'green',
      });
    } catch {
      notifications.show({
        title: 'Error',
        message: 'Failed to delete template',
        color: 'red',
      });
    } finally {
      setIsDeleting(false);
    }
  };

  const handleExpandContent = (e) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  return (
    <Card
      shadow='sm'
      padding='lg'
      radius='md'
      withBorder
      key={template.id}
      style={{
        flex: 1,
        backgroundColor: isSelected
          ? 'var(--mantine-color-blue-1)'
          : isSelectable && hovered
            ? 'var(--mantine-color-blue-0)'
            : 'transparent',
        cursor: isSelectable ? 'pointer' : 'default',
        border: isSelected ? '2px solid #228be6' : '',
      }}
      ref={ref}
      onClick={() =>
        isSelectable && onTemplateSelect && onTemplateSelect(template.id)
      }
    >
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <Text size={isAdmin ? 'md' : 'sm'} fw={500}>
              {template.title}
            </Text>

            {isDefault && (
              <Tooltip label='Added by default to all new jobs' position='top'>
                <Badge variant='light' color='blue'>
                  Default
                </Badge>
              </Tooltip>
            )}
          </div>

          {showActionButtons && (
            <div style={{ display: 'flex', gap: '10px' }}>
              <Tooltip label='Edit template' position='top'>
                <ActionIcon
                  size='xs'
                  color='blue'
                  variant='light'
                  onClick={() => setOpenConfirmEditModal(true)}
                >
                  <IconPencil />
                </ActionIcon>
              </Tooltip>
              {isAdmin ? (
                <Fragment>
                  {!isDefault && (
                    <Tooltip label='Delete template' position='top'>
                      <ActionIcon
                        size='xs'
                        color='red'
                        variant='light'
                        onClick={() => setOpenDeleteTemplateModal(true)}
                      >
                        <IconTrash />
                      </ActionIcon>
                    </Tooltip>
                  )}
                </Fragment>
              ) : (
                <Tooltip label='Remove template from job' position='top'>
                  <ActionIcon
                    size='xs'
                    color='gray'
                    variant='light'
                    onClick={handleRemoveTemplate}
                  >
                    <IconX />
                  </ActionIcon>
                </Tooltip>
              )}
            </div>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '8px 14px',
          }}
        >
          {template.instructions.trim().length > 0 ? (
            <Text
              ref={contentRef}
              size='xs'
              c='dimmed'
              style={{
                whiteSpace: 'pre-line',
              }}
              lineClamp={isExpanded ? 0 : maxLines}
            >
              {template.instructions}
            </Text>
          ) : (
            <Text
              ref={contentRef}
              size='xs'
              c='dimmed'
              style={{
                whiteSpace: 'pre-line',
                opacity: 0.5,
              }}
              lineClamp={isExpanded ? 0 : maxLines}
            >
              {ORG_BACKGROUND_INFO_PLACEHOLDER}
            </Text>
          )}
          {isContentLongEnough && (
            <Text
              c='blue'
              onClick={handleExpandContent}
              fw={500}
              size='xs'
              style={{
                padding: '0',
                cursor: 'pointer',
                alignSelf: 'flex-start',
              }}
              className='clickable-text'
            >
              {isExpanded ? 'Show less' : 'Show more'}
            </Text>
          )}
        </div>
      </div>
      <EditTemplateConfirmationModal
        open={openConfirmEditModal}
        onClose={() => setOpenConfirmEditModal(false)}
        onConfirmEdit={handleConfirmEdit}
      />
      {onEditTemplate && (
        <EditTemplateModal
          open={openEditModal}
          onClose={handleCloseEditModal}
          template={template}
          onEditTemplate={onEditTemplate}
          isDefault={isDefault}
        />
      )}
      <DeleteTemplateConfirmationModal
        open={openDeleteTemplateModal}
        onClose={() => setOpenDeleteTemplateModal(false)}
        onConfirmDelete={handleDeleteTemplate}
        isDeleting={isDeleting}
      />
    </Card>
  );
};

export default Template;
