import React from 'react';
import {
  Stack,
  Textarea,
  TextInput,
  Title,
  Text,
  Alert,
  Box,
} from '@mantine/core';
import { usePermissions } from 'hooks/usePermissions';
import { IconAlertCircle } from '@tabler/icons-react';

const WebCallEmailStep = ({ scriptInfo }) => {
  const { canEditCampaign } = usePermissions();

  const missingInterviewLink =
    !scriptInfo.values.webCallEmailBody?.includes('{interview_link}');

  return (
    <>
      <Stack h='100%' gap='md' style={{ paddingRight: '16px' }}>
        <Title order={5}>Interview Link Email</Title>

        <Text fz='sm' c='dimmed'>
          Use <code>{'{candidate_name}'}</code> to insert the candidate's name
          and <code>{'{interview_link}'}</code> to insert the interview link.
        </Text>

        {missingInterviewLink && (
          <Box mb='xs'>
            <Alert
              icon={<IconAlertCircle size={16} />}
              title='Email Body Missing Interview Link - Please Add {interview_link}'
              color='red'
              withCloseButton={false}
            ></Alert>
          </Box>
        )}

        <TextInput
          {...scriptInfo.getInputProps('webCallEmailSubject')}
          label='Subject'
          classNames={{
            input: canEditCampaign ? '' : 'view-only',
          }}
        />
        <Textarea
          className='fullHeightTextarea'
          {...scriptInfo.getInputProps('webCallEmailBody')}
          label='Body'
          classNames={{
            input: canEditCampaign ? '' : 'view-only',
          }}
          styles={{
            root: {
              marginBottom: '20px',
              minHeight: '300px',
            },
            input: {
              marginBottom: '10px',
              minHeight: '250px',
              color: 'black',
            },
            error: {
              position: 'absolute',
              bottom: '-22px',
            },
          }}
          error={
            missingInterviewLink
              ? 'Email body must include {interview_link}'
              : null
          }
        />
      </Stack>
    </>
  );
};

export default WebCallEmailStep;
