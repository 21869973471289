import env from 'env';

const LOCAL_SERVER_URL = env.REACT_APP_SERVER_URL;
const EXPECTED_ENV = env.ENV;

export const checkLocalServerRunning = async () => {
  if (!env.IS_LOCAL) {
    // only doing check for local development
    return true;
  }
  try {
    const response = await fetch(LOCAL_SERVER_URL);
    if (!response.ok) {
      alert('Failed connecting to local server');
      return false;
    }
    const data = await response.json();
    if (data.env !== EXPECTED_ENV) {
      alert(`Server ${EXPECTED_ENV} is not running on ${LOCAL_SERVER_URL}`);
      return false;
    }
    return true;
  } catch (error) {
    console.error(`Server check failed: ${error.message}`);
    alert('Failed connecting to local server');
    return false;
  }
};
