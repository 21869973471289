import { useEffect, useRef, useState } from 'react';
import { useAtom } from 'jotai';
import env from 'env';
import { RequirementImportance } from 'pages/editorv2/types';
import { notifications } from '@mantine/notifications';
import { isAxiosError } from 'axios';
import { transcriptionCacheAtom, resumeCacheAtom } from 'pages/editorv2/atoms';
import axios from 'api/axiosConfig';

interface GetTranscriptDataArgs {
  candidateId?: string;
  campaignId?: string;
  isFetchingCalls: boolean; // Delay fetching transcript data until calls are fetched
}

export interface RequirementGrade {
  question: string;
  answer: string;
  grade: string;
  importance: RequirementImportance;
  ts: number;
  title: string;
  transcript_idx: number;
}

export interface CandidateQuestion {
  question: string;
  answer: string;
  question_ts: number;
}

export interface TranscriptData {
  transcript: string;
  requirementGradesList: RequirementGrade[];
  callId: string;
  overallGrade: number;
  questionCompletionRate: number;
  candidateName: string;
  candidateEmail: string;
  candidatePhone: string;
  lastCalled: string;
  allCalls: any[];
  callComplete: boolean;
  webCall: boolean;
  emails: string[];
  candidateQuestions: CandidateQuestion[];
  needsTranslation: boolean;
  translatedTranscript?: string;
  translatedRequirementGradesList?: RequirementGrade[];
  translatedCandidateQuestions?: CandidateQuestion[];
  recordingURL?: string;
  _fetchedAt?: number;
  summary: string;
}

export const useGetTranscriptData = ({
  candidateId,
  campaignId,
  isFetchingCalls,
}: GetTranscriptDataArgs) => {
  const [isFetchingTranscript, setIsFetchingTranscript] = useState(true);
  const [transcriptData, setTranscriptData] = useState<TranscriptData>({
    transcript: '',
    requirementGradesList: [] as RequirementGrade[],
    callId: '',
    overallGrade: 0,
    questionCompletionRate: 0,
    candidateName: '',
    candidateEmail: '',
    candidatePhone: '',
    lastCalled: '',
    allCalls: [],
    emails: [],
    callComplete: false,
    webCall: false,
    candidateQuestions: [] as CandidateQuestion[],
    needsTranslation: false,
    summary: '',
    recordingURL: '',
  });
  const [transcriptError, setTranscriptError] = useState(false);
  const abortControllerRef = useRef<AbortController | null>(null);
  const [transcriptionCache, setTranscriptionCache] = useAtom(
    transcriptionCacheAtom
  );

  useEffect(() => {
    if (!candidateId || !campaignId || isFetchingCalls) {
      return;
    }

    // Cancel any ongoing requests before making a new one
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const controller = new AbortController();
    abortControllerRef.current = controller;

    const fetchTranscriptData = async () => {
      setIsFetchingTranscript(true);

      const cacheKey = `${candidateId}_${campaignId}`;

      if (transcriptionCache[cacheKey]) {
        setTranscriptData(transcriptionCache[cacheKey]);
        setTranscriptError(false);
        setIsFetchingTranscript(false);
        return;
      }

      try {
        const response = await axios.get(
          `${env.REACT_APP_SERVER_URL}/candidate/${candidateId}/call_details/${campaignId}`,
          {
            signal: controller.signal,
          }
        );
        let allCalls = response.data.all_calls || [];
        allCalls = allCalls?.sort((a, b) => {
          return (
            new Date(b.last_updated).getTime() -
            new Date(a.last_updated).getTime()
          );
        });

        const data: TranscriptData = {
          transcript: response.data.transcript,
          requirementGradesList: response.data.requirement_grades_list,
          candidateQuestions: response.data.candidate_questions,
          overallGrade:
            response.data.call_with_highest_completion?.overall_grade || 0,
          questionCompletionRate:
            response.data.call_with_highest_completion
              ?.question_completion_rate || 0,
          callId:
            response.data.call_with_highest_completion?.call_id || undefined,
          candidateName: response.data.candidate.fullName,
          candidatePhone: response.data.candidate.phoneNumber,
          candidateEmail: response.data.candidate.email,
          lastCalled:
            response.data.call_with_highest_completion?.last_updated || '',
          allCalls: allCalls,
          callComplete:
            response.data.call_with_highest_completion?.call_status ===
            'COMPLETED',
          emails: response.data.emails || [],
          webCall: response.data.call_with_highest_completion?.web_call,
          needsTranslation: response.data.needs_translation,
          summary: response.data.summary || '',
        };

        setTranscriptionCache({
          data: {
            [cacheKey]: data,
          },
          timestamp: Date.now(),
        });

        setTranscriptData(data);
        setTranscriptError(false);
        setIsFetchingTranscript(false);
      } catch (error) {
        if (isAxiosError(error)) {
          if (error.code === 'ERR_CANCELED') {
            return;
          }

          setIsFetchingTranscript(false);
          setTranscriptError(true);
          console.error('Error fetching transcript data:', error);
          notifications.show({
            title: 'There was an error retrieving the call details',
            message: error instanceof Error ? error.message : '',
            color: 'red',
          });
        }
      }
    };

    fetchTranscriptData();

    return () => {
      if (controller) {
        controller.abort();
      }
    };
  }, [
    candidateId,
    campaignId,
    setTranscriptionCache,
    transcriptionCache,
    isFetchingCalls,
  ]);

  return {
    isFetchingTranscript,
    transcriptData,
    transcriptError,
  };
};

export const useGetResumeURL = ({ candidateId, campaignId }) => {
  const [isFetchingResume, setIsFetchingResume] = useState(false);
  const [resumeError, setResumeError] = useState(false);
  const abortControllerRef = useRef<AbortController | null>(null);
  const [resumeCache, setResumeCache] = useAtom(resumeCacheAtom);

  useEffect(() => {
    if (!candidateId) {
      return;
    }

    const controller = new AbortController();
    abortControllerRef.current = controller;

    const fetchResumeURL = async () => {
      setIsFetchingResume(true);

      const cacheKey = `${candidateId}-${campaignId}`;

      if (resumeCache[cacheKey]) {
        setResumeError(false);
        setIsFetchingResume(false);
        return;
      }

      try {
        const response = await axios.get(
          `${env.REACT_APP_SERVER_URL}/candidate/${candidateId}/resume_url`,
          {
            signal: controller.signal,
          }
        );

        const data = {
          resumeURL: response.data.resume_url,
        };

        setResumeCache((prevCache) => ({
          ...prevCache,
          [cacheKey]: {
            ...prevCache[cacheKey],
            ...data,
          },
        }));

        setResumeError(false);
        setIsFetchingResume(false);
      } catch (error) {
        if (isAxiosError(error)) {
          if (error.code === 'ERR_CANCELED') {
            return;
          }

          setResumeError(true);
          console.error('Error fetching resume URL:', error);
          notifications.show({
            title: 'There was an error retrieving the resume URL',
            message: error instanceof Error ? error.message : '',
            color: 'red',
          });
        }
        setIsFetchingResume(false);
      }
    };

    fetchResumeURL();

    return () => {
      if (controller) {
        controller.abort();
      }
    };
  }, [candidateId, campaignId, setResumeCache, resumeCache]);

  return {
    isFetchingResume,
    resumeError,
  };
};
