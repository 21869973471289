import { CallStatusFilterState } from 'pages/job-posting-tips/components/types';
import { useState } from 'react';

export default function useCallStatusFilterState(): CallStatusFilterState {
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);

  const clear = () => {
    setSelectedStatuses([]);
  };

  const isEmpty = selectedStatuses.length === 0;

  return {
    selectedStatuses,
    onChangeSelectedStatuses: setSelectedStatuses,
    clear,
    isEmpty,
  };
}
