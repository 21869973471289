import React, { MouseEvent, useRef, useState } from 'react';
import env from 'env';
import {
  Box,
  Button,
  CopyButton,
  Menu,
  Modal,
  Text,
  ThemeIcon,
} from '@mantine/core';
import {
  IconCaretDownFilled,
  IconCheck,
  IconCopy,
  IconLock,
  IconWorld,
} from '@tabler/icons-react';
import './ShareTranscriptModal.css';
import { isAxiosError } from 'axios';
import { notifications } from '@mantine/notifications';
import { useAtom } from 'jotai';
import {
  campaignInfoAtomForCandidate,
  CandidateCampaignAccess,
} from 'pages/editorv2/atoms';

import axios from '../../api/axiosConfig';

interface ShareTranscriptModalProps {
  isOpen: boolean;
  onClose: () => void;
  candidateName: string;
  campaignId: string;
  candidateId: string;
}

const ShareTranscriptModal = ({
  isOpen,
  onClose,
  candidateName,
  campaignId,
  candidateId,
}: ShareTranscriptModalProps) => {
  const candidateCampaignInfoKey = candidateId + '_' + campaignId;
  const [candidateInfo, setCandidateInfo] = useAtom(
    campaignInfoAtomForCandidate(candidateCampaignInfoKey)
  );

  const shareAccess = candidateInfo.access;

  const setShareAccess = (val: CandidateCampaignAccess) =>
    setCandidateInfo({ access: val, _fetchedAt: undefined });

  const [isSettingAccess, setIsSettingAccess] = useState(false);

  const currentUrl = window.location.href;

  const abortControllerRef = useRef<AbortController | null>(null);

  const handleSetTranscriptAccess = async (
    e: MouseEvent<HTMLButtonElement>,
    access: CandidateCampaignAccess
  ) => {
    e.stopPropagation();
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const controller = new AbortController();
    abortControllerRef.current = controller;

    setShareAccess(access); // optimistic UI update

    try {
      setIsSettingAccess(true);
      await axios.patch(
        `${env.REACT_APP_SERVER_URL}/candidate/${candidateId}/access/${campaignId}`,
        {
          access,
        },
        { signal: controller.signal }
      );
    } catch (error) {
      if (isAxiosError(error) && error.code === 'ERR_CANCELED') {
        return;
      }
      console.error('Error updating transcript access:', error);
      notifications.show({
        title: `Transcript access`,
        message: 'There was an error updating transcript access',
        color: 'red',
      });
    } finally {
      setIsSettingAccess(false);
    }
  };

  return (
    <Modal
      className='share-transcript-modal'
      size='md'
      title={`Share ${candidateName}`}
      opened={isOpen}
      onClose={onClose}
      centered
      styles={{
        title: {
          color: 'black',
          fontSize: '20px',
          fontWeight: '600',
        },
      }}
    >
      <Text size='md' fw={500}>
        General Access
      </Text>
      <div
        style={{
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          margin: '10px 0px 30px 0px',
        }}
      >
        {shareAccess === 'RESTRICTED' ? (
          <ThemeIcon variant='filled' color='gray' radius='xl'>
            <IconLock size={16} />
          </ThemeIcon>
        ) : (
          <ThemeIcon variant='filled' color='green' radius='xl'>
            <IconWorld size={16} />
          </ThemeIcon>
        )}
        <div>
          <Menu width={200} position='bottom-start' shadow='xl'>
            <Menu.Target>
              <Box
                className='share-access-menu-target'
                style={{
                  display: 'flex',
                  gap: '5px',
                  alignItems: 'center',
                  padding: '5px',
                  width: 'fit-content',
                  height: 'fit-content',
                  borderRadius: '5px',
                }}
              >
                <Text size='sm' fw={500}>
                  {shareAccess === 'RESTRICTED'
                    ? 'Restricted'
                    : 'Anyone with the link'}
                </Text>
                <IconCaretDownFilled size='14px' />
              </Box>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Label>Access</Menu.Label>
              <Menu.Item
                onClick={(e) => {
                  handleSetTranscriptAccess(e, 'RESTRICTED');
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    gap: '5px',
                  }}
                >
                  {shareAccess === 'RESTRICTED' && <IconCheck color='green' />}
                  <Text size='sm'>Restricted</Text>
                </div>
              </Menu.Item>
              <Menu.Item
                onClick={(e) => {
                  handleSetTranscriptAccess(e, 'UNRESTRICTED');
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    gap: '5px',
                  }}
                >
                  {shareAccess === 'UNRESTRICTED' && (
                    <IconCheck color='green' />
                  )}
                  <Text size='sm'>Anyone with the link</Text>
                </div>
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
          <Text size='xs' ml='5px'>
            {shareAccess === 'RESTRICTED'
              ? 'Only people in the organization can access this transcript'
              : 'Anyone with this link can access this transcript'}
          </Text>
          {isSettingAccess && (
            <Text size='xs' ml='5px'>
              Updating...
            </Text>
          )}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <CopyButton value={currentUrl}>
          {({ copied, copy }) => (
            <Button variant='light' leftSection={<IconCopy />} onClick={copy}>
              {copied ? 'Copied!' : 'Copy Link'}
            </Button>
          )}
        </CopyButton>
        <Button onClick={onClose}>Done</Button>
      </div>
    </Modal>
  );
};

export default ShareTranscriptModal;
