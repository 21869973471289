import { v4 as uuidv4 } from 'uuid';

import { formatPhoneNumber, validatePhoneNumber } from './phoneUtils';
import { validateEmail } from './emailUtils';
import {
  convertTimezoneToTimezoneAbbreviation,
  isValidTimeZone,
} from './dateUtils';

interface ContactColumns {
  name: string | null;
  firstName: string | null;
  lastName: string | null;
  phone: string | null;
  email: string | null;
  timezone: string | null;
  zip: string | null;
  location: string | null;
  city?: string | null;
  state?: string | null;
}

interface RowData {
  id: string;
  errors: Record<string, string>;
  [key: string]: string | Record<string, string>;
}

export class ContactsCSVExtractor {
  static FULL_NAME_PATTERNS = [
    'candidate name',
    'candidate full name',
    'recruit name',
    'recruit full name',
    'full name',
    'name',
    'candidate',
    'recruit',
  ];
  static FIRST_NAME_PATTERNS = ['first name', 'first'];
  static LAST_NAME_PATTERNS = ['last name', 'last'];
  static PHONE_PATTERNS = [
    'phone number',
    'phone',
    'cell',
    'cell phone',
    'mobile phone',
    'mobile',
    'number',
    'phone1',
  ];
  static EMAIL_PATTERNS = ['email address', 'email'];
  static TIMEZONE_PATTERNS = ['timezone', 'tz'];
  static ZIP_PATTERNS = ['zipcode', 'postal code', 'zip'];
  static LOCATION_PATTERNS = ['candidate location', 'location'];
  static CITY_PATTERNS = ['city'];
  static STATE_PATTERNS = ['state'];

  private file: File;
  private requiredContactColumn: string;
  private columns: ContactColumns;
  private fileContent: string;
  private data: Record<string, string>[] = [];
  private validRows: Record<string, RowData> = {};
  private invalidRows: Record<string, RowData> = {};

  constructor(file: File, acceptEmail = false) {
    this.file = file;
    this.requiredContactColumn = acceptEmail ? 'email' : 'phone';
    this.fileContent = '';
    this.columns = {
      name: null,
      firstName: null,
      lastName: null,
      phone: null,
      email: null,
      timezone: null,
      zip: null,
      location: null,
      city: null,
      state: null,
    };
  }

  async extract(): Promise<{ validRows: RowData[]; invalidRows: RowData[] }> {
    // TODO: Move CSV logic to separate utils file
    await this.readCSV();
    await this.parseCSV();
    await this.normalizeColumns();
    await this.detectColumns();
    await this.validateRequiredColumns();
    await this.renameAndCastColumns();
    await this.validateData();

    const validRows = Object.values(this.validRows).slice(0, 10000);
    const invalidRows = Object.values(this.invalidRows);

    return { validRows, invalidRows };
  }

  private async readCSV(): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();
        reader.onload = async (event) => {
          this.fileContent = event.target?.result as string;
          resolve();
        };

        reader.onerror = (error) => {
          console.error(error);
          reject();
        };

        reader.readAsText(this.file);
      } catch (error) {
        console.error(error);
        reject();
      }
    });
  }

  private async parseCSV() {
    const lines = this.fileContent.split('\n');
    const headers = lines[0].split(',');

    await Promise.all(
      lines.slice(1).map(async (line) => {
        const row = this.processRow(line);
        if (row.length === headers.length) {
          const rowData: Record<string, string> = { id: uuidv4() };
          row.forEach((value, index) => {
            rowData[headers[index]] = value;
          });
          this.data.push(rowData);
        }
      })
    );
  }

  private processRow(line: string): string[] {
    const regex = /,(?=(?:(?:[^"]*"){2})*[^"]*$)/g;
    const row = line
      .split(regex)
      .map((item) => item.replace(/(^"|"$)/g, '').trim());
    return row;
  }

  private async normalizeColumns() {
    if (this.data.length === 0) {
      return;
    }

    const columns = Object.keys(this.data[0]);
    this.data.forEach((row) => {
      columns.forEach((col) => {
        const normalized = col.toLowerCase().trim();

        if (normalized === col) {
          return;
        }

        row[normalized] = row[col];
        delete row[col];
      });
    });
  }

  private async detectColumns() {
    if (this.data.length === 0) {
      return;
    }

    const columns = Object.keys(this.data[0]);

    this.columns.name = this.findBestMatch(
      columns,
      ContactsCSVExtractor.FULL_NAME_PATTERNS
    );
    if (!this.columns.name) {
      this.columns.firstName = this.findBestMatch(
        columns,
        ContactsCSVExtractor.FIRST_NAME_PATTERNS
      );
      this.columns.lastName = this.findBestMatch(
        columns,
        ContactsCSVExtractor.LAST_NAME_PATTERNS
      );
    }
    this.columns.phone = this.findBestMatch(
      columns,
      ContactsCSVExtractor.PHONE_PATTERNS
    );
    this.columns.email = this.findBestMatch(
      columns,
      ContactsCSVExtractor.EMAIL_PATTERNS
    );
    this.columns.timezone = this.findBestMatch(
      columns,
      ContactsCSVExtractor.TIMEZONE_PATTERNS
    );
    this.columns.zip = this.findBestMatch(
      columns,
      ContactsCSVExtractor.ZIP_PATTERNS
    );

    if (!this.columns.zip) {
      this.columns.location = this.findBestMatch(
        columns,
        ContactsCSVExtractor.LOCATION_PATTERNS
      );

      if (!this.columns.location) {
        this.columns.city = this.findBestMatch(
          columns,
          ContactsCSVExtractor.CITY_PATTERNS
        );
        this.columns.state = this.findBestMatch(
          columns,
          ContactsCSVExtractor.STATE_PATTERNS
        );
      }
    }
  }

  private async validateRequiredColumns() {
    const requiredColFound = this.columns[this.requiredContactColumn];
    if (!requiredColFound) {
      throw new Error(
        `Missing required column: '${this.requiredContactColumn}'. Please check your file headers.`
      );
    }

    if (
      !this.columns.name &&
      !(this.columns.firstName && this.columns.lastName)
    ) {
      throw new Error(
        'Missing required candidate name columns (full name, name, first name, last name). Please check your file headers.'
      );
    }
  }

  private async renameAndCastColumns() {
    if (this.data.length === 0) {
      return;
    }

    const renameMap: Record<string, string> = {};

    Object.entries(this.columns).forEach(([key, value]) => {
      if (value) {
        renameMap[value as string] = key;
      }
    });

    // Rename columns
    this.data = this.data.map((row) => {
      const newRow: Record<string, string> = {};
      Object.keys(row).forEach((col) => {
        const newCol = renameMap[col] || col;
        newRow[newCol] = row[col];
      });

      if (!newRow.name && newRow.firstName && newRow.lastName) {
        newRow.name = `${newRow.firstName} ${newRow.lastName}`;
        delete newRow.firstName;
        delete newRow.lastName;
      }

      if (!newRow.location && newRow.city && newRow.state) {
        newRow.location = `${newRow.city}, ${newRow.state}`;
        delete newRow.city;
        delete newRow.state;
      }

      return newRow;
    });

    if (!this.columns.name && this.columns.firstName && this.columns.lastName) {
      this.columns.name = 'name';
    }

    if (!this.columns.location && this.columns.city && this.columns.state) {
      this.columns.location = 'location';
    }
  }

  private async validateData() {
    if (this.data.length === 0) {
      return;
    }

    const seenPhoneNumbers: Record<string, string> = {};
    const seenEmails: Record<string, string> = {};

    const handleValidateContact = (
      value: string,
      id: string,
      type: 'phone' | 'email',
      validatedValue: boolean,
      errors: Record<string, string>,
      seenRecords: Record<string, string>,
      rowName: string
    ) => {
      if (!validatedValue) {
        errors[type] = `Invalid ${type}`;
      } else if (seenRecords[value]) {
        const firstDuplicateRow =
          this.validRows[seenRecords[value]] ||
          this.invalidRows[seenRecords[value]];
        errors[type] = `Duplicate ${type}: ${firstDuplicateRow.name}`;

        if (this.validRows[seenRecords[value]]) {
          this.invalidRows[seenRecords[value]] =
            this.validRows[seenRecords[value]];
          delete this.validRows[seenRecords[value]];
        }

        this.invalidRows[seenRecords[value]] = {
          ...this.invalidRows[seenRecords[value]],
          errors: {
            ...this.invalidRows[seenRecords[value]].errors,
            [type]: `Duplicate ${type}: ${rowName}`,
          },
        };
      }
      seenRecords[value] = id;
    };

    this.data.forEach((row) => {
      const errors: Record<string, string> = {};

      const { id, name, phone, email } = row;
      const rowData: RowData = { id, errors };

      const columns = Object.keys(row);
      if (!name) {
        errors['name'] = 'Missing name';
      }

      if (!phone && this.requiredContactColumn === 'phone') {
        errors['phone'] = 'Missing phone';
      } else if (columns.includes('phone') && phone) {
        const normalizedPhone = formatPhoneNumber(phone, 'national');
        const validatedPhone = validatePhoneNumber(normalizedPhone);
        handleValidateContact(
          normalizedPhone,
          id,
          'phone',
          validatedPhone,
          errors,
          seenPhoneNumbers,
          name
        );
      }

      if (!email && this.requiredContactColumn === 'email') {
        errors['email'] = 'Missing email';
      } else if (columns.includes('email') && email) {
        const validatedEmail = validateEmail(email);
        handleValidateContact(
          email,
          id,
          'email',
          validatedEmail,
          errors,
          seenEmails,
          name
        );
      }

      if (row.timezone) {
        if (!isValidTimeZone(row.timezone)) {
          errors['timezone'] = 'Invalid timezone';
        } else {
          row.timezone = convertTimezoneToTimezoneAbbreviation(row.timezone);
        }
      }

      const rowDataColumns = [
        'name',
        'phone',
        'email',
        'timezone',
        'zip',
        'location',
      ];

      rowDataColumns.forEach((key) => {
        if (this.columns[key] && key !== 'id' && key !== 'errors') {
          rowData[key] =
            key === 'phone'
              ? formatPhoneNumber(row[key], 'national')
              : row[key]
                ? row[key]
                : '';
        }
      });

      if (Object.keys(errors).length > 0) {
        this.invalidRows[id] = rowData;
      } else {
        this.validRows[id] = rowData;
      }
    });
  }

  private standardize(s: string): string {
    return s.toLowerCase().replace(/[_\s\-.]+/g, '');
  }

  private findBestMatch(inputKeys: string[], patterns: string[]) {
    for (const pattern of patterns) {
      const standardizedPattern = this.standardize(pattern);

      for (const key of inputKeys) {
        const standardizedKey = this.standardize(key);
        if (standardizedPattern === standardizedKey) {
          return key;
        }
      }
    }
    return null;
  }
}
