import React, { useMemo, useState, useEffect } from 'react';
import {
  Modal,
  TextInput,
  Select,
  SelectProps,
  Button,
  Group,
  Box,
  LoadingOverlay,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconPhone, IconVideo } from '@tabler/icons-react';
import axios from 'api/axiosConfig';
import env from 'env';

import TestCallModal from '../editorv2/components/TestCallModal';
import { CampaignResponse } from './ScriptsPage';

interface DemoFormValues {
  name: string;
  email: string;
  phone: string;
  selection: string;
}

interface DemoSelectModalProps {
  opened: boolean;
  onClose: () => void;
  onSubmit: (values: DemoFormValues) => void;
  campaignsData: CampaignResponse[];
  campaignsLoading: boolean;
}

const DemoSelectModal: React.FC<DemoSelectModalProps> = ({
  opened,
  onClose,
  onSubmit,
  campaignsData,
  campaignsLoading,
}) => {
  // State to track the current view (form or test call)
  const [showTestCall, setShowTestCall] = useState(false);
  const [selectedCampaignId, setSelectedCampaignId] = useState<string>('');
  const [testModalManuallyClosed, setTestModalManuallyClosed] = useState(false);

  const form = useForm<DemoFormValues>({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      selection: '',
    },
    validate: {
      name: (value) => (value.trim() ? null : 'Name is required'),
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
      phone: (value) => (value.trim() ? null : 'Phone number is required'),
      selection: (value) => (value ? null : 'Please select an option'),
    },
  });

  // Create a minimal scriptInfo form with just the voiceName fixed to "Lisa (American)"
  const scriptInfo = useForm({
    initialValues: {
      voiceName: 'Lisa (American)',
    },
  });

  // Function to mock the verifyCallOutro that's required by TestCallModal
  const verifyCallOutro = () => {
    // In demo mode, just return false to not block any calls
    return false;
  };

  // Transform campaignsData for the Select dropdown
  const campaignOptions =
    campaignsData
      ?.filter((c) => c.campaign_name !== 'All Demo Calls (Do Not Modify)')
      .map((campaign) => ({
        value: campaign.campaign_id,
        label: campaign.campaign_name,
      })) || [];

  // Create a lookup map for web call status
  const webCallMap: Record<string, boolean> = {};
  campaignsData.forEach((campaign) => {
    webCallMap[campaign.campaign_id] = !!campaign.web_call;
  });

  const selectedCampaign = useMemo(() => {
    return campaignsData.find(
      (campaign) => campaign.campaign_id === selectedCampaignId
    );
  }, [campaignsData, selectedCampaignId]);

  const renderSelectOption: SelectProps['renderOption'] = ({ option }) => (
    <Group gap='xs'>
      {webCallMap[option.value] ? (
        <IconVideo size={16} color='var(--salv-video-color)' />
      ) : (
        <IconPhone size={16} color='var(--salv-phone-color)' />
      )}
      <span>{option.label}</span>
    </Group>
  );

  const handleSubmit = (values: DemoFormValues) => {
    const selectedCampaign = campaignsData.find(
      (campaign) => campaign.campaign_id === values.selection
    );

    // First, do all the UI-related operations immediately
    onSubmit(values);
    localStorage.setItem('lastTestPhoneName', values.name);
    localStorage.setItem('lastTestPhoneNumber', values.phone);
    localStorage.setItem('demoUserEmail', values.email);

    setSelectedCampaignId(values.selection);
    setShowTestCall(true);

    // Then fire off the API request in the background
    // We use setTimeout to push it to the next event loop tick
    setTimeout(() => {
      axios
        .post(`${env.REACT_APP_SERVER_URL}/submit_demo_form`, {
          name: values.name,
          email: values.email,
          phone: values.phone,
          selection: selectedCampaign?.campaign_name,
        })
        .catch((error) => {
          console.error('Error submitting demo form:', error);
        });
    }, 0);
  };

  // Auto-select the first campaign when data loads
  useEffect(() => {
    if (campaignsData?.length > 0 && !form.values.selection) {
      form.setFieldValue('selection', campaignsData[0].campaign_id);
    }
  }, [campaignsData, form]);

  const handleTranscriptClosed = () => {
    window.open('https://calendly.com/mason-salv/tenzo-intro', '_blank');
  };

  // Render different content based on current view
  const renderContent = () => {
    if (showTestCall) {
      // Find the selected campaign from campaignsData
      const selectedCampaign = campaignsData.find(
        (campaign) => campaign.campaign_id === selectedCampaignId
      );

      return (
        <Box p='md' style={{ maxWidth: '40vw' }}>
          <div style={{ textAlign: 'center' }}>
            {/* Campaign selection dropdown */}

            <div style={{ marginTop: '10px' }}>
              <TestCallModal
                campaignId={selectedCampaignId}
                isWebCall={selectedCampaign?.web_call || false}
                scheduleFollowUp={selectedCampaign?.schedule_follow_up || false}
                verifyCallOutro={verifyCallOutro}
                webCallEmailBodyValid={true}
                scriptInfo={scriptInfo}
                disabled={false}
                emailToSendTestEmail={form.values.email}
                buttonSize='lg'
                autoOpenModal={!testModalManuallyClosed}
                noOverlay={true}
                noTransition={true}
                onOverallClose={() => setTestModalManuallyClosed(true)}
                onTranscriptClose={handleTranscriptClosed}
              />
            </div>
          </div>
          <br />
          <br />
          <Select
            label='Change Role'
            value={selectedCampaignId}
            data={campaignOptions}
            onChange={(value) => {
              if (value) {
                setSelectedCampaignId(value);
              }
            }}
            renderOption={renderSelectOption}
            mb='md'
            allowDeselect={false}
          />
        </Box>
      );
    }

    // Default form view
    return (
      <Box mx='auto' pos='relative' style={{ maxWidth: '40vw' }}>
        <LoadingOverlay
          visible={campaignsLoading}
          loaderProps={{ type: 'dots' }}
        />
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <TextInput
            label='Name'
            placeholder='Enter your name'
            required
            {...form.getInputProps('name')}
            mb='md'
          />

          <TextInput
            label='Email'
            placeholder='your.email@company.com'
            required
            {...form.getInputProps('email')}
            mb='md'
          />

          <TextInput
            label='Phone'
            placeholder='Enter your phone number'
            required
            {...form.getInputProps('phone')}
            mb='md'
          />

          <Select
            label='Role'
            placeholder='Select a role'
            data={campaignOptions}
            required
            {...form.getInputProps('selection')}
            mb='xl'
            renderOption={renderSelectOption}
            allowDeselect={false}
          />

          <Group justify='flex-end' mt='md'>
            <Button variant='outline' onClick={() => form.reset()}>
              Clear
            </Button>
            <Button type='submit'>Continue</Button>
          </Group>
        </form>
      </Box>
    );
  };

  return (
    <Modal
      opened={opened}
      onClose={() => {
        setShowTestCall(false);
        form.reset();
        setTestModalManuallyClosed(false);
        onClose();
      }}
      title={
        showTestCall ? selectedCampaign?.campaign_name : 'AI Recruiter Demo'
      }
      centered
      overlayProps={{
        backgroundOpacity: 0.55,
        blur: 3,
        style: {
          background:
            'linear-gradient(135deg, #82c3fb 0%, #4dabf7 50%, #d3dadf 100%)',
        },
      }}
      withCloseButton={showTestCall ? true : false}
      size={'md'}
      styles={{
        content: {
          padding: '12px',
        },
      }}
      radius='md'
    >
      {renderContent()}
    </Modal>
  );
};

export default DemoSelectModal;
