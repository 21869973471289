import React, { useEffect, useRef, useState } from 'react';
import {
  Avatar,
  Box,
  Drawer,
  Loader,
  ScrollArea,
  SimpleGrid,
  Text,
  Textarea,
  ThemeIcon,
} from '@mantine/core';
import axios from 'api/axiosConfig';
import './TranscriptChat.css';
import {
  IconArrowUp,
  IconCurrencyDollar,
  IconInfoCircle,
  IconMessage,
  IconSchool,
  IconUsers,
} from '@tabler/icons-react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

interface TranscriptChatProps {
  opened: boolean;
  onClose: () => void;
  campaignId: string;
  candidateIds?: string[];
  isMultiCandidate?: boolean;
  candidatesData?: any[];
  handleCandidateIdSelected?: (candidateId: string) => void;
  singleCallId?: string;
}

interface TranscriptMessage {
  role: string;
  content: string;
}

const TranscriptChat = ({
  opened,
  onClose,
  campaignId,
  candidateIds = [],
  isMultiCandidate = false,
  handleCandidateIdSelected = () => {},
  singleCallId,
}: TranscriptChatProps) => {
  const [messages, setMessages] = useState<TranscriptMessage[]>([]);
  const [input, setInput] = useState<string>('');
  const [isLoadingResponse, setIsLoadingResponse] = useState(false);
  const chatScrollAreaRef = useRef<HTMLDivElement | null>(null);

  const firstName = localStorage.getItem('firstName') || '';
  const lastName = localStorage.getItem('lastName') || '';
  const userName = firstName ? `${firstName} ${lastName}` : '';

  const prevOpenedRef = useRef(opened);

  useEffect(() => {
    if (opened && !prevOpenedRef.current) {
      setMessages([]);
    }
    prevOpenedRef.current = opened;
  }, [opened]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    if (chatScrollAreaRef.current) {
      const scrollableContent = chatScrollAreaRef.current.querySelector(
        '.mantine-ScrollArea-viewport'
      ) as HTMLElement;

      if (scrollableContent) {
        scrollableContent.scrollTop = scrollableContent.scrollHeight;
      }
    }
  };

  const handleChangeInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInput(e.target.value);
  };

  const handleSendMessage = async (message: string) => {
    if (!message.trim() || isLoadingResponse) {
      return;
    }

    if (!isMultiCandidate && !singleCallId) {
      return;
    }

    if (isMultiCandidate && candidateIds.length === 0) {
      return;
    }

    setMessages([...messages, { role: 'user', content: message }]);
    setInput('');

    try {
      setIsLoadingResponse(true);

      let response;

      if (isMultiCandidate) {
        response = await axios.post(
          `/transcript/multi_candidate_transcript_chat`,
          {
            // callIds: validCallIds,
            candidateIds: candidateIds,
            chatHistory: [...messages, { role: 'user', content: message }],
            campaignId,
          }
        );
      } else {
        response = await axios.post(
          `/transcript/${singleCallId}/candidate_transcript_chat`,
          {
            chatHistory: [...messages, { role: 'user', content: message }],
          }
        );
      }

      const { data } = response;

      setMessages((prevMessages) => [
        ...prevMessages,
        { role: 'assistant', content: data.response },
      ]);
    } catch (error) {
      console.error(error);
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          role: 'assistant',
          content:
            "I'm sorry, I encountered an error while analyzing the transcripts. Please try again later.",
        },
      ]);
    } finally {
      setIsLoadingResponse(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage(input);
    }
  };

  const getPrompts = () => {
    if (isMultiCandidate) {
      return multiCandidateSuggestedPrompts;
    }
    return suggestedPrompts;
  };

  const handleCandidateClick = (candidateIdx: string) => {
    const candidateId = candidateIds[candidateIdx];
    handleCandidateIdSelected(candidateId);
  };

  return (
    <Drawer
      opened={opened}
      position='right'
      size={isMultiCandidate ? 'xl' : 'lg'}
      onClose={onClose}
      className={'chat-drawer' + (isMultiCandidate ? ' multi-candidate' : '')}
      styles={{
        body: {
          height: 'calc(100vh - 60px)',
        },
        overlay: {
          '--overlay-z-index': isMultiCandidate ? 120 : 100000,
        },
      }}
      title={
        isMultiCandidate
          ? `Chat with the Salv Assistant - ${candidateIds.length} candidates selected`
          : 'Chat with the Salv Assistant'
      }
      lockScroll={false}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
        }}
        className='transcript-chat'
      >
        {messages.length < 1 && (
          <Box
            bg={'var(--mantine-color-violet-1)'}
            style={{
              borderRadius: '10px',
              padding: '10px',
              marginBottom: '20px',
            }}
          >
            <Text size='sm'>
              <Text
                component='span'
                fw={700}
              >{`Welcome ${userName}! 👋 `}</Text>
              {isMultiCandidate
                ? `The Salv Chat Assistant is here to help you search for
details across all the candidates in your campaign.`
                : `The Salv Chat Assistant is here to help you with any information you need regarding the candidate's transcript.`}
              {` Feel free to enter your own prompts or use any of the
suggested ones to get started.`}
            </Text>
          </Box>
        )}

        <ScrollArea
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            marginBottom: '40px',
          }}
          type='never'
          ref={chatScrollAreaRef}
        >
          {messages.map((message, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                flex: 1,
                margin: '10px 0px',
                alignItems: 'center',
                gap: '10px',
                flexDirection:
                  message.role === 'assistant' ? 'row' : 'row-reverse',
              }}
            >
              {message.role === 'assistant' ? (
                <Avatar
                  src='/imgs/salv_logo_just_squares.png'
                  radius='xl'
                  className='user-avatar'
                  size='md'
                />
              ) : (
                <Avatar
                  color='blue'
                  name={userName}
                  className='user-avatar'
                  size='md'
                />
              )}
              <Box
                bg={
                  message.role === 'assistant'
                    ? 'var(--mantine-color-gray-1)'
                    : 'var(--mantine-color-blue-1)'
                }
                style={{ borderRadius: '10px', maxWidth: '80%' }}
              >
                <div
                  style={{
                    width: '100%',
                    padding: '18px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                  }}
                >
                  {message.role === 'assistant' ? (
                    <ReactMarkdown
                      remarkPlugins={[remarkGfm]}
                      components={{
                        p: (props) => (
                          <p className='markdown-content' {...props} />
                        ),
                        ul: (props) => (
                          <ul className='markdown-content' {...props} />
                        ),
                        ol: (props) => (
                          <ol className='markdown-content' {...props} />
                        ),
                        li: (props) => (
                          <li className='markdown-content' {...props} />
                        ),
                        h1: ({ children, ...props }) => (
                          <h1 className='markdown-content' {...props}>
                            {children}
                          </h1>
                        ),
                        h2: ({ children, ...props }) => (
                          <h2 className='markdown-content' {...props}>
                            {children}
                          </h2>
                        ),
                        h3: ({ children, ...props }) => (
                          <h3 className='markdown-content' {...props}>
                            {children}
                          </h3>
                        ),
                        h4: ({ children, ...props }) => (
                          <h4 className='markdown-content' {...props}>
                            {children}
                          </h4>
                        ),
                        code: (props) => (
                          <code className='markdown-content' {...props} />
                        ),
                        pre: (props) => (
                          <pre className='markdown-content' {...props} />
                        ),
                        // **Custom handler for candidate links**
                        a: ({ children, ...props }) => {
                          const href = props.href || '';
                          // Check for candidate URLs in the new format
                          if (href.includes('/candidate/')) {
                            // Extract the candidate index from the URL
                            const match = href.match(/\/candidate\/(\d+)$/);
                            if (match && match[1]) {
                              const candidateIdx = match[1];

                              return (
                                <span
                                  onClick={() =>
                                    handleCandidateClick(candidateIdx)
                                  }
                                  style={{
                                    color: 'blue',
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                  }}
                                  className='candidate-link'
                                >
                                  {children}
                                </span>
                              );
                            }
                          }

                          // For external links, fallback to normal <a>
                          return (
                            <a
                              href={href}
                              target='_blank'
                              rel='noopener noreferrer'
                              {...props}
                            >
                              {children}
                            </a>
                          );
                        },
                      }}
                    >
                      {message.content}
                    </ReactMarkdown>
                  ) : (
                    <Text size='sm'>{message.content}</Text>
                  )}
                </div>
              </Box>
            </div>
          ))}
          {isLoadingResponse && (
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <Avatar src='/imgs/salv_logo_just_squares.png' radius='xl' />
              <Loader type='dots' color='blue' />
            </div>
          )}
        </ScrollArea>
        <div
          style={{
            marginTop: 'auto',
            position: 'relative',
            marginBottom: '20px',
          }}
        >
          {messages.length < 1 && (
            <div style={{ margin: '20px 0px' }}>
              <Text fw={700} mb={20}>
                Suggested Prompts
              </Text>
              <SimpleGrid cols={2} spacing='sm'>
                {getPrompts().map((prompt, index) => (
                  <Box
                    key={index}
                    style={{
                      display: 'flex',
                      width: '100%',
                      border: '1px solid var(--mantine-color-gray-3)',
                      borderRadius: '10px',
                      gap: '10px',
                      padding: '10px',
                      cursor: 'pointer',
                    }}
                    className='suggested-prompt'
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleSendMessage(prompt.message);
                    }}
                  >
                    {prompt.icon}
                    <Text size='sm'>{prompt.message}</Text>
                  </Box>
                ))}
              </SimpleGrid>
            </div>
          )}
          <div style={{ position: 'relative' }}>
            <Textarea
              placeholder={
                isMultiCandidate
                  ? 'Ask anything about the selected candidates...'
                  : 'Ask anything about the transcript...'
              }
              value={input}
              onChange={handleChangeInput}
              disabled={isLoadingResponse}
              styles={{ input: { paddingRight: 40, borderRadius: '12px' } }}
              minRows={1}
              onKeyDown={handleKeyDown}
            />
            <ThemeIcon
              size='md'
              color={
                isLoadingResponse || input.trim() === ''
                  ? 'gray'
                  : 'var(--mantine-color-blue-4)'
              }
              variant='filled'
              radius='xl'
              style={{
                position: 'absolute',
                right: 8,
                top: '50%',
                transform: 'translateY(-50%)',
                cursor:
                  isLoadingResponse || input.trim() === ''
                    ? 'not-allowed'
                    : 'pointer',
              }}
              className='send-message-button'
              onClick={() => {
                handleSendMessage(input);
              }}
            >
              <IconArrowUp />
            </ThemeIcon>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

const suggestedPrompts = [
  {
    message: 'What concerns did the candidate have about the job?',
    icon: <IconInfoCircle color='orange' />,
  },
  {
    message:
      'Did the candidate mention their primary reason for wanting this role?',
    icon: <IconMessage color='blue' />,
  },
  {
    message: `What's the candidate's highest level of education?`,
    icon: <IconSchool color='violet' />,
  },
  {
    message: 'Did the candidate mention their salary expectations?',
    icon: <IconCurrencyDollar color='green' />,
  },
];

const multiCandidateSuggestedPrompts = [
  {
    message: 'Which candidates did we leave positive comments for?',
    icon: <IconUsers color='blue' />,
  },
  {
    message: 'Which candidates indicated interest in the position over text?',
    icon: <IconMessage color='green' />,
  },
  {
    message: 'What were common questions asked by candidates during the call?',
    icon: <IconInfoCircle color='orange' />,
  },
  {
    message: 'Which candidates wanted a pay under $23 / hour?',
    icon: <IconCurrencyDollar color='violet' />,
  },
];

export default TranscriptChat;
