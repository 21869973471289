import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import { Button, Modal } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { UseFormReturnType } from '@mantine/form';
import { ScriptInfo } from 'pages/editorv2/types';
import axios from 'api/axiosConfig';
import env from 'env';

import Template, { SequenceSettingsTemplate } from './Template';
import AddTemplateModal from './AddTemplateModal';

interface AddBackgroundInfoTemplateToJobModalProps {
  open: boolean;
  onClose: () => void;
  scriptInfo: UseFormReturnType<ScriptInfo, (values: ScriptInfo) => ScriptInfo>;
  orgBackgroundInfoTemplates: SequenceSettingsTemplate[];
  setOrgBackgroundInfoTemplates: Dispatch<
    SetStateAction<SequenceSettingsTemplate[]>
  >;
}

const AddBackgroundInfoTemplateToJobModal = ({
  open,
  onClose,
  scriptInfo,
  setOrgBackgroundInfoTemplates,
  orgBackgroundInfoTemplates,
}: AddBackgroundInfoTemplateToJobModalProps) => {
  const [templates, setTemplates] = useState<SequenceSettingsTemplate[]>(
    orgBackgroundInfoTemplates
  );
  const [selectedTemplates, setSelectedTemplates] = useState<string[]>([]);
  const [openAddTemplateModal, setOpenAddTemplateModal] = useState(false);
  const templatesContainerRef = useRef<HTMLDivElement | null>(null);

  const templatesInJob = scriptInfo.getInputProps(
    'backgroundInfoTemplateIds'
  ).value;

  const availableTemplates = templates.filter(
    (template) => !templatesInJob.includes(template.id)
  );

  const handleSelectTemplate = (tempalteId: string) => {
    if (selectedTemplates.includes(tempalteId)) {
      setSelectedTemplates((prev) => prev.filter((id) => id !== tempalteId));
    } else {
      setSelectedTemplates((prev) => [...prev, tempalteId]);
    }
  };

  const handleAddTemplateToJob = () => {
    try {
      const currentTemplates = scriptInfo.getInputProps(
        'backgroundInfoTemplateIds'
      ).value;
      const updatedTemplates = [...currentTemplates, ...selectedTemplates];
      scriptInfo.setValues({
        backgroundInfoTemplateIds: updatedTemplates,
      });
      setSelectedTemplates([]);
      onClose();
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: 'Failed to add template to job',
        color: 'red',
      });
    }
  };

  const handleAddTemplate = async (templateData: {
    title: string;
    instructions: string;
  }) => {
    try {
      const orgId = localStorage.getItem('orgId');
      const response = await axios.post(
        `${env.REACT_APP_SERVER_URL}/add_background_info_templates`,
        {
          orgId,
          title: templateData.title,
          instructions: templateData.instructions,
        }
      );

      const { id } = response.data;
      const template = { ...templateData, id };

      setOrgBackgroundInfoTemplates((prev) => [...prev, template]);
      setTemplates((prev) => [...prev, template]);
      setSelectedTemplates((prev) => [...prev, template.id]);

      setTimeout(() => {
        if (templatesContainerRef.current) {
          templatesContainerRef.current.scrollTo({
            top: templatesContainerRef.current.scrollHeight,
            behavior: 'smooth',
          });
        }
      }, 50);
    } catch (error) {
      throw new Error(String(error));
    }
  };

  return (
    <Modal
      title='Add template to job'
      opened={open}
      onClose={onClose}
      size='lg'
      styles={{
        body: {
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          overflow: 'hidden',
          maxHeight: '80vh',
        },
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          maxHeight: 'calc(80vh - 90px)',
          overflow: 'scroll',
        }}
        ref={templatesContainerRef}
      >
        {availableTemplates.map((template) => {
          const isSelected = selectedTemplates.includes(template.id);
          return (
            <div
              style={{ display: 'flex', flexDirection: 'column' }}
              key={template.id}
            >
              <Template
                template={template}
                showActionButtons={false}
                isSelectable
                isSelected={isSelected}
                onTemplateSelect={handleSelectTemplate}
              />
            </div>
          );
        })}
      </div>
      <div style={{ display: 'flex', alignSelf: 'flex-end', gap: '10px' }}>
        <Button variant='outline' onClick={() => setOpenAddTemplateModal(true)}>
          Create New Template
        </Button>
        <Button
          onClick={handleAddTemplateToJob}
          disabled={selectedTemplates.length < 1}
        >
          {`Save (${selectedTemplates.length})`}
        </Button>
      </div>
      <AddTemplateModal
        open={openAddTemplateModal}
        onClose={() => setOpenAddTemplateModal(false)}
        handleAddTemplate={handleAddTemplate}
      />
    </Modal>
  );
};

export default AddBackgroundInfoTemplateToJobModal;
