import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
  useContext,
  memo,
} from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import {
  Table,
  Center,
  Loader,
  Badge,
  ActionIcon,
  Skeleton,
  Drawer,
  Menu,
  rem,
  Tooltip,
  Checkbox,
  Group,
  Button,
  TextInput,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useAtomValue, useAtom } from 'jotai';
import { notifications } from '@mantine/notifications';
import { v4 as uuidv4 } from 'uuid';
import {
  IconRefresh,
  IconTrash,
  IconDotsVertical,
  IconPlayerPlay,
  IconPlayerPause,
  IconDownload,
  IconEye,
  IconX,
  IconSearch,
  IconMessage,
} from '@tabler/icons-react';
import axios from 'api/axiosConfig';
import env from 'env';
import SortableHeader from 'components/common/SortableHeader';
import { convertUpperSnakeToTitle } from 'utils/formatUtils';
import { formatPhoneNumber } from 'utils/phoneUtils';
import { useKeyboard } from 'hooks/useKeyboard';
import { useGetResumeURL } from 'hooks/useGetTranscriptData';
import { useCandidateCampaignReview } from 'hooks/useCandidateCampaignReview';
import {
  scheduleFollowUpAtom,
  candidateCampaignInfoDictAtom,
  atsIntegrationJobIdAtom,
} from 'pages/editorv2/atoms';
import { OverallReviewIndicator } from 'components/transcript/ReviewIndicator';
import CandidateFeedback from 'components/transcript/CandidateFeedback';
import TranscriptWrapper from 'components/transcript/TranscriptWrapper';
import {
  FilterContext,
  withFilterContextProvider,
} from 'pages/job-posting-tips/components/FilterContext';
import {
  CallStatusFilterState,
  CallLengthFilterState,
  ScoreContactFilterState,
  CompletionFilterState,
  SMSStatusFilterState,
  DateFilterState,
  FeedbackFilterState,
  NumberOfCallsFilterState,
  UnreadStatusFilterState,
} from 'pages/job-posting-tips/components/types';
import FilterBar from 'pages/job-posting-tips/components/FilterBar';
import TranscriptChat from 'components/transcript/TranscriptChat';
import { useGetBulkTranscriptData } from 'hooks/useGetBulkTranscriptData';
import './CampaignDetailsPage.css';
import {
  convertSecondsToMinutesAndSeconds,
  formatToLocalTime,
} from 'utils/dateUtils';
import { debounce } from 'lodash';

import CampaignDetailStatsRow from './CampaignDetailStatsRow';

import './CampaignDetailsPage.css';
import 'index.css';

const CallStatus = {
  PENDING: 'PENDING',
  PAUSED: 'PAUSED', // only if candidate is paused, not overall campaign paused
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  IN_PROGRESS: 'IN_PROGRESS',
  CANCELLED: 'CANCELLED',
  VOICEMAIL: 'VOICEMAIL',
  NO_ANSWER: 'NO_ANSWER',
  INVALID_NUMBER: 'INVALID_NUMBER',
  INVITED: 'INVITED',
  NOT_INTERESTED: 'NOT_INTERESTED',
  CONNECTED: 'CONNECTED',
  INITIATED: 'INITIATED',
  INCOMPLETE: 'INCOMPLETE',
};

const SMSStatus = {
  NOT_INTERESTED: 'NOT_INTERESTED',
  MESSAGE_SENT: 'MESSAGE_SENT',
  CANDIDATE_REPLIED: 'CANDIDATE_REPLIED',
  NONE: 'NONE',
};

const SortColumn = {
  LAST_UPDATED: 'last_updated',
  OVERALL_GRADE: 'overall_grade',
  COMPLETION_RATE: 'completion_rate',
  CALL_LENGTH_SEC: 'call_length_sec',
};

interface SortConfig {
  column: string;
  direction: 'ascending' | 'descending';
}

const COLLAPSE_THRESHOLD = 1400; // Note: threshold here AND css file

const PAGINATION_AMOUNT = 50;

// Add this interface before the CandidateRow component
interface CandidateRowProps {
  item: any;
  isCollapsed: boolean;
  isWebCall: any;
  selectedCandidateId: string | null;
  selectedCandidateIds: Set<string>;
  scheduleFollowUp: boolean;
  handleRowClick: (candidateId: string) => void;
  handleSelect: (candidateId: string) => void;
  handleToggleStatusClick: (
    candidateId: string,
    currentStatus: string
  ) => Promise<void>;
  handleRemoveClick: (candidateCampaignId: string) => void;
  candidateIdsNeedMeetingFetched: string[];
  meetingTimes: Record<string, string | null>;
  campaignId: string;
  userId: string;
  callStatusToColor: (candidate: any) => string;
  smsStatusToColor: (campaign_info: any) => string;
  candidateToCallStatus: (candidate: any) => string | undefined;
  candidateToBadegeCallStatus: (candidate: any) => string | undefined;
  candidateToSmsTooltipStatus: (campaign_info: any) => string;
  candidateToCallTooltipStatus: (candidate: any) => string;
  candidateToSmsBadgeStatus: (campaign_info: any) => string;
  candidateToLastCalled: (candidate: any) => string;
  candidateToRecentCallLength: (candidate: any) => string;
  candidateToNumberOfCalls: (candidate: any) => number;
  candidateToOverallGrade: (candidate: any) => string;
  candidateToCompletionRate: (candidate: any) => string;
  formatMeetingTime: (timestamp: string | null) => string;
  formatPhoneNumber: (
    phoneNumber: string,
    format?: 'national' | 'international'
  ) => string;
  applicationStages: Record<
    string,
    { id: string; remote_id: string; name: string; index: number } | null
  >;
  candidateIdsNeedStageFetched: string[];
  atsIntegrationJobId: string | null;
}

const hasCallHappened = (call: any) =>
  call?.call_status !== CallStatus.PENDING &&
  call?.call_status !== CallStatus.INVITED &&
  call?.call_status !== CallStatus.CANCELLED;

const CandidateRow = memo<CandidateRowProps>(
  ({
    item,
    isCollapsed,
    isWebCall,
    selectedCandidateId,
    selectedCandidateIds,
    scheduleFollowUp,
    handleRowClick,
    handleSelect,
    handleToggleStatusClick,
    handleRemoveClick,
    candidateIdsNeedMeetingFetched,
    meetingTimes,
    campaignId,
    userId,
    callStatusToColor,
    smsStatusToColor,
    candidateToCallStatus,
    candidateToBadegeCallStatus,
    candidateToSmsTooltipStatus,
    candidateToCallTooltipStatus,
    candidateToSmsBadgeStatus,
    candidateToLastCalled,
    candidateToRecentCallLength,
    candidateToNumberOfCalls,
    candidateToOverallGrade,
    candidateToCompletionRate,
    formatMeetingTime,
    formatPhoneNumber,
    applicationStages,
    candidateIdsNeedStageFetched,
    atsIntegrationJobId,
  }) => {
    const candidatePaused = useCallback(() => {
      return candidateToCallStatus(item.candidate) === CallStatus.PAUSED;
    }, [item.candidate, candidateToCallStatus]);

    const candidatePending = useCallback(() => {
      return candidateToCallStatus(item.candidate) === CallStatus.PENDING;
    }, [item.candidate, candidateToCallStatus]);

    const MenuItems = useMemo(
      () => (
        <>
          {candidatePaused() || candidatePending() ? (
            <Menu.Item
              leftSection={
                candidatePaused() ? (
                  <IconPlayerPlay
                    style={{
                      width: rem(14),
                      height: rem(14),
                    }}
                  />
                ) : (
                  <IconPlayerPause
                    style={{
                      width: rem(14),
                      height: rem(14),
                    }}
                  />
                )
              }
              onClick={() => {
                handleToggleStatusClick(
                  item.candidate.candidate_id,
                  candidateToCallStatus(item.candidate) || CallStatus.PENDING
                );
              }}
            >
              {candidatePaused() ? 'Resume' : 'Pause'}
            </Menu.Item>
          ) : (
            <Tooltip label='Calls Complete'>
              <Menu.Item
                leftSection={
                  <IconPlayerPause
                    style={{
                      width: rem(14),
                      height: rem(14),
                    }}
                  />
                }
                disabled={true}
                onClick={() => {}}
              >
                {'Pause'}
              </Menu.Item>
            </Tooltip>
          )}
          <Menu.Divider />
          <Menu.Item
            color='red'
            leftSection={
              <IconTrash style={{ width: rem(14), height: rem(14) }} />
            }
            onClick={() => {
              handleRemoveClick(item.campaign_info.candidate_campaign_id);
            }}
          >
            Remove
          </Menu.Item>
        </>
      ),
      [
        candidatePaused,
        candidatePending,
        handleToggleStatusClick,
        handleRemoveClick,
        item.campaign_info.candidate_campaign_id,
        item.candidate,
        candidateToCallStatus,
      ]
    );

    // Memoize the checkbox cell separately
    const checkboxCell = useMemo(() => {
      return (
        <Table.Td className='checkbox-cell'>
          <Checkbox
            checked={selectedCandidateIds.has(item.candidate.candidate_id)}
            onChange={() => handleSelect(item.candidate.candidate_id)}
            onClick={(e) => e.stopPropagation()}
          />
        </Table.Td>
      );
    }, [item.candidate.candidate_id, selectedCandidateIds, handleSelect]);

    // Memoize the name cell
    const nameCell = useMemo(() => {
      return (
        <Table.Td
          style={{ display: 'flex', alignItems: 'center', gap: '12px' }}
        >
          <OverallReviewIndicator
            campaignId={campaignId}
            candidateId={item.candidate.candidate_id}
          />
          {item.candidate.full_name}
        </Table.Td>
      );
    }, [item.candidate.candidate_id, item.candidate.full_name, campaignId]);

    // Memoize the row content calculation
    const rowContent = useMemo(() => {
      return (
        <>
          {checkboxCell}
          {nameCell}
          {!isCollapsed && (
            <Table.Td>
              {isWebCall
                ? item.candidate.email
                : formatPhoneNumber(item.candidate.phone_number)}
            </Table.Td>
          )}
          <Table.Td>{candidateToLastCalled(item)}</Table.Td>
          <Table.Td>{candidateToRecentCallLength(item)}</Table.Td>
          {!isWebCall && <Table.Td>{candidateToNumberOfCalls(item)}</Table.Td>}
          <Table.Td>{candidateToOverallGrade(item)}</Table.Td>
          <Table.Td>{candidateToCompletionRate(item)}</Table.Td>
          <Table.Td
            style={{
              width: '8%',
            }}
          >
            <CandidateFeedback
              userId={userId}
              campaignId={campaignId}
              candidateId={item.candidate.candidate_id}
              handleToggleStatusClick={handleToggleStatusClick}
              callStatus={candidateToCallStatus(item.candidate)}
            />
          </Table.Td>
          {!isCollapsed && !isWebCall && (
            <Table.Td className='no-ellipses'>
              <Tooltip
                label={candidateToSmsTooltipStatus(item.campaign_info)}
                openDelay={200}
              >
                <Badge
                  variant='light'
                  color={smsStatusToColor(item.campaign_info)}
                >
                  {candidateToSmsBadgeStatus(item.campaign_info)}
                </Badge>
              </Tooltip>
            </Table.Td>
          )}
          <Table.Td className='no-ellipses'>
            <Tooltip
              label={candidateToCallTooltipStatus(item.candidate)}
              openDelay={200}
            >
              <Badge variant='light' color={callStatusToColor(item.candidate)}>
                {candidateToBadegeCallStatus(item.candidate)}
              </Badge>
            </Tooltip>
          </Table.Td>
          {atsIntegrationJobId && (
            <Table.Td className='no-ellipses'>
              {candidateIdsNeedStageFetched.includes(
                item.candidate.candidate_id
              ) ? (
                <Skeleton height={8} />
              ) : (
                applicationStages[item.candidate.candidate_id]?.name || '-'
              )}
            </Table.Td>
          )}
          {scheduleFollowUp && !isCollapsed && (
            <Table.Td className='no-ellipses'>
              {candidateIdsNeedMeetingFetched.includes(
                item.candidate.candidate_id
              ) ? (
                <Skeleton height={8} />
              ) : (
                formatMeetingTime(
                  meetingTimes[item.candidate.candidate_id] || null
                )
              )}
            </Table.Td>
          )}
          <Table.Td
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            className='no-ellipses'
          >
            <Menu shadow='md' width={200}>
              <Menu.Target>
                <ActionIcon variant='subtle'>
                  <IconDotsVertical size={20} />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Label>{item.candidate.full_name}</Menu.Label>
                {MenuItems}
              </Menu.Dropdown>
            </Menu>
          </Table.Td>
        </>
      );
    }, [
      item,
      callStatusToColor,
      smsStatusToColor,
      candidateToBadegeCallStatus,
      candidateToSmsTooltipStatus,
      candidateToCallTooltipStatus,
      candidateToSmsBadgeStatus,
      candidateToLastCalled,
      candidateToRecentCallLength,
      candidateToNumberOfCalls,
      formatMeetingTime,
      isCollapsed,
      isWebCall,
      formatPhoneNumber,
      candidateIdsNeedMeetingFetched,
      meetingTimes,
      campaignId,
      userId,
      MenuItems,
      candidateToCompletionRate,
      candidateToOverallGrade,
      candidateToCallStatus,
      handleToggleStatusClick,
      scheduleFollowUp,
      checkboxCell,
      nameCell,
      applicationStages,
      candidateIdsNeedStageFetched,
      atsIntegrationJobId,
    ]);

    return (
      <Table.Tr
        style={{ cursor: 'pointer' }}
        onClick={(e) => {
          if ((e.target as HTMLElement).closest('.checkbox-cell')) {
            return;
          }
          handleRowClick(item.candidate.candidate_id);
        }}
        bg={
          selectedCandidateIds.has(item.candidate.candidate_id)
            ? 'var(--mantine-color-blue-0)'
            : selectedCandidateId === item.candidate.candidate_id
              ? 'var(--mantine-color-gray-1)'
              : undefined
        }
      >
        {rowContent}
      </Table.Tr>
    );
  },
  (prevProps, nextProps) => {
    const shouldUpdate =
      prevProps.item === nextProps.item &&
      prevProps.isCollapsed === nextProps.isCollapsed &&
      prevProps.isWebCall === nextProps.isWebCall &&
      prevProps.selectedCandidateId === nextProps.selectedCandidateId &&
      prevProps.selectedCandidateIds === nextProps.selectedCandidateIds &&
      prevProps.meetingTimes === nextProps.meetingTimes &&
      JSON.stringify(prevProps.candidateIdsNeedStageFetched) ===
        JSON.stringify(nextProps.candidateIdsNeedStageFetched);
    return shouldUpdate;
  }
);

const CampaignDetailsPage = ({ isWebCall }) => {
  const [sort, setSort] = useState<SortConfig | undefined>({
    column: SortColumn.COMPLETION_RATE,
    direction: 'descending',
  });
  const handleSort = (column: string) => () => {
    setIsFetchingCalls(true);
    if (sort && sort.column === column) {
      if (sort.direction === 'descending')
        setSort({ column, direction: 'ascending' });
      else setSort(undefined);
    } else {
      setSort({ column, direction: 'descending' });
    }
  };

  const orgId = localStorage.getItem('orgId');
  const userId = localStorage.getItem('userId') || '';

  const scheduleFollowUp = useAtomValue(scheduleFollowUpAtom); // Subscribes to atom updates
  const [, setCandidateCampaignInfoDictAtom] = useAtom(
    candidateCampaignInfoDictAtom
  ); // Subscribes to atom updates
  // const [, setScriptInfoAtom] = useAtom(scriptInfoAtom);
  const atsIntegrationJobId = useAtomValue(atsIntegrationJobIdAtom);
  const [isCollapsed, setIsCollapsed] = useState(
    window.innerWidth <= COLLAPSE_THRESHOLD
  );

  useEffect(() => {
    const handleResize = () => {
      setIsCollapsed(window.innerWidth <= COLLAPSE_THRESHOLD);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial state based on window width

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const candidateId = searchParams.get('candidateId');

  const [totalCount, setTotalCount] = useState(0);
  const [isFetchingCalls, setIsFetchingCalls] = useState(true);
  const [candidates, setCandidates] = useState<any>([]);
  const [meetingTimes, setMeetingTimes] = useState<
    Record<string, string | null>
  >({});
  const [selectedCandidateId, setSelectedCandidateId] = useState<string | null>(
    candidateId
  );
  const [, setHighlightedCandidateId] = useState<string | null>(candidateId);

  const [transcriptOpened, { open: openTranscript, close: closeTranscript }] =
    useDisclosure(false);

  const location = useLocation();
  const match = location.pathname.match(
    /\/scripts\/script-editor\/(new(?:-phone|-web)?\/)?([^/]+)/
  );
  const isNew = !!match?.[1]; // 'new/' part exists if match[1] is truthy

  useEffect(() => {
    if (!selectedCandidateId) {
      return;
    }

    const queryParams = new URLSearchParams(location.search);
    queryParams.set('candidateId', selectedCandidateId);
    navigate(`${location.pathname}?${queryParams.toString()}`, {
      replace: true,
    });
    openTranscript();
  }, [
    location.pathname,
    location.search,
    navigate,
    openTranscript,
    selectedCandidateId,
  ]);

  const { campaignId: paramCampaignId } = useParams();
  const campaignId = match ? match[2] : paramCampaignId || '';

  const fetchLockRef = useRef<string | null>(null);

  const { markAllAsReviewed } = useCandidateCampaignReview();

  const [searchValue, setSearchValue] = useState('');

  const filterState = useContext(FilterContext);
  const callStatusFilterState = filterState?.activeFilters?.find(
    (filter) => filter.type === 'callStatus'
  )?.state as CallStatusFilterState;
  const callLengthFilterState = filterState?.activeFilters?.find(
    (filter) => filter.type === 'callLength'
  )?.state as CallLengthFilterState;
  const scoreContactFilterState = filterState?.activeFilters?.find(
    (filter) => filter.type === 'scoreContact'
  )?.state as ScoreContactFilterState;
  const completionFilterState = filterState?.activeFilters?.find(
    (filter) => filter.type === 'completion'
  )?.state as CompletionFilterState;
  const lastCalledFilterState = filterState?.activeFilters?.find(
    (filter) => filter.type === 'lastCalled'
  )?.state as DateFilterState;
  const smsStatusFilterState = filterState?.activeFilters?.find(
    (filter) => filter.type === 'smsStatus'
  )?.state as SMSStatusFilterState;
  const feedbackFilterState = filterState?.activeFilters?.find(
    (filter) => filter.type === 'feedback'
  )?.state as FeedbackFilterState;
  const numberOfCallsFilterState = filterState?.activeFilters?.find(
    (filter) => filter.type === 'numberOfCalls'
  )?.state as NumberOfCallsFilterState;
  const unreadStatusFilterState = filterState?.activeFilters?.find(
    (filter) => filter.type === 'unreadStatus'
  )?.state as UnreadStatusFilterState;

  const handleArrowKeyPress = (step: number) => {
    if (!selectedCandidateId) {
      return;
    }

    const currentIndex = candidates.findIndex(
      (candidate) => candidate.candidate.candidate_id === selectedCandidateId
    );

    if (currentIndex === -1) {
      return;
    }

    const nextCandidateIndex = currentIndex + step;

    if (nextCandidateIndex < 0 || nextCandidateIndex >= candidates.length) {
      return;
    }

    const nextCandidate = candidates[nextCandidateIndex];
    setSelectedCandidateId(nextCandidate.candidate.candidate_id);
  };

  useKeyboard('ArrowUp', () => {
    handleArrowKeyPress(-1);
  });

  useKeyboard('ArrowDown', () => {
    handleArrowKeyPress(1);
  });

  const [allCandidateIds, setAllCandidateIds] = useState<string[]>([]);

  const getNumberOfCalls = useCallback(
    (item: { candidate: { calls: any[]; candidate_id: string } }) => {
      return item.candidate.calls.filter(hasCallHappened).length || 0;
    },
    [] // Remove hasCallHappened from dependencies since it's now a constant function
  );

  const cancelCurrentFetch = () => {
    fetchLockRef.current = '' + uuidv4();
  };

  const fetchCandidates = useCallback(
    async ({ limit, skip, silent = false }) => {
      if (!campaignId) return;
      if (fetchLockRef.current && silent) {
        return;
      }
      const refLockKey = '' + uuidv4();

      try {
        fetchLockRef.current = refLockKey;
        if (!silent) {
          setIsFetchingCalls(true);
        }
        const fetchedAt = Date.now();
        const response = await axios.post(
          `${env.REACT_APP_SERVER_URL}/campaigns/campaign/${campaignId}/candidates_and_calls?skip=${skip}&limit=${limit}`,
          {
            sort: sort
              ? {
                  column: sort.column,
                  direction: sort.direction,
                }
              : undefined,
            filters: {
              search: searchValue || undefined,
              callStatus: callStatusFilterState?.selectedStatuses,
              callLength: callLengthFilterState
                ? {
                    min: callLengthFilterState.minLength,
                    max: callLengthFilterState.maxLength,
                  }
                : undefined,
              score: scoreContactFilterState
                ? {
                    min: scoreContactFilterState.minScore,
                    max: scoreContactFilterState.maxScore,
                  }
                : undefined,
              completion: completionFilterState
                ? {
                    min: completionFilterState.minCompletion,
                    max: completionFilterState.maxCompletion,
                  }
                : undefined,
              lastCalled: lastCalledFilterState?.isEmpty
                ? undefined
                : {
                    startDate: lastCalledFilterState?.startDate,
                    endDate: lastCalledFilterState?.endDate,
                  },
              smsStatus: smsStatusFilterState?.selectedStatuses,
              feedback: feedbackFilterState?.selectedFeedback,
              numberOfCalls: numberOfCallsFilterState?.isEmpty === false && {
                min: numberOfCallsFilterState?.minCalls,
                max: numberOfCallsFilterState?.maxCalls,
              },
              unreadStatus: unreadStatusFilterState?.selectedStatuses,
            },
            isNew,
            userId,
            orgId,
          }
        );

        if (fetchLockRef.current === refLockKey) {
          // don't set candidates if newer fetch started
          setCandidates((prev) => {
            if (silent) {
              return [...response.data.candidates];
            }
            return [...prev, ...response.data.candidates]; // Append new candidates
          });
          setTotalCount(response.data.total_records || 0);
          // Set candidate info atoms to be globally accessible
          setCandidateCampaignInfoDictAtom((prev) => {
            const updatedCampaignInfos = response.data.candidates.reduce(
              (acc, c) => {
                const candidateCampaignInfoKey =
                  c.candidate.candidate_id + '_' + campaignId;
                acc[candidateCampaignInfoKey] = {
                  feedback: c.campaign_info.feedback,
                  isCallReviewed: !c.review_items?.call_needs_review,
                  isSMSReviewed: !c.review_items?.sms_needs_review,
                  isEmailReviewed: !c.review_items?.email_needs_review,
                  hasComments: c.has_comments,
                  access: c.campaign_info.access,
                  campaignOrgId: response.data.campaign_org_id,
                  _fetchedAt: fetchedAt,
                };
                return acc;
              },
              { ...prev }
            );
            return updatedCampaignInfos;
          });
          // Store all candidate IDs
          setAllCandidateIds(response.data.all_candidate_ids);
        }
      } catch (error) {
        console.error('Error fetching candidates:', error);
        if (!isNew) {
          notifications.show({
            title: 'There was an error retrieving the campaign calls',
            message: error instanceof Error ? error.message : '',
            color: 'red',
          });
        }
      } finally {
        if (fetchLockRef.current === refLockKey) {
          setIsFetchingCalls(false);
          fetchLockRef.current = null;
        }
      }
    },
    [
      setCandidates,
      campaignId,
      sort,
      isNew,
      setCandidateCampaignInfoDictAtom,
      userId,
      searchValue,
      orgId,
      callStatusFilterState,
      callLengthFilterState,
      scoreContactFilterState,
      completionFilterState,
      lastCalledFilterState,
      smsStatusFilterState,
      feedbackFilterState,
      numberOfCallsFilterState,
      unreadStatusFilterState,
    ]
  );

  const candidateIdsNeedMeetingFetched = useMemo(() => {
    return candidates
      .map((candidate) => candidate.candidate.candidate_id)
      .filter((id) => !(id in meetingTimes));
  }, [candidates, meetingTimes]);

  const fetchMeetingTimes = useCallback(
    async () => {
      if (!campaignId) return;

      try {
        // If all candidate_ids already have meeting times, do nothing
        if (candidateIdsNeedMeetingFetched.length === 0) return;

        // Fetch meeting times for missing candidate_ids
        const response = await axios.post(
          `${env.REACT_APP_SERVER_URL}/campaign/get_meeting_times/${campaignId}`,
          { candidate_ids: candidateIdsNeedMeetingFetched } // Pass candidate_ids as a payload
        );

        const fetchedMeetingTimes = response.data || {};
        // Transform the map into a dictionary (no transformation needed if it's already in the right format)
        const newMeetingTimesMap = Object.entries(fetchedMeetingTimes).reduce(
          (acc, [candidate_id, meeting_time]) => {
            acc[candidate_id] = meeting_time || null;
            return acc;
          },
          {}
        );
        // Merge new meeting times with the existing meetingTimesMap
        setMeetingTimes((prevMeetingTimesMap) => ({
          ...prevMeetingTimesMap,
          ...newMeetingTimesMap,
        }));
      } catch (error) {
        console.error('Error fetching meeting times:', error);
        notifications.show({
          title: 'Error retrieving meeting times',
          message: error instanceof Error ? error.message : '',
          color: 'red',
        });
        const noMeetingTimesMap = candidateIdsNeedMeetingFetched.reduce(
          (map, id) => {
            map[id] = null; // Set the default value for each id
            return map;
          },
          {}
        );
        setMeetingTimes((prevMeetingTimesMap) => ({
          ...prevMeetingTimesMap,
          ...noMeetingTimesMap,
        }));
      }
    },
    [campaignId, candidateIdsNeedMeetingFetched] // Add dependencies
  );

  useGetBulkTranscriptData({ candidates, campaignId });

  useEffect(() => {
    if (!campaignId) return;

    fetchMeetingTimes();
  }, [campaignId, fetchMeetingTimes]);

  const debouncedFetchCandidates = useMemo(
    () =>
      debounce((limit: number, skip: number, silent: boolean = false) => {
        if (!campaignId) return;
        if (!silent) {
          setIsFetchingCalls(true);
        }
        if (skip === 0 && !silent) {
          setCandidates([]);
          setTotalCount(0);
        }
        fetchCandidates({
          limit: limit,
          skip: skip,
          silent: silent,
        });
      }, 300),
    [fetchCandidates, campaignId]
  );

  const [multiCandidateChatOpened, setMultiCandidateChatOpened] =
    useState(false);

  const useDeepCompareEffect = (effect, dependencies) => {
    // NOTE: something strange was happening when we applied a filter AND opened the transcript drawer
    // Likely due to the navitation, the useEffect depending on the filter dependencies would re-fire
    // even though the filter values hadn't changed.
    // This is a custom useEffect that uses a deep comparison of the dependencies array
    // to determine if the effect should actually run.
    const dependenciesString = JSON.stringify(dependencies);
    const ref = useRef(dependenciesString);
    const isFirstRender = useRef(true);

    useEffect(() => {
      // Always run on the first render
      if (isFirstRender.current) {
        isFirstRender.current = false;
        return effect();
      }

      // For subsequent renders, only run if dependencies actually changed
      if (ref.current !== dependenciesString) {
        ref.current = dependenciesString;
        return effect();
      }
    }, [dependenciesString, effect]);
  };

  useDeepCompareEffect(() => {
    if (!campaignId) return;

    setIsFetchingCalls(true);
    setCandidates([]);
    debouncedFetchCandidates(PAGINATION_AMOUNT, 0);
  }, [
    searchValue,
    sort,
    callStatusFilterState,
    callLengthFilterState,
    scoreContactFilterState,
    completionFilterState,
    lastCalledFilterState,
    smsStatusFilterState,
    feedbackFilterState,
    numberOfCallsFilterState,
    unreadStatusFilterState?.selectedStatuses,
    campaignId,
    // No need to include debouncedFetchCandidates
  ]);

  useGetResumeURL({
    candidateId: selectedCandidateId || '',
    campaignId,
  });

  const handleRowClick = useCallback((candidateId: string) => {
    setSelectedCandidateId(candidateId);
    setHighlightedCandidateId(candidateId);
  }, []);

  const candidateToCallStatus = useCallback((candidate): string => {
    const { calls } = candidate;
    if (calls && calls.length > 0) {
      const lastCall = calls[0];
      return lastCall.call_status || CallStatus.PENDING;
    }
    return CallStatus.PENDING;
  }, []);

  const candidatePaused = (candidate) => {
    return candidateToCallStatus(candidate) === CallStatus.PAUSED;
  };

  const handleTranscriptClose = useCallback(() => {
    closeTranscript();
    setSelectedCandidateId(null);
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete('candidateId');
    navigate(`${location.pathname}?${queryParams.toString()}`, {
      replace: true,
    });
  }, [closeTranscript, location.pathname, location.search, navigate]);

  const transcriptDrawerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      // TODO: Use children ref instead of targetting overlay
      const drawerOverlay = document.querySelector('.mantine-Drawer-overlay');
      const transcriptModal = document.querySelector('.share-transcript-modal');

      const isClickInsideDrawer =
        transcriptDrawerRef.current &&
        transcriptDrawerRef.current.contains(e.target as Node);

      const isClickInsideModal =
        transcriptModal && transcriptModal.contains(e.target as Node);

      const trElement = (e.target as HTMLElement).closest('tr');

      if (
        isClickInsideDrawer ||
        trElement ||
        drawerOverlay ||
        isClickInsideModal
      ) {
        // If click is inside the drawer or on a <tr>, do nothing
        return;
      }

      // Otherwise, close the drawer
      handleTranscriptClose();
    };

    if (transcriptOpened) {
      window.addEventListener('click', handleClickOutside);
    }

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [transcriptOpened, handleTranscriptClose]);

  const handleToggleStatusClick = async (
    candidateId: string,
    currentStatus: string
  ) => {
    const action = currentStatus === CallStatus.PAUSED ? 'resume' : 'pause';
    const newStatus =
      currentStatus === CallStatus.PAUSED
        ? CallStatus.PENDING
        : CallStatus.PAUSED;
    try {
      cancelCurrentFetch();
      await axios.post(
        `${env.REACT_APP_SERVER_URL}/candidate/${candidateId}/campaign/${campaignId}/${action}`
      );
      setCandidates((prevCandidates) => {
        const updatedCandidates = prevCandidates.map((item) =>
          item.candidate.candidate_id === candidateId
            ? {
                ...item,
                candidate: {
                  ...item.candidate,
                  calls:
                    item.candidate.calls && item.candidate.calls.length > 0
                      ? [
                          {
                            ...item.candidate.calls[0],
                            call_status: newStatus,
                          },
                          ...item.candidate.calls.slice(1),
                        ]
                      : item.candidate.calls,
                },
              }
            : item
        );
        return updatedCandidates;
      });
    } catch (error) {
      console.error('Error updating candidate call status:', error);
      notifications.show({
        title: 'Error updating candidate call status',
        message: error instanceof Error ? error.message : '',
        color: 'red',
      });
    }
  };

  const handleRemoveClick = async (candidateCampaignId: string) => {
    const originalCandidates = [...candidates];
    // optimistic update
    cancelCurrentFetch();
    setCandidates((prevCandidates) =>
      prevCandidates.filter(
        (candidate) =>
          candidate.campaign_info.candidate_campaign_id !== candidateCampaignId
      )
    );
    try {
      await axios.post(
        `${env.REACT_APP_SERVER_URL}/candidate/${candidateCampaignId}/remove`
      );
    } catch (error) {
      console.error('Error removing candidate:', error);
      notifications.show({
        title: 'Error removing candidate',
        message: error instanceof Error ? error.message : '',
        color: 'red',
      });
      // rollback optimistic update on failure
      setCandidates(originalCandidates);
    }
  };

  useEffect(() => {
    let delay = 10_000;

    const handleInterval = () => {
      debouncedFetchCandidates(
        Math.max(candidates.length, PAGINATION_AMOUNT),
        0,
        true
      );
      if (delay < 1_000_000) {
        delay *= 3;
      }
      clearInterval(intervalId);
      intervalId = setInterval(handleInterval, delay);
    };

    let intervalId = setInterval(handleInterval, delay);

    return () => clearInterval(intervalId);
  }, [debouncedFetchCandidates, candidates.length]);

  const [selectedCandidateIds, setSelectedCandidateIds] = useState<Set<string>>(
    new Set()
  );

  const handleSelect = useCallback((candidateId: string) => {
    setSelectedCandidateIds((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(candidateId)) {
        newSet.delete(candidateId);
      } else {
        newSet.add(candidateId);
      }
      return newSet;
    });
  }, []);

  const handleSelectAll = () => {
    if (selectedCandidateIds.size > 0) {
      setSelectedCandidateIds(new Set());
    } else {
      setSelectedCandidateIds(new Set(allCandidateIds));
    }
  };

  const handleBulkCancel = async () => {
    const isAllSelected = selectedCandidateIds.size === allCandidateIds.length;
    const idsToCancel = isAllSelected
      ? allCandidateIds
      : Array.from(selectedCandidateIds);

    const originalCandidates = [...candidates];

    setCandidates((prevCandidates) =>
      prevCandidates.map((candidate) => {
        if (idsToCancel.includes(candidate.candidate.candidate_id)) {
          return {
            ...candidate,
            candidate: {
              ...candidate.candidate,
              calls: candidate.candidate.calls.map((call) => ({
                ...call,
                call_status: 'CANCELLED',
              })),
            },
          };
        }
        return candidate;
      })
    );

    try {
      cancelCurrentFetch();
      await axios.post(`${env.REACT_APP_SERVER_URL}/candidates/bulk_cancel`, {
        campaignId,
        candidateIds: idsToCancel,
      });

      setSelectedCandidateIds(new Set());
    } catch (error) {
      console.error('Error cancelling calls:', error);
      notifications.show({
        title: 'Error cancelling calls',
        message: error instanceof Error ? error.message : '',
        color: 'red',
      });
      setCandidates(originalCandidates);
    }
  };

  const handleBulkRemove = async () => {
    const isAllSelected = selectedCandidateIds.size === allCandidateIds.length;
    const idsToRemove = isAllSelected
      ? allCandidateIds
      : Array.from(selectedCandidateIds);

    const originalCandidates = [...candidates];

    cancelCurrentFetch();
    setCandidates((prevCandidates) =>
      prevCandidates.filter(
        (candidate) => !idsToRemove.includes(candidate.candidate.candidate_id)
      )
    );

    try {
      await axios.post(`${env.REACT_APP_SERVER_URL}/candidates/bulk_remove`, {
        campaignId,
        candidateIds: idsToRemove,
      });

      setSelectedCandidateIds(new Set());
    } catch (error) {
      console.error('Error removing candidates:', error);
      notifications.show({
        title: 'Error removing candidates',
        message: error instanceof Error ? error.message : '',
        color: 'red',
      });
      setCandidates(originalCandidates);
    }
  };

  const handleBulkPauseResume = async () => {
    const isAllSelected = selectedCandidateIds.size === allCandidateIds.length;
    const idsToUpdate = isAllSelected
      ? allCandidateIds
      : Array.from(selectedCandidateIds);

    const anyPlaying = idsToUpdate.some((id) => {
      const candidate = candidates.find((c) => c.candidate.candidate_id === id);
      return candidate && !candidatePaused(candidate.candidate);
    });

    const action = anyPlaying ? 'pause' : 'resume';
    const actionText = anyPlaying ? 'Pause' : 'Resume';
    const originalCandidates = [...candidates];

    setCandidates((prevCandidates) =>
      prevCandidates.map((candidate) => {
        if (idsToUpdate.includes(candidate.candidate.candidate_id)) {
          return {
            ...candidate,
            candidate: {
              ...candidate.candidate,
              calls: candidate.candidate.calls.map((call) => ({
                ...call,
                call_status: anyPlaying
                  ? CallStatus.PAUSED
                  : CallStatus.PENDING,
              })),
            },
          };
        }
        return candidate;
      })
    );

    try {
      cancelCurrentFetch();
      await axios.post(
        `${env.REACT_APP_SERVER_URL}/candidate/bulk_pause_resume`,
        {
          campaignId,
          candidateIds: idsToUpdate,
          action,
        }
      );

      setSelectedCandidateIds(new Set());
    } catch (error) {
      console.error(`Error ${actionText.toLowerCase()}ing candidates:`, error);
      notifications.show({
        title: `Error ${actionText.toLowerCase()}ing candidates`,
        message: error instanceof Error ? error.message : '',
        color: 'red',
      });
      setCandidates(originalCandidates);
    }
  };

  const handleBulkDownload = async () => {
    const isAllSelected = selectedCandidateIds.size === allCandidateIds.length;
    const idsToExport = isAllSelected
      ? allCandidateIds
      : Array.from(selectedCandidateIds);

    try {
      const response = await axios.post(
        `${env.REACT_APP_SERVER_URL}/candidate/bulk_export`,
        {
          campaignId,
          candidateIds: idsToExport,
        },
        {
          responseType: 'blob',
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `campaign_${campaignId}_export.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading candidates:', error);
      notifications.show({
        title: 'Error downloading candidates',
        message: error instanceof Error ? error.message : '',
        color: 'red',
      });
    }
  };

  const handleBulkMarkAsRead = async () => {
    const isAllSelected = selectedCandidateIds.size === allCandidateIds.length;
    const idsToUpdate = isAllSelected
      ? allCandidateIds
      : Array.from(selectedCandidateIds);

    try {
      await markAllAsReviewed(campaignId, userId, new Set(idsToUpdate));

      setSelectedCandidateIds(new Set());
    } catch (error) {
      console.error('Error marking candidates as reviewed:', error);
      notifications.show({
        title: 'Error marking candidates as reviewed',
        message: error instanceof Error ? error.message : '',
        color: 'red',
      });
    }
  };

  const handleBulkChat = () => {
    setMultiCandidateChatOpened(true);
  };

  const handleCloseMultiCandidateChat = useCallback(() => {
    setMultiCandidateChatOpened(false);
    debouncedFetchCandidates(PAGINATION_AMOUNT, 0, true);
  }, [debouncedFetchCandidates]);

  const handleCandidateIdSelected = useCallback(
    (candidateId: string) => {
      setSelectedCandidateId(candidateId);
      setHighlightedCandidateId(candidateId);
      openTranscript();
    },
    [openTranscript]
  );

  const renderBulkActions = () => {
    if (selectedCandidateIds.size === 0) return null;

    const anyPlaying = Array.from(selectedCandidateIds).some((id) => {
      const candidate = candidates.find((c) => c.candidate.candidate_id === id);
      return candidate && !candidatePaused(candidate.candidate);
    });

    return (
      <div
        style={{
          position: 'absolute',
          top: '10px',
          left: 0,
          right: 0,
          padding: '8px 16px',
          background: 'white',
          // }} FILTER PR REBASE scroll handling:
          // onScroll={handleScroll}
          // BULK PR STYING:
          borderRadius: '8px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          display: 'flex',
          alignItems: 'center',
          gap: '12px',
          zIndex: 100,
          border: '1px solid var(--salv-dark-0)',
        }}
      >
        <div style={{ fontWeight: 500, color: 'var(--mantine-color-gray-7)' }}>
          {selectedCandidateIds.size} selected
        </div>
        <Button.Group>
          <Button
            size='xs'
            variant='subtle'
            onClick={handleBulkChat}
            leftSection={<IconMessage size={16} />}
          >
            Chat Assistant
          </Button>
          <Button
            size='xs'
            variant='subtle'
            onClick={handleBulkDownload}
            leftSection={<IconDownload size={16} />}
          >
            Export
          </Button>
          <Button
            size='xs'
            variant='subtle'
            onClick={handleBulkMarkAsRead}
            leftSection={<IconEye size={16} />}
          >
            Mark as Read
          </Button>
          <Button
            size='xs'
            variant='subtle'
            onClick={handleBulkPauseResume}
            leftSection={
              anyPlaying ? (
                <IconPlayerPause size={16} />
              ) : (
                <IconPlayerPlay size={16} />
              )
            }
          >
            {anyPlaying ? 'Pause' : 'Resume'} Calls
          </Button>
          <Button
            size='xs'
            variant='subtle'
            onClick={handleBulkCancel}
            leftSection={<IconX size={16} />}
            color='yellow'
          >
            Cancel Calls
          </Button>
          <Button
            size='xs'
            variant='subtle'
            onClick={handleBulkRemove}
            leftSection={<IconTrash size={16} />}
            color='red'
          >
            Remove
          </Button>
        </Button.Group>
      </div>
    );
  };

  const getSelectAllState = () => {
    if (selectedCandidateIds.size === 0) return false;
    if (selectedCandidateIds.size === allCandidateIds.length) return true;
    return 'indeterminate';
  };

  // Memoize expensive calculation functions
  const memoizedCallStatusToColor = useCallback(
    (candidate) => {
      const callStatus = candidateToCallStatus(candidate);
      switch (callStatus) {
        case CallStatus.COMPLETED:
          return 'green';
        case CallStatus.INVITED:
        case CallStatus.VOICEMAIL:
          return 'blue';
        case CallStatus.CANCELLED:
        case CallStatus.NOT_INTERESTED:
        case CallStatus.INVALID_NUMBER:
        case CallStatus.FAILED:
          return 'red';
        case CallStatus.INITIATED:
        case CallStatus.CONNECTED:
        case CallStatus.NO_ANSWER:
          return 'yellow';
        case CallStatus.PENDING:
        default:
          return 'gray';
      }
    },
    [candidateToCallStatus]
  );

  const memoizedSmsStatusToColor = useCallback((campaign_info) => {
    switch (campaign_info?.sms_status) {
      case CallStatus.NOT_INTERESTED:
        return 'red';
      case SMSStatus.MESSAGE_SENT:
        return 'blue';
      case SMSStatus.CANDIDATE_REPLIED:
        return 'green';
      case SMSStatus.NONE:
        return 'gray';
      default:
        return 'gray';
    }
  }, []);

  const memoizedCandidateToCallStatus = useCallback((candidate) => {
    const { calls } = candidate;
    if (calls && calls.length > 0) {
      const lastCall = calls[0];
      return lastCall.call_status || undefined;
    }
    return undefined;
  }, []);

  const memoizedCandidateToBadegeCallStatus = useCallback((candidate) => {
    const { calls } = candidate;
    if (calls && calls.length > 0) {
      const lastCall = calls[0];
      const lastCallStatus = lastCall.call_status;
      if (lastCallStatus === CallStatus.INCOMPLETE) {
        return 'Hung Up';
      }
      if (lastCallStatus === CallStatus.PENDING) {
        return 'Pending';
      }
      return convertUpperSnakeToTitle(lastCall.call_status);
    }
  }, []);

  const memoizedCandidateToSmsTooltipStatus = useCallback((campaign_info) => {
    return convertUpperSnakeToTitle(campaign_info?.sms_status || 'NONE');
  }, []);

  const memoizedCandidateToCallTooltipStatus = useCallback(
    (candidate) => {
      return convertUpperSnakeToTitle(
        candidateToCallStatus(candidate) || 'NONE'
      );
    },
    [candidateToCallStatus]
  );

  const memoizedCandidateToSmsBadgeStatus = useCallback((campaign_info) => {
    const display_status = (() => {
      switch (campaign_info?.sms_status) {
        case SMSStatus.NOT_INTERESTED:
          return 'NOT INTERESTED';
        case SMSStatus.MESSAGE_SENT:
          return 'SENT';
        case SMSStatus.CANDIDATE_REPLIED:
          return 'REPLIED';
        default:
          return campaign_info?.sms_status;
      }
    })();
    return convertUpperSnakeToTitle(display_status || 'NONE');
  }, []);

  const memoizedCandidateToLastCalled = useCallback(
    (candidate) => {
      const numCalls = getNumberOfCalls(candidate);
      return numCalls > 0
        ? formatToLocalTime(candidate.max_last_updated) || '-'
        : '-';
    },
    [getNumberOfCalls]
  );

  const memoizedCandidateToRecentCallLength = useCallback(
    (candidate) => {
      const max_call_length_sec = candidate.max_call_length_sec;
      const numCalls = getNumberOfCalls(candidate);
      return max_call_length_sec && numCalls > 0
        ? convertSecondsToMinutesAndSeconds(max_call_length_sec)
        : '-';
    },
    [getNumberOfCalls]
  );

  const memoizedCandidateToOverallGrade = useCallback(
    (candidate) => {
      const numCalls = getNumberOfCalls(candidate);
      if (numCalls === 0) {
        return '-';
      }
      if (!candidate.max_overall_grade && candidate.max_overall_grade !== 0) {
        return '-';
      }
      return `${candidate.max_overall_grade?.toFixed(0)}%` || '-';
    },
    [getNumberOfCalls]
  );

  const memoizedCandidateToCompletionRate = useCallback(
    (candidate) => {
      const numCalls = getNumberOfCalls(candidate);
      if (numCalls === 0) {
        return '-';
      }
      if (
        !candidate.max_completion_rate &&
        candidate.max_completion_rate !== 0
      ) {
        return '-';
      }
      return `${(candidate.max_completion_rate * 100).toFixed(0)}%` || '-';
    },
    [getNumberOfCalls]
  );

  const memoizedFormatMeetingTime = useCallback((timestamp) => {
    if (!timestamp) return '-';
    return formatToLocalTime(timestamp);
  }, []);

  // Also add tracing for the transcript drawer's useEffect
  useEffect(() => {
    if (!selectedCandidateId) {
      return;
    }

    const queryParams = new URLSearchParams(location.search);
    queryParams.set('candidateId', selectedCandidateId);
    navigate(`${location.pathname}?${queryParams.toString()}`, {
      replace: true,
    });
    openTranscript();
  }, [
    location.pathname,
    location.search,
    navigate,
    openTranscript,
    selectedCandidateId,
  ]);

  const [applicationStages, setApplicationStages] = useState<
    Record<
      string,
      { id: string; remote_id: string; name: string; index: number } | null
    >
  >({});

  const candidateIdsNeedStageFetched = useMemo(() => {
    return candidates
      .map((candidate) => candidate.candidate.candidate_id)
      .filter((id) => !(id in applicationStages));
  }, [candidates, applicationStages]);

  const fetchApplicationStages = useCallback(async () => {
    if (!campaignId || !atsIntegrationJobId) return;

    try {
      if (candidateIdsNeedStageFetched.length === 0) return;

      // Fetch application stages for missing candidate_ids
      const response = await axios.post(
        `${env.REACT_APP_SERVER_URL}/kombo/get_application_stages`,
        {
          campaignId: campaignId,
          candidateIds: candidateIdsNeedStageFetched,
        }
      );

      const fetchedApplicationStages = response.data || {};

      // Merge new application stages with the existing applicationStages
      setApplicationStages((prevApplicationStages) => ({
        ...prevApplicationStages,
        ...fetchedApplicationStages,
      }));
    } catch (error) {
      console.error('Error fetching application stages:', error);
      notifications.show({
        title: 'Error retrieving application stages',
        message: error instanceof Error ? error.message : '',
        color: 'red',
      });
      const noStagesMap = candidateIdsNeedStageFetched.reduce((map, id) => {
        map[id] = null; // Set the default value for each id
        return map;
      }, {});
      setApplicationStages((prevApplicationStages) => ({
        ...prevApplicationStages,
        ...noStagesMap,
      }));
    }
  }, [campaignId, candidateIdsNeedStageFetched, atsIntegrationJobId]);

  useEffect(() => {
    if (!campaignId || !atsIntegrationJobId) return;

    fetchApplicationStages();
  }, [campaignId, fetchApplicationStages, atsIntegrationJobId]);

  return (
    <div
      className='campaign-details-page'
      style={{
        marginTop: '0pt',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <div className='campaign-page-filter-bar'>
        <div className='filter-section'>
          <Group>
            <TextInput
              leftSection={<IconSearch size={16} />}
              placeholder='Search by name, phone, email'
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              radius='xl'
              styles={{
                input: {
                  fontSize: '12px',
                },
              }}
              w={240}
              rightSection={
                <ActionIcon
                  variant='subtle'
                  c='gray'
                  size='xs'
                  onClick={() => setSearchValue('')}
                >
                  <IconX size={12} />
                </ActionIcon>
              }
            />
            <FilterBar context='campaign' />
          </Group>
        </div>

        <div className='stats-section'>
          <CampaignDetailStatsRow
            campaignId={campaignId}
            isWebCall={isWebCall}
            candidateIds={allCandidateIds}
            activeFilters={filterState?.activeFilters}
          />
        </div>
      </div>
      {selectedCandidateIds.size > 0 && renderBulkActions()}
      <div
        style={{
          position: 'relative',
          flex: '1 1 auto',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          className='common-table-wrapper'
          style={{
            height: '100%',
            maxHeight: '100%',
            overflowY: 'auto',
            background: 'white',
            flex: 1,
            marginTop: '4px',
          }}
          onScroll={(e) => {
            const element = e.target as HTMLElement;

            if (
              element.scrollHeight - element.scrollTop <=
                element.clientHeight + 20 &&
              !isFetchingCalls
            ) {
              if (candidates.length >= totalCount) return;

              setIsFetchingCalls(true);
              debouncedFetchCandidates(PAGINATION_AMOUNT, candidates.length);
            }
          }}
        >
          <Table style={{ minWidth: '800px' }}>
            <Table.Thead style={{ position: 'relative', zIndex: 99 }}>
              <Table.Tr>
                <Table.Th style={{ width: '40px' }}>
                  <Checkbox
                    checked={getSelectAllState() === true}
                    indeterminate={getSelectAllState() === 'indeterminate'}
                    onChange={handleSelectAll}
                    aria-label='Select all candidates'
                  />
                </Table.Th>
                <Table.Th
                  style={{
                    width: '20%',
                  }}
                >
                  Name
                </Table.Th>
                {!isCollapsed && (
                  <Table.Th
                    style={{
                      width: '20%',
                    }}
                  >
                    {isWebCall ? 'Email' : 'Phone'}
                  </Table.Th>
                )}

                <Table.Th
                  style={{
                    width: '20%',
                  }}
                >
                  {isWebCall ? 'Called At' : 'Last Called'}
                </Table.Th>
                <SortableHeader
                  sorted={sort?.column === SortColumn.CALL_LENGTH_SEC}
                  reversed={sort?.direction === 'ascending'}
                  onSort={handleSort(SortColumn.CALL_LENGTH_SEC)}
                  style={{
                    width: '15%',
                  }}
                  className='no-ellipses'
                >
                  Call Length
                </SortableHeader>
                {!isWebCall && (
                  <Table.Th
                    style={{
                      width: '10%',
                    }}
                  >
                    Calls
                  </Table.Th>
                )}
                <SortableHeader
                  sorted={sort?.column === SortColumn.OVERALL_GRADE}
                  reversed={sort?.direction === 'ascending'}
                  onSort={handleSort(SortColumn.OVERALL_GRADE)}
                  style={{
                    width: '11%',
                  }}
                  className='no-ellipses'
                >
                  Score
                </SortableHeader>
                <SortableHeader
                  sorted={sort?.column === SortColumn.COMPLETION_RATE}
                  reversed={sort?.direction === 'ascending'}
                  onSort={handleSort(SortColumn.COMPLETION_RATE)}
                  style={{
                    width: '14%',
                  }}
                  className='no-ellipses'
                >
                  Completion
                </SortableHeader>

                <Table.Th
                  style={{
                    width: '13%',
                  }}
                >
                  Feedback
                </Table.Th>

                {!isCollapsed && !isWebCall && (
                  <Table.Th
                    style={{
                      width: '14%',
                    }}
                  >
                    SMS Status
                  </Table.Th>
                )}

                <Table.Th
                  style={{
                    width: '15%',
                  }}
                >
                  Call Status
                </Table.Th>

                {atsIntegrationJobId && (
                  <Table.Th
                    style={{
                      width: '15%',
                    }}
                  >
                    ATS Stage
                  </Table.Th>
                )}

                {scheduleFollowUp && !isCollapsed && (
                  <Table.Th
                    style={{
                      width: '10%',
                    }}
                  >
                    Meeting
                  </Table.Th>
                )}

                <Table.Th
                  style={{
                    width: '5%',
                    padding: '0',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center' /* Center horizontally */,
                      alignItems: 'center' /* Center vertically */,
                      height: '100%',
                      color: 'var(--salv-dark-6)',
                      marginRight: '5px',
                    }}
                    className='refreshIconContainer'
                  >
                    <ActionIcon
                      variant='transparent'
                      className='refreshIconContainer'
                      onClick={() => {
                        setIsFetchingCalls(true);
                        setCandidates([]);
                        debouncedFetchCandidates(PAGINATION_AMOUNT, 0);
                      }}
                    >
                      <IconRefresh className='refreshIconContainer' size={16} />
                    </ActionIcon>
                  </div>
                </Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {candidates &&
                candidates?.length > 0 &&
                candidates.map((item) => (
                  <CandidateRow
                    key={item.candidate.candidate_id}
                    item={item}
                    isCollapsed={isCollapsed}
                    isWebCall={isWebCall}
                    selectedCandidateId={selectedCandidateId}
                    selectedCandidateIds={selectedCandidateIds}
                    scheduleFollowUp={scheduleFollowUp}
                    handleRowClick={handleRowClick}
                    handleSelect={handleSelect}
                    handleToggleStatusClick={handleToggleStatusClick}
                    handleRemoveClick={handleRemoveClick}
                    candidateIdsNeedMeetingFetched={
                      candidateIdsNeedMeetingFetched
                    }
                    meetingTimes={meetingTimes}
                    campaignId={campaignId}
                    userId={userId}
                    callStatusToColor={memoizedCallStatusToColor}
                    smsStatusToColor={memoizedSmsStatusToColor}
                    candidateToCallStatus={memoizedCandidateToCallStatus}
                    candidateToBadegeCallStatus={
                      memoizedCandidateToBadegeCallStatus
                    }
                    candidateToSmsTooltipStatus={
                      memoizedCandidateToSmsTooltipStatus
                    }
                    candidateToCallTooltipStatus={
                      memoizedCandidateToCallTooltipStatus
                    }
                    candidateToSmsBadgeStatus={
                      memoizedCandidateToSmsBadgeStatus
                    }
                    candidateToLastCalled={memoizedCandidateToLastCalled}
                    candidateToRecentCallLength={
                      memoizedCandidateToRecentCallLength
                    }
                    candidateToNumberOfCalls={getNumberOfCalls}
                    candidateToOverallGrade={memoizedCandidateToOverallGrade}
                    candidateToCompletionRate={
                      memoizedCandidateToCompletionRate
                    }
                    formatMeetingTime={memoizedFormatMeetingTime}
                    formatPhoneNumber={formatPhoneNumber}
                    applicationStages={applicationStages}
                    candidateIdsNeedStageFetched={candidateIdsNeedStageFetched}
                    atsIntegrationJobId={atsIntegrationJobId || null}
                  />
                ))}
              {!isFetchingCalls && candidates && candidates?.length === 0 && (
                <tr>
                  <td colSpan={13} style={{ minWidth: '100%' }}>
                    <Center p='lg'>No contacts</Center>
                  </td>
                </tr>
              )}
              {isFetchingCalls && (
                <tr>
                  <td colSpan={13} style={{ minWidth: '100%', width: '100%' }}>
                    <Center p='lg'>
                      <Loader size='sm' type='dots' />
                    </Center>
                  </td>
                </tr>
              )}
            </Table.Tbody>
          </Table>
        </div>
      </div>
      <Drawer
        opened={transcriptOpened}
        onClose={handleTranscriptClose}
        position='right'
        size='1100px'
        withOverlay={false}
        styles={{
          content: {
            borderLeft: '1px solid var(--salv-dark-0)',
            boxShadow:
              '0px 4px 12px rgba(0, 0, 0, 0.12), 0px 1px 4px rgba(0, 0, 0, 0.08)',
            zIndex: 30000,
          },
          header: {
            zIndex: 30001,
          },
          body: {
            zIndex: 30000,
          },
          overlay: {
            zIndex: 29999,
          },
        }}
        withCloseButton={false}
        lockScroll={false}
        ref={transcriptDrawerRef}
      >
        <TranscriptWrapper
          showNavigationShortcut={candidates.length > 1}
          isFetchingCalls={isFetchingCalls}
          onClose={handleTranscriptClose}
          isDrawer
        />
      </Drawer>

      <TranscriptChat
        opened={multiCandidateChatOpened}
        onClose={handleCloseMultiCandidateChat}
        campaignId={campaignId}
        candidateIds={Array.from(selectedCandidateIds)}
        isMultiCandidate={true}
        handleCandidateIdSelected={handleCandidateIdSelected}
      />
    </div>
  );
};

export default withFilterContextProvider(CampaignDetailsPage);
