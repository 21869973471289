import React, { useRef, useState, useEffect, useCallback } from 'react';
import env from 'env';
import { notifications } from '@mantine/notifications';
import { ActionIcon, Tooltip, Popover, Button } from '@mantine/core';
import {
  IconThumbDown,
  IconThumbUp,
  IconThumbUpFilled,
  IconThumbDownFilled,
} from '@tabler/icons-react';
import { isAxiosError } from 'axios';
import './CandidateFeedback.css';
import { MouseEvent } from 'react';
import { campaignInfoAtomForCandidate } from 'pages/editorv2/atoms';
import { useAtom } from 'jotai';
import { IconMessage } from '@tabler/icons-react';

import Comments, { CommentRef } from './Comments';
import axios from '../../api/axiosConfig';

interface CandidateFeedbackProps {
  campaignId: string;
  candidateId: string;
  userId: string;
  highlightRow?: (highlight: boolean) => void;
  onCommentClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  inTranscript?: boolean;
  handleToggleStatusClick?: (
    candidateId: string,
    currentStatus: string
  ) => Promise<void>;
  callStatus?: string;
}

const CandidateFeedback = ({
  campaignId,
  candidateId,
  userId,
  highlightRow,
  onCommentClick,
  inTranscript = false,
  handleToggleStatusClick,
  callStatus,
}: CandidateFeedbackProps) => {
  const [commentsOpen, setCommentsOpen] = useState(false);
  const candidateCampaignInfoKey = candidateId + '_' + campaignId;
  const [candidateInfo, setCandidateInfo] = useAtom(
    campaignInfoAtomForCandidate(candidateCampaignInfoKey)
  );
  const commentsRef = useRef<CommentRef>(null);
  const [commentsCount, setCommentsCount] = useState(0);
  const hasInitializedCountRef = useRef<Record<string, boolean>>({});
  const abortControllerRef = useRef<AbortController | null>(null);
  const hasComments = candidateInfo.hasComments;
  const feedbackValue = candidateInfo.feedback;

  const fetchCommentsCountOnce = useCallback(async () => {
    const candidateKey = `${candidateId}_${campaignId}`;

    if (!hasComments || hasInitializedCountRef.current[candidateKey]) {
      return;
    }

    try {
      const response = await axios.get(
        `${env.REACT_APP_SERVER_URL}/candidate/${candidateId}/comments/${campaignId}/count`
      );
      setCommentsCount(response.data.count || 0);
      hasInitializedCountRef.current[candidateKey] = true;
    } catch (error) {
      console.error('Error fetching comments count:', error);
    }
  }, [campaignId, candidateId, hasComments]);

  useEffect(() => {
    if (hasComments) {
      fetchCommentsCountOnce();
    }
  }, [candidateId, campaignId, hasComments, fetchCommentsCountOnce]);

  const updateCommentsCount = useCallback((count: number) => {
    setCommentsCount(count);
  }, []);

  const handleCommentsToggle = (opened: boolean) => {
    setCommentsOpen(opened);

    if (opened && commentsRef.current) {
      commentsRef.current.resetInitialLoadState();
    }

    if (highlightRow) {
      highlightRow(opened);
    }
  };

  const handleCandidateCampaignFeedback = async (
    e: MouseEvent<HTMLButtonElement>,
    feedback: 'thumbs_up' | 'thumbs_down'
  ) => {
    e.stopPropagation();
    e.preventDefault();

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const controller = new AbortController();
    abortControllerRef.current = controller;

    const updatedFeedback =
      feedback === feedbackValue ? 'no_feedback' : feedback;
    setCandidateInfo({ ...candidateInfo, feedback: updatedFeedback }); // optimistic UI update

    try {
      await axios.patch(
        `${env.REACT_APP_SERVER_URL}/candidate/${candidateId}/feedback/${campaignId}`,
        {
          feedback: updatedFeedback,
        },
        { signal: controller.signal }
      );

      if (
        feedback === 'thumbs_down' &&
        updatedFeedback === 'thumbs_down' &&
        handleToggleStatusClick &&
        callStatus === 'PENDING'
      ) {
        const notificationId = notifications.show({
          title: 'Candidate Has Been Paused',
          message: (
            <div style={{ display: 'flex', gap: '4px' }}>
              <span>Continue Calling?</span>
              <Button
                variant='subtle'
                onClick={async () => {
                  if (handleToggleStatusClick) {
                    notifications.show({
                      title: 'Candidate resumed',
                      message: 'Candidate has been resumed for calling',
                      color: 'green',
                    });
                    notifications.hide(notificationId);
                    await handleToggleStatusClick(candidateId, 'PAUSED');
                  }
                }}
                style={{ padding: '0', height: 'auto' }}
              >
                Resume Calls
              </Button>
            </div>
          ),
          color: 'yellow',
          autoClose: 10000,
        });
        await handleToggleStatusClick(candidateId, 'PENDING');
      }
    } catch (error) {
      if (isAxiosError(error) && error.code === 'ERR_CANCELED') {
        return;
      }
      console.error('Error updating candidate campaign feedback:', error);
      notifications.show({
        title: `Candidate feedback`,
        message: "There was an error updating candidate's feedback",
        color: 'red',
      });
    }
  };

  const renderCommentsButton = () => {
    if (inTranscript) {
      return (
        <Tooltip
          label='See Comments'
          position='bottom'
          openDelay={400}
          style={{ opacity: 0.8 }}
        >
          <div className='comment-button-container'>
            <ActionIcon
              variant='transparent'
              className={`comment-action-button ${hasComments ? 'has-comments' : 'no-comments'}`}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                if (onCommentClick) {
                  onCommentClick(e);
                }
              }}
            >
              <IconMessage className='comment-icon' />
            </ActionIcon>
            {hasComments && commentsCount > 0 && (
              <div className='comments-count'>{commentsCount}</div>
            )}
            {/* {hasComments && commentsCount > 0 && (
              <Badge
                className='comments-count-badge'
                size='xs'
                variant='filled'
                color='blue'
              >
                {commentsCount}
              </Badge>
            )} */}
          </div>
        </Tooltip>
      );
    }

    return (
      <Popover
        opened={commentsOpen}
        onChange={handleCommentsToggle}
        position='bottom'
        width={350}
        shadow='md'
        withArrow
        arrowPosition='center'
        classNames={{
          dropdown: 'comments-popover-dropdown',
        }}
        styles={{
          dropdown: {
            padding: 0,
            margin: 0,
          },
        }}
        zIndex={9500}
      >
        <Popover.Target>
          <Tooltip
            label={hasComments ? 'See Comments' : 'Add Comment'}
            position='bottom'
            openDelay={400}
            style={{ opacity: 0.8 }}
            disabled={commentsOpen}
          >
            <div className='comment-button-container'>
              <ActionIcon
                variant='transparent'
                className={`comment-action-button ${hasComments ? 'has-comments' : 'no-comments'}`}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setCommentsOpen(!commentsOpen);
                  if (highlightRow) {
                    highlightRow(!commentsOpen);
                  }
                }}
              >
                <IconMessage className='comment-icon' />
              </ActionIcon>
              {hasComments && commentsCount > 0 && (
                <div className='comments-count-container'>
                  <div className='comments-count'>{commentsCount}</div>
                </div>
              )}
              {/* {hasComments && commentsCount > 0 && (
                <Badge
                  className='comments-count-badge'
                  size='xs'
                  variant='filled'
                  color='blue'
                >
                  {commentsCount}
                </Badge>
              )} */}
            </div>
          </Tooltip>
        </Popover.Target>
        <Popover.Dropdown
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          className='comments-popover-content'
          p={0}
          m={0}
          style={{ padding: 0, margin: 0 }}
        >
          <Comments
            campaignId={campaignId}
            candidateId={candidateId}
            userId={userId}
            inPopover={true}
            ref={commentsRef}
            onCommentsLoaded={updateCommentsCount}
          />
        </Popover.Dropdown>
      </Popover>
    );
  };

  return (
    <div
      className={`candidate-feedback-container ${
        commentsOpen ? 'popover-open' : ''
      } ${hasComments ? 'has-comments' : 'no-comments'}`}
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <Tooltip
        label='Mark candidate as qualified for the role'
        position='bottom'
        openDelay={400}
        style={{ opacity: 0.8 }}
      >
        <ActionIcon
          variant='transparent'
          onClick={(e) => handleCandidateCampaignFeedback(e, 'thumbs_up')}
        >
          {feedbackValue === 'thumbs_up' ? (
            <IconThumbUpFilled className='thumb-icon thumb-up' />
          ) : (
            <IconThumbUp
              color='var(--mantine-color-green-4)'
              className='clickable-icon thumb-icon thumb-up'
            />
          )}
        </ActionIcon>
      </Tooltip>
      <Tooltip
        label='Mark candidate as unqualified for the role'
        position='bottom'
        openDelay={400}
        style={{ opacity: 0.8 }}
      >
        <ActionIcon
          variant='transparent'
          onClick={(e) => handleCandidateCampaignFeedback(e, 'thumbs_down')}
        >
          {feedbackValue === 'thumbs_down' ? (
            <IconThumbDownFilled className='thumb-icon thumb-down' />
          ) : (
            <IconThumbDown
              color='var(--mantine-color-red-4)'
              className='clickable-icon thumb-icon thumb-down'
            />
          )}
        </ActionIcon>
      </Tooltip>

      {renderCommentsButton()}
    </div>
  );
};

export default CandidateFeedback;
