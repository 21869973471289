// atoms.ts
import { atom } from 'jotai';
import { selectAtom, atomFamily } from 'jotai/utils';
import { TranscriptData } from 'hooks/useGetTranscriptData';

import { ScriptInfo } from './types';

export const scriptInfoAtom = atom<ScriptInfo | null>(null);
export const webCallAtom = selectAtom(
  scriptInfoAtom,
  (scriptInfo) => !!scriptInfo?.webCall
);
export const scheduleFollowUpAtom = selectAtom(
  scriptInfoAtom,
  (scriptInfo) => !!scriptInfo?.scheduleFollowUp
);
export const atsIntegrationJobIdAtom = selectAtom(
  scriptInfoAtom,
  (scriptInfo) => scriptInfo?.atsIntegrationJobId
);
export const baseTranscriptionCacheAtom = atom<Record<string, TranscriptData>>(
  {}
);
export const resumeCacheAtom = atom({});

export type CampaignAtomInfo = {
  candidatesNeedingReview?: Set<string>;
};
export const campaignCacheAtom = atom<Record<string, CampaignAtomInfo>>({});

export type CandidateCampaignFeedback =
  | 'thumbs_up'
  | 'thumbs_down'
  | 'no_feedback';

export type CandidateCampaignAccess = 'RESTRICTED' | 'UNRESTRICTED';

type CandidateCampaignInfo = {
  feedback?: CandidateCampaignFeedback;
  hasComments?: boolean;
  isCallReviewed?: boolean;
  isSMSReviewed?: boolean;
  isEmailReviewed?: boolean;
  access?: CandidateCampaignAccess;
  campaignOrgId?: string;
  // You can add more fields here as you need

  // We add a _fetchedAt timestamp so that we know when the data was retrieved
  _fetchedAt?: number;
};

// First, we create an underlying base atom to hold the candidate info dictionary.
const candidateCampaignInfoDictAtomBase = atom<
  Record<string, CandidateCampaignInfo>
>({});

// Now, we define our exported candidateCampaignInfoDictAtom with a custom (overridden) write function.
// This write function will run the updater callback (like the one you already use) and then for each key,
// it will check if the new candidate info has a _fetchedAt timestamp. If so, and if that value is older than the
// timestamp stored in candidateUpdateTimestampsAtom (using the key for that candidate),
// the update for that candidate is skipped.
export const candidateCampaignInfoDictAtom = atom(
  (get) => get(candidateCampaignInfoDictAtomBase),
  (
    get,
    set,
    updateFnOrData:
      | Record<string, CandidateCampaignInfo>
      | ((
          prev: Record<string, CandidateCampaignInfo>
        ) => Record<string, CandidateCampaignInfo>)
  ) => {
    const prevData = get(candidateCampaignInfoDictAtomBase);
    const timestamps = get(candidateUpdateTimestampsAtom);

    const newData =
      typeof updateFnOrData === 'function'
        ? updateFnOrData(prevData)
        : updateFnOrData;

    // Here we merge the new updates with the previous data—skipping updates that are stale.
    const mergedData: Record<string, CandidateCampaignInfo> = { ...prevData };
    for (const key in newData) {
      const candidateUpdate = newData[key];
      if (candidateUpdate && candidateUpdate._fetchedAt !== undefined) {
        // Look up the last update timestamp (or 0 if none exists)
        const lastUpdate = timestamps[key] ?? 0;
        // If the fetched update is older than the stored timestamp, skip updating this candidate
        if (candidateUpdate._fetchedAt < lastUpdate) {
          continue;
        }
      }
      mergedData[key] = candidateUpdate;
    }

    // Write the merged data back into the base atom.
    set(candidateCampaignInfoDictAtomBase, mergedData);
  }
);

// The candidateUpdateTimestampsAtom is left unchanged.
export const candidateUpdateTimestampsAtom = atom<{ [key: string]: number }>(
  {}
); // use this to prevent updates due to race conditions

export const campaignInfoAtomForCandidate = atomFamily(
  (campaignCandidateId: string) =>
    atom(
      // Getter: returns that candidate's object from the global store
      (get) => {
        const allCandidateInfo = get(candidateCampaignInfoDictAtom);
        return allCandidateInfo[campaignCandidateId] || {};
      },
      // Setter: merges updates into that candidate's object with timestamp tracking
      (
        get,
        set,
        updatedFields: Partial<CandidateCampaignInfo> & { _fetchedAt?: number }
      ) => {
        const prevData = get(candidateCampaignInfoDictAtom);
        const prevTimestamps = get(candidateUpdateTimestampsAtom);

        const currentTime = Date.now();
        const lastUpdateTime = prevTimestamps[campaignCandidateId] || 0;
        if (
          updatedFields._fetchedAt &&
          updatedFields._fetchedAt < lastUpdateTime
        ) {
          // If the fetched data is older than the last update, ignore it
          return;
        }

        // Update the timestamp only if it's a user-triggered update
        const isUserUpdate = !updatedFields._fetchedAt;

        set(candidateCampaignInfoDictAtom, {
          ...prevData,
          [campaignCandidateId]: {
            ...prevData[campaignCandidateId],
            ...updatedFields,
          },
        });

        if (isUserUpdate) {
          set(candidateUpdateTimestampsAtom, {
            ...prevTimestamps,
            [campaignCandidateId]: currentTime,
          });
        }
      }
    )
);

interface UpdateTranscriptionCachePayload {
  data: Record<string, Partial<TranscriptData>>;
  timestamp: number;
}

export const transcriptionCacheAtom = atom(
  (get) => get(baseTranscriptionCacheAtom),
  (get, set, payload: UpdateTranscriptionCachePayload) => {
    const { data, timestamp } = payload;
    const currentCache = get(baseTranscriptionCacheAtom);

    const updatedCache = { ...currentCache };

    Object.entries(data).forEach(([key, value]) => {
      const existingEntry = updatedCache[key];

      if (
        // Case 1: Entry doesn't exist yet
        !existingEntry ||
        // Case 2: Entry exists but doesn't have timestamp
        !existingEntry._fetchedAt ||
        // Case 3: New data is more recent than existing data
        existingEntry._fetchedAt < timestamp
      ) {
        updatedCache[key] = {
          ...existingEntry,
          ...value,
          _fetchedAt: timestamp,
        };
      }
    });

    set(baseTranscriptionCacheAtom, updatedCache);
  }
);
