import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import {
  Alert,
  Button,
  Flex,
  NavLink,
  Stack,
  Text,
  Textarea,
  Title,
  Modal,
  Divider,
  Loader,
} from '@mantine/core';
import './EditorPageV2.css';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { IconExclamationCircle, IconRocket } from '@tabler/icons-react';
import { useAtom } from 'jotai';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { modals } from '@mantine/modals';
import { useListState } from '@mantine/hooks';
import { v4 as uuidv4 } from 'uuid';
import { debounce } from 'lodash';
import env from 'env';
import { Player } from '@lottiefiles/react-lottie-player';
import AddContactsModal from 'components/campaign/AddContactsModal';
import axios from 'api/axiosConfig';
import { usePermissions } from 'hooks/usePermissions';
import { SequenceSettingsTemplate } from 'components/template/Template';

import { UNTITLED_SCRIPT } from './contants';
import { scriptInfoAtom } from './atoms';
import CallerSettingsStep from './components/CallerSettingsStep';
import FailedCallSmsStep from './components/FailedCallSmsStep';
import FailedCallEmailStep from './components/FailedCallEmailStep';
import BackgroundInfoStep from './components/BackgroundInfoStep';
import ScriptPreviewStep from './components/ScriptPreviewStep';
import FollowUpStep from './components/FollowUpStep';
import WebCallEmailStep from './components/WebCallEmailStep';
import {
  RequirementImportance,
  CandidateRequirement,
  ScriptInfo,
  QuestionType,
} from './types';
import ScreeningQuestionsStep, {
  EmptyRequirementEditState,
  RequriementEditState,
} from './components/ScreeningQuestionsStep';
import { getOutroFromSchedule } from './components/Outro';
import GenerateStep from './components/GenerateStep';
import TestCallModal from './components/TestCallModal';

const enum StepKey {
  JobDescription = 'job-description',
  CallScript = 'call-script',
  CallIntro = 'call-intro',
  ScreeningQuestions = 'screening-questions',
  FollowupScheduling = 'followup-scheduling',
  FailedCallSms = 'failed-call-sms',
  FailedCallEmail = 'failed-call-email',
  InterviewLinkEmail = 'interview-link-email',
  BackgroundInfo = 'background-info',
  Settings = 'settings',
}

// Define sort order here:
const allStepKeysSorted = [
  StepKey.JobDescription,
  StepKey.CallScript,
  StepKey.CallIntro,
  StepKey.ScreeningQuestions,
  StepKey.FollowupScheduling,
  StepKey.FailedCallSms,
  StepKey.FailedCallEmail,
  StepKey.InterviewLinkEmail,
  StepKey.BackgroundInfo,
  StepKey.Settings,
] as const;

export default function EditorPageV2({
  setScriptTitle,
  scriptFetched,
  setScriptFetched,
  isCampaignActive,
  setIsCampaignActive,
  handleContactsRefresh,
  canSubmitCampaign,
  setCanSubmitCampaign,
}) {
  const location = useLocation();
  const folderPathPattern = /\/scripts\/folders\/([^/]+)\/script-editor/;
  const isFolderPath = folderPathPattern.test(location.pathname);

  let match;
  if (isFolderPath) {
    // For URLs with folder path
    match = location.pathname.match(
      /\/scripts\/folders\/[^/]+\/script-editor\/(new(?:-phone|-web)?\/)?([^/]+)/
    );
  } else {
    // For regular URLs without folder path
    match = location.pathname.match(
      /\/scripts\/script-editor\/(new(?:-phone|-web)?\/)?([^/]+)/
    );
  }
  const { campaignId: paramCampaignId, folderId } = useParams();
  const isNew = !!match?.[1]; // 'new/' part exists if match[1] is truthy
  const isNewWeb = match?.[1]?.includes('web');
  const campaignId = match ? match[2] : paramCampaignId;

  const { canEditCampaign } = usePermissions();
  const email = localStorage.getItem('email') || '';
  const isInternal = email?.includes('salv.ai');

  // scriptFetched is set when it's actually fetched
  // needsReFetch is set if we need a re-fetch (immediately after first fetch)
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [unsavedBackgroundInfoChanges, setUnsavedBackgroundInfoChanges] =
    useState(false);
  const [opened, setOpened] = useState(false);

  // just a UI hack so we don't show 'saving' when the script first loads

  const isInitialFetch = useRef(true);
  const needsReFetch = useRef(true);

  const [activeStep, setActiveStep] = useState<number>(0);

  const [searchParams] = useSearchParams();
  const paramStep = searchParams.get('step') as StepKey | null;

  const orgId = localStorage.getItem('orgId');

  const [scriptGenerationGuidelineEdits, setScriptGenerationGuidelineEdits] =
    useState('');
  const orgSettings = useForm({
    initialValues: {
      callOrgBackgroundInfo: '',
      scriptGenerationGuidelines: '',
      question_bank: [],
      backgroundInfoId: '' + uuidv4(),
      ai_caller_settings: {
        introducesAs: '',
        allowEditIntroduceAs: true,
        name: '',
        allowEditName: true,
      },
    },
  });

  const [recruitingEmail, setRecruitingEmail] = useState<string | null>(null);
  useEffect(() => {
    const fetchOrgOutreachEmail = async () => {
      if (!orgId) {
        return;
      }
      try {
        const response = await axios.post(
          `${env.REACT_APP_SERVER_URL}/get_org_recruiting_email`,
          { orgId: orgId }
        );
        setRecruitingEmail(response.data.recuitingEmail);
      } catch (error) {
        console.error('Error fetching recruiting email');
        console.error(error);
      }
    };
    fetchOrgOutreachEmail();
  }, [orgId]);

  const [orgBackgroundInfoTemplates, setOrgBackgroundInfoTemplates] = useState<
    SequenceSettingsTemplate[]
  >([]);
  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await axios.get(
          `${env.REACT_APP_SERVER_URL}/org/${orgId}/background_info_templates`
        );
        const { templates } = response.data;
        setOrgBackgroundInfoTemplates(templates || []);
      } catch (error) {
        notifications.show({
          title: 'Error',
          message: 'Failed to fetch background info templates',
          color: 'red',
        });
      }
    };

    fetchTemplates();
  }, [orgId]);

  const [orgScriptGenerationTemplates, setOrgScriptGenerationTemplates] =
    useState<SequenceSettingsTemplate[]>([]);
  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await axios.get(
          `${env.REACT_APP_SERVER_URL}/org/${orgId}/script_generation_templates`
        );
        const { templates } = response.data;
        setOrgScriptGenerationTemplates(templates || []);
      } catch (error) {
        notifications.show({
          title: 'Error',
          message: 'Failed to fetch script generation templates',
          color: 'red',
        });
      }
    };

    fetchTemplates();
  }, [orgId]);

  const [requirements, requirementsHandlers] =
    useListState<CandidateRequirement>([]);
  const [generatingScript, setGeneratingScript] = useState<boolean>(false);
  // const [requirementBeingEdited, setRequirementBeingEdited] = useState<
  //   number | undefined
  // >();

  const [requirementItemBeingEdited, setRequirementItemBeingEdited] =
    useState<RequriementEditState>(EmptyRequirementEditState);

  const [campaginModalOpen, setCampaignModalOpen] = useState<boolean>(false);

  const defaultScheduleFollowUp =
    !!localStorage.getItem('calAPIKey') &&
    !!localStorage.getItem('calEventTypeID');
  const scriptInfo = useForm<ScriptInfo>({
    initialValues: {
      jobDescription: '',
      callIntro: '',
      backgroundInfo: '',
      callOutro: getOutroFromSchedule(defaultScheduleFollowUp).actualValue,
      failedCallSms: [] as string[],
      failedCallEmailSubject: '',
      failedCallEmailBody: '',
      webCallEmailBody: '',
      webCallEmailSubject: '',
      name: UNTITLED_SCRIPT,
      campaignId: campaignId || '',
      passingScore: 60,

      // Old campaign Settings
      scheduleFollowUp: defaultScheduleFollowUp,
      email: email,
      callNewApplicants: false,
      jobTitle: '',
      jobCity: '',
      jobState: '',
      callFromNumber: '',
      useScriptScaffolding: true,
      LLMModel: 'faster',
      voiceName: 'Lisa (American)',
      rescheduleCallOnVoicemail: true,
      callRetryCount: 3,
      callRetryHours: 6,
      reminderEmailCount: 2,
      reminderEmailHours: 24,
      webCall: !!isNewWeb,
      webLinkExpirationDays: 5,
      superBoostedKeywords: [] as string[],
      backgroundInfoTemplateIds: [orgId] as string[], // NOTE: If new always use default template. Default template id is orgId
      folderId: folderId || '',
      atsIntegrationJobId: '',
      aiCallerName: '',
      aiCallerIntroducesAs: '',
      scriptGenerationTemplateIds: [orgId] as string[], // NOTE: If new always use default template. Default template id is orgId
    },
  });

  const [, setScriptInfoAtom] = useAtom(scriptInfoAtom);
  useEffect(() => {
    setScriptInfoAtom(scriptInfo.values); // Synchronize form values with the atom
  }, [scriptInfo.values, setScriptInfoAtom]);

  const isWebCall = scriptInfo.values.webCall; // for easer access

  useEffect(() => {
    setScriptFetched(false);
    needsReFetch.current = true;
  }, [campaignId, setScriptFetched]);

  useEffect(() => {
    const fetchScript = async () => {
      if (scriptFetched || !needsReFetch.current) {
        return;
      }
      needsReFetch.current = false;
      const setInitialFetchFalse = () => {
        isInitialFetch.current = false;
      };
      const setInitialFetchFalseAfterTimeout = () => {
        setTimeout(setInitialFetchFalse, 2000);
      };

      try {
        const backgroundInfoResp = await axios.get(
          `${env.REACT_APP_SERVER_URL}/org_background_info/${orgId}`
        );
        // Always fetch the background info
        if (backgroundInfoResp?.data) {
          orgSettings.setValues({
            callOrgBackgroundInfo:
              backgroundInfoResp?.data?.callOrgBackgroundInfo || '',
            scriptGenerationGuidelines:
              backgroundInfoResp?.data?.scriptGenerationGuidelines || '',
            question_bank: backgroundInfoResp?.data?.question_bank || [],
            ai_caller_settings:
              backgroundInfoResp?.data?.ai_caller_settings || null,
          });
          setScriptGenerationGuidelineEdits(
            backgroundInfoResp?.data?.scriptGenerationGuidelines || ''
          );
          scriptInfo.initialize({
            ...scriptInfo.values,
            aiCallerName:
              backgroundInfoResp?.data?.ai_caller_settings?.name || '',
            aiCallerIntroducesAs:
              backgroundInfoResp?.data?.ai_caller_settings.introducesAs || '',
          });
        }
        let scriptResponse;
        try {
          scriptResponse = await axios.get(
            `${env.REACT_APP_SERVER_URL}/script/${campaignId}`
          );
        } catch (error) {
          throw error;
        }

        if (!scriptResponse?.data && isNew) {
          // script failure would happen on first page open
          scriptInfo.reset();
          requirementsHandlers.setState([]);
          setScriptFetched(true);
          setEditingName(true);
          setInitialFetchFalseAfterTimeout();
          return;
        }

        // Only difference between these two objects is campaign_settings is from the sql
        // but script_info from cosmos. Made them separate here for clarity during developement
        const { script_info, campaign_settings } = scriptResponse.data;

        const infoToSet = {
          callIntro: script_info.callIntro,
          backgroundInfo: script_info.backgroundInfo,
          callOutro: script_info.callOutro,
          failedCallSms: script_info.failedCallSms,
          name: campaign_settings.campaign_name,
          campaignId: script_info.campaignId,
          failedCallEmailSubject: script_info.failedCallEmailSubject || '',
          failedCallEmailBody: script_info.failedCallEmailBody || '',
          webCallEmailSubject: script_info.webCallEmailSubject || '',
          webCallEmailBody: script_info.webCallEmailBody || '',
          jobDescription: script_info.jobDescription,
          passingScore:
            typeof script_info.passingScore === 'number'
              ? script_info.passingScore
              : 60,
          callNewApplicants: campaign_settings.call_new_applicants,
          superBoostedKeywords: script_info.superBoostedKeywords,
          backgroundInfoTemplateIds: script_info.backgroundInfoTemplateIds,
          aiCallerName: orgSettings.values.ai_caller_settings.allowEditName
            ? script_info.aiCallerName
            : orgSettings.values.ai_caller_settings.name,
          aiCallerIntroducesAs: orgSettings.values.ai_caller_settings
            .allowEditIntroduceAs
            ? script_info.aiCallerIntroducesAs
            : orgSettings.values.ai_caller_settings.introducesAs,
          scriptGenerationTemplateIds: script_info.scriptGenerationTemplateIds,
          // Indeed info (internal settings): TODO: make all that work
          email: campaign_settings.results_email,
          voiceName: campaign_settings.voice_name,
          rescheduleCallOnVoicemail:
            campaign_settings.reschedule_call_on_voicemail,
          callRetryCount: campaign_settings.call_retry_count,
          callRetryHours: campaign_settings.call_retry_hours,
          callFromNumber: campaign_settings.call_from_number,
          reminderEmailHours:
            campaign_settings.reminder_email_hours !== undefined &&
            campaign_settings.reminder_email_hours !== null
              ? campaign_settings.reminder_email_hours
              : 24,
          reminderEmailCount:
            campaign_settings.reminder_email_count !== undefined &&
            campaign_settings.reminder_email_count !== null
              ? campaign_settings.reminder_email_count
              : 2,
          webLinkExpirationDays: campaign_settings.web_link_expiration_days,
          webCall: campaign_settings.web_call,
          scheduleFollowUp: !!campaign_settings.schedule_follow_up,
          atsIntegrationJobId: campaign_settings.ats_integration_job_id,
        };
        setScriptTitle(campaign_settings.campaign_name);
        scriptInfo.setValues(infoToSet);
        const requirementsWithDefaults: CandidateRequirement[] =
          script_info.requirements.map((r) => ({
            ...r,
            questionType: r.questionType ?? QuestionType.Normal,
          }));
        requirementsHandlers.setState(requirementsWithDefaults);
        // scriptInfo.setFieldValue(
        //   'scheduleFollowUp',
        //   !!script_info.scheduleFollowUp
        // );

        setScriptFetched(true);
        // this is somewhat hacky, we set this initial fetch done after timeout
        // so that we only "save" on state diffs we are confident are triggered not by the initial fetch
        // it's just a qol thing so that the user doesn't see an uneeded "save" when they first open the script while the state is setting
        // This is because the state changing is what triggers the useEffects
        // It's technically a potential race condition but should barely ever happen and I think this is the cleanest solution code-wise
        // + it's not too bad when it triggeres since it would only "save" the successfullt fetched values anyways
        // The one actually bad case is when BOTH setStates take > 2 seconds, one fails, one succeeds, and then the update is only partial,
        // Using useStates are tricky due to race conditions between set states and setstates triggering the save useEffects

        // TODO: I believe the only non-timeout hack solution is to track if each state value has been independently set to the value the backend returned
        // , doubly checking the backend return values with useRefs, and that the backend fetch succeeded.
        // something like
        // if (backendFetchedRef.current && valueRefAFromBackend.current === stateValueForA && sameForBB && sameForC..)
        //      then saving A,B,C allowed (A, B, C being parts of the script maintained as independent variables)
        // For now, the timeouts should be good enough since setState should never take more than a few millieconds

        // Note to future developer: you must be very careful when to use refs and state. If you try to use state variables in the useEffect or useaCallback that triggers saves,
        // changing those variables will cause the saves to fire
        setInitialFetchFalseAfterTimeout();
      } catch (error: unknown) {
        notifications.show({
          title: 'There was an error retrieving your script. Editing Disabled',
          message: error instanceof Error ? error.message : '',
          color: 'red',
        });
        console.error('Error fetching campaign details:', error);
      } finally {
      }
    };
    const debouncedFetchScript = debounce(() => {
      fetchScript();
    }, 250);
    debouncedFetchScript();
  }, [
    campaignId,
    scriptInfo,
    orgSettings,
    isNew,
    scriptFetched,
    setScriptFetched,
    requirementsHandlers,
    orgId,
    setScriptTitle,
  ]);

  const hideMap = useMemo(() => {
    // Define rules for which steps to hide
    const map: Record<StepKey, boolean | undefined> = allStepKeysSorted.reduce(
      (acc, key) => {
        switch (key) {
          case StepKey.FailedCallSms:
            acc[key] = isWebCall;
            break;
          case StepKey.FailedCallEmail:
            acc[key] = isWebCall || !isInternal;
            break;
          case StepKey.InterviewLinkEmail:
            acc[key] = !isWebCall;
            break;
          default:
            acc[key] = undefined; // Default for unspecified keys
            break;
        }
        return acc;
      },
      {} as Record<StepKey, boolean | undefined>
    );

    return map;
  }, [isWebCall, isInternal]);

  const visibleStepKeys: StepKey[] = useMemo(() => {
    return (Object.keys(hideMap) as StepKey[]).filter(
      (stepKey) => !hideMap[stepKey]
    );
  }, [hideMap]);

  const [activeStepKey, setActiveStepKey] = useState<StepKey>(
    visibleStepKeys[0]
  );

  const handleActiveStepIdxChange = useCallback(
    (idx) => {
      setActiveStepKey(visibleStepKeys[idx]);
    },
    [visibleStepKeys]
  );

  const handleGenerateScript = useCallback(() => {
    setGeneratingScript(true);
    axios
      .post(`${env.REACT_APP_SERVER_URL}/generate_script`, {
        jobDescription: scriptInfo.values.jobDescription,
        scriptGenerationTemplateIds:
          scriptInfo.values.scriptGenerationTemplateIds,
        isWebCall: isWebCall,
      })
      .then((response) => {
        scriptInfo.setValues({
          callIntro: response.data.call_intro,
          backgroundInfo: `START_JOB_DESCRIPTION\n${scriptInfo.values.jobDescription}\nEND_JOB_DESCRIPTION`,
          failedCallSms: response.data.failed_call_sms,
          failedCallEmailSubject: response.data.failed_call_email_subject || '',
          failedCallEmailBody: response.data.failed_call_email_body || '',
          webCallEmailBody: response.data.interview_link_email_body || '',
          webCallEmailSubject: response.data.interview_link_email_subject || '',
        });
        requirementsHandlers.setState(
          response.data.requirements.map((requirement) => ({
            question: requirement.question,
            llmGradingInstructions: requirement.llm_grading_instructions,
            importance: RequirementImportance.Normal,
            failIfZero: false,
            questionType: QuestionType.Normal,
          })) || []
        );
        handleActiveStepIdxChange(activeStep + 1);
      })
      .catch((e) => {
        notifications.show({
          title: 'Error generating call script',
          message: e.message,
          color: 'red',
        });
      })
      .finally(() => {
        setGeneratingScript(false);
      });
  }, [
    activeStep,
    requirementsHandlers,
    scriptInfo,
    isWebCall,
    handleActiveStepIdxChange,
  ]);

  const handleClickGenerateScript = useCallback(() => {
    const hasExistingContent =
      Object.entries(scriptInfo.values)
        .filter(
          ([key]) =>
            key === 'callIntro' ||
            key === 'webCallEmailBody' ||
            key === 'webCallEmailSubject'
        )
        .some(([, value]) => !!value) || requirements.length > 0;

    if (hasExistingContent) {
      modals.openConfirmModal({
        title: 'Generate Script Info from Job Description',
        children: (
          <Alert color='red' icon={<IconExclamationCircle size={16} />}>
            Warning: this action will overwrite your existing script and
            requirements. Are you sure you want to proceed?
          </Alert>
        ),
        labels: { confirm: 'Generate', cancel: 'Cancel' },
        onConfirm: handleGenerateScript,
      });
    } else {
      handleGenerateScript();
    }
  }, [handleGenerateScript, requirements.length, scriptInfo.values]);

  const noChanges = useMemo(() => {
    const {
      callIntro,
      backgroundInfo,
      failedCallSms,
      failedCallEmailSubject,
      failedCallEmailBody,
      name,
    } = scriptInfo.values;
    return (
      name === UNTITLED_SCRIPT &&
      !callIntro &&
      !backgroundInfo &&
      !failedCallSms &&
      !failedCallEmailSubject &&
      !failedCallEmailBody &&
      !requirements?.length
    );
  }, [scriptInfo.values, requirements?.length]);

  const saveScript = useCallback(async () => {
    // Make a POST request to /edit_script with the existing script's details

    const {
      jobDescription,
      callIntro,
      backgroundInfo,
      callOutro,
      failedCallSms,
      failedCallEmailSubject,
      failedCallEmailBody,
      name,
      passingScore,
      // campaign settings:
      email,
      callFromNumber,
      voiceName,
      rescheduleCallOnVoicemail,
      callRetryHours,
      callRetryCount,
      callNewApplicants,
      webCallEmailSubject,
      webCallEmailBody,
      reminderEmailCount,
      reminderEmailHours,
      webCall,
      webLinkExpirationDays,
      superBoostedKeywords,
      backgroundInfoTemplateIds,
      folderId,
      aiCallerName,
      aiCallerIntroducesAs,
      scriptGenerationTemplateIds,
    } = scriptInfo.values;
    const payload = {
      jobDescription,
      callIntro,
      backgroundInfo,
      callOutro,
      failedCallSms,
      failedCallEmailSubject,
      failedCallEmailBody,
      webCallEmailSubject,
      webCallEmailBody,
      name,
      passingScore,
      requirements: requirements,
      scheduleFollowUp: scriptInfo.values.scheduleFollowUp,
      // campaign settings:
      email,
      callFromNumber: callFromNumber ? callFromNumber : '',
      voiceName,
      rescheduleCallOnVoicemail,
      callRetryCount,
      reminderEmailCount,
      reminderEmailHours,
      callRetryHours,
      callNewApplicants,
      webLinkExpirationDays,
      // url
      campaignId,
      webCall,
      superBoostedKeywords,
      backgroundInfoTemplateIds,
      folderId,
      aiCallerName,
      aiCallerIntroducesAs,
      scriptGenerationTemplateIds,
    };

    if (noChanges) {
      setUnsavedChanges(false);
      // If no edits made, don't save progress
      return;
    }

    setUnsavedChanges(true);

    try {
      await axios.post(`${env.REACT_APP_SERVER_URL}/save_script`, payload);
      setUnsavedChanges(false);
      setScriptTitle(name);
    } catch (error) {
      console.error('Error updating script:', error);
    }
  }, [requirements, scriptInfo.values, noChanges, setScriptTitle, campaignId]);

  useEffect(() => {
    if (noChanges || isInitialFetch.current) {
      return;
    }
    setUnsavedChanges(true);
    const debouncedSaveScript = debounce(saveScript, 1000);

    debouncedSaveScript();

    // Cleanup function to cancel debounce on unmount or dependency change
    return () => {
      debouncedSaveScript.cancel();
    };
  }, [saveScript, noChanges]);

  const saveBackgroundInfo = useCallback(async () => {
    // Make a PATCH request to org_background_info endpoint

    const { callOrgBackgroundInfo, scriptGenerationGuidelines, question_bank } =
      orgSettings.values;
    const payload = {
      patch: {
        callOrgBackgroundInfo,
        scriptGenerationGuidelines,
        question_bank,
      },
    };

    try {
      await axios.patch(
        `${env.REACT_APP_SERVER_URL}/org_background_info/${orgId}`,
        payload
      );
      setUnsavedBackgroundInfoChanges(false);
    } catch (error) {
      console.error('Error saving background info:', error);
    }
  }, [orgSettings.values, orgId]);

  useEffect(() => {
    if (isInitialFetch.current) {
      return;
    }

    setUnsavedBackgroundInfoChanges(true);
    const debouncedSaveBackgroundInfo = debounce(saveBackgroundInfo, 2000);

    debouncedSaveBackgroundInfo();

    // Cleanup function to cancel debounce on unmount or dependency change
    return () => {
      debouncedSaveBackgroundInfo.cancel();
    };
  }, [saveBackgroundInfo]);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      // Check if the target is not within the table container
      const htmlTarget = e.target as HTMLElement;
      function hasClassOrParent(
        element: HTMLElement,
        className: string
      ): boolean {
        const numParentsToLookThrough = 3;
        let parentCount = 0;
        while (element) {
          if (parentCount > numParentsToLookThrough) {
            break;
          }
          if (element.classList.contains(className)) return true;
          element = element.parentElement as HTMLElement;
          parentCount += 1;
        }
        return false;
      }

      if (!hasClassOrParent(htmlTarget, 'editableField')) {
        setRequirementItemBeingEdited(EmptyRequirementEditState);
      }

      if (!hasClassOrParent(htmlTarget, 'titleInputArea')) {
        setEditingName(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const verifyCallOutro = () => {
    if (
      scriptInfo.values.scheduleFollowUp &&
      (!localStorage.getItem('calAPIKey') ||
        !localStorage.getItem('calEventTypeID'))
    ) {
      notifications.show({
        title: 'Calendar Integration Required',
        message:
          'Please set up your Calendar Integration or deselect "Schedule Follow Up" Call Outro option.',
        color: 'red',
      });
      return true;
    }

    if (
      scriptInfo.values.scheduleFollowUp &&
      !scriptInfo.values.callOutro.includes('{book_call}')
    ) {
      notifications.show({
        title: '{book_call} Required',
        message:
          'Please include "{book_call}" in your Call Outro or deselect the "Schedule Follow Up" option.',
        color: 'red',
      });
      return true;
    }
    return false;
  };

  // const handleRowClick = (index: number) => (e: React.MouseEvent) => {
  // e.preventDefault();
  // e.stopPropagation();
  // setRequirementBeingEdited((current) =>
  //   current === index ? undefined : index
  // );
  // };

  // companyInfoEdits !== orgSettings.values.callOrgBackgroundInfo;

  useEffect(() => {
    if (!paramStep) {
      return;
    }
    // Check if the paramStep is a valid step key
    const isValidStepKey = allStepKeysSorted.includes(paramStep);

    if (!isValidStepKey) {
      console.error(`Invalid step key: ${paramStep}`);
      return;
    }

    setActiveStepKey(paramStep);
  }, [paramStep]);

  // Ensure that if the visibleStepKeys change, the active step changes accordingly
  useEffect(() => {
    const newActiveStep = visibleStepKeys.indexOf(activeStepKey, 0);
    setActiveStep(newActiveStep);
  }, [visibleStepKeys, activeStepKey]);

  const steps: {
    label: string;
    description?: string;
    key: StepKey;
    indent?: boolean;
    isComplete: boolean;
    element?: React.ReactNode;
  }[] = useMemo(() => {
    const handlePreviousStep = () => {
      handleActiveStepIdxChange(activeStep - 1);
    };
    const handleNextStep = () => {
      handleActiveStepIdxChange(activeStep + 1);
    };

    const stepsArr = [
      {
        label: 'Job Description (Optional)',
        key: StepKey.JobDescription,
        // description: 'Auto-generate a call script and requirements',
        isComplete: true,
        element: (
          <>
            <div className='stepSectionContainer'>
              <GenerateStep
                scriptInfo={scriptInfo}
                orgSettings={orgSettings}
                generatingScript={generatingScript}
                scriptGenerationGuidelineEdits={scriptGenerationGuidelineEdits}
                setScriptGenerationGuidelineEdits={
                  setScriptGenerationGuidelineEdits
                }
                orgScriptGenerationTemplates={orgScriptGenerationTemplates}
                setOrgScriptGenerationTemplates={
                  setOrgScriptGenerationTemplates
                }
              />
            </div>

            <div className='stepButtonsContainer'>
              <Flex justify='flex-end' gap='md'>
                <Button variant='outline' onClick={handleNextStep}>
                  Skip
                </Button>
                <Button
                  onClick={handleClickGenerateScript}
                  loading={generatingScript}
                  disabled={
                    !scriptInfo.values.jobDescription || !canEditCampaign
                  }
                >
                  Generate Script and Requirements
                </Button>
              </Flex>
            </div>
          </>
        ),
      },
      {
        label: 'Call Script',
        isComplete: true,
        key: StepKey.CallScript,
        element: (
          <>
            <div className='stepSectionContainer'>
              <Stack>
                <Title order={4}>Script Preview</Title>
                <ScriptPreviewStep
                  requirements={requirements}
                  scriptInfo={scriptInfo}
                />
              </Stack>
            </div>

            <div className='stepButtonsContainer'>
              <Flex h='100%' justify='flex-end' align='flex-end' gap='md'>
                <Button variant='outline' onClick={handlePreviousStep}>
                  Previous
                </Button>
                <Button onClick={handleNextStep}>Next</Button>
              </Flex>
            </div>
          </>
        ),
      },
      {
        label: 'Call Intro',
        key: StepKey.CallIntro,
        isComplete: !!scriptInfo.values.callIntro,
        indent: true,
        element: (
          <>
            <div className='stepSectionContainer'>
              <Stack h='100%'>
                <Title order={5}>Call Intro</Title>
                <Text fz='sm' c='dimmed'>
                  This message will be used at the beginning of the call to
                  introduce the candidate to the company and role. Use{' '}
                  {'{candidate_name}'} to insert the candidate's name.
                </Text>
                <Textarea
                  className={'halfHeightTextarea'}
                  {...scriptInfo.getInputProps('callIntro')}
                  classNames={{
                    input: canEditCampaign ? '' : 'view-only',
                  }}
                />
              </Stack>
            </div>

            <div className='stepButtonsContainer'>
              <Flex h='100%' justify='flex-end' align='flex-end' gap='md'>
                <Button variant='outline' onClick={handlePreviousStep}>
                  Previous
                </Button>
                <Button onClick={handleNextStep}>Next</Button>
              </Flex>
            </div>
          </>
        ),
      },
      {
        label: 'Screening Questions',
        key: StepKey.ScreeningQuestions,
        isComplete:
          requirements?.length !== 0 &&
          requirements?.every((req) => !!req.question),
        indent: true,
        element: (
          <>
            <div className='stepSectionContainer'>
              <ScreeningQuestionsStep
                requirements={requirements}
                requirementsHandlers={requirementsHandlers}
                requirementItemBeingEdited={requirementItemBeingEdited}
                setRequirementItemBeingEdited={setRequirementItemBeingEdited}
                unsavedChanges={unsavedChanges}
                orgSettings={orgSettings}
              />
            </div>
            <div className='stepButtonsContainer'>
              <Flex h='100%' justify='flex-end' align='flex-end' gap='md'>
                <Button variant='outline' onClick={handlePreviousStep}>
                  Previous
                </Button>
                <Button onClick={handleNextStep}>Next</Button>
              </Flex>
            </div>
          </>
        ),
      },
      {
        label: 'Follow-Up & Scheduling',
        key: StepKey.FollowupScheduling,
        isComplete: !!scriptInfo.values.callOutro,
        indent: true,
        element: (
          <>
            <div className='stepSectionContainer'>
              <FollowUpStep scriptInfo={scriptInfo} />
            </div>
            <div className='stepButtonsContainer'>
              <Flex h='100%' justify='flex-end' align='flex-end' gap='md'>
                <Button variant='outline' onClick={handlePreviousStep}>
                  Previous
                </Button>
                <Button onClick={handleNextStep}>Next</Button>
              </Flex>
            </div>
          </>
        ),
      },
      {
        label: 'Failed Call SMS',
        key: StepKey.FailedCallSms,
        isComplete: !!scriptInfo.values.failedCallSms?.length,
        element: (
          <>
            <div className='stepSectionContainer'>
              <Stack h='100%'>
                <Title order={5}>SMS Message for Failed Calls</Title>
                <FailedCallSmsStep scriptInfo={scriptInfo} />
              </Stack>
            </div>
            <div className='stepButtonsContainer'>
              <Flex h='100%' justify='flex-end' align='flex-end' gap='md'>
                <Button variant='outline' onClick={handlePreviousStep}>
                  Previous
                </Button>
                <Button onClick={handleNextStep}>Next</Button>
              </Flex>
            </div>
          </>
        ),
      },
      {
        label: 'Failed Call Email',
        key: StepKey.FailedCallEmail,
        isComplete:
          !recruitingEmail ||
          (!!scriptInfo.values.failedCallEmailBody &&
            !!scriptInfo.values.failedCallEmailSubject),
        element: (
          <>
            <div className='stepSectionContainer'>
              <FailedCallEmailStep
                recruitingEmail={recruitingEmail}
                scriptInfo={scriptInfo}
              />
            </div>
            <div className='stepButtonsContainer'>
              <Flex h='100%' justify='flex-end' align='flex-end' gap='md'>
                <Button variant='outline' onClick={handlePreviousStep}>
                  Previous
                </Button>
                <Button onClick={handleNextStep}>Next</Button>
              </Flex>
            </div>
          </>
        ),
      },
      {
        label: 'Interview Link Email',
        key: StepKey.InterviewLinkEmail,
        isComplete:
          !!scriptInfo.values.webCallEmailBody &&
          !!scriptInfo.values.webCallEmailSubject &&
          scriptInfo.values.webCallEmailBody.includes('{interview_link}'),
        element: (
          <>
            <div className='stepSectionContainer'>
              <WebCallEmailStep scriptInfo={scriptInfo} />
            </div>
            <div className='stepButtonsContainer'>
              <Flex h='100%' justify='flex-end' align='flex-end' gap='md'>
                <Button variant='outline' onClick={handlePreviousStep}>
                  Previous
                </Button>
                <Button onClick={handleNextStep}>Next</Button>
              </Flex>
            </div>
          </>
        ),
      },
      {
        label: 'Background Info',
        key: StepKey.BackgroundInfo,
        isComplete: !!scriptInfo.values.backgroundInfo,
        element: (
          <>
            <div className='stepSectionContainer'>
              {/* NOTE: outer minHeight currently prevents divs from overlapping in small browser window size.
              must be greater than minHeight + total height of other elements */}
              <BackgroundInfoStep
                scriptInfo={scriptInfo}
                orgBackgroundInfoTemplates={orgBackgroundInfoTemplates}
                setOrgBackgroundInfoTemplates={setOrgBackgroundInfoTemplates}
              />
            </div>
            <div className='stepButtonsContainer'>
              <Flex h='100%' justify='flex-end' align='flex-end' gap='md'>
                <Button variant='outline' onClick={handlePreviousStep}>
                  Previous
                </Button>
                <Button onClick={handleNextStep}>Next</Button>
              </Flex>
            </div>
          </>
        ),
      },
      {
        label: 'Settings',
        key: StepKey.Settings,
        isComplete: true,
        element: (
          <>
            {/* NOTE: outer minHeight currently prevents divs from overlapping in small browser window size.
              must be greater than minHeight + total height of other elements */}
            <div className='stepSectionContainer'>
              <Stack h='100%'>
                <Title order={5}>Settings</Title>
                <CallerSettingsStep
                  campaignSettings={scriptInfo}
                  campaignId={campaignId}
                  orgSettings={orgSettings}
                />
              </Stack>
            </div>
            <div className='stepButtonsContainer'>
              <Flex h='100%' justify='flex-end' align='flex-end' gap='md'>
                <Button variant='outline' onClick={handlePreviousStep}>
                  Previous
                </Button>
              </Flex>
            </div>
          </>
        ),
      },
    ];
    return stepsArr
      .filter((s) => !hideMap[s.key])
      .sort(
        (a, b) =>
          allStepKeysSorted.indexOf(a.key) - allStepKeysSorted.indexOf(b.key)
      );
  }, [
    scriptInfo,
    orgSettings,
    generatingScript,
    scriptGenerationGuidelineEdits,
    orgScriptGenerationTemplates,
    handleClickGenerateScript,
    canEditCampaign,
    requirements,
    requirementsHandlers,
    requirementItemBeingEdited,
    unsavedChanges,
    recruitingEmail,
    orgBackgroundInfoTemplates,
    campaignId,
    handleActiveStepIdxChange,
    activeStep,
    hideMap,
  ]);

  useEffect(() => {
    setCanSubmitCampaign(steps.every((step) => step.isComplete));
  }, [steps, setCanSubmitCampaign]);

  const safeActiveStep = activeStep < steps.length ? activeStep : 0;
  const active = steps[safeActiveStep];

  const handleStepClick = (step: number) => () => {
    handleActiveStepIdxChange(step);
  };

  const [editingName, setEditingName] = useState(false);

  if (!scriptFetched) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Loader type='dots' style={{ marginTop: '2%' }} />
      </div>
    );
  }

  return (
    <div className='editorPageContainer'>
      <Stack
        // p='md'
        className='column-padding'
        style={{
          overflowY: 'scroll',
          gap: '14px',
        }}
      >
        {opened && (
          <Modal
            opened={opened}
            onClose={() => setOpened(false)}
            withCloseButton={false}
            centered
            size='lg'
          >
            <Player
              autoplay
              loop={false}
              src='/confetti.json'
              style={{
                width: '100%',
                height: '100%',
              }}
            />
          </Modal>
        )}
        <div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Textarea
              className={`titleInputArea editableText ${editingName ? 'editingTitleActive' : ''}`}
              autosize
              {...scriptInfo.getInputProps('name')}
              minRows={1}
              onClick={() => {
                setEditingName(true);
              }}
            />
          </div>
          <div
            style={{
              paddingLeft: '6px',
              margin: '0px',
              fontSize: '10px',
              color: '#696969',
              marginTop: '4px',
            }}
          >
            {!noChanges ? (
              <>
                {unsavedChanges || unsavedBackgroundInfoChanges
                  ? 'Saving...'
                  : 'Saved'}
              </>
            ) : (
              <div style={{ visibility: 'hidden' }}>{'Saving'}</div>
            )}
          </div>
        </div>

        {steps.map(({ label, description, isComplete, indent }, i) => (
          <>
            <NavLink
              key={i}
              active={activeStep === i}
              label={label}
              description={description}
              w={indent ? 'calc(100% - 26px)' : '100%'}
              onClick={handleStepClick(i)}
              color={isComplete ? '' : 'red'}
              style={{
                color: isComplete ? '' : 'var(--mantine-color-red-6)',
                borderRadius: '6px',
                marginLeft: indent ? '26px' : '0px',
              }}
            />
          </>
        ))}
        <Divider />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            // justifyContent: 'center',
            height: '100%',
            marginTop: '14px',
          }}
        >
          <TestCallModal
            campaignId={campaignId}
            isWebCall={isWebCall}
            scheduleFollowUp={scriptInfo.values.scheduleFollowUp}
            verifyCallOutro={verifyCallOutro}
            webCallEmailBodyValid={
              isWebCall
                ? scriptInfo.values.webCallEmailBody?.includes(
                    '{interview_link}'
                  )
                : true
            }
            scriptInfo={scriptInfo}
            disabled={!canSubmitCampaign}
          />

          {canEditCampaign && (
            <Button
              onClick={() => {
                if (verifyCallOutro()) {
                  return;
                }
                setCampaignModalOpen(true);
              }}
              disabled={!canSubmitCampaign}
              className={'campaign-action-button'}
              leftSection={<IconRocket size={20} />}
            >
              Add Contacts
            </Button>
          )}
          <AddContactsModal
            campaignModalOpen={campaginModalOpen}
            setCampaignModalOpen={setCampaignModalOpen}
            campaignId={campaignId}
            isCampaignActive={isCampaignActive}
            setIsCampaignActive={setIsCampaignActive}
            handleContactsRefresh={handleContactsRefresh}
            isWebCall={isWebCall}
          />
        </div>
      </Stack>
      <div className='column-padding'>
        <div className='stepContainer'>{active.element && active.element}</div>
      </div>
    </div>
  );
}
