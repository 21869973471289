import React, {
  createRef,
  useEffect,
  useState,
  useCallback,
  Fragment,
} from 'react';
import {
  Anchor,
  Button,
  Divider,
  FileInput,
  Flex,
  ScrollArea,
  Table,
  Text,
  Modal,
  Tooltip,
  Checkbox,
  Input,
  Loader,
  ActionIcon,
} from '@mantine/core';
import { startCampaign } from 'pages/scripts/actions';
import { Player } from '@lottiefiles/react-lottie-player';
import {
  IconAlertTriangle,
  IconCircleCheck,
  IconExclamationCircle,
  IconPlayerPlay,
  IconCopy,
  IconCopyCheck,
} from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import env from 'env';
import './AddContactsModal.css';
import { useForm } from '@mantine/form';
import { Select, TextInput } from '@mantine/core';
import { ContactsCSVExtractor } from 'utils/contactsCsvUtils';
import { validateEmail } from 'utils/emailUtils';
import { formatPhoneNumber, validatePhoneNumber } from 'utils/phoneUtils';
import { formatToLocalTime, isValidTimeZone } from 'utils/dateUtils';
import axios from 'api/axiosConfig';

interface PreviewData {
  valid_rows: Array<any>;
  invalid_rows: { [key: string]: any }[];
  already_uploaded_in_campaign: { [key: string]: any }[];
  already_uploaded_in_other_campaigns: { [key: string]: any }[];
  other_errors: string[];
}

interface ModalContent {
  invalidRows: { content: { [key: string]: any }[]; checkedRows: Set<string> };
  alreadyUploadedInCampaign: {
    content: { [key: string]: any }[];
    checkedRows: Set<string>;
  };
  alreadyUploadedInOtherCampaigns: {
    content: { [key: string]: any }[];
    checkedRows: Set<string>;
  };
}

const AddContactsModal = ({
  campaignModalOpen,
  setCampaignModalOpen,
  campaignId,
  isCampaignActive,
  setIsCampaignActive,
  handleContactsRefresh,
  isWebCall,
}) => {
  const [modalStep, setModalStep] = useState<
    'initial' | 'process' | 'preview' | 'success' | 'sequence' | 'manualForm'
  >('initial');
  const [selectedFile, setSelectedFile] = useState(null);
  const [numContactsUploaded, setNumContactsUploaded] = useState(undefined);
  const [previewData, setPreviewData] = useState<PreviewData>({
    valid_rows: [],
    invalid_rows: [],
    already_uploaded_in_campaign: [],
    already_uploaded_in_other_campaigns: [],
    other_errors: [],
  });
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [modalContent, setModalContent] = useState<ModalContent>({
    invalidRows: { content: [], checkedRows: new Set() },
    alreadyUploadedInCampaign: { content: [], checkedRows: new Set() },
    alreadyUploadedInOtherCampaigns: { content: [], checkedRows: new Set() },
  });
  const [modalContentType, setModalContentType] = useState<
    | 'invalidRows'
    | 'alreadyUploadedInCampaign'
    | 'alreadyUploadedInOtherCampaigns'
  >();

  const [isCopied, setIsCopied] = useState(false);
  const [permanentWebLink, setPermanentWebLink] = useState('');
  const handleCopy = () => {
    navigator.clipboard.writeText(permanentWebLink);
    setIsCopied(true);
    notifications.show({
      title: 'Link Copied',
      message: 'Permanent link copied to clipboard',
      color: 'green',
    });
  };

  useEffect(() => {
    if (campaignId && isWebCall && campaignModalOpen && !permanentWebLink) {
      const fetchPermanentWebLink = async () => {
        try {
          const response = await axios.post(
            `${env.REACT_APP_SERVER_URL}/generate_campaign_link`,
            {
              campaign_id: campaignId,
            }
          );
          setPermanentWebLink(response.data.link);
        } catch (error: any) {
          console.error('Error generating campaign link:', error);
          notifications.show({
            title: 'Error',
            message: 'Failed to generate campaign link',
            color: 'red',
          });
        }
      };

      fetchPermanentWebLink();
    }
  }, [campaignId, isWebCall, campaignModalOpen, permanentWebLink]);

  const [rowsShowingAllCalls, setRowsShowingAllCalls] = useState<string[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [invalidRowsReviewed, setInvalidRowsReviewed] =
    useState<boolean>(false);
  const [
    alreadyUploadedInOtherCampaignsReviewed,
    setAlreadyUploadedInOtherCampaignsReviewed,
  ] = useState<boolean>(false);

  const fileInputRef = createRef<HTMLButtonElement>();
  const reviewModalContent: { [key: string]: any }[] = modalContentType
    ? modalContent[modalContentType].content
    : [];
  const reviewModalCheckedRows: Set<string> = modalContentType
    ? modalContent[modalContentType].checkedRows
    : new Set();

  const expectedTableHeaders = [
    'name',
    'phone',
    'email',
    'timezone',
    'location',
    'zip',
  ];

  const form = useForm({
    initialValues: {
      name: '',
      phone: '',
      email: '',
      timezone: '',
      zipcode: '',
    },
    validate: {
      name: (value) => (value.length < 2 ? 'Name is required' : null),
      phone: (value) => {
        if (!isWebCall) {
          if (!value) return 'Phone is required';
          if (!validatePhoneNumber(value))
            return 'Please enter a valid US phone number';
        }
        return null;
      },
      email: (value) => {
        if (isWebCall) {
          if (!value) return 'Email is required for video calls';
          if (!/^\S+@\S+$/.test(value)) return 'Invalid email';
        }
        return null;
      },
      zipcode: (value) => {
        if (value && !/^\d{5}(-\d{4})?$/.test(value)) {
          return 'Invalid US zipcode';
        }
        return null;
      },
    },
    transformValues: (values) => ({
      ...values,
      phone: values.phone
        ? formatPhoneNumber(values.phone, 'national')
        : values.phone,
    }),
  });

  const handleFileSelect = (event) => {
    const file = event;
    if (file) {
      const extension = file.name.split('.').pop().toLowerCase();
      const isCSV = extension === 'csv' || file.type === 'text/csv';
      if (isCSV) {
        setSelectedFile(file);
      } else {
        notifications.show({
          title: 'Invalid File',
          message: 'Only CSV files are allowed.',
          color: 'red',
        });
      }
    }
  };

  const orgId = localStorage.getItem('orgId') || '';

  useEffect(() => {
    const handleUploadFile = async () => {
      if (!selectedFile) return;
      const formData = new FormData();
      const userId = localStorage.getItem('userId') || '';
      formData.append('userId', userId);
      formData.append('file', selectedFile);

      try {
        await axios.post(
          `${env.REACT_APP_SERVER_URL}/upload_candidate_csv`,
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          }
        );
      } catch (error) {
        console.error('file upload failed', error);
      }
    };
    if (selectedFile) {
      handleUploadFile();
    }
  }, [selectedFile]);

  const handlePreview = useCallback(async () => {
    setLoadingPreview(true);
    if (!selectedFile) {
      return;
    }

    const extractor = new ContactsCSVExtractor(selectedFile, isWebCall);
    try {
      const { validRows, invalidRows } = await extractor.extract();
      setPreviewData({
        valid_rows: validRows,
        invalid_rows: invalidRows,
        already_uploaded_in_campaign: [],
        already_uploaded_in_other_campaigns: [],
        other_errors: [],
      });
      setModalStep('process');
    } catch (error) {
      console.error(error);
      if (error instanceof Error) {
        notifications.show({
          title: 'An error occurred',
          message: error instanceof Error ? error.message : '',
          color: 'red',
        });

        setPreviewData((prevData) => ({
          ...prevData,
          other_errors: [error.message],
        }));
      }
    } finally {
      setLoadingPreview(false);
    }
  }, [selectedFile, isWebCall]);

  useEffect(() => {
    if (selectedFile) {
      handlePreview();
    }
  }, [selectedFile, handlePreview]);

  const handleCancel = () => {
    setCampaignModalOpen(false);
    setTimeout(() => {
      setSelectedFile(null);
      setPreviewData({
        valid_rows: [],
        invalid_rows: [],
        other_errors: [],
        already_uploaded_in_campaign: [],
        already_uploaded_in_other_campaigns: [],
      });
      resetModalContent();
      setModalStep('initial');
      form.reset();
    }, 200);
  };

  const handleClickReupload = () => {
    setTimeout(() => {
      setSelectedFile(null);
      setPreviewData({
        valid_rows: [],
        invalid_rows: [],
        other_errors: [],
        already_uploaded_in_campaign: [],
        already_uploaded_in_other_campaigns: [],
      });
      setModalStep('initial');
      setInvalidRowsReviewed(false);
      setAlreadyUploadedInOtherCampaignsReviewed(false);
      resetModalContent();
      form.reset();
    }, 200);
  };

  const handleActivateCampaign = () => {
    startCampaign(campaignId)
      .then(() => {
        setIsCampaignActive(true);
        setModalStep('sequence');
      })
      .catch((err) => {
        console.error('error setting campaign active', err);
      });
  };

  const preventDefaults = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleFileDrop = (e) => {
    preventDefaults(e);

    const files = e.dataTransfer.files;
    if (files.length) {
      handleFileSelect(files[0]);
    }
  };

  const handleDownloadExample = () => {
    if (isWebCall) {
      window.location.href =
        'https://salvstorageaccount.blob.core.windows.net/public/example_email_list.csv';
    } else {
      window.location.href =
        'https://salvstorageaccount.blob.core.windows.net/public/example_call_list.csv';
    }
  };

  const renderGreenCheck = (size = 48) => (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='green'
      className='bi bi-check-circle-fill'
      viewBox='0 0 16 16'
    >
      <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.93 10.58l-2.47-2.47-.708.708 3.18 3.18 6.364-6.364-.708-.708L6.93 10.58z' />
    </svg>
  );

  const resetModalContent = () => {
    setModalContent({
      invalidRows: { content: [], checkedRows: new Set() },
      alreadyUploadedInCampaign: { content: [], checkedRows: new Set() },
      alreadyUploadedInOtherCampaigns: { content: [], checkedRows: new Set() },
    });
  };

  const handleSubmit = () => {
    setLoadingSubmit(true);

    axios
      .post(`${env.REACT_APP_SERVER_URL}/add_contacts`, {
        campaignId: campaignId,
        validRows: previewData.valid_rows,
      })
      .then((resp) => {
        setModalStep('success');
        if (resp.data.num_contacts) {
          setNumContactsUploaded(resp.data.num_contacts);
        }
        setAlreadyUploadedInOtherCampaignsReviewed(false);
        setInvalidRowsReviewed(false);
        resetModalContent();
        handleContactsRefresh();
      })
      .catch((e) => {
        notifications.show({
          title: 'Failed to upload contacts',
          message: e.message,
          color: 'red',
        });
      })
      .finally(() => {
        setLoadingSubmit(false);
      });
  };

  const handleProcessSubmit = async () => {
    setLoadingPreview(true);
    try {
      const response = await axios.post(
        `${env.REACT_APP_SERVER_URL}/add_contacts_preview`,
        {
          contacts: previewData.valid_rows,
          campaignId: campaignId,
          orgId: orgId,
          acceptsEmail: isWebCall,
        }
      );

      const {
        valid_rows,
        invalid_rows,
        already_uploaded_in_campaign,
        already_uploaded_in_other_campaigns,
      } = response.data;
      setInvalidRowsReviewed(false);

      if (invalid_rows.length > 0) {
        setPreviewData((prevData) => ({
          ...prevData,
          valid_rows: valid_rows,
          invalid_rows: invalid_rows,
        }));

        setModalContent((prevContent) => ({
          ...prevContent,
          invalidRows: {
            content: invalid_rows,
            checkedRows: new Set(invalid_rows.map((row) => row.id)),
          },
        }));

        return;
      }

      setPreviewData({
        valid_rows: valid_rows,
        invalid_rows: [],
        already_uploaded_in_campaign: already_uploaded_in_campaign,
        already_uploaded_in_other_campaigns:
          already_uploaded_in_other_campaigns,
        other_errors: [],
      });
      resetModalContent();
      setModalStep('preview');
    } catch (error) {
      console.error('Error processing contacts', error);
      notifications.show({
        title: 'Failed to process contacts',
        message: error instanceof Error ? error.message : '',
        color: 'red',
      });
    } finally {
      setLoadingPreview(false);
    }
  };

  interface ApiError {
    message: string;
  }

  const handleManualSubmit = async (values) => {
    setLoadingSubmit(true);
    try {
      const response = await axios.post(
        `${env.REACT_APP_SERVER_URL}/add_contacts`,
        {
          campaignId: campaignId,
          runInBackground: false,
          validRows: [
            {
              name: values.name,
              phone: values.phone,
              email: values.email,
              timezone: values.timezone,
              zipcode: values.zipcode,
            },
          ],
        }
      );
      setModalStep('success');
      handleContactsRefresh();
      if (response.data.num_contacts) {
        setNumContactsUploaded(response.data.num_contacts);
      }
    } catch (error) {
      const apiError = error as ApiError;
      notifications.show({
        title: 'Failed to add contact',
        message: apiError.message,
        color: 'red',
      });
    }
    setLoadingSubmit(false);
  };

  const renderAddContacts = () => {
    return (
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div style={{ width: '360px' }}>
            <div
              onDragOver={preventDefaults}
              onDrop={handleFileDrop}
              className='contacts-file-input'
            >
              <FileInput
                placeholder='Upload CSV'
                label='Upload candidates csv file'
                required
                value={selectedFile}
                ref={fileInputRef}
                error={!selectedFile}
                onChange={handleFileSelect}
              />
              <Anchor
                size='sm'
                onClick={handleDownloadExample}
                style={{ position: 'relative', top: '6px' }}
              >
                Download Example CSV
              </Anchor>
            </div>
            <br />
          </div>
        </div>
        <Divider label='Or' labelPosition='center' my='md' />
        <Button
          onClick={() => setModalStep('manualForm')}
          variant='subtle'
          fullWidth
        >
          Enter Contact Details Manually
        </Button>
        {isWebCall && renderPermanentWebLink()}
      </div>
    );
  };

  const renderPermanentWebLink = () => {
    return (
      <>
        <Divider label='Or' labelPosition='center' my='md' />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
          }}
        >
          <Text
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              fontSize: '12px',
              opacity: 0.6,
            }}
          >
            Allow candidates to enter their own contact information
          </Text>
          <div
            style={{
              display: 'flex',
              padding: '10px',
              gap: '24px',
              marginLeft: 'auto',
              marginRight: 'auto',
              opacity: isCampaignActive && permanentWebLink ? 1 : 0.8,
              pointerEvents: permanentWebLink ? 'auto' : 'none',
            }}
          >
            <Tooltip
              label={
                !isCampaignActive
                  ? 'Campaign not active, permanent web link will not function'
                  : 'Navigate to permanent interview link'
              }
            >
              <Text
                className='permanent-interview-link-nav-text'
                style={{
                  alignContent: 'center',
                  alignItems: 'center',
                  fontSize: '14px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  window.open(
                    permanentWebLink,
                    '_blank',
                    'noopener,noreferrer'
                  );
                }}
              >
                Permanent Interview Link
              </Text>
            </Tooltip>

            <Tooltip label='Copy permanent link'>
              <ActionIcon variant='transparent' onClick={handleCopy}>
                {isCopied ? (
                  <IconCopyCheck size={20} />
                ) : (
                  <IconCopy size={20} />
                )}
              </ActionIcon>
            </Tooltip>
          </div>
        </div>
        <div style={{ marginBottom: '4px' }} />
      </>
    );
  };

  const renderContactsSuccess = () => (
    <Flex
      justify='center'
      align='center'
      direction='column'
      gap='md'
      style={{ minHeight: '200px' }}
    >
      <h3
        style={{
          textAlign: 'center',
          marginTop: '0px',
          top: '-20px',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        {numContactsUploaded
          ? `${numContactsUploaded} Contact${numContactsUploaded === 1 ? '' : 's'} Uploaded`
          : 'Contacts Uploaded'}
        {!isCampaignActive && renderGreenCheck(24)}
      </h3>

      {isCampaignActive ? (
        renderGreenCheck()
      ) : (
        <Button
          size='md'
          leftSection={<IconPlayerPlay size={20} />}
          onClick={handleActivateCampaign}
        >
          Activate Sequence
        </Button>
      )}
    </Flex>
  );

  const renderSequenceStarted = () => (
    <Flex
      justify='center'
      align='center'
      direction='column'
      gap='md'
      style={{ minHeight: '340px' }}
    >
      <div style={{ position: 'absolute' }}>
        <Player
          autoplay
          loop
          src='/confetti.json'
          style={{ width: '100%', height: '100%' }}
        />
      </div>
      <h1
        style={{
          textAlign: 'center',
          marginTop: '0px',
          top: '-10px',
          position: 'relative',
        }}
      >
        Sequence Started Successfully 🚀
      </h1>
      {renderGreenCheck()}
    </Flex>
  );

  let previewTableHeaders: string[] = [];
  expectedTableHeaders.forEach((header) => {
    if (previewData.valid_rows.some((row) => row[header] != null)) {
      previewTableHeaders.push(header);
    }
  });

  const renderPreviewTable = () => {
    return (
      <div>
        {previewData.valid_rows.length > 0 && (
          <Fragment>
            <Text size='lg' fw={600} mb='sm' style={{ textAlign: 'center' }}>
              Preview Contacts ({previewData.valid_rows?.length} rows)
            </Text>
            {modalStep === 'process' && continueButtonDisabled && (
              <Text
                size='sm'
                mb='sm'
                style={{ textAlign: 'center', color: 'red' }}
              >
                Resolve errors to submit contacts.
              </Text>
            )}
            {modalStep === 'preview' && submitButtonDisabled && (
              <Text
                size='sm'
                mb='sm'
                style={{ textAlign: 'center', color: 'red' }}
              >
                Review remaining warnings to submit contacts.
              </Text>
            )}

            <ScrollArea
              h={400}
              style={{ border: '1px solid #e0e0e0', borderRadius: '8px' }}
            >
              <Table striped highlightOnHover>
                <thead>
                  <tr>
                    {previewTableHeaders.map((key) => {
                      if (
                        key === 'zip' &&
                        previewTableHeaders.includes('location')
                      ) {
                        return null;
                      }
                      return (
                        <th
                          key={key}
                          style={{ textAlign: 'left', paddingLeft: '18px' }}
                        >
                          {key.replace(/_/g, ' ').toUpperCase()}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {previewData.valid_rows.slice(0, 1000).map((row) => {
                    const { id } = row;
                    return (
                      <tr key={id}>
                        {previewTableHeaders.map((key, colIndex) => {
                          if (
                            key === 'zip' &&
                            previewTableHeaders.includes('location')
                          ) {
                            return null;
                          }
                          const value = row[key];
                          return (
                            <td
                              key={colIndex}
                              style={{ textAlign: 'left', paddingLeft: '18px' }}
                            >
                              {value ? String(value) : '-'}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </ScrollArea>
          </Fragment>
        )}
        <Flex justify='flex-end' gap='md' mt='md'>
          <Button variant='outline' color='blue' onClick={handleClickReupload}>
            Re-upload
          </Button>
          {modalStep === 'preview' && (
            <Tooltip
              label='Review remaining warnings to submit contacts'
              hidden={!submitButtonDisabled}
              openDelay={200}
              withArrow
              position='top'
            >
              <Button
                onClick={handleSubmit}
                loading={loadingSubmit}
                color='blue'
                disabled={submitButtonDisabled}
              >
                Submit
              </Button>
            </Tooltip>
          )}
          {modalStep === 'process' && (
            <Tooltip
              label='Resolve errors to submit contacts'
              hidden={!continueButtonDisabled}
              openDelay={200}
              withArrow
              position='top'
            >
              <Button
                onClick={handleProcessSubmit}
                color='blue'
                disabled={continueButtonDisabled}
                style={{
                  pointerEvents: loadingPreview ? 'none' : 'auto',
                }}
              >
                {/* Custom button loader because default loader cannot handle custom ui very well*/}
                {loadingPreview ? (
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <Text size='sm' color='white' style={{ fontWeight: 700 }}>
                      {'Analyzing'}
                    </Text>
                    <Loader size='xs' color='white' />
                  </div>
                ) : (
                  'Continue'
                )}
              </Button>
            </Tooltip>
          )}
        </Flex>
      </div>
    );
  };

  const submitButtonDisabled =
    previewData.valid_rows?.length < 1 ||
    (previewData.already_uploaded_in_other_campaigns.length > 0 &&
      !alreadyUploadedInOtherCampaignsReviewed);

  const continueButtonDisabled =
    previewData.valid_rows?.length < 1 ||
    (previewData.invalid_rows.length > 0 && !invalidRowsReviewed);

  const renderErrorMessages = () => {
    const {
      valid_rows,
      invalid_rows,
      already_uploaded_in_campaign,
      already_uploaded_in_other_campaigns,
      other_errors,
    } = previewData;

    const messageStyles = {
      success: {
        backgroundColor: '#d4edda',
        color: '#155724',
      },
      error: {
        backgroundColor: '#f8d7da',
        color: '#721c24',
      },
      warning: {
        backgroundColor: '#fff3cd',
        color: '#856404',
      },
      common: {
        padding: '10px',
        borderRadius: '5px',
        marginBottom: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
    };

    const renderIcon = (type) => {
      switch (type) {
        case 'success':
          return (
            <IconCircleCheck
              size={16}
              color='#155724'
              style={{ marginRight: '8px' }}
            />
          );
        case 'error':
          return (
            <IconExclamationCircle
              size={16}
              color='#721c24'
              style={{ marginRight: '8px' }}
            />
          );
        case 'warning':
          return (
            <IconAlertTriangle
              size={16}
              color='#856404'
              style={{ marginRight: '8px' }}
            />
          );
        default:
          return null;
      }
    };

    const handleViewClick = (
      content: { [key: string]: any }[],
      contentType?:
        | 'invalidRows'
        | 'alreadyUploadedInCampaign'
        | 'alreadyUploadedInOtherCampaigns'
    ) => {
      if (!contentType) {
        return;
      }

      const checkedContent = new Set<string>();
      content.forEach((row) => {
        checkedContent.add(row.id);
      });

      if (modalContent[contentType]?.content?.length < 1) {
        setModalContent((prevContent) => ({
          ...prevContent,
          [contentType]: {
            content,
            checkedRows: checkedContent,
          },
        }));
      }

      setModalContentType(contentType);
      setModalOpen(true);
    };

    type ValidationMessage = {
      type: string;
      condition: boolean;
      text: string;
      viewContent?: { [key: string]: any }[];
      contentType?:
        | 'invalidRows'
        | 'alreadyUploadedInCampaign'
        | 'alreadyUploadedInOtherCampaigns';
    };

    const showInvalidRowsWarning =
      valid_rows?.length > 0 && invalidRowsReviewed;
    const showAlreadyUploadedInOtherCampaignsWarning =
      valid_rows?.length > 0 && alreadyUploadedInOtherCampaignsReviewed;

    const messages: ValidationMessage[] = [
      {
        type: 'success',
        condition: valid_rows?.length > 0,
        text: `${valid_rows?.length} valid contact${valid_rows?.length === 1 ? '' : 's'} ${modalStep === 'preview' ? 'ready to submit' : 'extracted'}`,
      },
      {
        type: showInvalidRowsWarning ? 'warning' : 'error',
        condition: invalid_rows?.length > 0,
        text: showInvalidRowsWarning
          ? `Excluding ${invalid_rows?.length} row${invalid_rows?.length > 1 ? 's' : ''} with invalid data`
          : `${invalid_rows?.length} row${invalid_rows?.length > 1 ? 's' : ''} with invalid data found`,
        viewContent: invalid_rows,
        contentType: 'invalidRows',
      },
      {
        type: 'warning',
        condition: already_uploaded_in_campaign?.length > 0,
        text: `${already_uploaded_in_campaign?.length} Contact${already_uploaded_in_campaign?.length > 1 ? 's' : ''} already uploaded in this campaign and ${already_uploaded_in_campaign?.length > 1 ? 'were' : 'was'} removed`,
        viewContent: already_uploaded_in_campaign,
        contentType: 'alreadyUploadedInCampaign',
      },
      {
        type: 'warning',
        condition: already_uploaded_in_other_campaigns?.length > 0,
        text: showAlreadyUploadedInOtherCampaignsWarning
          ? `Excluding ${already_uploaded_in_other_campaigns?.length} Contact${already_uploaded_in_other_campaigns?.length > 1 ? 's' : ''} found in other campaigns`
          : `${already_uploaded_in_other_campaigns?.length} Contact${already_uploaded_in_other_campaigns?.length > 1 ? 's' : ''} found in other campaigns`,
        viewContent: already_uploaded_in_other_campaigns,
        contentType: 'alreadyUploadedInOtherCampaigns',
      },
      {
        type: 'error',
        condition: other_errors?.length > 0,
        text: other_errors?.join('/n'),
      },
    ];

    if (messages.filter((m) => m.condition).length === 0) {
      return <div style={{ marginBottom: '6px' }}></div>;
    }

    const handleChange = (id: string, field: string, value: string) => {
      if (!modalContentType) {
        return;
      }

      setModalContent((prevContent) => {
        const content = prevContent[modalContentType].content;
        const updatedContent = content.map((row) => {
          if (row.id === id) {
            const updatedRow = { ...row, [field]: value };
            switch (field) {
              case 'name':
                if (value) {
                  delete updatedRow.errors[field];
                } else {
                  updatedRow.errors[field] = 'Missing name';
                }
                break;
              case 'timezone':
                if (value && isValidTimeZone(value)) {
                  delete updatedRow.errors[field];
                }
                break;
              case 'location':
                if (value) {
                  delete updatedRow.errors[field];
                }
                break;
              case 'phone':
              case 'email':
                const isRequired =
                  (field === 'phone' && !isWebCall) ||
                  (field === 'email' && isWebCall);
                const valueField = value;
                const oldValue = row[field]; // handles phone and email
                const formattedOldValue =
                  field === 'phone'
                    ? formatPhoneNumber(oldValue, 'national')
                    : oldValue;
                const formattedValue =
                  field === 'phone'
                    ? formatPhoneNumber(valueField, 'national')
                    : valueField;

                // Remove duplicates first
                const prevDuplicateRows = content.filter((row) => {
                  const fieldValue =
                    field === 'phone'
                      ? formatPhoneNumber(row.phone, 'national')
                      : row.email;

                  if (!formattedOldValue || !fieldValue) return false;

                  return fieldValue === formattedOldValue && row.id !== id;
                });

                if (prevDuplicateRows.length === 1) {
                  delete prevDuplicateRows[0].errors[field];
                } else if (prevDuplicateRows.length > 1) {
                  const duplicateNames = prevDuplicateRows.map(
                    (row) => row.name
                  );
                  prevDuplicateRows.forEach((row) => {
                    if (row.name === prevDuplicateRows[0].name) {
                      row.errors[field] =
                        `Duplicate ${field}: ${duplicateNames[1]}`;
                    } else {
                      row.errors[field] =
                        `Duplicate ${field}: ${duplicateNames[0]}`;
                    }
                  });
                }

                // Now validate the current value
                if (!valueField) {
                  if (isRequired) {
                    updatedRow.errors[field] = `Missing ${field}`;
                  } else {
                    delete updatedRow.errors[field];
                  }
                } else if (
                  (field === 'phone' && !validatePhoneNumber(valueField)) ||
                  (field === 'email' && !validateEmail(valueField))
                ) {
                  updatedRow.errors[field] = `Invalid ${field}`;
                } else {
                  // Proceed to check for duplicates if the value is valid
                  const allRows = [...content, ...previewData.valid_rows];
                  const duplicateRow = allRows.find((row) => {
                    const fieldValue =
                      field === 'phone'
                        ? formatPhoneNumber(row.phone, 'national')
                        : row.email;

                    if (!formattedValue || !fieldValue) return false;

                    return fieldValue === formattedValue && row.id !== id;
                  });

                  if (duplicateRow) {
                    updatedRow.errors[field] =
                      `Duplicate ${field}: ${duplicateRow.name}`;

                    // Handling other possible duplicate rows (in modalContent)
                    const otherDuplicateRow = content.find((row) => {
                      return (
                        (field === 'phone'
                          ? formatPhoneNumber(row.phone, 'national')
                          : row.email) === formattedValue && row.id !== id
                      );
                    });

                    if (otherDuplicateRow) {
                      otherDuplicateRow.errors[field] =
                        `Duplicate ${field}: ${updatedRow.name}`;
                    }
                  } else {
                    delete updatedRow.errors[field];
                  }

                  if (field === 'phone') {
                    updatedRow.phone = formattedValue;
                  }
                }
                break;
            }

            return updatedRow;
          }
          return row;
        });

        return {
          ...prevContent,
          [modalContentType]: {
            ...prevContent[modalContentType],
            content: updatedContent,
          },
        };
      });
    };

    const handleChangeCheckbox = (id: string, checked: boolean) => {
      if (!modalContentType) {
        return;
      }

      setModalContent((prevContent) => {
        const updatedCheckedRows = new Set(
          prevContent[modalContentType].checkedRows
        );
        if (checked) {
          updatedCheckedRows.add(id);
        } else {
          updatedCheckedRows.delete(id);
        }

        return {
          ...prevContent,
          [modalContentType]: {
            ...prevContent[modalContentType],
            checkedRows: updatedCheckedRows,
          },
        };
      });
    };

    const selectedRows = reviewModalContent.filter((row) =>
      reviewModalCheckedRows.has(row.id)
    );

    const isSavedEnabled = selectedRows.every(
      (row) => row?.errors && Object.keys(row.errors).length === 0
    );

    const fixedRows = selectedRows.filter(
      (row) => row?.errors && Object.keys(row?.errors).length === 0
    );

    const handleSaveFixedRows = () => {
      if (!modalContentType) {
        return;
      }

      // Add fixed rows from valid_rows, remove them from invalid_rows or already_uploaded_in_other_campaigns
      setPreviewData((prevData) => ({
        ...prevData,
        valid_rows: [
          ...prevData.valid_rows,
          ...fixedRows.filter(
            (fixedRow) =>
              !prevData.valid_rows.some((row) => row.id === fixedRow.id)
          ),
        ],
        invalid_rows: prevData.invalid_rows.filter(
          (row) => !fixedRows.some((fixedRow) => fixedRow.id === row.id)
        ),
        already_uploaded_in_other_campaigns:
          prevData.already_uploaded_in_other_campaigns.filter(
            (row) => !fixedRows.some((fixedRow) => fixedRow.id === row.id)
          ),
      }));
      setModalOpen(false);
      setRowsShowingAllCalls([]);

      // Remove fixed rows from modalContent and remove ids from checkedRows
      setModalContent((prevContent) => {
        const updatedContent = prevContent[modalContentType].content.filter(
          (row) => !fixedRows.some((fixedRow) => fixedRow.id === row.id)
        );
        const updatedCheckedRows = new Set(
          prevContent[modalContentType].checkedRows
        );

        updatedCheckedRows.forEach((id) => {
          if (fixedRows.some((fixedRow) => fixedRow.id === id)) {
            updatedCheckedRows.delete(id);
          }
        });

        return {
          ...prevContent,
          [modalContentType]: {
            content: updatedContent,
            checkedRows: updatedCheckedRows,
          },
        };
      });

      if (modalContentType === 'alreadyUploadedInOtherCampaigns') {
        setAlreadyUploadedInOtherCampaignsReviewed(true);
      }

      if (modalContentType === 'invalidRows') {
        setInvalidRowsReviewed(true);
      }
    };

    const handleSelectOrDeselectAll = (e) => {
      if (!modalContentType) {
        return;
      }

      const checked = e.target.checked;
      setModalContent((prevContent) => ({
        ...prevContent,
        [modalContentType]: {
          ...prevContent[modalContentType],
          checkedRows: checked
            ? new Set(reviewModalContent.map((row) => row.id))
            : new Set(),
        },
      }));
    };

    const handleCloseReviewModal = () => {
      setModalOpen(false);
    };

    const handleShowMoreCalls = (id) => {
      setRowsShowingAllCalls((prev) =>
        prev.includes(id) ? prev.filter((i) => i !== id) : [...prev, id]
      );
    };

    let modalHeaders: string[] = [];
    expectedTableHeaders.forEach((header) => {
      if (reviewModalContent.some((row) => row[header] != null)) {
        modalHeaders.push(header);
      }
    });

    return (
      <div style={{ padding: '10px', borderRadius: '8px' }}>
        {messages.map(
          ({ type, condition, text, viewContent, contentType }) =>
            condition && (
              <div
                key={text}
                style={{ ...messageStyles[type], ...messageStyles.common }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {renderIcon(type)}
                  <Text size='sm' style={{ textAlign: 'left' }}>
                    {text}
                  </Text>
                </div>
                {viewContent && (
                  <Button
                    size='xs'
                    variant='subtle'
                    color={
                      type === 'error' || type === 'warning'
                        ? messageStyles[type].color
                        : ''
                    }
                    onClick={() => handleViewClick(viewContent, contentType)}
                  >
                    {contentType === 'alreadyUploadedInCampaign'
                      ? 'View'
                      : 'Review'}
                  </Button>
                )}
              </div>
            )
        )}
        <Modal
          opened={modalOpen}
          onClose={handleCloseReviewModal}
          title='Error Details'
          size='auto'
          styles={{
            body: {
              display: 'flex',
              flexDirection: 'column',
              maxHeight: '80vh',
              overflow: 'hidden',
            },
          }}
        >
          <Divider my='sm' />
          {modalContentType === 'invalidRows' && (
            <Text
              size='sm'
              style={{
                marginBottom: '10px',
                textAlign: 'center',
                color: 'red',
              }}
            >
              Review and fix errors in red or exclude invalid candidates to
              continue
            </Text>
          )}
          {modalContentType === 'alreadyUploadedInOtherCampaigns' && (
            <Text
              size='sm'
              style={{
                marginBottom: '10px',
                textAlign: 'center',
                color: 'red',
                whiteSpace: 'pre-line',
              }}
            >
              These candidates were already uploaded in other sequences.
              <span style={{ display: 'block' }}>
                Select to include or exclude them from this sequence.
              </span>
            </Text>
          )}
          <div style={{ maxHeight: 'calc(80vh - 120px)', overflow: 'scroll' }}>
            {reviewModalContent.length > 0 && (
              <Table
                withRowBorders
                style={{
                  maxWidth: '900px',
                  minWidth: '600px',
                  width: 'max-content',
                }}
              >
                <Table.Thead>
                  <Table.Tr>
                    {modalContentType !== 'alreadyUploadedInCampaign' && (
                      <Table.Th style={{ width: '30px', paddingLeft: '4px' }}>
                        <Tooltip
                          label={
                            reviewModalContent.length ===
                            reviewModalCheckedRows.size
                              ? 'Deselect All'
                              : 'Select All'
                          }
                          position='right'
                          withArrow
                          openDelay={200}
                        >
                          <Checkbox
                            variant='outline'
                            checked={
                              reviewModalContent.length ===
                              reviewModalCheckedRows.size
                            }
                            onChange={handleSelectOrDeselectAll}
                          />
                        </Tooltip>
                      </Table.Th>
                    )}
                    {modalHeaders.map((key) => {
                      if (key === 'zip' && modalHeaders.includes('location')) {
                        return null;
                      }

                      return (
                        <Table.Th
                          key={key}
                          style={{
                            textAlign: 'left',
                            paddingLeft: '18px',
                          }}
                        >
                          {key.replace(/_/g, ' ').toUpperCase()}
                        </Table.Th>
                      );
                    })}
                  </Table.Tr>
                </Table.Thead>
                <tbody>
                  {reviewModalContent.map((row) => {
                    const { id, errors, calls } = row;
                    const isChecked = reviewModalCheckedRows.has(id);
                    return (
                      <Fragment key={id}>
                        <tr>
                          {modalContentType !== 'alreadyUploadedInCampaign' && (
                            <td>
                              <Tooltip
                                label={
                                  isChecked
                                    ? 'Remove candidate'
                                    : 'Include candidate'
                                }
                                position='right'
                                withArrow
                                openDelay={200}
                              >
                                <Checkbox
                                  variant='outline'
                                  style={{ paddingLeft: '4px' }}
                                  checked={isChecked}
                                  onChange={(e) =>
                                    handleChangeCheckbox(id, e.target.checked)
                                  }
                                />
                              </Tooltip>
                            </td>
                          )}
                          {modalHeaders.map((key, index) => {
                            if (
                              key === 'zip' &&
                              modalHeaders.includes('location')
                            ) {
                              return null;
                            }
                            const value = row[key];
                            const isWarning =
                              key === 'timezone' ||
                              key === 'zip' ||
                              key === 'location';
                            return (
                              <td
                                key={`${key}-${id}`}
                                style={{
                                  textAlign: 'left',
                                  paddingLeft: index === 0 ? '' : '18px',
                                }}
                              >
                                {modalContentType ===
                                  'alreadyUploadedInCampaign' ||
                                modalContentType ===
                                  'alreadyUploadedInOtherCampaigns' ? (
                                  <Text
                                    style={{
                                      padding: '8px',
                                      borderRadius: '5px',
                                      // border:
                                      //   '1px solid var(--mantine-color-gray-4)',
                                      minHeight: '40px',
                                      minWidth: '150px',
                                    }}
                                    size='sm'
                                  >
                                    {value}
                                  </Text>
                                ) : (
                                  <Tooltip
                                    label={errors && errors[key]}
                                    position='top'
                                    withArrow
                                    hidden={!isChecked || !errors[key]}
                                    openDelay={200}
                                  >
                                    {key === 'timezone' ? (
                                      <Select
                                        data={[
                                          {
                                            value: 'EST',
                                            label: 'Eastern Time',
                                          },
                                          {
                                            value: 'CST',
                                            label: 'Central Time',
                                          },
                                          {
                                            value: 'MST',
                                            label: 'Mountain Time',
                                          },
                                          {
                                            value: 'PST',
                                            label: 'Pacific Time',
                                          },
                                        ]}
                                        value={value}
                                        onChange={(e) =>
                                          handleChange(id, key, e as string)
                                        }
                                        style={{
                                          border:
                                            !errors[key] || !isChecked
                                              ? ''
                                              : isWarning
                                                ? '1px solid #FFA500'
                                                : '1px solid red',
                                          borderRadius: '5px',
                                        }}
                                      />
                                    ) : (
                                      <Input
                                        name={key}
                                        disabled={!isChecked}
                                        value={value}
                                        onChange={(e) =>
                                          handleChange(id, key, e.target.value)
                                        }
                                        style={{
                                          border:
                                            !errors[key] || !isChecked
                                              ? ''
                                              : isWarning
                                                ? '1px solid #FFA500'
                                                : '1px solid red',
                                          borderRadius: '5px',
                                        }}
                                      />
                                    )}
                                  </Tooltip>
                                )}
                              </td>
                            );
                          })}
                        </tr>
                        {calls && calls.length > 0 && (
                          <tr>
                            <td />
                            <td colSpan={modalHeaders.length}>
                              <div style={{ padding: '10px' }}>
                                <Text
                                  size='xs'
                                  style={{
                                    marginBottom: '2px',
                                    fontWeight: '600',
                                  }}
                                >
                                  Previous calls:
                                  {calls.length > 1 && (
                                    <span
                                      onClick={() => handleShowMoreCalls(id)}
                                      style={{
                                        color: 'var(--mantine-color-blue-4',
                                        fontWeight: '600',
                                        cursor: 'pointer',
                                        marginLeft: '5px',
                                      }}
                                    >
                                      {rowsShowingAllCalls.includes(id)
                                        ? `Hide Calls`
                                        : `View All Calls (${calls.length})`}
                                    </span>
                                  )}
                                </Text>
                                <Text size='xs' style={{ marginLeft: '10px' }}>
                                  &bull;{' '}
                                  {`Last called on ${formatToLocalTime(calls[0].called_at)} - ${calls[0].campaign_name}`}
                                </Text>
                                {calls.length > 1 &&
                                  rowsShowingAllCalls.includes(id) && (
                                    <>
                                      {calls.slice(1).map((call) => (
                                        <Text
                                          size='xs'
                                          key={call.called_at}
                                          style={{ marginLeft: '10px' }}
                                        >
                                          &bull;{' '}
                                          {`Called on ${formatToLocalTime(call.called_at)} - ${call.campaign_name}`}
                                        </Text>
                                      ))}
                                    </>
                                  )}
                              </div>
                            </td>
                          </tr>
                        )}
                      </Fragment>
                    );
                  })}
                </tbody>
              </Table>
            )}
          </div>
          {modalContentType !== 'alreadyUploadedInCampaign' && (
            <Tooltip
              label='Fix errors or exclude invalid contacts to continue'
              hidden={isSavedEnabled}
              openDelay={200}
              withArrow
              position='top'
            >
              <Button
                style={{ alignSelf: 'flex-end', marginTop: '10px' }}
                disabled={!isSavedEnabled}
                onClick={handleSaveFixedRows}
              >
                {reviewModalCheckedRows.size > 0
                  ? `Continue with (${reviewModalCheckedRows.size}/${reviewModalContent.length}) contacts`
                  : 'Continue without fixing'}
              </Button>
            </Tooltip>
          )}
        </Modal>
      </div>
    );
  };

  const renderManualForm = () => {
    return (
      <form onSubmit={form.onSubmit(handleManualSubmit)}>
        <TextInput
          required
          label='Name'
          placeholder='Full Name'
          {...form.getInputProps('name')}
        />
        <TextInput
          required={!isWebCall}
          label='Phone'
          placeholder='(123) 456-7890'
          {...form.getInputProps('phone')}
        />
        <TextInput
          required={isWebCall}
          label='Email'
          placeholder='email@example.com'
          {...form.getInputProps('email')}
        />
        <Select
          label='Timezone'
          placeholder='Select timezone'
          data={[
            { value: 'EST', label: 'Eastern Time' },
            { value: 'CST', label: 'Central Time' },
            { value: 'MST', label: 'Mountain Time' },
            { value: 'PST', label: 'Pacific Time' },
          ]}
          {...form.getInputProps('timezone')}
        />
        <TextInput
          label='Zipcode'
          placeholder='12345'
          {...form.getInputProps('zipcode')}
        />
        <Button type='submit' loading={loadingSubmit} mt='md' fullWidth>
          Add Contact
        </Button>
      </form>
    );
  };

  const renderModalContent = () => {
    switch (modalStep) {
      case 'initial':
        return renderAddContacts();
      case 'process':
        return renderPreviewTable();
      case 'preview':
        return renderPreviewTable();
      case 'success':
        return renderContactsSuccess();
      case 'sequence':
        return renderSequenceStarted();
      case 'manualForm':
        return renderManualForm();
      default:
        return renderAddContacts();
    }
  };

  let modalHeading = 'Add Contacts';
  switch (modalStep) {
    case 'process':
      modalHeading = 'Add Contacts (Review and Fix Invalid Contacts Data)';
      break;
    case 'preview':
      modalHeading = 'Final Review';
      break;
  }

  return (
    <Modal
      opened={campaignModalOpen}
      onClose={handleCancel}
      title={modalHeading}
      size='lg'
    >
      <Divider style={{ marginBottom: '10px' }} />
      {(modalStep === 'initial' ||
        modalStep === 'process' ||
        modalStep === 'preview') &&
        renderErrorMessages()}
      {renderModalContent()}
    </Modal>
  );
};

export default AddContactsModal;
