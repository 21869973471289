import { useEffect, useState } from 'react';
import env from 'env';
import axios from 'api/axiosConfig';
import { notifications } from '@mantine/notifications';

export const useGetTranscriptAccess = ({ candidateId, campaignId }) => {
  const [isFetchingTranscriptAccess, setIsFetchingTranscriptAccess] =
    useState(true);
  const [transcriptAccess, setTranscriptAccess] = useState({
    hasAccess: false,
    isSameOrg: false,
  });
  const orgId = localStorage.getItem('orgId');
  useEffect(() => {
    if (!candidateId || !campaignId) {
      return;
    }

    const fetchTranscriptAccess = async () => {
      setIsFetchingTranscriptAccess(true);

      try {
        const response = await axios.get(
          `${env.REACT_APP_SERVER_URL}/candidate/${candidateId}/access/${campaignId}`
        );

        const { access, orgId: campaignOrgId } = response.data;

        if (access === 'UNRESTRICTED' || campaignOrgId === orgId) {
          setTranscriptAccess({
            hasAccess: true,
            isSameOrg: campaignOrgId === orgId,
          });
        } else {
          console.error('no access according to response');
          setTranscriptAccess({ hasAccess: false, isSameOrg: false });
        }

        setIsFetchingTranscriptAccess(false);
      } catch (error) {
        setIsFetchingTranscriptAccess(false);
        console.error(error);
        notifications.show({
          title: 'There was an error retrieving transcript access',
          message: error instanceof Error ? error.message : '',
          color: 'red',
        });
      }
    };

    fetchTranscriptAccess();
  }, [campaignId, candidateId, orgId]);

  return {
    isFetchingTranscriptAccess,
    transcriptAccess,
  };
};
