import React from 'react';
import { Checkbox, Menu } from '@mantine/core';
import { convertUpperSnakeToTitle } from 'utils/formatUtils';

import { CallStatusFilterState } from './types';
import FilterPill from './FilterPill';

const CALL_STATUSES = [
  'PENDING',
  'COMPLETED',
  'FAILED',
  'VOICEMAIL',
  'NO_ANSWER',
  'INVALID_NUMBER',
  'NOT_INTERESTED',
  'CONNECTED',
];

interface ComponentProps {
  onClear: () => void;
  onClose: () => void;
  callStatusFilterState: CallStatusFilterState;
}

export default function CallStatusFilter({
  onClear,
  onClose,
  callStatusFilterState: { selectedStatuses, onChangeSelectedStatuses },
}: ComponentProps) {
  const filterLabel = selectedStatuses.length
    ? String(selectedStatuses.length)
    : undefined;

  const handleItemClick = (status: string) => () => {
    if (selectedStatuses.includes(status)) {
      onChangeSelectedStatuses(selectedStatuses.filter((s) => s !== status));
    } else {
      onChangeSelectedStatuses([...selectedStatuses, status]);
    }
  };

  const handleSelectAll = () => {
    if (selectedStatuses.length === 0) {
      onChangeSelectedStatuses(CALL_STATUSES);
    } else {
      onChangeSelectedStatuses([]);
    }
  };

  return (
    <FilterPill
      title='Call Status'
      label={filterLabel}
      onClear={onClear}
      onClose={onClose}
    >
      <Menu.Item
        leftSection={
          <Checkbox
            size='xs'
            checked={selectedStatuses.length > 0}
            indeterminate={
              selectedStatuses.length > 0 &&
              CALL_STATUSES.length !== selectedStatuses.length
            }
            readOnly
          />
        }
        onClick={handleSelectAll}
      >
        Select all
      </Menu.Item>
      <Menu.Divider />
      {CALL_STATUSES.map((status) => (
        <Menu.Item
          key={status}
          leftSection={
            <Checkbox
              size='xs'
              checked={selectedStatuses.includes(status)}
              readOnly
            />
          }
          onClick={handleItemClick(status)}
        >
          {convertUpperSnakeToTitle(status)}
        </Menu.Item>
      ))}
    </FilterPill>
  );
}
