// Convert UTC time into local time according to the browser
export const formatToLocalTime = (timeInput, detail = false) => {
  // Ensure the time string is treated as UTC by appending 'Z' if it's not already included.
  let dateObj;

  if (timeInput instanceof Date) {
    // Already a Date object; just use it directly
    dateObj = timeInput;
  } else {
    // If it's a string, keep your old logic of forcing UTC
    // (assuming that string is truly a UTC timestamp)
    const utcTimeWithZ = timeInput.endsWith('Z') ? timeInput : `${timeInput}Z`;
    dateObj = new Date(utcTimeWithZ);
  }

  // Get the current date to compare
  let today = new Date();
  let isToday = dateObj.toDateString() === today.toDateString();

  if (isToday) {
    return (
      'Today at ' +
      dateObj.toLocaleTimeString(undefined, {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
      })
    );
  } else {
    return (
      dateObj.toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }) +
      (detail ? ' at ' : ' ') +
      (detail
        ? dateObj.toLocaleTimeString(undefined, {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true,
          })
        : '')
    );
  }
};

export function formatToLocalTimeGmail(date) {
  if (!date) return '';

  const dateObj = typeof date === 'string' ? new Date(date) : date;

  if (isNaN(dateObj.getTime())) return '';

  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }).format(dateObj);
}

export const daysAgo = (dateUtc) => {
  // Create a date object from the UTC date string
  const date = new Date(dateUtc);
  // Get the current date
  const now = new Date();
  // Calculate the difference in milliseconds
  const diff = now.getTime() - date.getTime();
  // Convert the difference from milliseconds to days
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));

  if (days <= 0) {
    return 'Today';
  } else if (days === 1) {
    return 'Yesterday';
  } else {
    return `${days} days ago`;
  }
};

export const convertSecondsToMinutesAndSeconds = (totalSeconds) => {
  let minutes = Math.floor(totalSeconds / 60);
  let seconds = totalSeconds % 60;
  return minutes > 0 ? `${minutes}m ${seconds}s` : `${seconds}s`;
};

export const convertSecondsToHoursMinutes = (totalSeconds) => {
  let totalMinutes = Math.floor(totalSeconds / 60);
  let hours = Math.floor(totalSeconds / 60 / 60);
  let minutes = totalMinutes % 60;
  return hours > 0 ? `${hours}hr ${minutes}m` : `${minutes}m`;
};

export const isValidTimeZone = (tz) => {
  if (!Intl || !Intl.DateTimeFormat().resolvedOptions().timeZone) {
    throw new Error('Time zones are not available in this environment');
  }

  try {
    Intl.DateTimeFormat(undefined, { timeZone: tz });
    return true;
  } catch (ex) {
    return false;
  }
};

export const convertTimezoneToTimezoneAbbreviation = (timezone) => {
  const date = new Date();
  const options = {
    timeZone: timezone,
    timeZoneName: 'short',
  };
  const timeString = date.toLocaleTimeString('en-US', options);

  return timeString.split(' ').pop();
};
