import { Button, Modal, TextInput } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import React, { useEffect, useState } from 'react';
import env from 'env';
import axios from 'api/axiosConfig';

const RenameFolderModal = ({ open, onClose, folder, onRenameFolder }) => {
  const [folderName, setFolderName] = useState('');
  const [isRenamingFolder, setIsRenamingFolder] = useState(false);

  useEffect(() => {
    if (!folder) {
      return;
    }

    setFolderName(folder.folder_name);
  }, [folder]);

  const handleRenameFolder = (e, folderName: string) => {
    e.preventDefault();
    try {
      setIsRenamingFolder(true);
      axios.patch(
        `${env.REACT_APP_SERVER_URL}/campaign_folders/${folder.folder_id}/rename`,
        {
          folderName,
        }
      );
      onRenameFolder({ ...folder, folder_name: folderName });
      onClose();
      setFolderName('');
    } catch (error) {
      console.error('Error renaming folder:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to rename folder',
        color: 'red',
      });
    } finally {
      setIsRenamingFolder(false);
    }
  };

  const handleChangeFolderName = (event) => {
    setFolderName(event.currentTarget.value);
  };

  return (
    <Modal title='Rename Folder' opened={open} onClose={onClose} size='sm'>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <TextInput
          label='Folder Name'
          onChange={handleChangeFolderName}
          value={folderName}
        />
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}
        >
          <Button variant='outline' onClick={onClose}>
            Cancel
          </Button>
          <Button
            color='blue'
            onClick={(e) => handleRenameFolder(e, folderName)}
            disabled={!folderName}
            loading={isRenamingFolder}
          >
            Rename
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default RenameFolderModal;
