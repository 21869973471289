import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { Button, Stack, Text, Textarea, Title } from '@mantine/core';
import { usePermissions } from 'hooks/usePermissions';
import Template, {
  SequenceSettingsTemplate,
} from 'components/template/Template';
import AddBackgroundInfoTemplateToJobModal from 'components/template/AddBackgroundInfoTemplateToJobModal';
import { UseFormReturnType } from '@mantine/form';
import axios from 'api/axiosConfig';
import env from 'env';

import { ScriptInfo } from '../types';

interface BackgroundInfoStepProps {
  scriptInfo: UseFormReturnType<ScriptInfo, (values: ScriptInfo) => ScriptInfo>;
  orgBackgroundInfoTemplates: SequenceSettingsTemplate[];
  setOrgBackgroundInfoTemplates: Dispatch<
    SetStateAction<SequenceSettingsTemplate[]>
  >;
}

const BackgroundInfoStep = ({
  scriptInfo,
  orgBackgroundInfoTemplates,
  setOrgBackgroundInfoTemplates,
}: BackgroundInfoStepProps) => {
  const { canEditCampaign } = usePermissions();
  const [openAddTemplateModal, setOpenAddTemplateModal] = useState(false);

  const jobTemplatesIds = scriptInfo.getInputProps(
    'backgroundInfoTemplateIds'
  ).value;

  const handleRemoveTemplate = (id: string) => {
    const updatedTemplates = jobTemplatesIds.filter(
      (templateId: string) => templateId !== id
    );

    scriptInfo.setValues({ backgroundInfoTemplateIds: updatedTemplates });
  };

  const { jobsInTemplate } = useMemo(() => {
    const jobTemplatesIdsSet = new Set(jobTemplatesIds);

    const sortTemplates = (templates) =>
      templates.sort((a, b) => {
        if (a.is_default && !b.is_default) return -1;
        if (!a.is_default && b.is_default) return 1;
        return 0;
      });

    const jobsInTemplate = sortTemplates(
      orgBackgroundInfoTemplates.filter((template) =>
        jobTemplatesIdsSet.has(template.id)
      )
    );

    return { jobsInTemplate };
  }, [jobTemplatesIds, orgBackgroundInfoTemplates]);

  const handleEditTemplate = async (template: SequenceSettingsTemplate) => {
    try {
      await axios.patch(
        `${env.REACT_APP_SERVER_URL}/background_info_templates/${template.id}/edit`,
        {
          orgId: template.org_id,
          title: template.title,
          instructions: template.instructions,
        }
      );
      setOrgBackgroundInfoTemplates((templates: SequenceSettingsTemplate[]) => {
        return templates.map((t) => {
          if (t.id === template.id) {
            return template;
          }
          return t;
        });
      });
    } catch (error) {
      throw new Error(String(error));
    }
  };

  return (
    <>
      <Stack style={{ minHeight: '300px', height: '40vh' }}>
        <Title order={5}>Job-Specific Background Info and Instructions</Title>
        <Text fz='sm' c='dimmed'>
          Background knowledge the AI can use to provide context or answer
          questions about the job.
          <br />
          This is not directly part of the script.
        </Text>
        <Textarea
          className='fullHeightTextarea'
          placeholder={`-This candidate had applied to our ad on Indeed.
-The jobsite is at 123 1st St, San Francisco, CA 94105. It is just off highway 1 near the McDonald's.
-This is a 6 month contract with opportunity to convert to perm after that.`}
          {...scriptInfo.getInputProps('backgroundInfo')}
          classNames={{
            input: canEditCampaign ? '' : 'view-only',
          }}
        />
      </Stack>
      <Stack>
        <Title order={5}>Background Info Templates</Title>
        <Text size='sm' c='dimmed'>
          Add common background info templates for your organization.
        </Text>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            marginTop: '16px',
          }}
        >
          {jobsInTemplate.map((template: SequenceSettingsTemplate) => {
            return (
              <Template
                template={template}
                onEditTemplate={handleEditTemplate}
                handleRemoveTemplate={() => handleRemoveTemplate(template.id)}
                showActionButtons={canEditCampaign}
              />
            );
          })}
          {canEditCampaign && (
            <Button
              color='blue'
              onClick={() => setOpenAddTemplateModal(true)}
              style={{ alignSelf: 'center' }}
              variant='outline'
            >
              Add Template to Job
            </Button>
          )}
        </div>
        <AddBackgroundInfoTemplateToJobModal
          open={openAddTemplateModal}
          onClose={() => setOpenAddTemplateModal(false)}
          scriptInfo={scriptInfo}
          setOrgBackgroundInfoTemplates={setOrgBackgroundInfoTemplates}
          orgBackgroundInfoTemplates={orgBackgroundInfoTemplates}
        />
      </Stack>
    </>
  );
};

export default BackgroundInfoStep;
