import React from 'react';
import {
  Box,
  Center,
  Group,
  Loader,
  Paper,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import {
  IconMinus,
  IconTrendingDown,
  IconTrendingUp,
} from '@tabler/icons-react';

interface MetricTileProps {
  title: string;
  value: string | number | React.ReactNode;
  icon?: React.ReactNode;
  isLoading?: boolean;
  color?: string;
  trend?: {
    value: number;
    direction: 'up' | 'down' | 'neutral';
  };
}

export const MetricTile: React.FC<MetricTileProps> = ({
  title,
  value,
  icon,
  isLoading = false,
  color,
  trend,
}) => {
  const theme = useMantineTheme();

  function hexToRgb(hex: string) {
    const bigint = parseInt(hex.replace('#', ''), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return `${r}, ${g}, ${b}`;
  }

  return (
    <Paper
      withBorder
      p='md'
      radius='md'
      style={{ height: '160px', width: '100%' }}
      styles={(theme) => ({
        root: {
          '&:hover': {
            transform: 'translateY(-4px)',
            boxShadow: theme.shadows.md,
          },
          background: color
            ? `linear-gradient(45deg, ${theme.white} 0%, rgba(${hexToRgb(color)}, 0.1) 100%)`
            : undefined,
        },
      })}
    >
      {isLoading ? (
        <Center h='100%'>
          <Loader size='sm' />
        </Center>
      ) : (
        <Stack h='100%' justify='space-between' gap={5}>
          <Group justify='space-between' mb={5}>
            <Text size='sm' fw={500} color='dimmed'>
              {title}
            </Text>
            {icon && <Box>{icon}</Box>}
          </Group>

          <Box my='auto' ta='center'>
            <Text
              size='xl'
              fw={700}
              color={color || theme.primaryColor}
              style={{ fontSize: '28px' }}
            >
              {value}
            </Text>
          </Box>

          {trend && (
            <Group justify='center'>
              {trend.direction === 'up' ? (
                <IconTrendingUp
                  size={16}
                  stroke={1.5}
                  color={theme.colors.green[6]}
                />
              ) : trend.direction === 'down' ? (
                <IconTrendingDown
                  size={16}
                  stroke={1.5}
                  color={theme.colors.red[6]}
                />
              ) : (
                <IconMinus
                  size={16}
                  stroke={1.5}
                  color={theme.colors.gray[6]}
                />
              )}
              <Text
                size='xs'
                color={
                  trend.direction === 'up'
                    ? theme.colors.green[6]
                    : trend.direction === 'down'
                      ? theme.colors.red[6]
                      : theme.colors.gray[6]
                }
              >
                {trend.value}%{' '}
                {trend.direction === 'up'
                  ? 'increase'
                  : trend.direction === 'down'
                    ? 'decrease'
                    : 'change'}
              </Text>
            </Group>
          )}
        </Stack>
      )}
    </Paper>
  );
};
