import { ActionIcon, Badge, Indicator, Text, Tooltip } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import { campaignQuestionsDataColorMap } from 'pages/questions/AnalysisTab';
import { CampaignQuestion } from 'pages/questions/QuestionsPage';
import React from 'react';

interface CampaignQuestionCardProps {
  question: CampaignQuestion;
  handleUnansweredQuestions?: (questionIds: string[]) => void;
  showActionIcons?: boolean;
}

const CampaignQuestionCard = ({
  question,
  handleUnansweredQuestions,
  showActionIcons = true,
}: CampaignQuestionCardProps) => {
  return (
    <div
      key={question.question_id}
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '16px',
        border: '1px solid #e1e1e1',
        padding: '16px',
        borderRadius: '8px',
        width: '100%',
      }}
    >
      {!question.is_answered && <Indicator color='red' />}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <Text size='sm' fw={600}>
              {question.question}
            </Text>
            {question.category !== 'Other' && (
              <Badge
                color={campaignQuestionsDataColorMap[question.category]}
                size='xs'
              >
                {question.category}
              </Badge>
            )}
          </div>
          {showActionIcons && handleUnansweredQuestions && (
            <Tooltip label='Dismiss' position='top' withArrow>
              <ActionIcon
                size='xs'
                color='gray'
                variant='light'
                onClick={() =>
                  handleUnansweredQuestions([question.question_id])
                }
              >
                <IconX size={16} />
              </ActionIcon>
            </Tooltip>
          )}
        </div>
        <Text size='xs'>{question.answer}</Text>
      </div>
    </div>
  );
};

export default CampaignQuestionCard;
