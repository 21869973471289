import React from 'react';
import { Button, Modal, Text } from '@mantine/core';

interface DeleteTemplateConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  onConfirmDelete: () => void;
  isDeleting: boolean;
}

const DeleteTemplateConfirmationModal = ({
  open,
  onClose,
  onConfirmDelete,
  isDeleting,
}: DeleteTemplateConfirmationModalProps) => {
  return (
    <Modal
      title='Delete Template'
      opened={open}
      onClose={onClose}
      size='md'
      styles={{ title: { color: 'black' } }}
    >
      <Text
        size='sm'
        fw='600'
        style={{ marginBottom: '16px', textAlign: 'center' }}
      >
        Are you sure you want to delete this template? Deleting this template
        will remove it from all jobs using it
      </Text>
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
        <Button variant='outline' onClick={onClose}>
          Cancel
        </Button>
        <Button color='red' onClick={onConfirmDelete} loading={isDeleting}>
          Delete
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteTemplateConfirmationModal;
