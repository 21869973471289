import React, { useState } from 'react';
import { Button, Modal, Textarea, TextInput, Tooltip } from '@mantine/core';
import { notifications } from '@mantine/notifications';

import { ORG_BACKGROUND_INFO_PLACEHOLDER } from './constants';
import { SequenceSettingsTemplate } from './Template';

interface EditTemplateModalProps {
  open: boolean;
  onClose: () => void;
  template: SequenceSettingsTemplate;
  onEditTemplate: (template: SequenceSettingsTemplate) => Promise<void>;
  isDefault: boolean;
}

const EditTemplateModal = ({
  open,
  onClose,
  template,
  onEditTemplate,
  isDefault,
}: EditTemplateModalProps) => {
  const [templateData, setTemplateData] =
    useState<SequenceSettingsTemplate>(template);
  const [isEditing, setIsEditing] = useState(false);

  const handleChangeField = (e) => {
    setTemplateData({
      ...templateData,
      [e.target.name]: e.target.value,
    });
  };

  const handleEditTemplate = async () => {
    try {
      setIsEditing(true);
      await onEditTemplate(templateData);
      onClose();
      notifications.show({
        title: 'Success',
        message: 'Template edited successfully',
        color: 'green',
      });
    } catch (error) {
      console.error('Error editing template:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to edit template',
        color: 'red',
      });
    } finally {
      setIsEditing(false);
    }
  };

  return (
    <Modal title='Edit Template' opened={open} onClose={onClose} size='lg'>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        }}
      >
        <TextInput
          label='Title'
          name='title'
          value={templateData.title}
          onChange={handleChangeField}
          disabled={isDefault}
        />
        <Textarea
          autosize
          label='Instructions'
          name='instructions'
          value={templateData.instructions}
          onChange={handleChangeField}
          minRows={5}
          placeholder={ORG_BACKGROUND_INFO_PLACEHOLDER}
        />
        <div
          style={{
            display: 'flex',
            alignSelf: 'flex-end',
            gap: '10px',
          }}
        >
          <Button color='gray' size='sm' onClick={onClose}>
            Cancel
          </Button>
          <Tooltip
            label='Fill in all fields to save'
            position='top'
            withArrow
            hidden={!!templateData.title && !!templateData.instructions}
          >
            <Button
              color='blue'
              size='sm'
              onClick={handleEditTemplate}
              loading={isEditing}
              disabled={!templateData.title || !templateData.instructions}
            >
              Save
            </Button>
          </Tooltip>
        </div>
      </div>
    </Modal>
  );
};

export default EditTemplateModal;
