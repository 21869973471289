import React, { useCallback, useState, useEffect } from 'react';
import './Transcript.css';
import { useDisclosure } from '@mantine/hooks';
import ReactJsonView from '@microlink/react-json-view';
import { Drawer, Button } from '@mantine/core';
import axios from 'api/axiosConfig';
import env from 'env';
import { formatToLocalTime } from 'utils/dateUtils';
import { useStytchMember } from '@stytch/react/b2b';

const SupportWindow = ({ allCalls, candidateId, campaignId }) => {
  const [opened, { open, close }] = useDisclosure(false);
  const [callsWithDetails, setCallsWithDetails] = useState(allCalls);

  const fetchCallDetails = useCallback(async () => {
    try {
      const response = await axios.get(
        `${env.REACT_APP_SERVER_URL}/candidate/${candidateId}/campaign/${campaignId}/call_details`
      );
      const allCallsWithDetailsAdded = allCalls.map((call) => {
        const detailCall = response.data.calls.find(
          (detailCall) => detailCall.id === call.call_id
        );
        if (detailCall) {
          call.call_details = detailCall;
        }
        return call;
      });
      setCallsWithDetails((prevCallsWithDetails) => [
        ...prevCallsWithDetails,
        ...allCallsWithDetailsAdded,
      ]);
    } catch (error) {
      alert('Error fetching call details');
      console.error('Error fetching comments:', error);
    }
  }, [candidateId, campaignId, allCalls]);

  const { member } = useStytchMember();

  const isSupport = member?.email_address === 'support@salv.ai';

  useEffect(() => {
    if (opened) {
      fetchCallDetails();
    }
  }, [opened, fetchCallDetails]);

  const renderSupportWindow = () => (
    <div
      style={{
        fontSize: '10px',
        lineHeight: '.8rem',
      }}
    >
      {callsWithDetails
        .sort(
          (a, b) =>
            new Date(b.created).getTime() - new Date(a.created).getTime()
        )
        .map((c) => {
          return (
            <div>
              <div>{formatToLocalTime(c.created)}</div>
              <video
                controls
                style={{
                  borderRadius: '16px',
                  width: '50%',
                  position: 'relative',
                  height: '60px',
                  marginBottom: '6px',
                }}
              >
                <source src={c.recording_url} type='video/mp4' />
                Your browser does not support the video tag.
              </video>
              <ReactJsonView src={c} collapsed={1} displayDataTypes={false} />
              <br />
              <br />
            </div>
          );
        })}
    </div>
  );

  if (!isSupport) {
    return <></>;
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Drawer
        offset={8}
        radius='md'
        opened={opened}
        size='85%'
        onClose={close}
        title='All Calls'
        position='bottom'
      >
        {renderSupportWindow()}
      </Drawer>
      <div
        style={{
          zIndex: 100000000,
          position: 'absolute',
          bottom: 0,
          right: '40px',
        }}
      >
        <Button variant='default' onClick={open} color='black'>
          All Calls
        </Button>
      </div>
    </div>
  );
};

export default SupportWindow;
