import React, { useState, useEffect } from 'react';
import { Group, Button, Tooltip, Menu } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { IconLogin2, IconMenu2 } from '@tabler/icons-react';
import { useIsMobile } from 'pages/web-call/components/useIsMobile';

import classes from './SideNavBar.module.css';

const COLLAPSE_THRESHOLD = 1400; // Note: threshold here AND css file

export default function NoLoginSideNavBar() {
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const [isCollapsed, setIsCollapsed] = useState(
    window.innerWidth <= COLLAPSE_THRESHOLD
  );

  useEffect(() => {
    const handleResize = () => {
      setIsCollapsed(window.innerWidth <= COLLAPSE_THRESHOLD);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial state based on window width

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleGoToLogin = () => {
    navigate('/login');
  };

  return (
    <div style={{ display: 'flex' }}>
      {isMobile ? (
        <Menu
          shadow='md'
          width={200}
          zIndex={99999}
          position='bottom-start'
          offset={0}
        >
          <Menu.Target>
            <IconMenu2 style={{ marginTop: '10px', marginLeft: '10px' }} />
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Label>Account</Menu.Label>
            <Menu.Item leftSection={<IconLogin2 />} onClick={handleGoToLogin}>
              Login
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      ) : (
        <nav className={classes.navbar}>
          <div className={classes.navbarMain}>
            <Group
              className={classes.header}
              justify='space-between'
              style={{ textAlign: 'center' }}
            >
              <div className={classes.sideNavBarHeader}>
                <img
                  className={classes.full_logo} // Use CSS module class here
                  src='/imgs/salv_full_logo_further.png'
                  alt='Salv Full Logo'
                />
                <img
                  className={classes.small_logo} // Use CSS module class here
                  src='/imgs/salv_logo_just_squares.png'
                  alt='Salv Logo Just Squares'
                />
              </div>
            </Group>
          </div>

          <div className={classes.footer}>
            <Tooltip label='Log In' position='top' withArrow>
              {isCollapsed ? (
                <Button
                  style={{
                    flex: 1,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  variant='outline'
                  onClick={handleGoToLogin}
                >
                  <IconLogin2 />
                </Button>
              ) : (
                <Button
                  style={{
                    flex: 1,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  variant='outline'
                  leftSection={<IconLogin2 />}
                  onClick={handleGoToLogin}
                >
                  Log In
                </Button>
              )}
            </Tooltip>
          </div>
        </nav>
      )}
    </div>
  );
}
