import React, { useState } from 'react';
import { Modal, TextInput, Button, Box, Stack, Text } from '@mantine/core';

interface Errors {
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
}

const CandidateFormModal = ({ isOpen, onClose, onSubmit }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState<Errors>({});

  const validate = () => {
    const newErrors: Errors = {};
    if (!firstName) newErrors.firstName = 'First name is required';
    if (!lastName) newErrors.lastName = 'Last name is required';
    if (!phone) newErrors.phone = 'Phone number is required';
    if (!email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = 'Email is invalid';
    }
    return newErrors;
  };

  const handleSubmit = () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      onSubmit({ firstName, lastName, phone, email });
      onClose();
    }
  };

  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      title='Candidate Details'
      withCloseButton={false}
      closeOnClickOutside={false}
    >
      <Box p='md'>
        <Stack>
          <TextInput
            label='First Name'
            value={firstName}
            onChange={(event) => setFirstName(event.currentTarget.value)}
            error={errors.firstName}
          />
          <TextInput
            label='Last Name'
            value={lastName}
            onChange={(event) => setLastName(event.currentTarget.value)}
            error={errors.lastName}
          />
          <TextInput
            label='Phone Number'
            value={phone}
            onChange={(event) => setPhone(event.currentTarget.value)}
            error={errors.phone}
          />
          <TextInput
            label='Email'
            value={email}
            onChange={(event) => setEmail(event.currentTarget.value)}
            error={errors.email}
          />
          {Object.keys(errors).length > 0 && (
            <Text color='red'>Please fix the errors above</Text>
          )}
          <Button onClick={handleSubmit}>Submit</Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default CandidateFormModal;
