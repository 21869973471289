import { useState, useCallback } from 'react';
import { UnreadStatusFilterState } from 'pages/job-posting-tips/components/types';

const ALL_UNREAD_STATUSES = ['UNREAD_CALLS', 'UNREAD_SMS', 'UNREAD_EMAIL'];

export default function useUnreadStatusFilterState(): UnreadStatusFilterState {
  const [selectedStatuses, setSelectedStatuses] =
    useState<string[]>(ALL_UNREAD_STATUSES);

  const onChangeSelectedStatuses = useCallback((newStatuses: string[]) => {
    setSelectedStatuses(newStatuses);
  }, []);

  const clear = useCallback(() => {
    setSelectedStatuses([]);
  }, []);

  return {
    selectedStatuses,
    onChangeSelectedStatuses,
    clear,
    isEmpty: selectedStatuses.length === 0,
  };
}
