import React, { useState, useRef, useEffect } from 'react';
import { Text } from '@mantine/core';
import './TruncatedText.css';
interface TruncatedTextProps {
  text?: string;
  maxLines?: number;
}

export const TruncatedText = ({
  text = '',
  maxLines = 2,
}: TruncatedTextProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (textRef.current) {
      const element = textRef.current;
      setIsOverflowing(element.scrollHeight > element.clientHeight);
    }
  }, [text]);

  const handleExpandText = (e) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <Text
        ref={textRef}
        size='sm'
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: isExpanded ? 'unset' : maxLines,
          WebkitBoxOrient: 'vertical',
          maxHeight: isExpanded ? 'none' : `${maxLines * 1.5}em`,
        }}
      >
        {text}
      </Text>
      {isOverflowing && (
        <Text
          size='xs'
          c='blue'
          style={{ cursor: 'pointer', marginTop: 4 }}
          onClick={handleExpandText}
          className='see-more-button'
        >
          {isExpanded ? 'Hide' : 'See More'}
        </Text>
      )}
    </div>
  );
};
