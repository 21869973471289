import React, { useEffect, useReducer } from 'react';
import { Button, Tooltip, ButtonProps } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconRefresh } from '@tabler/icons-react';
import axios from 'api/axiosConfig';
import env from 'env';

interface AtsSyncButtonProps
  extends Omit<ButtonProps, 'loading' | 'disabled' | 'onClick'> {
  // We omit these props because they're controlled by the component
}

type SyncState = {
  isSyncing: boolean;
  isLoading: boolean;
};

type SyncAction =
  | { type: 'START_SYNC_CLICKED' }
  | { type: 'START_SYNC' }
  | { type: 'SYNC_FAILED' }
  | { type: 'SET_SYNC_STATUS'; isSyncing: boolean; showNotification: boolean }
  | { type: 'FINISH_LOADING' };

const syncReducer = (state: SyncState, action: SyncAction): SyncState => {
  switch (action.type) {
    case 'START_SYNC_CLICKED':
      return { ...state, isLoading: true };
    case 'START_SYNC':
      return { ...state, isSyncing: true };
    case 'SYNC_FAILED':
      return { ...state, isSyncing: false };
    case 'SET_SYNC_STATUS':
      if (
        state.isSyncing &&
        !action.isSyncing &&
        !state.isLoading &&
        action.showNotification
      ) {
        notifications.show({
          title: 'Sync Complete',
          message: 'ATS data has been successfully synchronized',
          color: 'green',
        });
      }
      return { ...state, isSyncing: action.isSyncing };
    case 'FINISH_LOADING':
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

const ATSSyncButton: React.FC<AtsSyncButtonProps> = (props) => {
  const [state, dispatch] = useReducer(syncReducer, {
    isSyncing: false,
    isLoading: true,
  });
  const orgId = localStorage.getItem('orgId');
  const atsIntegrations = localStorage.getItem('atsIntegrations');

  const { isSyncing, isLoading } = state;

  const startSync = async () => {
    dispatch({ type: 'START_SYNC_CLICKED' });
    try {
      await axios.post(`${env.REACT_APP_SERVER_URL}/kombo/force_sync`, {
        orgId: orgId,
      });
      dispatch({ type: 'START_SYNC' });
    } catch (error) {
      console.error('Error starting sync:', error);
      notifications.show({
        title: 'Sync Failed',
        message: 'Failed to start ATS synchronization',
        color: 'red',
      });
      dispatch({ type: 'SYNC_FAILED' });
    }
  };

  useEffect(() => {
    const checkSyncStatus = async () => {
      try {
        const response = await axios.post(
          `${env.REACT_APP_SERVER_URL}/kombo/get_sync_status`,
          {
            orgId: orgId,
          }
        );

        const isStillSyncing = response.data.message === 'Sync in progress';
        dispatch({
          type: 'SET_SYNC_STATUS',
          isSyncing: isStillSyncing,
          showNotification: isSyncing,
        });
      } catch (error) {
        console.error('Error checking sync status:', error);
      } finally {
        dispatch({ type: 'FINISH_LOADING' });
      }
    };

    // Check sync status when component mounts
    // Will be called one more time when isSyncing is false after a poll completes
    checkSyncStatus();

    // Set up polling if syncing
    let interval: NodeJS.Timeout | null = null;

    if (isSyncing) {
      interval = setInterval(checkSyncStatus, 5000); // Poll every 5 seconds
    }

    // Clean up interval on unmount or when sync completes
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [orgId, isSyncing]);

  if (
    !atsIntegrations ||
    atsIntegrations.length === 0 ||
    atsIntegrations === '[]'
  ) {
    return <></>;
  }

  return (
    <Tooltip
      label={isSyncing ? 'Synchronizing ATS data...' : ''}
      position='top'
      disabled={!isSyncing}
      withArrow
    >
      <Button
        leftSection={<IconRefresh size={16} />}
        onClick={startSync}
        loading={isSyncing || isLoading}
        loaderProps={isLoading && !isSyncing ? { size: 0 } : undefined}
        disabled={isSyncing || isLoading}
        color='blue'
        variant='outline'
        style={{
          opacity: isLoading ? 0.2 : 1,
        }}
        {...props}
      >
        {isSyncing ? 'Syncing...' : 'Sync ATS'}
      </Button>
    </Tooltip>
  );
};

export default ATSSyncButton;
