import React, { createContext, useState } from 'react';
import { produce } from 'immer';
import useCallStatusFilterState from 'pages/campaign/details/useCallStatusFilterState';
import useCallLengthFilterState from 'pages/campaign/details/useCallLengthFilterState';
import useScoreContactFilterState from 'pages/campaign/details/useScoreContactFilterState';
import useCompletionFilterState from 'pages/campaign/details/useCompletionFilterState';
import useSMSStatusFilterState from 'pages/campaign/details/useSMSStatusFilterState';
import useFeedbackFilterState from 'pages/campaign/details/useFeedbackFilterState';
import useNumberOfCallsFilterState from 'pages/campaign/details/useNumberOfCallsFilterState';
import useUnreadStatusFilterState from 'pages/campaign/details/useUnreadStatusFilterState';

import { FilterType } from './types';
import useLocationFilterState from './useLocationFilterState';
import useAgencyFilterState from './useAgencyFilterState';
import useScoreFilterState from './useScoreFilterState';
import useDateFilterState from './useDateFilterState';
import useClientFilterState from './useClientFilterState';
import useRevenueFilterState from './useRevenueFilterState';

interface FilterState {
  activeFilters: {
    type: FilterType;
    state: any;
    label: string;
  }[];
  addFilter: (filter: FilterType) => void;
  removeFilter: (filterType: FilterType) => void;
  clearAll: () => void;
}

export const FilterContext = createContext<FilterState | undefined>(undefined);

interface FilterContextProviderProps {
  children: React.ReactNode;
}

export function FilterContextProvider({
  children,
}: FilterContextProviderProps) {
  const locationFilterState = useLocationFilterState();
  const clientFilterState = useClientFilterState();
  const agencyFilterState = useAgencyFilterState();
  const revenueFilterState = useRevenueFilterState();
  const scoreFilterState = useScoreFilterState();
  const postedDateFilterState = useDateFilterState();
  const lastSeenDateFilterState = useDateFilterState();
  const callStatusFilterState = useCallStatusFilterState();
  const callLengthFilterState = useCallLengthFilterState();
  const scoreContactFilterState = useScoreContactFilterState();
  const completionFilterState = useCompletionFilterState();
  const lastCalledFilterState = useDateFilterState();
  const smsStatusFilterState = useSMSStatusFilterState();
  const feedbackFilterState = useFeedbackFilterState();
  const numberOfCallsFilterState = useNumberOfCallsFilterState();
  const unreadStatusFilterState = useUnreadStatusFilterState();

  const [activeFilterTypes, setActiveFilterTypes] = useState<FilterType[]>([]);

  const getFilterStateForType = (filterType: FilterType) => {
    switch (filterType) {
      case 'location':
        return locationFilterState;
      case 'client':
        return clientFilterState;
      case 'revenue':
        return revenueFilterState;
      case 'agency':
        return agencyFilterState;
      case 'score':
        return scoreFilterState;
      case 'posted':
        return postedDateFilterState;
      case 'seen':
        return lastSeenDateFilterState;
      case 'callStatus':
        return callStatusFilterState;
      case 'callLength':
        return callLengthFilterState;
      case 'scoreContact':
        return scoreContactFilterState;
      case 'completion':
        return completionFilterState;
      case 'lastCalled':
        return lastCalledFilterState;
      case 'smsStatus':
        return smsStatusFilterState;
      case 'feedback':
        return feedbackFilterState;
      case 'numberOfCalls':
        return numberOfCallsFilterState;
      case 'unreadStatus':
        return unreadStatusFilterState;
    }
  };

  const getFilterLabelForType = (filterType: FilterType) => {
    const FILTER_LABELS = {
      location: 'Location',
      client: 'Client',
      revenue: 'Revenue',
      agency: 'Agency',
      score: 'Score',
      posted: 'Date Posted',
      seen: 'Last Seen',
      callStatus: 'Call Status',
      callLength: 'Call Length',
      scoreContact: 'Score',
      completion: 'Completion',
      lastCalled: 'Last Called',
      smsStatus: 'SMS Status',
      feedback: 'Feedback',
      numberOfCalls: 'Number of Calls',
      unreadStatus: 'Unread Status',
    };

    return FILTER_LABELS[filterType] || filterType;
  };

  const activeFilters = activeFilterTypes.map((filterType) => ({
    type: filterType,
    state: getFilterStateForType(filterType),
    label: getFilterLabelForType(filterType),
  }));

  const addFilter = (type: FilterType) => {
    setActiveFilterTypes(
      produce(activeFilterTypes, (draft) => {
        draft.push(type);
      })
    );
  };

  const removeFilter = (filterType: FilterType) => {
    setActiveFilterTypes(
      activeFilterTypes.filter(
        (activeFilterType) => activeFilterType !== filterType
      )
    );
    getFilterStateForType(filterType)?.clear();
  };

  const clearAll = () => {
    activeFilterTypes.forEach((filterType) => {
      getFilterStateForType(filterType)?.clear();
    });
    setActiveFilterTypes([]);
  };

  return (
    <FilterContext.Provider
      value={{ activeFilters, addFilter, removeFilter, clearAll }}
    >
      {children}
    </FilterContext.Provider>
  );
}

export const withFilterContextProvider =
  <T extends {}>(Component: React.ComponentType<T>) =>
  (props: T) => {
    return (
      <FilterContextProvider>
        <Component {...props} />
      </FilterContextProvider>
    );
  };
