import React, { Fragment, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useGetTranscriptData } from 'hooks/useGetTranscriptData';
import {
  ActionIcon,
  Center,
  CloseButton,
  Loader,
  Title,
  Tooltip,
} from '@mantine/core';
import {
  IconChevronsRight,
  IconClipboard,
  IconNotification,
  IconShare3,
} from '@tabler/icons-react';
import { useCandidateCampaignReview } from 'hooks/useCandidateCampaignReview';
import { useAtom } from 'jotai';
import { campaignInfoAtomForCandidate } from 'pages/editorv2/atoms';

import Transcript, { Tab, TabOptions } from './Transcript';
import SupportWindow from './SupportWindow';
import ShareTranscriptModal from './ShareTranscriptModal';

interface TranscriptWrapperProps {
  showNavigationShortcut: boolean;
  isFetchingCalls: boolean;
  onClose: () => void;
  isDrawer?: boolean;
}

const TranscriptWrapper = ({
  showNavigationShortcut,
  isFetchingCalls,
  onClose,
  isDrawer,
}: TranscriptWrapperProps) => {
  const [openShareModal, setOpenShareModal] = useState(false);
  const [activeTab, setActiveTab] = useState<Tab>(TabOptions.CALL);
  const { campaignId } = useParams();

  const [searchParams] = useSearchParams();
  const candidateId = searchParams.get('candidateId') || '';

  const candidateCampaignInfoKey = candidateId + '_' + campaignId;
  const [candidateInfo] = useAtom(
    campaignInfoAtomForCandidate(candidateCampaignInfoKey)
  );

  const showMarkAsUnreviewedButton =
    activeTab === TabOptions.CALL ||
    activeTab === TabOptions.SMS ||
    activeTab === TabOptions.EMAIL;

  const enableMarkAsUnreviewedButton = () => {
    switch (activeTab) {
      case TabOptions.CALL:
        return candidateInfo?.isCallReviewed;
      case TabOptions.SMS:
        return candidateInfo?.isSMSReviewed;
      case TabOptions.EMAIL:
        return candidateInfo?.isEmailReviewed;
      default:
        return false;
    }
  };

  const markAllAsReviewedTooltipText = () => {
    switch (activeTab) {
      case TabOptions.CALL:
        return 'Mark call as unreviewed';
      case TabOptions.SMS:
        return 'Mark sms as unreviewed';
      case TabOptions.EMAIL:
        return 'Mark email as unreviewed';
      default:
        return '';
    }
  };

  const { transcriptData, transcriptError, isFetchingTranscript } =
    useGetTranscriptData({
      candidateId,
      campaignId,
      isFetchingCalls,
    });

  const { handleUnreviewChanges } = useCandidateCampaignReview();

  const handleMarkAsUnreviewed = (e) => {
    e.stopPropagation();
    const changesReviewed = {
      ...(activeTab === TabOptions.CALL ? { isCallReviewed: false } : {}),
      ...(activeTab === TabOptions.SMS ? { isSMSReviewed: false } : {}),
      ...(activeTab === TabOptions.EMAIL ? { isEmailReviewed: false } : {}),
    };
    handleUnreviewChanges({
      changesReviewed,
      campaignId: campaignId || '',
      candidateId: candidateId || '',
    });
  };

  // Hack to show loading spinner when fetching transcript data
  const showLoading =
    isFetchingTranscript || !transcriptData.candidateName || isFetchingCalls;

  const handleShareTranscriptModal = () => {
    setOpenShareModal(!openShareModal);
  };

  const showSunmittalReportIcon =
    transcriptData.requirementGradesList?.length > 0;

  const openSubmittalReport = () => {
    // Open submittal report in new page
    window.open(
      `/campaigns/${campaignId}/candidates/${candidateId}/calls/${transcriptData.callId}/submittal-report`,
      '_blank'
    );
  };

  return (
    <Fragment>
      {isDrawer && (
        <div
          style={{
            position: 'absolute',
            top: '6px',
            left: '5px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            paddingLeft: '10px',
            paddingRight: '22px',
          }}
        >
          <CloseButton
            icon={<IconChevronsRight size={22} stroke={1.5} />}
            onClick={onClose}
          />
          {!isFetchingTranscript && (
            <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
              <Tooltip
                label='Show Submittal Report'
                position='bottom'
                style={{ opacity: 0.8 }}
              >
                <ActionIcon
                  size='sm'
                  style={{ width: 'fit-content' }}
                  variant='subtle'
                  disabled={!showSunmittalReportIcon}
                  onClick={openSubmittalReport}
                >
                  <IconClipboard size={20} color='var(--salv-dark-5)' />
                </ActionIcon>
              </Tooltip>

              {showMarkAsUnreviewedButton && (
                <Tooltip
                  label={markAllAsReviewedTooltipText()}
                  position='bottom'
                  style={{ opacity: 0.8 }}
                >
                  <ActionIcon
                    size='sm'
                    style={{ width: 'fit-content' }}
                    variant='subtle'
                    onClick={handleMarkAsUnreviewed}
                    disabled={!enableMarkAsUnreviewedButton()}
                  >
                    <IconNotification size={20} color='var(--salv-dark-5)' />
                  </ActionIcon>
                </Tooltip>
              )}
              <Tooltip label='Share' position='bottom' style={{ opacity: 0.8 }}>
                <ActionIcon
                  size='sm'
                  style={{ width: 'fit-content' }}
                  variant='subtle'
                  onClick={handleShareTranscriptModal}
                >
                  <IconShare3 size={20} color='var(--salv-dark-5)' />
                </ActionIcon>
              </Tooltip>
            </div>
          )}
        </div>
      )}
      {transcriptError ? (
        <div
          style={{
            padding: '100px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Title c={'black'}>Failed to retrieve call details</Title>
        </div>
      ) : showLoading ? (
        <Center p='lg'>
          <Loader size='sm' type='dots' />
        </Center>
      ) : (
        <Transcript
          transcript={transcriptData.transcript}
          requirementGradesList={transcriptData.requirementGradesList}
          candidateQuestions={transcriptData.candidateQuestions}
          callId={transcriptData.callId}
          overallGrade={transcriptData.overallGrade}
          completionRate={transcriptData.questionCompletionRate}
          candidateName={transcriptData.candidateName}
          lastCalled={transcriptData.lastCalled}
          allCalls={transcriptData.allCalls}
          callComplete={transcriptData.callComplete}
          webCall={transcriptData.webCall}
          candidateId={candidateId}
          campaignId={campaignId}
          showNavigationShortcut={showNavigationShortcut}
          candidateEmail={transcriptData.candidateEmail}
          candidatePhone={transcriptData.candidatePhone}
          needsTranslation={transcriptData.needsTranslation}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          summary={transcriptData.summary}
          recordingURL={transcriptData.recordingURL}
        />
      )}
      <SupportWindow
        allCalls={transcriptData.allCalls}
        campaignId={campaignId}
        candidateId={candidateId}
      />
      <ShareTranscriptModal
        isOpen={openShareModal}
        onClose={handleShareTranscriptModal}
        candidateName={transcriptData.candidateName}
        candidateId={candidateId}
        campaignId={campaignId || ''}
      />
    </Fragment>
  );
};

export default TranscriptWrapper;
