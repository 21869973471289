import React from 'react';
import { Alert, Button, Modal } from '@mantine/core';
import { IconAlertTriangle } from '@tabler/icons-react';

interface EditTemplateConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  onConfirmEdit: () => void;
}

const EditTemplateConfirmationModal = ({
  open,
  onClose,
  onConfirmEdit,
}: EditTemplateConfirmationModalProps) => {
  return (
    <Modal
      title='Company Template Edit Warning Confirmation'
      opened={open}
      onClose={onClose}
      size='lg'
    >
      <Alert
        color='yellow'
        icon={<IconAlertTriangle size={16} />}
        style={{
          padding: '16px',
          backgroundColor: 'var(--salv-warning-yellow)',
        }}
      >
        <div style={{ fontSize: '16px' }}>
          <b>Warning</b>: Changes to this template will affect all jobs using
          it.
        </div>
      </Alert>
      <div style={{ margin: '16px 0px' }}>
        Modifications to a background information template will be applied to{' '}
        <b>all jobs, scripts, and campaign sequences</b> using that template in
        your organization.
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
        <Button variant='outline' onClick={onClose}>
          Cancel
        </Button>
        <Button
          color='var(--salv-warning-yellow)'
          style={{ color: 'black' }}
          onClick={onConfirmEdit}
        >
          <IconAlertTriangle size={16} style={{ marginRight: '8px' }} />
          Acknowledge and Edit
        </Button>
      </div>
    </Modal>
  );
};

export default EditTemplateConfirmationModal;
