import React, { useEffect, useState, useCallback } from 'react';
import {
  IconTrash,
  IconHelp,
  IconDotsVertical,
  IconWand,
} from '@tabler/icons-react';
import {
  ActionIcon,
  Group,
  Input,
  Table,
  Tooltip,
  Modal,
  Switch,
  Button,
  Text,
  Box,
  Textarea,
} from '@mantine/core';
import { useListState } from '@mantine/hooks';
import axios from 'axios';
import env from 'env';

import {
  RequirementImportance,
  CandidateRequirement,
  QuestionType,
} from '../../pages/editorv2/types';
import ImportanceBadgeSelector from '../../pages/editorv2/components/ImportanceBadgeSelector';
import QuestionTypeBadgeSelector from '../../pages/editorv2/components/QuestionTypeBadgeSelector';
import { EditableField } from './EditableField';

import './QuestionTable.css';

export interface QuestionBankItem extends CandidateRequirement {
  bankTitle: string;
}

interface QuestionTableProps {
  questions: QuestionBankItem[];
  onQuestionsChange: (questions: QuestionBankItem[]) => void;
  showBankTitle?: boolean;
  isEditable?: boolean;
  unsavedChanges?: boolean;
}

/**
 * Helper to deep-compare two arrays of questions
 * (Adjust to your own needs: e.g. a shallow compare might suffice).
 */
function areQuestionsEqual(
  a: QuestionBankItem[],
  b: QuestionBankItem[]
): boolean {
  return JSON.stringify(a) === JSON.stringify(b);
}

export const QuestionTable = ({
  questions,
  onQuestionsChange,
  showBankTitle = false,
  isEditable = true,
  unsavedChanges = false,
}: QuestionTableProps) => {
  const [openEditIndex, setOpenEditIndex] = useState(-1);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isGenerateModalOpen, setIsGenerateModalOpen] = useState(false);
  const [additionalInstructions, setAdditionalInstructions] = useState('');

  // Local state to manage questions in this table
  const [questionsState, handlers] = useListState<QuestionBankItem>(questions);

  /**
   * Whenever parent's `questions` prop changes, update local state if needed.
   */
  useEffect(() => {
    if (!areQuestionsEqual(questions, questionsState)) {
      handlers.setState(questions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questions, questionsState, handlers]);

  /**
   * Update local state and also notify parent that the questions have changed.
   */
  const updateQuestionsAndNotifyParent = useCallback(
    (updated: QuestionBankItem[]) => {
      handlers.setState(updated);
      onQuestionsChange(updated);
    },
    [handlers, onQuestionsChange]
  );

  // DELETE HANDLER
  const handleDeleteQuestion = useCallback(
    (index: number) => {
      const newArray = [...questionsState];
      newArray.splice(index, 1);
      updateQuestionsAndNotifyParent(newArray);
    },
    [questionsState, updateQuestionsAndNotifyParent]
  );

  // FIELD CHANGE
  const handleFieldChange = useCallback(
    (index: number, field: keyof QuestionBankItem) => (value: any) => {
      const newArray = [...questionsState];
      (newArray[index] as any)[field] = value;
      updateQuestionsAndNotifyParent(newArray);
    },
    [questionsState, updateQuestionsAndNotifyParent]
  );

  // GENERATE LLM CRITERIA
  const handleGenerateGradingCriteria = (index: number) => {
    setCurrentQuestionIndex(index);
    setIsGenerateModalOpen(true);
  };

  const handleGenerateSubmit = async () => {
    if (currentQuestionIndex < 0) return;
    try {
      setIsGenerating(true);
      const response = await axios.post(
        `${env.REACT_APP_SERVER_URL}/generate-grading-criteria`,
        {
          question: questionsState[currentQuestionIndex].question,
          additionalInstructions,
        }
      );
      const newArray = [...questionsState];
      newArray[currentQuestionIndex].llmGradingInstructions =
        response.data.gradingInstructions;

      updateQuestionsAndNotifyParent(newArray);
      setIsGenerateModalOpen(false);
      setAdditionalInstructions('');
    } catch (error) {
      console.error('Error generating grading criteria:', error);
    } finally {
      setIsGenerating(false);
      setCurrentQuestionIndex(-1);
    }
  };

  return (
    <>
      <div>
        <Table className='requirementTable'>
          <Table.Thead>
            <Table.Tr>
              {showBankTitle && (
                <Table.Th style={{ width: '15%' }}>
                  Title
                  <Input.Label required />
                  <Tooltip label='Title of the question'>
                    <IconHelp
                      size={16}
                      style={{
                        marginLeft: '4px',
                        position: 'relative',
                        top: '3px',
                      }}
                    />
                  </Tooltip>
                </Table.Th>
              )}
              <Table.Th style={{ width: showBankTitle ? '39%' : '44%' }}>
                Question
                <Input.Label required />
                <Tooltip label='Questions worded as they will be asked during the screen'>
                  <IconHelp
                    size={16}
                    style={{
                      marginLeft: '4px',
                      position: 'relative',
                      top: '3px',
                    }}
                  />
                </Tooltip>
              </Table.Th>
              <Table.Th style={{ width: '46%' }}>
                Grading Instructions
                <Tooltip label='Rules for how to score response on a scale from 0 to 100'>
                  <IconHelp
                    size={16}
                    style={{
                      marginLeft: '4px',
                      position: 'relative',
                      top: '3px',
                    }}
                  />
                </Tooltip>
              </Table.Th>
              <Table.Th style={{ width: '10%' }} />
            </Table.Tr>
          </Table.Thead>

          <Table.Tbody>
            {questionsState.map((question, index) => (
              <Table.Tr key={index}>
                {showBankTitle && (
                  <Table.Td style={{ width: '15%' }}>
                    <EditableField
                      value={question.bankTitle}
                      onChange={handleFieldChange(index, 'bankTitle')}
                      placeholder='Question Title'
                      disabled={!isEditable}
                    />
                  </Table.Td>
                )}

                <Table.Td style={{ width: showBankTitle ? '39%' : '44%' }}>
                  <EditableField
                    value={question.question}
                    onChange={handleFieldChange(index, 'question')}
                    placeholder='What is your highest level of education?'
                    disabled={!isEditable}
                  />
                </Table.Td>

                <Table.Td style={{ width: '46%' }}>
                  <EditableField
                    value={question.llmGradingInstructions}
                    onChange={handleFieldChange(
                      index,
                      'llmGradingInstructions'
                    )}
                    placeholder='Score 100 if the candidate has a bachelors or higher...'
                    disabled={!isEditable}
                  />
                </Table.Td>

                <Table.Td style={{ width: '10%', paddingRight: '16px' }}>
                  <Group w='max-content' gap='xs'>
                    <div style={{ display: 'flex', gap: '8px' }}>
                      <Tooltip label='Generate grading instructions'>
                        <ActionIcon
                          variant='subtle'
                          onClick={() => handleGenerateGradingCriteria(index)}
                          loading={
                            isGenerating && currentQuestionIndex === index
                          }
                          disabled={!isEditable}
                        >
                          <IconWand size={16} />
                        </ActionIcon>
                      </Tooltip>

                      <ActionIcon
                        variant='subtle'
                        color='red'
                        onClick={() => handleDeleteQuestion(index)}
                        disabled={!isEditable}
                      >
                        <IconTrash size={16} />
                      </ActionIcon>

                      <Tooltip label='More options'>
                        <ActionIcon
                          variant='subtle'
                          onClick={() => setOpenEditIndex(index)}
                          disabled={!isEditable}
                          style={{ marginRight: '8px' }}
                        >
                          <IconDotsVertical size={16} />
                        </ActionIcon>
                      </Tooltip>
                    </div>
                  </Group>
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </div>

      {/* Modal to edit the entire row */}
      <Modal
        opened={openEditIndex !== -1}
        onClose={() => setOpenEditIndex(-1)}
        title={
          <div>
            <div>Edit Question</div>
            {/* <div
              style={{
                fontSize: '10px',
                color: '#696969',
                marginTop: '8px',
                marginLeft: '4px',
              }}
            >
              {unsavedChanges ? 'Saving...' : 'Saved'}
            </div> */}
          </div>
        }
        size='lg'
        centered
        withCloseButton
        overlayProps={{
          backgroundOpacity: 0.3,
        }}
      >
        {openEditIndex !== -1 && (
          <div
            style={{
              height: `calc(100% - 40px)`,
              paddingBottom: `40px`,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '22px',
              marginBottom: '20px',
              overflow: 'scroll',
              width: '100%',
              marginTop: '10px',
            }}
          >
            <div style={{ width: '100%' }}>
              <h4 className='section-label'>Title</h4>
              <EditableField
                value={questionsState[openEditIndex].bankTitle}
                onChange={handleFieldChange(openEditIndex, 'bankTitle')}
                placeholder='Question Title'
                alwaysShowBorder
              />
            </div>

            <div style={{ width: '100%' }}>
              <h4 className='section-label'>Question</h4>
              <EditableField
                value={questionsState[openEditIndex].question}
                onChange={handleFieldChange(openEditIndex, 'question')}
                placeholder='What is your highest level of education?'
                description={
                  questionsState[openEditIndex].questionType ===
                  QuestionType.GradeOnly
                    ? '* Will not be asked during screen. Grade extracted from entire conversation context'
                    : questionsState[openEditIndex].questionType ===
                        QuestionType.AutoGenerated
                      ? '* Provide instructions to AI for how to generate the question during the screen'
                      : ''
                }
                shaded={
                  questionsState[openEditIndex].questionType !==
                  QuestionType.Normal
                }
                alwaysShowBorder
              />
            </div>

            <div style={{ width: '100%' }}>
              <h4 className='section-label'>Grading Instructions</h4>
              <EditableField
                value={questionsState[openEditIndex].llmGradingInstructions}
                onChange={handleFieldChange(
                  openEditIndex,
                  'llmGradingInstructions'
                )}
                placeholder='Score 100 if the candidate has a bachelors or higher...'
                alwaysShowBorder
              />
            </div>

            <div style={{ width: '100%' }}>
              <h4 className='section-label'>Additional Settings</h4>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '10px',
                }}
              >
                <div>
                  <h5 className='subsection-label'>Importance</h5>
                  <p className='subsection-description'>
                    Weight of the question score toward the overall score. Info
                    is not weighted in the overall score
                  </p>
                </div>
                <div style={{ width: 'auto' }}>
                  <ImportanceBadgeSelector
                    value={questionsState[openEditIndex].importance}
                    onChange={(value) =>
                      handleFieldChange(openEditIndex, 'importance')(value)
                    }
                  />
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '10px',
                }}
              >
                <div>
                  <h5 className='subsection-label'>Fail if Zero</h5>
                  <p className='subsection-description'>
                    Set overall candidate score to 0 if this question score is 0
                  </p>
                </div>
                <Switch
                  checked={questionsState[openEditIndex].failIfZero}
                  onChange={(e) =>
                    handleFieldChange(
                      openEditIndex,
                      'failIfZero'
                    )(e.currentTarget.checked)
                  }
                  disabled={
                    questionsState[openEditIndex].importance ===
                    RequirementImportance.Info
                  }
                />
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '10px',
                }}
              >
                <div>
                  <h5 className='subsection-label'>Question Type</h5>
                  <p className='subsection-description'>
                    Choose question type. Grade Only is not asked during screen.
                    Auto Generated is generated dynamically during the screen.
                  </p>
                </div>
                <div style={{ width: 'auto' }}>
                  <QuestionTypeBadgeSelector
                    value={questionsState[openEditIndex].questionType}
                    onChange={(value) =>
                      handleFieldChange(openEditIndex, 'questionType')(value)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal>

      {/* Modal for LLM auto-generation */}
      <Modal
        opened={isGenerateModalOpen}
        onClose={() => {
          setIsGenerateModalOpen(false);
          setAdditionalInstructions('');
          setCurrentQuestionIndex(-1);
        }}
        title='Generate Grading Criteria'
        centered
        size='md'
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Text size='sm'>
            AI will use the job description and additional instructions to
            generate grading criteria for the following question
          </Text>

          {currentQuestionIndex >= 0 && (
            <Box
              style={{
                backgroundColor: 'var(--mantine-color-gray-1)',
                borderRadius: '8px',
                padding: '12px',
                margin: '8px 0',
              }}
            >
              <Text size='sm'>
                {questionsState[currentQuestionIndex].question}
              </Text>
            </Box>
          )}

          <Textarea
            label='Additional generation instructions'
            description='Optionally provide additional instructions the AI will follow when generating the grading criteria'
            placeholder='Ex: Consider technical skills and experience level in the evaluation'
            value={additionalInstructions}
            onChange={(e) => setAdditionalInstructions(e.target.value)}
            minRows={3}
          />

          <Button
            onClick={handleGenerateSubmit}
            loading={isGenerating}
            rightSection={<IconWand size={16} />}
            style={{ marginTop: '14px' }}
          >
            Generate
          </Button>
        </div>
      </Modal>
    </>
  );
};
