import React, { useEffect, useState } from 'react';
import { Button, Loader, Radio, Select, Text, TextInput } from '@mantine/core';
import axios from 'api/axiosConfig';
import env from 'env';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';

export interface CallerSettings {
  introducesAs: string;
  allowEditIntroduceAs: 'Yes' | 'No';
  name: string;
  allowEditName: 'Yes' | 'No';
}

const CallerSettingsManager = ({ onUnsavedChanges, refreshTrigger }) => {
  const [loading, setLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const {
    setValues,
    onSubmit,
    values,
    getInputProps,
    setFieldValue,
    isDirty,
    isTouched,
    resetDirty,
    resetTouched,
  } = useForm({
    initialValues: {
      introducesAs: '',
      allowEditIntroduceAs: 'Yes',
      name: '',
      allowEditName: 'Yes',
    } as CallerSettings,
    validate: {
      name: (value) => {
        if (value.length < 3) {
          return 'AI caller name must be at least 3 characters long';
        }
        return null;
      },
    },
  });

  useEffect(() => {
    onUnsavedChanges(isDirty() || isTouched());
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (isDirty() || isTouched()) {
        e.preventDefault();
        e.returnValue = ''; // This triggers the confirmation dialog in some browsers.
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [isDirty, isTouched, onUnsavedChanges, values]);

  useEffect(() => {
    const fetchCallerSettings = async () => {
      try {
        setLoading(true);
        const orgId = localStorage.getItem('orgId');
        const response = await axios.get(
          `${env.REACT_APP_SERVER_URL}/org_background_info/${orgId}`
        );
        const aiCallerSettings = response.data?.ai_caller_settings;
        const aiCallerSettingsIsEmpty =
          Object.keys(aiCallerSettings).length === 0;

        if (!aiCallerSettingsIsEmpty) {
          setValues({
            introducesAs: aiCallerSettings.introducesAs,
            allowEditIntroduceAs: aiCallerSettings.allowEditIntroduceAs
              ? 'Yes'
              : 'No',
            name: aiCallerSettings.name,
            allowEditName: aiCallerSettings.allowEditName ? 'Yes' : 'No',
          });
          resetDirty();
          resetTouched();
        }
      } catch (error) {
        notifications.show({
          title: 'Error',
          message: 'Failed to fetch caller settings',
          color: 'red',
        });
      } finally {
        setLoading(false);
      }
    };

    fetchCallerSettings();
  }, [resetDirty, resetTouched, setValues, refreshTrigger]);

  const handleSubmit = async (values: CallerSettings) => {
    const orgId = localStorage.getItem('orgId');
    try {
      setIsSaving(true);
      await axios.patch(
        `${env.REACT_APP_SERVER_URL}/org_background_info/${orgId}`,
        {
          patch: {
            ai_caller_settings: {
              introducesAs: values.introducesAs,
              allowEditIntroduceAs: values.allowEditIntroduceAs === 'Yes',
              name: values.name,
              allowEditName: values.allowEditName === 'Yes',
            },
          },
        }
      );
      resetDirty();
      resetTouched();

      notifications.show({
        title: 'Success',
        message: 'AI caller settings updated successfully',
        color: 'green',
      });
    } catch (error) {
      console.error('Error updating AI caller settings:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to update AI caller settings',
        color: 'red',
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleOnChangeRadio = (field: string, value: string) => {
    setFieldValue(field, value);
  };

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Loader size='md' />
      </div>
    );
  }

  return (
    <div style={{ padding: '20px' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '24px',
        }}
      >
        <div>
          <Text size='lg' fw={500} mb={8}>
            AI Caller Settings Manager
          </Text>
          <Text size='sm' c='dimmed'>
            Manage AI Caller settings.
          </Text>
        </div>
      </div>

      <form onSubmit={onSubmit(handleSubmit)}>
        <div
          style={{
            display: 'flex',
            gap: '20px',
            flexDirection: 'column',
            width: '40%',
          }}
        >
          <Select
            label='AI Caller Will Introduce Itself As'
            placeholder='Select how ai caller will introduce itself'
            disabled={loading}
            {...getInputProps('introducesAs')}
            onChange={(value) => {
              setFieldValue('introducesAs', value || 'A Digital Assistant');
            }}
            data={[
              {
                value: 'A Digital Assistant',
                label: 'A Digital Assistant',
              },
              {
                value: 'A Human Recruiter',
                label: 'A Human Recruiter',
              },
            ]}
          />
          <Radio.Group
            value={values.allowEditIntroduceAs}
            label='Allow users to edit introduction in job?'
            onChange={(value) =>
              handleOnChangeRadio('allowEditIntroduceAs', value)
            }
          >
            <Radio
              checked={values.allowEditIntroduceAs === 'No'}
              label='Required: Set for all jobs.'
              value='No'
              size='xs'
              m='5px'
            />
            <Radio
              checked={values.allowEditIntroduceAs === 'Yes'}
              label='Optional: Allow users to edit this setting in each job.'
              value='Yes'
              size='xs'
              m='5px'
            />
          </Radio.Group>

          <TextInput
            label='AI Caller Name'
            placeholder='Enter AI caller name'
            disabled={loading}
            {...getInputProps('name')}
          />

          <Radio.Group
            label='Allow users to edit name in job?'
            value={values.allowEditName}
            onChange={(value) => handleOnChangeRadio('allowEditName', value)}
          >
            <Radio
              checked={values.allowEditIntroduceAs === 'No'}
              label='Required: Set for all jobs.'
              value='No'
              size='xs'
              m='5px'
            />
            <Radio
              checked={values.allowEditIntroduceAs === 'Yes'}
              label='Optional: Allow users to edit this setting in each job.'
              value='Yes'
              size='xs'
              m='5px'
            />
          </Radio.Group>

          <Button
            style={{ alignSelf: 'flex-end' }}
            type='submit'
            loading={isSaving}
            disabled={!isDirty() || !isTouched()}
          >
            Save Changes
          </Button>
        </div>
      </form>
    </div>
  );
};

export default CallerSettingsManager;
