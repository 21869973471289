import { ScoreContactFilterState } from 'pages/job-posting-tips/components/types';
import { useState } from 'react';

const DEFAULT_MIN_SCORE = 0;
const DEFAULT_MAX_SCORE = 100;

export type ScoreSelectionMode = 'less' | 'greater' | 'between';

export default function useScoreContactFilterState(): ScoreContactFilterState {
  const [minScore, setMinScore] = useState(DEFAULT_MIN_SCORE);
  const [maxScore, setMaxScore] = useState(DEFAULT_MAX_SCORE);
  const [selectionMode, setSelectionMode] =
    useState<ScoreSelectionMode>('between');

  const clear = () => {
    setMinScore(DEFAULT_MIN_SCORE);
    setMaxScore(DEFAULT_MAX_SCORE);
    setSelectionMode('between');
  };

  const isEmpty =
    minScore === DEFAULT_MIN_SCORE &&
    maxScore === DEFAULT_MAX_SCORE &&
    selectionMode === 'between';

  return {
    minScore,
    maxScore,
    selectionMode,
    onChangeMinScore: setMinScore,
    onChangeMaxScore: setMaxScore,
    onChangeSelectionMode: setSelectionMode,
    clear,
    isEmpty,
  };
}
