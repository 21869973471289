import { useCallback } from 'react';
import { useAtomCallback } from 'jotai/utils';
import env from 'env';
import { notifications } from '@mantine/notifications';
import {
  CampaignAtomInfo,
  campaignCacheAtom,
  campaignInfoAtomForCandidate,
} from 'pages/editorv2/atoms';
import { Setter } from 'jotai';

import axios from '../../src/api/axiosConfig';

export type ChangesReviewed = {
  isCallReviewed?: boolean;
  isSMSReviewed?: boolean;
  isEmailReviewed?: boolean;
};

export const useCandidateCampaignReview = () => {
  const handleReviewChanges = useAtomCallback(
    useCallback(
      async (
        get,
        set,
        {
          campaignId,
          candidateId,
          userId,
          changesReviewed,
        }: {
          campaignId: string;
          candidateId: string;
          userId: string;
          changesReviewed: ChangesReviewed;
        }
      ) => {
        if (!campaignId || !candidateId || !userId) {
          console.error('Missing required parameters for reviewChanges');
          return;
        }

        const candidateCampaignInfoKey = `${candidateId}_${campaignId}`;
        const candidateInfo = get(
          campaignInfoAtomForCandidate(candidateCampaignInfoKey)
        );

        if (!candidateInfo) {
          console.error(
            'No candidate info available for:',
            candidateCampaignInfoKey
          );
          return;
        }

        const prevCandidateInfo = { ...candidateInfo };

        // Optimistic updates
        updateCandidateReviewState(
          set,
          candidateCampaignInfoKey,
          candidateInfo,
          changesReviewed
        );
        removeCandidateFromReviewList(set, campaignId, candidateId);

        try {
          await axios.post(
            `${env.REACT_APP_SERVER_URL}/candidate/${candidateId}/review_changes/${campaignId}`,
            { userId, ...changesReviewed }
          );
        } catch (error) {
          console.error('Error updating changes reviewed:', error);

          // Rollback state changes
          set(
            campaignInfoAtomForCandidate(candidateCampaignInfoKey),
            prevCandidateInfo
          );

          addCandidateToReviewList(set, campaignId, candidateId);

          notifications.show({
            title: 'There was an error updating changes reviewed',
            message: error instanceof Error ? error.message : '',
            color: 'red',
          });
        }
      },
      []
    )
  );

  const handleUnreviewChanges = useAtomCallback(
    useCallback(
      async (
        get,
        set,
        {
          campaignId,
          candidateId,
          changesReviewed,
        }: {
          campaignId: string;
          candidateId: string;
          changesReviewed: ChangesReviewed;
        }
      ) => {
        if (!campaignId || !candidateId) {
          console.error('Missing required parameters for unreviewChanges');
          return;
        }

        const candidateCampaignInfoKey = `${candidateId}_${campaignId}`;
        const candidateInfo = get(
          campaignInfoAtomForCandidate(candidateCampaignInfoKey)
        );

        if (!candidateInfo) {
          console.error(
            'No candidate info available for:',
            candidateCampaignInfoKey
          );
          return;
        }

        const prevCandidateInfo = { ...candidateInfo };

        // Optimistic updates
        updateCandidateReviewState(
          set,
          candidateCampaignInfoKey,
          candidateInfo,
          changesReviewed
        );
        addCandidateToReviewList(set, campaignId, candidateId);

        try {
          await axios.post(
            `${env.REACT_APP_SERVER_URL}/candidate/${candidateId}/unreview_changes/${campaignId}`,
            changesReviewed
          );
        } catch (error) {
          console.error('Error unreviewing changes:', error);

          // Rollback state changes
          set(
            campaignInfoAtomForCandidate(candidateCampaignInfoKey),
            prevCandidateInfo
          );

          // Only remove from review list if it was fully reviewed before
          if (isFullyReviewed(prevCandidateInfo)) {
            removeCandidateFromReviewList(set, campaignId, candidateId);
          }

          notifications.show({
            title: 'There was an error unreviewing changes',
            message: error instanceof Error ? error.message : '',
            color: 'red',
          });
        }
      },
      []
    )
  );

  const markAllAsReviewed = useAtomCallback(
    useCallback(
      async (
        get,
        set,
        campaignId: string,
        userId: string,
        candidateIds?: Set<string>
      ) => {
        if (!campaignId || !userId) {
          console.error('Missing required parameters for markAllAsReviewed');
          return;
        }

        const campaignCache = get(campaignCacheAtom);
        const campaignData = campaignCache[campaignId];

        if (!campaignData || !campaignData.candidatesNeedingReview) {
          return;
        }

        const idsToUpdate =
          candidateIds || campaignData.candidatesNeedingReview;

        if (idsToUpdate.size === 0) {
          return;
        }

        // Store original state for potential rollback
        const originalCandidateStates = new Map();
        idsToUpdate.forEach((candidateId) => {
          const cacheKey = `${candidateId}_${campaignId}`;
          const info = get(campaignInfoAtomForCandidate(cacheKey));
          if (info) {
            originalCandidateStates.set(candidateId, { ...info });
          }
        });

        const originalCampaignCache = { ...get(campaignCacheAtom) };

        // Optimistic updates
        idsToUpdate.forEach((candidateId) => {
          const cacheKey = `${candidateId}_${campaignId}`;
          const info = get(campaignInfoAtomForCandidate(cacheKey));

          if (!info) return;

          const allReviewedChanges: ChangesReviewed = {
            isCallReviewed: true,
            isSMSReviewed: true,
            isEmailReviewed: true,
          };

          updateCandidateReviewState(set, cacheKey, info, allReviewedChanges);
        });

        clearReviewList(set, campaignId);

        try {
          await axios.post(
            `${env.REACT_APP_SERVER_URL}/campaigns/${campaignId}/mark_all_as_reviewed`,
            {
              userId,
              candidateIds: Array.from(idsToUpdate),
            }
          );
        } catch (error) {
          console.error('Error marking all candidates as reviewed:', error);

          // Rollback state changes
          originalCandidateStates.forEach((originalState, candidateId) => {
            const cacheKey = `${candidateId}_${campaignId}`;
            set(campaignInfoAtomForCandidate(cacheKey), originalState);
          });

          set(campaignCacheAtom, originalCampaignCache);

          notifications.show({
            title: 'Failed to mark all candidates as reviewed',
            message: error instanceof Error ? error.message : '',
            color: 'red',
          });

          throw error;
        }
      },
      []
    )
  );

  return {
    handleReviewChanges,
    handleUnreviewChanges,
    markAllAsReviewed,
  };
};

function updateCandidateReviewState(
  set: Setter,
  candidateCampaignInfoKey: string,
  candidateInfo: any,
  changes: ChangesReviewed
) {
  set(campaignInfoAtomForCandidate(candidateCampaignInfoKey), {
    ...candidateInfo,
    ...changes,
    _fetchedAt: undefined,
  });
}

function addCandidateToReviewList(
  set: Setter,
  campaignId: string,
  candidateId: string
) {
  set(campaignCacheAtom, (prevCache: Record<string, CampaignAtomInfo>) => {
    const campaign = prevCache[campaignId];
    if (!campaign) return prevCache;

    const updatedSet = new Set(campaign.candidatesNeedingReview);
    updatedSet.add(candidateId);

    return {
      ...prevCache,
      [campaignId]: {
        ...campaign,
        candidatesNeedingReview: updatedSet,
      },
    };
  });
}

function removeCandidateFromReviewList(
  set: Setter,
  campaignId: string,
  candidateId: string
) {
  set(campaignCacheAtom, (prevCache: Record<string, CampaignAtomInfo>) => {
    const campaign = prevCache[campaignId];
    if (!campaign) return prevCache;

    const updatedSet = new Set(campaign.candidatesNeedingReview);
    updatedSet.delete(candidateId);

    return {
      ...prevCache,
      [campaignId]: {
        ...campaign,
        candidatesNeedingReview: updatedSet,
      },
    };
  });
}

function clearReviewList(set: Setter, campaignId: string) {
  set(campaignCacheAtom, (prevCache: Record<string, CampaignAtomInfo>) => {
    const campaign = prevCache[campaignId];
    if (!campaign) return prevCache;

    return {
      ...prevCache,
      [campaignId]: {
        ...campaign,
        candidatesNeedingReview: new Set(),
      },
    };
  });
}

function isFullyReviewed(candidateInfo: any): boolean {
  return (
    (candidateInfo.isCallReviewed ?? false) &&
    (candidateInfo.isSMSReviewed ?? false) &&
    (candidateInfo.isEmailReviewed ?? false)
  );
}
