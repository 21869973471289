import React, { useState } from 'react';
import { Button, Modal, Text } from '@mantine/core';
import env from 'env';
import axios from 'api/axiosConfig';
import { notifications } from '@mantine/notifications';

interface DeleteCampaignFolderModalProps {
  open: boolean;
  onClose: () => void;
  folder: any;
  onDeleteFolder: (folderId: string) => void;
}

const DeleteCampaignFolderModal = ({
  open,
  onClose,
  folder,
  onDeleteFolder,
}: DeleteCampaignFolderModalProps) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteCampaignFolder = async () => {
    try {
      setIsDeleting(true);
      await axios.delete(
        `${env.REACT_APP_SERVER_URL}/campaign_folders/${folder.folder_id}/delete`
      );
      onDeleteFolder(folder.folder_id);
      onClose();
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: 'Failed to delete folder',
        color: 'red',
      });
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <Modal
      opened={open}
      onClose={onClose}
      title={`Delete ${folder?.folder_name}`}
    >
      <div>
        <Text size='sm' fw={500} style={{ marginBottom: '16px' }}>
          {`Deleting this folder will also delete ${
            folder?.subfolder_count
          } subfolders and ${folder?.campaign_count} campaigns`}
        </Text>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
        <Button onClick={onClose} variant='outline'>
          Cancel
        </Button>
        <Button
          color='red'
          onClick={handleDeleteCampaignFolder}
          loading={isDeleting}
        >
          Delete
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteCampaignFolderModal;
