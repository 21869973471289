import React, { useState } from 'react';
import { notifications } from '@mantine/notifications';
import { Button, Modal, Textarea, TextInput, Tooltip } from '@mantine/core';

import { ORG_BACKGROUND_INFO_PLACEHOLDER } from './constants';
import { SequenceSettingsTemplate } from './Template';

interface AddTemplateModalProps {
  open: boolean;
  onClose: () => void;
  handleAddTemplate: ({
    title,
    instructions,
  }: {
    title: string;
    instructions: string;
  }) => Promise<void>;
}

export const AddTemplateModal = ({
  open,
  onClose,
  handleAddTemplate,
}: AddTemplateModalProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [templateData, setTemplateData] = useState<SequenceSettingsTemplate>({
    id: '',
    title: '',
    instructions: '',
  });

  const handleChangeField = (e) => {
    setTemplateData({
      ...templateData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSaveTemplate = async () => {
    try {
      setIsSubmitting(true);
      await handleAddTemplate({
        title: templateData.title,
        instructions: templateData.instructions,
      });

      setTemplateData({
        id: '',
        title: '',
        instructions: '',
      });
      onClose();
      notifications.show({
        title: 'Success',
        message: 'Template added successfully',
        color: 'green',
      });
    } catch (error) {
      console.error('Error saving template:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to save template',
        color: 'red',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal title='Add Template' opened={open} onClose={onClose} size='lg'>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        }}
      >
        <TextInput
          label='Title'
          name='title'
          value={templateData.title}
          onChange={handleChangeField}
          placeholder='3rd Shift Information'
        />
        <Textarea
          autosize
          label='Instructions'
          name='instructions'
          value={templateData.instructions}
          onChange={handleChangeField}
          minRows={5}
          placeholder={ORG_BACKGROUND_INFO_PLACEHOLDER}
        />
        <div
          style={{
            display: 'flex',
            alignSelf: 'flex-end',
            gap: '10px',
          }}
        >
          <Button color='gray' size='sm' onClick={onClose}>
            Cancel
          </Button>
          <Tooltip
            label='Fill in all fields to save'
            position='top'
            withArrow
            hidden={!!templateData.title && !!templateData.instructions}
          >
            <Button
              color='blue'
              size='sm'
              onClick={handleSaveTemplate}
              loading={isSubmitting}
              disabled={!templateData.title || !templateData.instructions}
            >
              Save
            </Button>
          </Tooltip>
        </div>
      </div>
    </Modal>
  );
};

export default AddTemplateModal;
