import React, { useState, useEffect } from 'react';
import { Badge, Group, ScrollArea, Text, Center } from '@mantine/core';
import { formatToLocalTime, formatToLocalTimeGmail } from 'utils/dateUtils';
import axios from 'api/axiosConfig';
import env from 'env';
import { notifications } from '@mantine/notifications';

export interface Email {
  id: string;
  subject: string;
  content: string;
  from: string;
  to: string[];
  provider: string;
  timestamp: string;
  isIncoming: boolean;
}

interface EmailHistoryProps {
  recruitingEmail?: string;
  candidateId: string | undefined;
  campaignId: string | undefined;
}

const CHARACTER_LIMIT = 300;

const EmailMessage: React.FC<{ email: Email; recruitingEmail?: string }> = ({
  email,
  recruitingEmail,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const isIncoming = recruitingEmail
    ? email.from.toLowerCase() === recruitingEmail.toLowerCase()
    : email.isIncoming;

  const shouldShowMore = email.content.length > CHARACTER_LIMIT;
  const displayContent = isExpanded
    ? email.content
    : email.content.slice(0, CHARACTER_LIMIT) + (shouldShowMore ? '...' : '');

  return (
    <Group
      style={{
        marginTop: 12,
        alignItems: 'flex-start',
        padding: '12px',
        backgroundColor: isIncoming ? 'var(--mantine-color-blue-0)' : 'white',
        borderRadius: '8px',
        border: '1px solid var(--mantine-color-gray-3)',
      }}
    >
      <div style={{ width: '100%' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '8px',
          }}
        >
          <div>
            <Text size='sm' fw={600} c={isIncoming ? 'blue' : 'dark'}>
              {isIncoming ? 'Received' : 'Sent'}
            </Text>
            <Text size='xs' c='dimmed'>
              {email.provider === 'gmail'
                ? formatToLocalTimeGmail(email.timestamp)
                : formatToLocalTime(email.timestamp)}
            </Text>
          </div>
          <Badge
            variant='light'
            color={isIncoming ? 'blue' : 'gray'}
            style={{ textTransform: 'none' }}
          >
            {isIncoming
              ? `To: ${email.to[0]}${email.to.length > 1 ? ` +${email.to.length - 1} more` : ''}`
              : `From: ${email.from}`}
          </Badge>
        </div>

        {email.subject && (
          <Text size='sm' fw={600} style={{ marginBottom: '8px' }}>
            {email.subject}
          </Text>
        )}

        <div>
          <Text
            size='sm'
            style={{
              whiteSpace: 'pre-wrap',
              color: 'var(--mantine-color-gray-7)',
            }}
          >
            {displayContent}
          </Text>

          {shouldShowMore && (
            <Badge
              size='sm'
              c='white'
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
                marginTop: '4px',
              }}
              onClick={(e) => {
                e.stopPropagation();
                setIsExpanded(!isExpanded);
              }}
            >
              {isExpanded ? 'Show Less' : 'Show More'}
            </Badge>
          )}
        </div>
      </div>
    </Group>
  );
};

const EmailHistory: React.FC<EmailHistoryProps> = ({
  recruitingEmail,
  candidateId,
  campaignId,
}) => {
  const [emails, setEmails] = useState<Email[]>([]);
  const [isFetchingEmails, setIsFetchingEmails] = useState(false);
  const [emailError, setEmailError] = useState(false);
  useEffect(() => {
    if (!candidateId || !campaignId) return;
    const fetchEmails = async () => {
      setIsFetchingEmails(true);
      setEmailError(false);

      // prioritize other requests
      await new Promise((resolve) => setTimeout(resolve, 1000));

      try {
        const response = await axios.get(
          `${env.REACT_APP_SERVER_URL}/candidate/${candidateId}/emails/${campaignId}`
        );
        const { emails } = response.data;

        setEmails(emails || '');
      } catch (error) {
        setEmailError(true);
        console.error('Error fetching emails:', error);
        notifications.show({
          title: 'There was an error retrieving emails',
          message: error instanceof Error ? error.message : '',
          color: 'red',
        });
      } finally {
        setIsFetchingEmails(false);
      }
    };

    fetchEmails();
  }, [campaignId, candidateId]);

  if (isFetchingEmails) {
    return (
      <div style={{ padding: '16px' }}>
        {[1, 2, 3].map((i) => (
          <div
            key={i}
            style={{
              marginBottom: '16px',
              padding: '12px',
              border: '1px solid var(--mantine-color-gray-3)',
              borderRadius: '8px',
            }}
          >
            <div className='animate-pulse'>
              <div
                style={{
                  height: '16px',
                  width: '25%',
                  backgroundColor: 'var(--mantine-color-gray-2)',
                  marginBottom: '8px',
                  borderRadius: '4px',
                }}
              />
              <div
                style={{
                  height: '12px',
                  width: '75%',
                  backgroundColor: 'var(--mantine-color-gray-2)',
                  marginBottom: '8px',
                  borderRadius: '4px',
                }}
              />
              <div
                style={{
                  height: '12px',
                  width: '50%',
                  backgroundColor: 'var(--mantine-color-gray-2)',
                  borderRadius: '4px',
                }}
              />
            </div>
          </div>
        ))}
      </div>
    );
  }

  if (emailError) {
    return (
      <Center style={{ textAlign: 'center', height: '100%' }}>
        <h5>Failed to fetch Email History</h5>
      </Center>
    );
  }

  if (!emails.length) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          padding: '20px',
        }}
      >
        <Text c='dimmed' size='sm'>
          No email history available
        </Text>
      </div>
    );
  }

  return (
    <div className='transcript-column'>
      <ScrollArea className='scrollable-content'>
        <div className='scroll-fade-top' />
        <div className='inside' style={{ padding: '16px' }}>
          {emails
            .sort(
              (a, b) =>
                new Date(b.timestamp).getTime() -
                new Date(a.timestamp).getTime()
            )
            .map((email) => (
              <EmailMessage
                key={email.id}
                email={email}
                recruitingEmail={recruitingEmail}
              />
            ))}
        </div>
        <div className='scroll-fade-bottom' />
      </ScrollArea>
    </div>
  );
};

export default EmailHistory;
