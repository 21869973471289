import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { Button, ActionIcon, Textarea, Avatar, Loader } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import env from 'env';
import { campaignInfoAtomForCandidate } from 'pages/editorv2/atoms';
import { notifications } from '@mantine/notifications';
import './Transcript.css';
import { useAtom } from 'jotai';
import axios from 'api/axiosConfig';

interface CommentProps {
  campaignId: string;
  candidateId: string;
  userId: string;
  inPopover?: boolean;
  inputRef?: React.RefObject<HTMLDivElement>;
  onCommentsLoaded?: (commentsCount: number) => void;
}

export interface CommentRef {
  resetInitialLoadState: () => void;
}

interface Comment {
  comment_id: string;
  text: string;
  user_email: string;
  user_first_name: string;
  user_last_name: string;
  created_at: string;
  modified_at?: string;
}

const Comments = forwardRef<CommentRef, CommentProps>(
  (
    {
      campaignId,
      candidateId,
      userId,
      inPopover = false,
      inputRef,
      onCommentsLoaded,
    },
    ref
  ) => {
    const [comments, setComments] = useState<Comment[]>([]);
    const [newComment, setNewComment] = useState('');
    const [isSavingComment, setIsSavingComment] = useState(false);
    const [isLoadingComments, setIsLoadingComments] = useState(false);
    const [initialPopoverLoad, setInitialPopoverLoad] = useState(true);
    const [deletingCommentId, setDeletingCommentId] = useState<string | null>(
      null
    );
    const [commentsLoaded, setCommentsLoaded] = useState(false);

    const commentsListRef = useRef<HTMLDivElement>(null);

    // Expose methods to parent via ref
    useImperativeHandle(ref, () => ({
      resetInitialLoadState: () => {
        setInitialPopoverLoad(true);
        setIsLoadingComments(true);
        setCommentsLoaded(false);
      },
    }));

    const candidateCampaignInfoKey = candidateId + '_' + campaignId;
    const [, setCandidateInfo] = useAtom(
      campaignInfoAtomForCandidate(candidateCampaignInfoKey)
    );

    const fetchComments = useCallback(
      async (isRefresh = false) => {
        if ((inPopover && initialPopoverLoad) || isRefresh) {
          setIsLoadingComments(true);
        }

        try {
          const response = await axios.get(
            `${env.REACT_APP_SERVER_URL}/candidate/${candidateId}/comments/${campaignId}`
          );
          const commentsData = response.data || [];
          setComments(commentsData);

          setCandidateInfo({
            hasComments: commentsData.length > 0,
            _fetchedAt: undefined,
          });

          if (onCommentsLoaded && (isRefresh || !commentsLoaded)) {
            onCommentsLoaded(commentsData.length);
          }

          setCommentsLoaded(true);
        } catch (error) {
          console.error('Error fetching comments:', error);
        } finally {
          if ((inPopover && initialPopoverLoad) || isRefresh) {
            setIsLoadingComments(false);
            if (initialPopoverLoad) setInitialPopoverLoad(false);
          }
        }
      },
      [
        candidateId,
        campaignId,
        setCandidateInfo,
        inPopover,
        initialPopoverLoad,
        onCommentsLoaded,
        commentsLoaded,
      ]
    );

    const scrollToBottom = useCallback(() => {
      if (commentsListRef.current) {
        const { scrollHeight } = commentsListRef.current;
        commentsListRef.current.scrollTop = scrollHeight;
      }
    }, []);

    useEffect(() => {
      if (comments.length > 0) {
        scrollToBottom();
      }
    }, [comments.length, scrollToBottom]);

    const handleSaveComment = async () => {
      if (!newComment.trim()) return;

      setIsSavingComment(true);
      try {
        await axios.post(
          `${env.REACT_APP_SERVER_URL}/candidate/${candidateId}/comments/${campaignId}`,
          {
            text: newComment,
            userId: userId,
          }
        );
        // Passar true para indicar que é um refresh após salvar
        await fetchComments(true);
        setNewComment('');
      } catch (error) {
        console.error('Error saving comment:', error);
      } finally {
        setIsSavingComment(false);
      }
    };

    const handleDeleteComment = async (commentId: string) => {
      setDeletingCommentId(commentId);
      try {
        setComments((prevComments) =>
          prevComments.filter((comment) => comment.comment_id !== commentId)
        );
        if (onCommentsLoaded) {
          onCommentsLoaded(comments.length - 1);
        }
        await axios.delete(
          `${env.REACT_APP_SERVER_URL}/candidate/${candidateId}/comments/${campaignId}/${commentId}`
        );
      } catch (error) {
        console.error('Error deleting comment:', error);
        notifications.show({
          title: 'Error',
          message: 'Failed to delete comment. Click to reload the page.',
          color: 'red',
          onClick: () => window.location.reload(),
        });
      } finally {
        setDeletingCommentId(null);
      }
    };

    useEffect(() => {
      if (candidateId && campaignId && !commentsLoaded) {
        fetchComments();
      }
    }, [candidateId, campaignId, fetchComments, commentsLoaded]);

    return (
      <div
        className={`comments-section ${inPopover ? 'in-popover' : ''}`}
        style={inPopover ? { margin: 0, padding: 0 } : undefined}
      >
        {!inPopover && (
          <h4 style={{ marginTop: '0px', marginBottom: '12px' }}>Comments</h4>
        )}

        <div
          className='comments-list'
          ref={commentsListRef}
          style={inPopover ? { marginBottom: 0 } : undefined}
        >
          {isLoadingComments && initialPopoverLoad && inPopover ? (
            <div className='comments-loading-container'>
              <Loader size='sm' color='blue' />
              <div className='loading-text'>Loading comments...</div>
            </div>
          ) : (
            <>
              {comments.length === 0 && inPopover && (
                <div className='no-comments-message'>No comments yet.</div>
              )}
              {comments.map((comment) => (
                <div key={comment.comment_id} className='comment'>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar
                        variant='filled'
                        color='var(--mantine-color-blue-3)'
                        size={inPopover ? 32 : 36}
                        name={
                          comment.user_first_name + ' ' + comment.user_last_name
                        }
                        style={{
                          margin: inPopover ? '4px' : '6px',
                          marginRight: inPopover ? '10px' : '14px',
                        }}
                      />
                      <div>
                        <div className='comment-author'>
                          {comment.user_first_name +
                            ' ' +
                            comment.user_last_name}
                        </div>
                        <small className='comment-date'>
                          {new Date(comment.created_at).toLocaleString(
                            undefined,
                            {
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                              hour: 'numeric',
                              minute: 'numeric',
                            }
                          )}
                        </small>
                      </div>
                    </div>
                    {userId && (
                      <ActionIcon
                        variant='subtle'
                        color='red'
                        onClick={() => handleDeleteComment(comment.comment_id)}
                        disabled={deletingCommentId === comment.comment_id}
                      >
                        <IconTrash size={16} />
                      </ActionIcon>
                    )}
                  </div>
                  <p className='comment-text'>{comment.text}</p>
                </div>
              ))}
            </>
          )}
        </div>

        <div
          className='new-comment'
          style={inPopover ? { margin: 0 } : undefined}
          ref={inputRef}
        >
          <Textarea
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder='Add a comment...'
            style={{
              borderRadius: '12px',
              marginBottom: newComment ? (inPopover ? '6px' : undefined) : 0,
            }}
            minRows={1}
            autosize={inPopover}
            maxRows={inPopover ? 4 : undefined}
            disabled={isSavingComment && inPopover}
          />
          <div
            className={inPopover ? 'comment-buttons' : ''}
            style={{
              display: newComment ? 'flex' : 'none',
              width: '100%',
              gap: '8px',
              justifyContent: inPopover ? 'flex-end' : undefined,
              marginLeft: inPopover ? undefined : 'auto',
              marginTop: inPopover ? (newComment ? '6px' : 0) : undefined,
              marginBottom: 0,
            }}
          >
            <Button
              size='xs'
              onClick={() => setNewComment('')}
              disabled={isSavingComment || !newComment.trim()}
              style={
                inPopover ? {} : { padding: '0px 30px', marginLeft: 'auto' }
              }
              className={inPopover ? 'discard-button' : ''}
              variant='outline'
            >
              Discard
            </Button>
            <Button
              size='xs'
              onClick={handleSaveComment}
              disabled={isSavingComment || !newComment.trim()}
              style={inPopover ? {} : { padding: '0px 30px' }}
              color='var(--mantine-color-blue-6)'
              className={inPopover ? 'save-button' : ''}
            >
              {isSavingComment && inPopover ? (
                <>
                  <Loader size='xs' color='white' />
                  <span style={{ marginLeft: 5 }}>Saving...</span>
                </>
              ) : (
                'Save'
              )}
            </Button>
          </div>
        </div>
      </div>
    );
  }
);

export default Comments;
