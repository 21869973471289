import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
  Fragment,
} from 'react';
import {
  Box,
  ScrollArea,
  Text,
  RingProgress,
  Tabs,
  Button,
  Tooltip,
  Kbd,
  Loader,
  CloseButton,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import axios from 'api/axiosConfig';
import { formatToLocalTime } from 'utils/dateUtils';
import {
  IconCalendar,
  IconCalendarCheck,
  IconMessageCircleSearch,
} from '@tabler/icons-react';
import { convertUpperSnakeToTitle } from 'utils/formatUtils';
import env from 'env';
import { notifications } from '@mantine/notifications';
import {
  CandidateQuestion,
  RequirementGrade,
} from 'hooks/useGetTranscriptData';
import { useCandidateCampaignReview } from 'hooks/useCandidateCampaignReview';
import { resumeCacheAtom, transcriptionCacheAtom } from 'pages/editorv2/atoms';
import { useAtom } from 'jotai';

import SMSTab from './SmsTab';
import TranscriptLines from './TranscriptLines';
import { MIN_VISIBLE_TRANSCRIPT_HEIGHT } from './constants';
import { TranscriptMessage } from './types';
import WebCallRecordingPlayer from './WebCallRecordingPlayer';
import PhoneCallRecordingPlayer from './PhoneCallRecordingPlayerV2';
import './Transcript.css';
import EmailHistory from './EmailHistory';
import CandidateFeedback from './CandidateFeedback';
import Comments from './Comments';
import AutoScrollButton from './AutoScrollButton';
import ReviewIndicator from './ReviewIndicator';
import TranscriptChat from './TranscriptChat';
import { CandidateInfoDisplay } from '../CandidateInfoDisplay';
import TranscriptSummary from './TranscriptSummary';

/* ----------------------------------------------------------------
   Tabs Setup
----------------------------------------------------------------- */

export const TabOptions = {
  CALL: 'Call',
  SMS: 'SMS',
  EMAIL: 'Email',
  RESUME: 'Resume',
} as const;

export type Tab = (typeof TabOptions)[keyof typeof TabOptions];
const isTab = (value: any): value is Tab => {
  return Object.values(TabOptions).includes(value);
};

export interface TranscriptLine {
  start_ts: number;
  role: string;
  content: string;
}

/* ----------------------------------------------------------------
   Transcript Props
----------------------------------------------------------------- */
interface TranscriptProps {
  transcript: TranscriptMessage[] | string; // Phone call transcript in raw text form
  requirementGradesList: RequirementGrade[];
  callId: string;
  overallGrade?: number;
  completionRate?: number;
  candidateName?: string;
  candidateEmail?: string;
  candidatePhone?: string;
  lastCalled?: string;
  testCall?: boolean;
  allCalls?: any[];
  callComplete?: boolean;
  webCall?: boolean;
  candidateId?: string;
  campaignId?: string;
  showNavigationShortcut?: boolean;
  candidateQuestions?: CandidateQuestion[];
  needsTranslation?: boolean;
  activeTab: Tab;
  setActiveTab: (tab: Tab) => void;
  summary: string;
  recordingURL?: string;
}

/* ----------------------------------------------------------------
   Main Transcript Component
----------------------------------------------------------------- */
const Transcript: React.FC<TranscriptProps> = ({
  transcript,
  requirementGradesList,
  callId,
  overallGrade,
  completionRate,
  candidateName,
  candidateEmail,
  candidatePhone,
  lastCalled,
  testCall,
  allCalls,
  callComplete,
  webCall,
  candidateId,
  campaignId,
  showNavigationShortcut,
  candidateQuestions = [],
  needsTranslation,
  activeTab,
  setActiveTab,
  summary,
  recordingURL,
}) => {
  /* BEGIN PIN VIDEO LOGIC */
  const [columnHeight, setColumnHeight] = useState(0);
  const outerContainerRef = useRef<HTMLDivElement>(null);
  const [videoHeight, setVideoHeight] = useState(0);
  const [
    openedTranscriptChat,
    { open: openTranscriptChat, close: closeTranscriptChat },
  ] = useDisclosure(false);

  // Maintain whether user is scrolling to override auto‐scrolling
  const [userScrolling, setUserScrolling] = useState(false);

  // Current time in milliseconds from the phone or web call player
  const [currentTimeMs, setCurrentTimeMs] = useState(0);

  // If set to true below, we'll do "scrollIntoView" logic in TranscriptLines
  const scrollingProgrammatically = useRef(false);

  const handleTimeUpdate = (currentTime: number) => {
    setCurrentTimeMs(currentTime * 1000);
  };

  const [completionRateRingDisplay, setCompletionRateRingDisplay] = useState(0);
  const [overallGradeRingDisplay, setOverallGradeRingDisplay] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      setCompletionRateRingDisplay(completionRate || 0);
      setOverallGradeRingDisplay(overallGrade || 0);
    }, 10);
  }, [
    completionRate,
    overallGrade,
    completionRateRingDisplay,
    overallGradeRingDisplay,
  ]);

  // We'll store the current DOM node so we can remove listeners if it unmounts:
  const scrollableElementRef = useRef<HTMLDivElement | null>(null);

  // Observe scroll events. If user scrolls, we set userScrolling = true
  const scrollableCallbackRef = useCallback((node: HTMLDivElement | null) => {
    const onUserScrollStart = () => {
      setUserScrolling(true);
    };
    if (scrollableElementRef.current) {
      const oldNode = scrollableElementRef.current;
      oldNode.removeEventListener('wheel', onUserScrollStart);
      oldNode.removeEventListener('touchstart', onUserScrollStart);
      oldNode.removeEventListener('touchmove', onUserScrollStart);
      oldNode.removeEventListener('keydown', onUserScrollStart);
    }
    if (node) {
      node.addEventListener('wheel', onUserScrollStart, { passive: true });
      node.addEventListener('touchstart', onUserScrollStart, { passive: true });
      node.addEventListener('touchmove', onUserScrollStart, { passive: true });
      node.addEventListener('keydown', onUserScrollStart);
      scrollableElementRef.current = node;
    } else {
      scrollableElementRef.current = null;
    }
  }, []);

  const resizeObserverRef = useRef<ResizeObserver | null>(null);
  // This measures the overall height of the transcript content for pinning logic
  const measuredRef = useCallback((node: HTMLDivElement | null) => {
    if (resizeObserverRef.current) {
      resizeObserverRef.current.disconnect();
      resizeObserverRef.current = null;
    }
    if (node) {
      const observer = new ResizeObserver(([entry]) => {
        setColumnHeight(entry.contentRect.height);
      });
      observer.observe(node);
      // measure once
      setColumnHeight(node.getBoundingClientRect().height);
      resizeObserverRef.current = observer;
    }
  }, []);

  // Similarly track the size of the video container to do partial pin
  const videoContainerObserverRef = useRef<ResizeObserver | null>(null);
  const videoContainerCallbackRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (videoContainerObserverRef.current) {
        videoContainerObserverRef.current.disconnect();
        videoContainerObserverRef.current = null;
      }
      if (node) {
        const observer = new ResizeObserver(([entry]) => {
          setVideoHeight(entry.contentRect.height);
        });
        observer.observe(node);
        setVideoHeight(node.getBoundingClientRect().height);
        videoContainerObserverRef.current = observer;
      }
    },
    []
  );

  const email = localStorage.getItem('email') || '';
  const userId = localStorage.getItem('userId') || '';
  const isInternal = email.includes('salv.ai');
  const calApiKey = localStorage.getItem('calAPIKey');
  const [sentSchedule, setSentSchedule] = useState(false);

  const [isNavigationShortcutDissmissed, setIsNavigationShortcutDissmissed] =
    useState(() => {
      return localStorage.getItem('dismiss_navigation_shortcut') === 'true';
    });

  const { handleReviewChanges } = useCandidateCampaignReview();

  // This is the timestamp we jump to when user clicks a QA item or transcript line
  const [seekTimestamp, setSeekTimestamp] = useState<number | null>(null);

  useEffect(() => {
    const changesReviewed = {};
    switch (activeTab) {
      case TabOptions.CALL:
        changesReviewed['isCallReviewed'] = true;
        break;
      case TabOptions.SMS:
        changesReviewed['isSMSReviewed'] = true;
        break;
      case TabOptions.EMAIL:
        changesReviewed['isEmailReviewed'] = true;
        break;
      default:
        break;
    }

    handleReviewChanges({
      campaignId: campaignId || '',
      candidateId: candidateId || '',
      userId: userId,
      changesReviewed,
    });
  }, [activeTab, handleReviewChanges, campaignId, candidateId, userId]);

  // When a QA item or transcript line is clicked, we jump to that timestamp
  const onTimestampClick = (timestamp: number | null) => {
    if (timestamp !== null) {
      setSeekTimestamp(timestamp);
      // Force programmatic scroll & highlight
      setUserScrolling(false);
    }
  };

  const transcriptionCacheKey = `${candidateId}_${campaignId}`;
  const [resumeCache, setResumeCache] = useAtom(resumeCacheAtom);
  const resumeURL = resumeCache[transcriptionCacheKey]?.resumeURL;
  const resumeText = resumeCache[transcriptionCacheKey]?.resumeText;
  const showResume = !testCall && resumeURL;

  // fetch resume text if we have a resume url
  useEffect(() => {
    if (!showResume) {
      return;
    }
    const fetchResumeText = async () => {
      try {
        const response = await axios.get(
          `${env.REACT_APP_SERVER_URL}/candidate/${candidateId}/resume_text`
        );

        const { resume_text } = response.data;
        if (resume_text) {
          setResumeCache((prev) => ({
            ...prev,
            [transcriptionCacheKey]: {
              ...prev[transcriptionCacheKey],
              resumeText: resume_text,
            },
          }));
        }
      } catch (error) {
        console.error('Error fetching resume text:', error);
        notifications.show({
          title: 'There was an error retrieving resume text',
          message: error instanceof Error ? error.message : '',
          color: 'red',
        });
      }
    };
    fetchResumeText();
  }, [candidateId, setResumeCache, showResume, transcriptionCacheKey]);

  const renderResumePDF = useMemo(() => {
    // debugger; // Uncomment to debug
    if (!resumeURL) {
      return null;
    }

    return (
      <CandidateInfoDisplay
        candidate={{
          fullName: candidateName || '',
          phoneNumber: candidatePhone || '',
          email: candidateEmail || '',
          resumeUrl: resumeURL,
          resumeText: resumeText,
        }}
      />
    );
  }, [resumeURL, candidateName, candidatePhone, candidateEmail, resumeText]);

  const dismissNavigationShortcut = () => {
    localStorage.setItem('dismiss_navigation_shortcut', 'true');
    setIsNavigationShortcutDissmissed(true);
  };

  const [showTranslated, setShowTranslated] = useState(false);
  const [isTranslating, setIsTranslating] = useState(false);
  const [translatedTranscript, setTranslatedTranscript] = useState<
    TranscriptMessage[] | string
  >([]);
  const [
    translatedRequirementsGradesList,
    setTranslatedRequirementsGradesList,
  ] = useState<RequirementGrade[]>([]);
  const [translatedCandidateQuestions, setTranslatedCandidateQuestions] =
    useState<CandidateQuestion[]>([]);

  const [transcriptionCache, setTranscriptionCache] = useAtom(
    transcriptionCacheAtom
  );

  const handleTranslateTranscript = async (e) => {
    e.stopPropagation();
    if (isTranslating) {
      return;
    }

    if (showTranslated) {
      setShowTranslated(false);
      return;
    }

    if (transcriptionCache[transcriptionCacheKey]?.translatedTranscript) {
      handleSetTranslatedData({
        translatedTranscript:
          transcriptionCache[transcriptionCacheKey].translatedTranscript,
        translatedRequirementsGradesList:
          transcriptionCache[transcriptionCacheKey]
            .translatedRequirementGradesList,
        translatedCandidateQuestions:
          transcriptionCache[transcriptionCacheKey]
            .translatedCandidateQuestions,
      });
      return;
    }

    try {
      setIsTranslating(true);
      const response = await axios.post(
        `${env.REACT_APP_SERVER_URL}/translate_transcript`,
        {
          transcript,
          requirementGradesList,
          candidateQuestions: candidateQuestions || [],
        }
      );

      const {
        translated_transcript,
        translated_requirement_grades_list,
        translated_candidate_questions,
      } = response.data;

      setTranscriptionCache({
        data: {
          [transcriptionCacheKey]: {
            translatedTranscript: translated_transcript,
            translatedRequirementGradesList: translated_requirement_grades_list,
            translatedCandidateQuestions: translated_candidate_questions,
          },
        },
        timestamp: Date.now(),
      });

      handleSetTranslatedData({
        translatedTranscript: translated_transcript,
        translatedRequirementsGradesList: translated_requirement_grades_list,
        translatedCandidateQuestions: translated_candidate_questions,
      });
    } catch (error) {
      console.error('Error translating transcript:', error);
      notifications.show({
        title: 'There was an error translating the transcript',
        message: error instanceof Error ? error.message : '',
        color: 'red',
      });
    } finally {
      setIsTranslating(false);
    }
  };

  const handleSetTranslatedData = ({
    translatedTranscript,
    translatedRequirementsGradesList,
    translatedCandidateQuestions,
  }) => {
    setTranslatedTranscript(translatedTranscript);
    setTranslatedRequirementsGradesList(translatedRequirementsGradesList);
    setTranslatedCandidateQuestions(translatedCandidateQuestions);
    setShowTranslated(true);
  };

  const transcriptToUse = showTranslated ? translatedTranscript : transcript;
  const requirementGradesListToUse = showTranslated
    ? translatedRequirementsGradesList || []
    : requirementGradesList || [];
  const candidateQuestionsToUse = showTranslated
    ? translatedCandidateQuestions || []
    : candidateQuestions || [];

  const commentsScrollRef = useRef<HTMLDivElement>(null);
  const commentsInputRef = useRef<HTMLDivElement>(null);

  const scrollToComments = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();

    setTimeout(() => {
      if (commentsInputRef.current) {
        commentsInputRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      } else if (commentsScrollRef.current) {
        commentsScrollRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }, 100);
  }, []);

  /* ---------------------------
     QA / Results Column
  ----------------------------*/
  const renderResultsColumn = () => {
    if (!callId) {
      return (
        <div className='qa-column'>
          <h4
            className='candidate-name'
            style={{ marginTop: 0, marginBottom: 0 }}
          >
            {candidateName}
          </h4>

          <h4 style={{ margin: 'auto' }}> No Screening Results</h4>
        </div>
      );
    }
    return (
      <div className='qa-column'>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', gap: '17px', alignItems: 'center' }}>
            <h4
              className='candidate-name'
              style={{ marginTop: 0, marginBottom: 0 }}
            >
              {candidateName}
            </h4>
            {!testCall && campaignId && candidateId && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CandidateFeedback
                  campaignId={campaignId}
                  candidateId={candidateId}
                  userId={userId}
                  onCommentClick={scrollToComments}
                  inTranscript={true}
                />
              </div>
            )}
          </div>
          {!!calApiKey &&
            !!completionRate &&
            completionRate > 0 &&
            (email.includes('mason@salv.ai') ||
              email.includes('john@salv.ai')) && (
              <div
                style={{
                  zIndex: 1000,
                  position: 'relative',
                  marginLeft: '6px',
                }}
              >
                {!sentSchedule ? (
                  <Tooltip
                    openDelay={500}
                    label='AI will reach out over SMS to schedule next interview with the candidate'
                  >
                    <Button
                      size='xs'
                      variant='subtle'
                      onClick={(e) => {
                        e.stopPropagation();
                        setSentSchedule(true);
                      }}
                      leftSection={<IconCalendar size={18} />}
                    >
                      Schedule Next Interview
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    size='xs'
                    variant='subtle'
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    disabled={true}
                    leftSection={<IconCalendarCheck />}
                  >
                    Follow Up Requested
                  </Button>
                )}
              </div>
            )}
        </div>

        <div
          style={{
            display: 'flex',
            gap: '15%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
            <RingProgress
              size={65}
              thickness={6}
              roundCaps
              // transitionDuration={500}
              sections={[
                {
                  value: completionRateRingDisplay * 100,
                  color: 'var(--mantine-color-blue-4)',
                },
              ]}
              label={
                <Text c='blue' fw={700} ta='center' size='xs'>
                  {`${((completionRate || 0) * 100).toFixed(0)}%`}
                </Text>
              }
            />
            <div style={{ fontWeight: '500' }}>Completion Rate</div>
          </div>
          <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
            <RingProgress
              size={65}
              thickness={6}
              roundCaps
              // TODO: something in the UI is eating up the cpu
              // transitionDuration={500}
              sections={[
                {
                  value: overallGradeRingDisplay,
                  color: 'var(--mantine-color-blue-4)',
                },
              ]}
              label={
                <Text c='blue' fw={700} ta='center' size='xs'>
                  {`${(overallGrade || 0).toFixed(0)}%`}
                </Text>
              }
            />
            <div style={{ fontWeight: '500' }}>Grade</div>
          </div>
        </div>
        <Button
          onClick={openTranscriptChat}
          color='var(--mantine-color-blue-5)'
          leftSection={<IconMessageCircleSearch size={18} />}
          style={{ height: '32px', overflow: 'visible' }}
          className='start-chat-button'
          // NOTE: set height explicitly because it was dynamically shrinking
        >
          Ask anything about this interview
        </Button>

        <ScrollArea
          className='scrollable-content'
          style={{
            marginTop:
              '-10px' /* Found that less padding for inside looks slightly better when the top section is the summary, otherwise this is not needed */,
          }}
        >
          <div className='scroll-fade-top'></div>
          <div className='inside'>
            <TranscriptSummary
              candidateId={candidateId || ''}
              campaignId={campaignId}
              callId={callId}
              summary={summary}
            />
            <div style={{ height: '20px' }}></div>

            {(requirementGradesListToUse || []).map(
              ({ question, answer, grade, ts, title }, index) => (
                <div
                  key={index}
                  className={'qa-item' + (ts ? ' clickable-qa-item' : '')}
                  onClick={() => {
                    if (ts) {
                      onTimestampClick(ts);
                    }
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                    <div className={`grade ${isTranslating ? 'dimmed' : ''}`}>
                      {grade}
                    </div>
                    <div
                      className={`question ${isTranslating ? 'dimmed' : ''}`}
                    >
                      {title || question}
                    </div>
                  </div>
                  <div className={`answer ${isTranslating ? 'dimmed' : ''}`}>
                    {answer}
                  </div>
                </div>
              )
            )}
          </div>
          {(candidateQuestionsToUse || [])?.length > 0 && (
            <>
              <h3
                style={{
                  marginBottom: '16px',
                  fontSize: '16px',
                  fontWeight: '500',
                }}
              >
                Candidate Questions
              </h3>
              {(candidateQuestionsToUse || [])
                .filter(
                  ({ answer }) => answer && answer.toLowerCase() !== 'unknown'
                )
                .map(({ question, answer, question_ts }, index) => (
                  <div
                    key={`candidate-${index}`}
                    className={
                      'qa-item' + (question_ts ? ' clickable-qa-item' : '')
                    }
                    onClick={() => {
                      if (question_ts) {
                        onTimestampClick(question_ts);
                      }
                    }}
                  >
                    <div
                      className={`question ${isTranslating ? 'dimmed' : ''}`}
                      style={{ fontWeight: 'bold' }}
                    >
                      {question}
                    </div>
                    <div className={`answer ${isTranslating ? 'dimmed' : ''}`}>
                      {answer}
                    </div>
                  </div>
                ))}
            </>
          )}
          {!testCall && (
            <div ref={commentsScrollRef}>
              <Comments
                campaignId={campaignId || ''}
                candidateId={candidateId || ''}
                userId={userId}
                inputRef={commentsInputRef} // Passamos a ref para o componente Comments
              />
            </div>
          )}
          <div className='scroll-fade-bottom'></div>
        </ScrollArea>
        <TranscriptChat
          opened={openedTranscriptChat}
          onClose={closeTranscriptChat}
          campaignId={campaignId || ''}
          singleCallId={callId}
        />
      </div>
    );
  };

  const callToTimeDisplay = (call: any) => {
    if (call.call_status !== 'PENDING') {
      return formatToLocalTime(call.last_updated, true);
    } else {
      if (
        call.scheduled_datetime &&
        call.call_initiated_by === 'CANDIDATE_RESCHEDULED'
      ) {
        return (
          formatToLocalTime(call.scheduled_datetime, true) + ' (schdeuled)'
        );
      } else {
        return '';
      }
    }
  };

  // Clicking "resume auto‐scroll" button
  const onResumeAutoScroll = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setUserScrolling(false);
    },
    []
  );

  /* ---------------------------
     RETURN
  ----------------------------*/

  return (
    <Box className='transcript-container' key={candidateId + callId}>
      <Tabs
        variant='pills'
        value={activeTab}
        onChange={(value) => {
          if (isTab(value)) setActiveTab(value);
        }}
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          zIndex: 10001,
        }}
      >
        <div
          style={{
            flex: 1,
            overflow: testCall ? 'hidden' : 'unset',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingTop: '6px',
            }}
          >
            <Tabs.List>
              <Tabs.Tab
                value={TabOptions.CALL}
                classNames={{
                  tab: 'header-tab',
                }}
              >
                {!testCall && (
                  <ReviewIndicator
                    campaignId={campaignId || ''}
                    candidateId={candidateId || ''}
                    review='Call'
                  />
                )}
                {!webCall ? 'Phone Call' : 'Video Call'}
              </Tabs.Tab>
              {!testCall && !webCall && (
                <Tabs.Tab
                  value={TabOptions.SMS}
                  classNames={{
                    tab: 'header-tab',
                  }}
                >
                  <ReviewIndicator
                    campaignId={campaignId || ''}
                    candidateId={candidateId || ''}
                    review='SMS'
                  />
                  SMS
                </Tabs.Tab>
              )}
              {isInternal && !webCall && (
                <Tabs.Tab
                  value={TabOptions.EMAIL}
                  classNames={{
                    tab: 'header-tab',
                  }}
                >
                  <ReviewIndicator
                    campaignId={campaignId || ''}
                    candidateId={candidateId || ''}
                    review='Email'
                  />
                  Email
                </Tabs.Tab>
              )}
              {!testCall && showResume && (
                <Tabs.Tab
                  value={TabOptions.RESUME}
                  classNames={{
                    tab: 'header-tab',
                  }}
                >
                  Resume
                </Tabs.Tab>
              )}
            </Tabs.List>
          </div>

          {/* ---------- SMS Tab Panel ---------- */}
          <Tabs.Panel
            value={TabOptions.SMS}
            style={{ height: 'calc(100vh - 90px)', padding: 0, margin: 0 }}
          >
            <SMSTab
              candidateId={candidateId}
              campaignId={campaignId}
              allCalls={allCalls}
              userScrolling={userScrolling}
              scrollingProgrammatically={scrollingProgrammatically}
            />
          </Tabs.Panel>

          {/* ---------- CALL Tab Panel ---------- */}
          <Tabs.Panel
            value={TabOptions.CALL}
            style={{ height: 'calc(100% - 40px)', padding: 0, margin: 0 }}
          >
            {!allCalls?.length && !testCall && (
              <div className='transcript-column'>
                <h4 style={{ margin: 'auto' }}> No Call Results</h4>
              </div>
            )}
            {!!allCalls?.length && !callComplete && !testCall && (
              <div className='transcript-column calls-container'>
                <h3>Call History</h3>
                <ScrollArea className='scrollable-content'>
                  {allCalls.map((c, idx) => (
                    <div
                      key={idx}
                      style={{
                        display: 'flex',
                        padding: '14px',
                        gap: '8px',
                        fontSize: '14px',
                        justifyContent: 'space-between',
                        marginRight: '25%',
                        border: '1px solid var(--salv-dark-1)',
                        borderRadius: '8px',
                        marginBottom: '10px',
                        opacity:
                          c.call_status === 'PENDING' ||
                          c.call_status === 'CANCELLED'
                            ? 0.4
                            : 1,
                      }}
                    >
                      <div style={{ fontWeight: '600', fontSize: '16px' }}>
                        {convertUpperSnakeToTitle(c.call_status)}
                      </div>
                      <div style={{ opacity: 0.7 }}>{callToTimeDisplay(c)}</div>
                    </div>
                  ))}
                </ScrollArea>
              </div>
            )}

            {callComplete && (
              <div
                className={`transcript-column ${
                  webCall ? 'video' : 'phone'
                }-transcript-column`}
              >
                {!!callId && callComplete && (
                  <div
                    className={
                      columnHeight < MIN_VISIBLE_TRANSCRIPT_HEIGHT
                        ? 'scrollable-container overflow-y-auto'
                        : 'scrollable-container'
                    }
                    ref={outerContainerRef}
                  >
                    <div
                      ref={videoContainerCallbackRef}
                      className={
                        webCall && columnHeight > MIN_VISIBLE_TRANSCRIPT_HEIGHT
                          ? 'video-container-sticky'
                          : ''
                      }
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginTop: '6px',
                        }}
                      >
                        {lastCalled && (
                          <div style={{ padding: '8px', fontSize: '12px' }}>
                            {`Call from ${formatToLocalTime(lastCalled, true)}`}
                          </div>
                        )}
                        {needsTranslation && (
                          <Fragment>
                            {isTranslating ? (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'baseline',
                                  gap: '2px',
                                }}
                              >
                                <Text c='blue' size='xs' fw={600}>
                                  Translating
                                </Text>
                                <Loader
                                  size='xs'
                                  style={{ marginRight: '8px' }}
                                  type='dots'
                                  color='blue'
                                />
                              </div>
                            ) : (
                              <Button
                                size='xs'
                                variant='subtle'
                                onClick={handleTranslateTranscript}
                              >
                                {showTranslated
                                  ? 'Translated - Show Original'
                                  : 'Translate to English'}
                              </Button>
                            )}
                          </Fragment>
                        )}
                      </div>
                      {webCall ? (
                        <WebCallRecordingPlayer
                          callId={callId}
                          seekTimestamp={seekTimestamp}
                          testCall={testCall}
                          onTimeUpdate={handleTimeUpdate}
                          recordingURL={recordingURL}
                        />
                      ) : (
                        <PhoneCallRecordingPlayer
                          callId={callId}
                          seekTimestamp={seekTimestamp}
                          webAudio={false}
                          onTimeUpdate={handleTimeUpdate}
                          recordingURL={recordingURL}
                        />
                      )}
                    </div>

                    <div
                      className={
                        columnHeight > MIN_VISIBLE_TRANSCRIPT_HEIGHT
                          ? 'transcript-box overflow-y-auto'
                          : 'transcript-box'
                      }
                      style={{
                        height:
                          columnHeight > MIN_VISIBLE_TRANSCRIPT_HEIGHT
                            ? `calc(100% - ${videoHeight}px)`
                            : 'auto',
                        position: 'absolute',
                      }}
                      ref={scrollableCallbackRef}
                    >
                      <TranscriptLines
                        transcript={transcriptToUse}
                        sms={false}
                        onTimestampClick={onTimestampClick}
                        clickable={true}
                        currentTimeMs={currentTimeMs}
                        userScrolling={userScrolling}
                        scrollingProgrammatically={scrollingProgrammatically}
                        columnHeight={columnHeight}
                        outerContainer={outerContainerRef}
                        autoScroll={true}
                        isTranslating={isTranslating}
                      />
                    </div>

                    {/* Invisible measured box */}
                    <div
                      className={'transcript-box overflow-y-auto'}
                      style={{
                        height: `calc(100% - ${videoHeight}px)`,
                        position: 'absolute',
                        visibility: 'hidden',
                      }}
                      ref={measuredRef}
                    >
                      <TranscriptLines
                        transcript={transcript}
                        sms={false}
                        onTimestampClick={onTimestampClick}
                        clickable={true}
                        currentTimeMs={currentTimeMs}
                        userScrolling={userScrolling}
                        scrollingProgrammatically={scrollingProgrammatically}
                        columnHeight={columnHeight}
                        outerContainer={outerContainerRef}
                        autoScroll={false} // doesn't matter, it's hidden anyway
                        isTranslating={isTranslating}
                      />
                    </div>
                  </div>
                )}
                <AutoScrollButton
                  userScrolling={userScrolling}
                  onResumeAutoScroll={onResumeAutoScroll}
                  {...(testCall && { styleOverride: { bottom: '0px' } })}
                />
                <div
                  className='scroll-fade-bottom'
                  style={{ height: '45px' }}
                ></div>
              </div>
            )}
          </Tabs.Panel>

          {/* ---------- EMAIL Tab Panel ---------- */}
          <Tabs.Panel
            value={TabOptions.EMAIL}
            style={{ height: 'calc(100% - 90px)', padding: 0, margin: 0 }}
          >
            <EmailHistory candidateId={candidateId} campaignId={campaignId} />
          </Tabs.Panel>

          {/* ---------- RESUME Tab Panel ---------- */}
          <Tabs.Panel
            value={TabOptions.RESUME}
            style={{ height: 'calc(100vh - 90px)', padding: 0, margin: 0 }}
          >
            <div className='transcript-column'>
              <ScrollArea
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {renderResumePDF}
              </ScrollArea>
            </div>
          </Tabs.Panel>
        </div>
      </Tabs>

      {/* Results Column */}
      {renderResultsColumn()}

      {/* Keyboard Shortcut Prompt */}
      {showNavigationShortcut && !isNavigationShortcutDissmissed && (
        <Box className='keyboard-shortcut-container'>
          <div className='keyboard-shortcut-content'>
            Use
            <Kbd style={{ margin: '0px 3px 0px 6px' }}>&uarr;</Kbd>
            <Kbd style={{ margin: '0px 6px 0px 3px' }}>&darr;</Kbd>
            to navigate
          </div>
          <CloseButton onClick={dismissNavigationShortcut} />
        </Box>
      )}
    </Box>
  );
};

export default Transcript;
