import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  Center,
  Modal,
  Select,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { IconPhone, IconVideo, IconMail } from '@tabler/icons-react';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { useDisclosure } from '@mantine/hooks';
import { v4 as uuidv4 } from 'uuid';
import axios from 'api/axiosConfig';
import env from 'env';
import { encodeData } from 'pages/web-call/utils';

import Transcript, {
  Tab,
  TabOptions,
} from '../../../components/transcript/Transcript';

// Define a generic form type with at least a voiceName field
interface VoiceNameForm {
  values: {
    voiceName: string;
    [key: string]: any;
  };
  getInputProps: (path: string) => any;
  setFieldValue: (path: string, value: any) => void;
}

interface TestCallModalProps {
  campaignId: string;
  isWebCall: boolean;
  scheduleFollowUp: boolean;
  verifyCallOutro: () => boolean;
  webCallEmailBodyValid?: boolean;
  disabled?: boolean;
  // Use the generic VoiceNameForm type
  scriptInfo: VoiceNameForm;
  emailToSendTestEmail?: string;
  buttonSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  autoOpenModal?: boolean;
  noOverlay?: boolean;
  noTransition?: boolean;
  onOverallClose?: () => void;
  onTranscriptClose?: () => void;
}

const TestCallModal: React.FC<TestCallModalProps> = ({
  campaignId,
  isWebCall,
  scheduleFollowUp,
  verifyCallOutro,
  webCallEmailBodyValid = true,
  disabled = false,
  scriptInfo,
  emailToSendTestEmail,
  buttonSize,
  autoOpenModal = false,
  noOverlay = false,
  noTransition = false,
  onOverallClose,
  onTranscriptClose,
}) => {
  // State for modals
  const [testCallModalOpen, setTestCallModalOpen] = useState<boolean>(false);
  const [testWebCallModalOpen, setTestWebCallModalOpen] =
    useState<boolean>(false);

  // State for test call status
  const [testCallActive, setTestCallActive] = useState<boolean>(false);
  const [webCallActive, setWebCallActive] = useState<boolean>(false);
  const [callId, setCallId] = useState<string | null>(null);
  const [callStatusMsg, setCallStatusMsg] = useState<string>('');

  // State for transcript and results
  const [transcript, setTranscript] = useState<string>('');
  const [requirementGradesList, setRequirementGradesList] = useState<any>([]);
  const [candidateQuestions, setCandidateQuestions] = useState<any>([]);
  const [overallGrade, setOverallGrade] = useState(0);
  const [completionRate, setCompletionRate] = useState(0);
  const [finishedCallAt, setFinishedCallAt] = useState('');
  const [activeTab, setActiveTab] = useState<Tab>(TabOptions.CALL);
  const [summary, setSummary] = useState<string>('');

  // State for email sending
  const [sendingLinkEmail, setSendingLinkEmail] = useState<boolean>(false);

  // Candidate timezone
  const [candidateTimezone, setCandidateTimezone] = useState<string>(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  // Transcript modal controls
  const [transcriptOpened, { open: openTranscript, close: closeTranscript }] =
    useDisclosure(false);

  // Load stored values
  let lastTestPhoneNumber = localStorage.getItem('lastTestPhoneNumber') || '';
  let lastTestPhoneName = localStorage.getItem('lastTestPhoneName') || '';
  const userEmail = localStorage.getItem('email') || '';

  // Form for test call info
  const testCallInfo = useForm({
    initialValues: {
      candidateName: lastTestPhoneName,
      phoneNumber: lastTestPhoneNumber,
      candidateEmail: emailToSendTestEmail ? emailToSendTestEmail : userEmail,
    },
  });

  // Effect to open transcript when call is complete
  useEffect(() => {
    if (testCallActive === false && webCallActive === false && transcript) {
      openTranscript();
    }
  }, [testCallActive, webCallActive, transcript, openTranscript]);

  // WebSocket connection for call status updates
  useEffect(() => {
    let ws: WebSocket | null = null;
    if (callId && (testCallActive || webCallActive)) {
      ws = new WebSocket(`${env.REACT_APP_WS_URL}/ws/call_status/${callId}`);

      ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.type === 'event_transcript') {
          setTranscript(data.transcript);
          setRequirementGradesList(data.requirement_grades_list);
          setCandidateQuestions(data.candidate_questions);
          setSummary(data.summary);
          const currentUtcTime = new Date().toISOString();
          setCompletionRate(data.question_completion_rate);
          setOverallGrade(data.overall_grade);
          setFinishedCallAt(currentUtcTime);
          setTestCallActive(false);
          setWebCallActive(false);
        } else if (data.type === 'event_phone_call_ended') {
          setCallStatusMsg('Call complete. Extracting answers...');
        } else if (data.type === 'event_phone_call_connected') {
          setCallStatusMsg(
            `On the phone with ${testCallInfo.values.candidateName}...`
          );
        }
      };

      ws.onerror = (error) => {
        console.error('WebSocket error:', error);
      };
    }

    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, [
    callId,
    testCallActive,
    webCallActive,
    testCallInfo.values.candidateName,
  ]);

  // Reset test call results
  const resetTestCallResults = () => {
    setCallId('');
    setTranscript('');
    setCallStatusMsg('');
    setSummary('');
  };

  // Handle test phone call
  const handleTestCall = async (e: React.FormEvent) => {
    resetTestCallResults();
    e.preventDefault();

    if (verifyCallOutro()) {
      return;
    }

    localStorage.setItem(
      'lastTestPhoneName',
      testCallInfo.values.candidateName
    );
    localStorage.setItem(
      'lastTestPhoneNumber',
      testCallInfo.values.phoneNumber
    );

    setTestCallActive(true);
    setCallStatusMsg(`Setting up voice agent...`);

    const payload: Record<string, any> = {
      campaignId,
      candidateName: testCallInfo.values.candidateName,
      phoneNumber: testCallInfo.values.phoneNumber,
      voiceName: scriptInfo.values.voiceName,
    };

    if (candidateTimezone) {
      payload.candidateTimezone = candidateTimezone;
    }

    try {
      const response = await axios.post(
        `${env.REACT_APP_SERVER_URL}/test_call`,
        payload
      );
      setCallId(response.data.data.call_id);
      setCallStatusMsg(`Calling ${testCallInfo.values.candidateName}...`);
    } catch (error) {
      console.error('Error initiating call:', error);
      setTestCallActive(false);
      setCallStatusMsg('Failed to initiate call. Please try again.');
    }
  };

  // Handle test web call
  const handleTestWebCall = async () => {
    resetTestCallResults();
    const newCallId = '' + uuidv4();
    const data: Record<string, any> = {
      campaignId: campaignId,
      candidateName: testCallInfo.values.candidateName,
      voiceName: scriptInfo.values.voiceName,
      defaultId: newCallId,
    };
    setCallId(newCallId);
    setTestCallActive(true);
    setCallStatusMsg(`Test call environment created...`);
    if (candidateTimezone) {
      data.candidateTimezone = candidateTimezone;
    }
    const encodedData = encodeData(data);
    const base_url = env.REACT_APP_INTERVIEW_REDIRECT_URL;
    const link_url = `${base_url}/web-interview/test-call/${encodedData}`;
    window.open(link_url, '_blank', 'noopener,noreferrer');
  };

  // Handle web call email
  const handleWebCallEmail = async () => {
    if (!webCallEmailBodyValid) {
      notifications.show({
        title: 'Missing Interview Link',
        message:
          'Your email body must include {interview_link} so candidates can access the interview.',
        color: 'red',
      });
      return;
    }

    const payload: Record<string, any> = {
      candidateEmail: testCallInfo.values.candidateEmail,
      campaignId: campaignId,
      candidateName: testCallInfo.values.candidateName,
      voiceName: scriptInfo.values.voiceName,
    };
    if (candidateTimezone) {
      payload.candidateTimezone = candidateTimezone;
    }
    try {
      setSendingLinkEmail(true);
      const response = await axios.post(
        `${env.REACT_APP_SERVER_URL}/send_web_call_test_email`,
        payload
      );
      notifications.show({
        title: 'Email sent successfully',
        message: `Email sent to ${testCallInfo.values.candidateEmail}`,
        color: 'green',
      });
      setCallId(response.data.call_id);
      setTestCallActive(true);
      setCallStatusMsg('Test email sent');
    } catch (error) {
      console.error('Error sending email:', error);
    } finally {
      setSendingLinkEmail(false);
    }
  };

  // Call Status component
  const CallStatus = () => {
    const closeButtonHeight = 40;
    return (
      <Modal
        opened={testCallActive}
        onClose={() => {
          setTestCallActive(false);
        }}
        centered
        size='xl'
        withCloseButton
        closeOnClickOutside={false}
      >
        <Center
          style={{
            height: `calc(100% - ${closeButtonHeight}px)`,
            paddingBottom: `${closeButtonHeight}px`,
          }}
        >
          <Text ta='center' fw={700} style={{ fontSize: '40px' }}>
            {callStatusMsg}
          </Text>
        </Center>
      </Modal>
    );
  };

  // Render trigger buttons (for external use)
  const renderTriggerButtons = () => (
    <>
      {!isWebCall && (
        <Button
          variant='light'
          leftSection={<IconPhone size={20} />}
          onClick={() => {
            if (verifyCallOutro()) {
              return;
            }
            setTestCallModalOpen(true);
          }}
          className={'campaign-action-button'}
          disabled={disabled}
          {...(buttonSize ? { size: buttonSize } : {})}
        >
          Test Call
        </Button>
      )}
      {isWebCall && (
        <Button
          variant='light'
          leftSection={<IconVideo size={20} />}
          onClick={() => {
            if (verifyCallOutro()) {
              return;
            }
            setTestWebCallModalOpen(true);
          }}
          className={'campaign-action-button'}
          disabled={disabled}
          {...(buttonSize ? { size: buttonSize } : {})}
        >
          Test Video Call
        </Button>
      )}
    </>
  );

  // Conditional auto-open
  useEffect(() => {
    if (autoOpenModal && !disabled) {
      // const timer = setTimeout(() => {
      //   if (isWebCall) {
      //     setTestWebCallModalOpen(true);
      //   } else {
      //     setTestCallModalOpen(true);
      //   }
      // }, 100);
      if (isWebCall) {
        setTestWebCallModalOpen(true);
      } else {
        setTestCallModalOpen(true);
      }

      // return () => clearTimeout(timer);
    }
  }, [autoOpenModal, disabled, isWebCall]);

  return (
    <>
      {/* Phone Call Modal */}
      <Modal
        opened={testCallModalOpen}
        onClose={() => {
          setTestCallModalOpen(false);
          onOverallClose?.();
        }}
        centered
        title='Test Call'
        overlayProps={noOverlay ? { opacity: 0 } : undefined}
        transitionProps={noTransition ? { duration: 0 } : undefined}
      >
        <Card shadow='sm' miw={300}>
          <Stack>
            <TextInput
              label='Candidate Name'
              placeholder='John Doe'
              required
              {...testCallInfo.getInputProps('candidateName')}
            />
            <TextInput
              label='Candidate Phone Number'
              placeholder='123-456-7890'
              required
              {...testCallInfo.getInputProps('phoneNumber')}
            />
            <Select
              label='Voice'
              data={[
                'Lisa (American)',
                'George (American)',
                'Abby (British)',
                'William (British)',
              ]}
              placeholder='Select a voice'
              nothingFoundMessage='No matching voices'
              {...scriptInfo.getInputProps('voiceName')}
              allowDeselect={false}
            />
            <Select
              label='Timezone'
              value={candidateTimezone}
              onChange={(value) => setCandidateTimezone(value || '')}
              data={[
                'America/New_York',
                'America/Chicago',
                'America/Denver',
                'America/Los_Angeles',
              ]}
              placeholder='Select a timezone'
              nothingFoundMessage='No matching timezones'
              allowDeselect={false}
            />
            <br />
            <Button
              variant='light'
              leftSection={<IconPhone size={20} />}
              onClick={handleTestCall}
              size='lg'
            >
              Test Call
            </Button>
          </Stack>
        </Card>
      </Modal>

      {/* Web Call Modal */}
      <Modal
        opened={testWebCallModalOpen}
        onClose={() => {
          setTestWebCallModalOpen(false);
          onOverallClose?.();
        }}
        centered
        title='Test Video Call'
        overlayProps={noOverlay ? { opacity: 0 } : undefined}
        transitionProps={noTransition ? { duration: 0 } : undefined}
      >
        <Card shadow='sm' miw={300}>
          <Stack>
            <TextInput
              label='Candidate Name'
              placeholder='John Doe'
              required
              {...testCallInfo.getInputProps('candidateName')}
            />
            <Select
              label='Voice'
              data={[
                'Lisa (American)',
                'George (American)',
                'Abby (British)',
                'William (British)',
              ]}
              placeholder='Select a voice'
              nothingFoundMessage='No matching voices'
              {...scriptInfo.getInputProps('voiceName')}
              allowDeselect={false}
            />
            {scheduleFollowUp && (
              <Select
                label='Timezone'
                value={candidateTimezone}
                onChange={(value) => setCandidateTimezone(value || '')}
                data={[
                  'America/New_York',
                  'America/Chicago',
                  'America/Denver',
                  'America/Los_Angeles',
                ]}
                placeholder='Select a timezone'
                nothingFoundMessage='No matching timezones'
                allowDeselect={false}
              />
            )}
            <Button
              variant='light'
              leftSection={<IconVideo size={20} />}
              onClick={handleTestWebCall}
              disabled={!testCallInfo.values.candidateName}
              size='md'
            >
              Test Video Call
            </Button>
            <br />
            <TextInput
              label='Candidate Email'
              placeholder='examplecandidate@gmail.com'
              required
              {...testCallInfo.getInputProps('candidateEmail')}
            />
            <Button
              variant='light'
              leftSection={<IconMail size={20} />}
              onClick={handleWebCallEmail}
              disabled={
                !testCallInfo.values.candidateName ||
                !testCallInfo.values.candidateEmail ||
                sendingLinkEmail
              }
              size='md'
            >
              Send Test Email
            </Button>
          </Stack>
        </Card>
      </Modal>

      {/* Active Call Status Modal */}
      {testCallActive && <CallStatus />}
      {webCallActive && <CallStatus />}

      {/* Transcript Modal */}
      <Modal
        opened={transcriptOpened}
        onClose={() => {
          closeTranscript();
          resetTestCallResults();
          onTranscriptClose?.();
        }}
        centered
        size='80%'
        styles={{
          header: {
            backgroundColor: 'transparent',
          },
        }}
      >
        {callId && (
          <Transcript
            transcript={transcript}
            requirementGradesList={requirementGradesList}
            candidateQuestions={candidateQuestions}
            callId={callId}
            lastCalled={finishedCallAt}
            overallGrade={overallGrade}
            completionRate={completionRate}
            testCall={true}
            candidateName={testCallInfo.values.candidateName}
            callComplete={true}
            webCall={isWebCall}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            summary={summary}
          />
        )}
      </Modal>

      {/* Expose buttons as component content */}
      {renderTriggerButtons()}
    </>
  );
};

export default TestCallModal;
