import React, { useEffect, useState } from 'react';
import { Button, Loader, Tabs, Text } from '@mantine/core';
import env from 'env';
import axios from 'api/axiosConfig';
import { useParams } from 'react-router-dom';
import { notifications } from '@mantine/notifications';
import { IconArrowBack } from '@tabler/icons-react';

import AnalysisTab from './AnalysisTab';
import AllQuestionsTab from './AllQuestionsTab';
import UnansweredQuestionsTab from './UnansweredQuestionsTab';

export interface CampaignQuestion {
  question_id: string;
  question: string;
  answer: string;
  category: string;
  is_answered: boolean;
  color: string;
}

const TabOptions = {
  UNANSWERED: 'unanswered',
  ALL: 'all',
  ANALYSIS: 'analysis',
} as const;

type Tab = (typeof TabOptions)[keyof typeof TabOptions];

const isTab = (value: any): value is Tab => {
  return Object.values(TabOptions).includes(value);
};

const QuestionsPage = ({ setScriptActiveTab }) => {
  const { campaignId } = useParams();
  const [activeTab, setActiveTab] = useState<Tab>(TabOptions.UNANSWERED);
  const [allQuestions, setAllQuestions] = useState<CampaignQuestion[]>([]);
  const [unansweredQuestions, setUnansweredQuestions] = useState<
    CampaignQuestion[]
  >([]);
  const [analysisData, setAnalysisData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    const fetchQuestionsData = async () => {
      try {
        setIsFetching(true);
        const response = await axios.get(
          `${env.REACT_APP_SERVER_URL}/campaigns/${campaignId}/questions`
        );

        const {
          all_questions,
          unanswered_questions,
          questions_analytics_data,
        } = response.data;
        if (all_questions && unanswered_questions && questions_analytics_data) {
          setAllQuestions(all_questions);
          setUnansweredQuestions(unanswered_questions);
          setAnalysisData(questions_analytics_data);
          if (unanswered_questions.length < 1) {
            setActiveTab(TabOptions.ALL);
          }
        }
      } catch (error) {
        console.error('Error fetching questions:', error);
        notifications.show({
          title: 'Error',
          message: 'Failed to fetch questions data',
          color: 'red',
        });
      } finally {
        setIsFetching(false);
      }
    };

    fetchQuestionsData();
  }, [campaignId]);

  const handleUnansweredQuestions = async (question_ids: string[]) => {
    if (question_ids.length < 1) {
      return;
    }

    try {
      await axios.post(
        `${env.REACT_APP_SERVER_URL}/mark_questions_as_answered`,
        { question_ids }
      );

      setUnansweredQuestions((prev) =>
        prev.filter((question) => !question_ids.includes(question.question_id))
      );
      setAllQuestions((prev) =>
        prev.map((question) => {
          if (question_ids.includes(question.question_id)) {
            return { ...question, is_answered: true };
          }
          return question;
        })
      );

      notifications.show({
        title: 'Success',
        message: (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <Text size='sm'>Questions marked as answered successfully</Text>
            <Text size='sm'>
              You can undo this action by clicking the button below.
            </Text>
            <Button
              variant='light'
              size='xs'
              style={{ alignSelf: 'flex-end' }}
              leftSection={<IconArrowBack size={14} />}
              onClick={() => {
                notifications.clean();
                handleUndoMarkAsAnswered(question_ids);
              }}
            >
              Undo
            </Button>
          </div>
        ),
        color: 'green',
        autoClose: 15000,
      });
    } catch (error) {
      console.error('Error marking questions as unanswered:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to mark questions as answered',
        color: 'red',
      });
    }
  };

  const handleUndoMarkAsAnswered = async (question_ids: string[]) => {
    if (question_ids.length < 1) {
      return;
    }

    try {
      await axios.post(
        `${env.REACT_APP_SERVER_URL}/mark_questions_as_unanswered`,
        { question_ids }
      );

      setUnansweredQuestions((prev) =>
        prev.concat(
          allQuestions.filter((question) =>
            question_ids.includes(question.question_id)
          )
        )
      );

      setAllQuestions((prev) =>
        prev.map((question) => {
          if (question_ids.includes(question.question_id)) {
            return { ...question, is_answered: false };
          }
          return question;
        })
      );

      notifications.show({
        title: 'Success',
        message: 'Questions marked as unanswered successfully',
        color: 'green',
      });
    } catch (error) {
      console.error('Error marking questions as unanswered:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to mark questions as unanswered',
        color: 'red',
      });
    }
  };

  if (isFetching) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Loader />
      </div>
    );
  }

  return (
    <div style={{ marginTop: '20px' }}>
      <Tabs
        color='gray'
        variant='pills'
        value={activeTab}
        onChange={(value) => {
          if (isTab(value)) {
            setActiveTab(value);
          }
        }}
        style={{
          flex: 1,
          display: 'flex',
          gap: '20px',
          height: '100%',
        }}
        orientation='vertical'
      >
        <Tabs.List>
          <Tabs.Tab
            value={TabOptions.UNANSWERED}
            classNames={{
              tab: 'header-tab',
            }}
          >
            {`Unanswered (${unansweredQuestions.length})`}
          </Tabs.Tab>
          <Tabs.Tab
            value={TabOptions.ALL}
            classNames={{
              tab: 'header-tab',
            }}
          >
            All Questions
          </Tabs.Tab>
          <Tabs.Tab
            value={TabOptions.ANALYSIS}
            classNames={{
              tab: 'header-tab',
            }}
          >
            Analysis
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value={TabOptions.UNANSWERED}>
          <UnansweredQuestionsTab
            questions={unansweredQuestions}
            handleUnansweredQuestions={handleUnansweredQuestions}
            setScriptActiveTab={setScriptActiveTab}
          />
        </Tabs.Panel>
        <Tabs.Panel value={TabOptions.ALL}>
          <AllQuestionsTab questions={allQuestions} />
        </Tabs.Panel>
        <Tabs.Panel value={TabOptions.ANALYSIS}>
          <AnalysisTab data={analysisData} />
        </Tabs.Panel>
      </Tabs>
    </div>
  );
};

export default QuestionsPage;
