import { Anchor, Button, Center, Group, Menu, Text } from '@mantine/core';
import React, { useContext } from 'react';
import {
  IconCalendarPlus,
  IconEye,
  IconFilterPlus,
  IconMapPin,
  TablerIcon,
} from '@tabler/icons-react';

import LocationFilter from './LocationFilter';
import { FilterType, JobPostingFilterType, CampaignFilterType } from './types';
import AgencyFilter from './AgencyFilter';
import ClientRevenueFilter from './ClientRevenueFilter';
import { FilterContext } from './FilterContext';
import ScoreFilter from './ScoreFilter';
import DateFilter from './DateFilter';
import ClientFilter from './ClientFilter';
import CallStatusFilter from './CallStatusFilter';
import CallLengthFilter from './CallLengthFilter';
import ScoreContactFilter from './ScoreContactFilter';
import CompletionFilter from './CompletionFilter';
import SMSStatusFilter from './SMSStatusFilter';
import FeedbackFilter from './FeedbackFilter';
import NumberOfCallsFilter from './NumberOfCallsFilter';
import UnreadStatusFilter from './UnreadStatusFilter';

const JOB_POSTING_FILTER_LABELS: Record<
  JobPostingFilterType,
  { label: string; Icon?: TablerIcon }
> = {
  location: {
    label: 'Location',
    Icon: IconMapPin,
  },
  client: {
    label: 'Client',
  },
  revenue: {
    label: 'Revenue',
  },
  agency: {
    label: 'Agency',
  },
  score: {
    label: 'Score',
  },
  posted: {
    label: 'Date Posted',
    Icon: IconCalendarPlus,
  },
  seen: {
    label: 'Last Seen',
    Icon: IconEye,
  },
};

const CAMPAIGN_FILTER_LABELS: Record<
  CampaignFilterType,
  { label: string; Icon?: TablerIcon }
> = {
  callStatus: {
    label: 'Call Status',
  },
  callLength: {
    label: 'Call Length',
  },
  scoreContact: {
    label: 'Score',
  },
  completion: {
    label: 'Completion',
  },
  lastCalled: {
    label: 'Last Called',
  },
  smsStatus: {
    label: 'SMS Status',
  },
  feedback: {
    label: 'Feedback',
  },
  numberOfCalls: { label: 'Number of Calls' },
  unreadStatus: { label: 'Unread Status' },
};

interface FilterBarProps {
  context?: 'job-posting' | 'campaign';
}

export default function FilterBar({ context = 'job-posting' }: FilterBarProps) {
  const filterState = useContext(FilterContext);
  if (!filterState) return null;

  const FILTER_LABELS =
    context === 'job-posting'
      ? JOB_POSTING_FILTER_LABELS
      : CAMPAIGN_FILTER_LABELS;

  const renderFilter = (filter: {
    type: FilterType;
    state: any;
    label: string;
  }) => {
    const { type, state, label } = filter;
    const { Icon } = FILTER_LABELS[type as keyof typeof FILTER_LABELS] || {
      label: '',
    };
    // not sure if the intention was to use labelFromConfig || label

    const handleClear = () => {
      filterState.removeFilter(type);
    };

    const handleClose = () => {
      // if (filter.state.isEmpty) filterState.removeFilter(filter.type);
    };

    const commonProps = {
      key: type,
      onClear: handleClear,
      onClose: handleClose,
    };

    switch (type) {
      case 'location':
        return <LocationFilter locationFilterState={state} {...commonProps} />;
      case 'client':
        return <ClientFilter clientFilterState={state} {...commonProps} />;
      case 'agency':
        return <AgencyFilter agencyFilterState={state} {...commonProps} />;
      case 'revenue':
        return (
          <ClientRevenueFilter revenueFilterState={state} {...commonProps} />
        );
      case 'score':
        return <ScoreFilter scoreFilterState={state} {...commonProps} />;
      case 'posted':
        return (
          <DateFilter
            title={label}
            Icon={Icon}
            dateFilterState={state}
            {...commonProps}
          />
        );
      case 'seen':
        return (
          <DateFilter
            title={label}
            Icon={Icon}
            dateFilterState={state}
            {...commonProps}
          />
        );
      case 'callStatus':
        return (
          <CallStatusFilter callStatusFilterState={state} {...commonProps} />
        );
      case 'callLength':
        return (
          <CallLengthFilter callLengthFilterState={state} {...commonProps} />
        );
      case 'scoreContact':
        return (
          <ScoreContactFilter
            scoreContactFilterState={state}
            {...commonProps}
          />
        );
      case 'completion':
        return (
          <CompletionFilter completionFilterState={state} {...commonProps} />
        );
      case 'lastCalled':
        return (
          <DateFilter
            title={label}
            Icon={Icon}
            dateFilterState={state}
            {...commonProps}
          />
        );
      case 'smsStatus':
        return (
          <SMSStatusFilter smsStatusFilterState={state} {...commonProps} />
        );
      case 'feedback':
        return <FeedbackFilter feedbackFilterState={state} {...commonProps} />;
      case 'numberOfCalls':
        return (
          <NumberOfCallsFilter
            numberOfCallsFilterState={state}
            {...commonProps}
          />
        );
      case 'unreadStatus':
        return (
          <UnreadStatusFilter
            unreadStatusFilterState={state}
            {...commonProps}
          />
        );
    }
  };

  const filterMenuItems = (() => {
    const selectedFilters = new Set(
      filterState.activeFilters.map((filter) => filter.type)
    );
    const unselectedFilters = Object.entries(FILTER_LABELS).filter(
      ([filterType, _]) => !selectedFilters.has(filterType as FilterType)
    );

    return unselectedFilters.length > 0 ? (
      unselectedFilters.map(([filterType, { label, Icon }]) => {
        const handleClick = () => {
          filterState.addFilter(filterType as FilterType);
        };

        return (
          <Menu.Item
            key={filterType}
            leftSection={Icon ? <Icon size={16} /> : undefined}
            onClick={handleClick}
          >
            {label}
          </Menu.Item>
        );
      })
    ) : (
      <Center p='md'>
        <Text size='sm'>All filters selected.</Text>
      </Center>
    );
  })();

  return (
    <Group>
      <Menu position='bottom-start'>
        <Menu.Target>
          <Button
            leftSection={<IconFilterPlus size={20} />}
            variant='light'
            color='blue'
          >
            Filter
          </Button>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Label>Filter by</Menu.Label>
          {filterMenuItems}
        </Menu.Dropdown>
      </Menu>
      {filterState.activeFilters.map(renderFilter)}
      {filterState.activeFilters.length > 0 && (
        <Anchor onClick={filterState.clearAll}>Clear all</Anchor>
      )}
    </Group>
  );
}
