import React from 'react';
import { PieChart } from '@mantine/charts';
import { Card, Text } from '@mantine/core';

export const campaignQuestionsDataColorMap = {
  Compensation: 'var(--mantine-color-blue-5)',
  'Work Schedule': 'var(--mantine-color-grape-5)',
  'Work Location': 'var(--mantine-color-violet-5)',
  'Medical And Insuarance Benefits': 'var(--mantine-color-grape-9)',
  'Job Duties': 'var(--mantine-color-blue-9)',
  Other: 'var(--mantine-color-violet-9)',
};

const AnalysisTab = ({ data }) => {
  const pieChartData = data.map((item) => ({
    ...item,
    color: campaignQuestionsDataColorMap[item.name],
  }));

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {data.length === 0 ? (
        <div
          style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            marginTop: '5%',
          }}
        >
          <Text size='sm' c='dimmed'>
            No analytics data available
          </Text>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            gap: '20px',
            justifyContent: 'center',
            width: '100%',
            alignItems: 'center',
            marginTop: '12%',
          }}
        >
          <PieChart data={pieChartData} size={300} />
          <Card shadow='lg' style={{ marginRight: '5%' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
              }}
            >
              {data.map((item) => (
                <div
                  key={item.key}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '20px',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      gap: '10px',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        width: '12px',
                        height: '12px',
                        borderRadius: '50%',
                        backgroundColor:
                          campaignQuestionsDataColorMap[item.name],
                      }}
                    />
                    <Text size='sm'>{item.name}</Text>
                  </div>
                  <Text size='sm'>{item.value}</Text>
                </div>
              ))}
            </div>
          </Card>
        </div>
      )}
    </div>
  );
};

export default AnalysisTab;
