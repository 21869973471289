import React, { useState } from 'react';
import { Tooltip } from '@mantine/core';

interface CopyableTextProps {
  text: string;
  children: React.ReactNode;
}

export const CopyableText: React.FC<CopyableTextProps> = ({
  text,
  children,
}) => {
  const [tooltipText, setTooltipText] = useState('Copy');

  const handleCopy = () => {
    navigator.clipboard.writeText(text).then(() => {
      setTooltipText('Copied!');
      setTimeout(() => setTooltipText('Copy'), 2000);
    });
  };

  return (
    <Tooltip label={tooltipText} position='top' withArrow>
      <span
        onClick={handleCopy}
        style={{
          cursor: 'pointer',
          display: 'inline-flex',
          alignItems: 'center',
        }}
      >
        {children}
      </span>
    </Tooltip>
  );
};
