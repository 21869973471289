import React from 'react';
import {
  Group,
  Input,
  NumberInput,
  RangeSlider,
  Slider,
  Stack,
  Radio,
} from '@mantine/core';

import { CompletionFilterState } from './types';
import FilterPill from './FilterPill';

const MIN_COMPLETION = 0;
const MAX_COMPLETION = 100;

interface ComponentProps {
  onClear: () => void;
  onClose: () => void;
  completionFilterState: CompletionFilterState;
}

export default function CompletionFilter({
  onClear,
  onClose,
  completionFilterState: {
    minCompletion,
    maxCompletion,
    selectionMode,
    onChangeMinCompletion,
    onChangeMaxCompletion,
    onChangeSelectionMode,
  },
}: ComponentProps) {
  const filterLabel = (() => {
    switch (selectionMode) {
      case 'less':
        return `< ${maxCompletion}%`;
      case 'greater':
        return `> ${minCompletion}%`;
      case 'between':
        return `${minCompletion}% - ${maxCompletion}%`;
    }
  })();

  const handleChangeSelectionMode = (value: string) => {
    const mode = value as 'less' | 'greater' | 'between';
    if (mode === 'less') {
      onChangeMinCompletion(MIN_COMPLETION);
      if (!maxCompletion) onChangeMaxCompletion(50);
    } else if (mode === 'greater') {
      if (!minCompletion) onChangeMinCompletion(50);
      onChangeMaxCompletion(MAX_COMPLETION);
    }
    onChangeSelectionMode(mode);
  };

  return (
    <FilterPill
      title='Completion'
      label={filterLabel}
      onClear={onClear}
      onClose={onClose}
    >
      <Stack gap='xs' p='sm'>
        <Radio.Group value={selectionMode} onChange={handleChangeSelectionMode}>
          <Group>
            <Radio
              label='Less than'
              value='less'
              style={{ cursor: 'pointer' }}
            />
            <Radio
              label='Between'
              value='between'
              style={{ cursor: 'pointer' }}
            />
            <Radio
              label='Greater than'
              value='greater'
              style={{ cursor: 'pointer' }}
            />
          </Group>
        </Radio.Group>

        {selectionMode === 'between' ? (
          <>
            <Group justify='space-between'>
              <Input.Label>Min Completion (%)</Input.Label>
              <NumberInput
                w='50%'
                min={MIN_COMPLETION}
                max={maxCompletion}
                value={minCompletion}
                onChange={(val) => onChangeMinCompletion(Number(val))}
              />
            </Group>
            <Group justify='space-between'>
              <Input.Label>Max Completion (%)</Input.Label>
              <NumberInput
                w='50%'
                min={minCompletion}
                max={MAX_COMPLETION}
                value={maxCompletion}
                onChange={(val) => onChangeMaxCompletion(Number(val))}
              />
            </Group>
            <RangeSlider
              mb='lg'
              min={MIN_COMPLETION}
              max={MAX_COMPLETION}
              marks={[
                { value: 25, label: '25%' },
                { value: 50, label: '50%' },
                { value: 75, label: '75%' },
              ]}
              value={[minCompletion, maxCompletion] as [number, number]}
              onChange={([min, max]) => {
                onChangeMinCompletion(min);
                onChangeMaxCompletion(max);
              }}
            />
          </>
        ) : (
          <>
            {selectionMode === 'greater' ? (
              <>
                <Group justify='space-between'>
                  <Input.Label>Min Completion (%)</Input.Label>
                  <NumberInput
                    w='50%'
                    min={MIN_COMPLETION}
                    max={MAX_COMPLETION}
                    value={minCompletion}
                    onChange={(val) => onChangeMinCompletion(Number(val))}
                  />
                </Group>
                <Slider
                  mb='lg'
                  min={MIN_COMPLETION}
                  max={MAX_COMPLETION}
                  marks={[
                    { value: 25, label: '25%' },
                    { value: 50, label: '50%' },
                    { value: 75, label: '75%' },
                  ]}
                  value={minCompletion}
                  onChange={(val) => onChangeMinCompletion(val)}
                  inverted
                />
              </>
            ) : (
              <>
                <Group justify='space-between'>
                  <Input.Label>
                    {selectionMode === 'less'
                      ? 'Max Completion (%)'
                      : 'Min Completion (%)'}
                  </Input.Label>
                  <NumberInput
                    w='50%'
                    min={MIN_COMPLETION}
                    max={MAX_COMPLETION}
                    value={
                      selectionMode === 'less' ? maxCompletion : minCompletion
                    }
                    onChange={(val) =>
                      selectionMode === 'less'
                        ? onChangeMaxCompletion(Number(val))
                        : onChangeMinCompletion(Number(val))
                    }
                  />
                </Group>
                <Slider
                  mb='lg'
                  min={MIN_COMPLETION}
                  max={MAX_COMPLETION}
                  marks={[
                    { value: 25, label: '25%' },
                    { value: 50, label: '50%' },
                    { value: 75, label: '75%' },
                  ]}
                  value={
                    selectionMode === 'less' ? maxCompletion : minCompletion
                  }
                  onChange={(val) =>
                    selectionMode === 'less'
                      ? onChangeMaxCompletion(val)
                      : onChangeMinCompletion(val)
                  }
                />
              </>
            )}
          </>
        )}
      </Stack>
    </FilterPill>
  );
}
