import { useEffect, useRef } from 'react';
import { useAtom } from 'jotai';
import env from 'env';
import { transcriptionCacheAtom } from 'pages/editorv2/atoms';
import axios from 'api/axiosConfig';

import {
  CandidateQuestion,
  RequirementGrade,
  TranscriptData,
} from './useGetTranscriptData';

interface Call {
  overall_grade: number;
  question_completion_rate: number;
  call_id: string;
  last_updated: string;
  call_status: string;
  web_call: boolean;
}

interface GetTranscriptData {
  transcript: string;
  requirement_grades_list: RequirementGrade[];
  candidate_questions: CandidateQuestion[];
  call_with_highest_completion?: Call;
  all_calls: Call[];
  emails: string[];
  needs_translation: boolean;
  candidate: {
    candidate_id: string;
    fullName: string;
    email: string;
    phoneNumber: string;
  };
  summary: string;
}

export const useGetBulkTranscriptData = ({ candidates, campaignId }) => {
  const abortControllerRef = useRef<AbortController | null>(null);
  const [, setTranscriptionCache] = useAtom(transcriptionCacheAtom);

  useEffect(() => {
    if (candidates.length === 0 || !campaignId) {
      return;
    }

    // Cancel any ongoing requests before making a new one
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const controller = new AbortController();
    abortControllerRef.current = controller;

    const bulkFetchTranscriptData = async () => {
      const candidateIds = candidates.map(
        (candidate) => candidate.candidate.candidate_id
      );

      if (candidateIds.length === 0) {
        return;
      }

      try {
        const res = await axios.get(
          `${env.REACT_APP_SERVER_URL}/campaign/${campaignId}/bulk_calls_details`,
          {
            params: {
              candidateIds: JSON.stringify(candidateIds),
            },
          }
        );

        const transcriptData: Record<string, GetTranscriptData> = res.data;
        const transcriptCacheData: Record<string, TranscriptData> = {};
        Object.entries(transcriptData).forEach(([key, value]) => {
          transcriptCacheData[key] = {
            transcript: value.transcript,
            requirementGradesList: value.requirement_grades_list,
            candidateQuestions: value.candidate_questions,
            overallGrade:
              value.call_with_highest_completion?.overall_grade || 0,
            questionCompletionRate:
              value.call_with_highest_completion?.question_completion_rate || 0,
            callId: value.call_with_highest_completion?.call_id || '',
            candidateName: value.candidate.fullName,
            candidateEmail: value.candidate.email,
            candidatePhone: value.candidate.phoneNumber,
            lastCalled: value.call_with_highest_completion?.last_updated || '',
            allCalls: value.all_calls || [],
            callComplete:
              value.call_with_highest_completion?.call_status === 'COMPLETED',
            emails: value.emails || [],
            webCall: !!value.call_with_highest_completion?.web_call,
            needsTranslation: value.needs_translation,
            summary: value.summary || '',
          };
        });
        setTranscriptionCache({
          data: transcriptCacheData,
          timestamp: Date.now(),
        });
      } catch (error) {
        console.error('Error fetching bulk calls details:', error);
      }
    };

    bulkFetchTranscriptData();
  }, [candidates, campaignId, setTranscriptionCache]);
};
