import React from 'react';
import {
  Group,
  Input,
  NumberInput,
  RangeSlider,
  Slider,
  Stack,
  Radio,
} from '@mantine/core';
import { convertSecondsToMinutesAndSeconds } from 'utils/dateUtils';

import { CallLengthFilterState } from './types';
import FilterPill from './FilterPill';

const MIN_LENGTH = 0;
const MAX_LENGTH = 3600;

const secondsToMinutes = (seconds: number) => Math.round(seconds / 60);

const splitSecondsToMinutesAndSeconds = (totalSeconds: number) => ({
  minutes: Math.floor(totalSeconds / 60),
  seconds: totalSeconds % 60,
});

const combineMinutesAndSecondsToSeconds = (minutes: number, seconds: number) =>
  minutes * 60 + seconds;

interface ComponentProps {
  onClear: () => void;
  onClose: () => void;
  callLengthFilterState: CallLengthFilterState;
}

interface TimeInputGroupProps {
  label: string;
  minutes: number;
  seconds: number;
  onChangeMinutes: (val: number) => void;
  onChangeSeconds: (val: number) => void;
}

const TimeInputGroup: React.FC<TimeInputGroupProps> = ({
  label,
  minutes,
  seconds,
  onChangeMinutes,
  onChangeSeconds,
}) => (
  <Group justify='space-between' align='center'>
    <Input.Label>{label}</Input.Label>
    <Group gap='xs'>
      <NumberInput
        w={90}
        label='Minutes'
        min={0}
        max={60}
        value={minutes}
        onChange={(val) => onChangeMinutes(Number(val))}
      />
      <NumberInput
        w={90}
        label='Seconds'
        min={0}
        max={59}
        value={seconds}
        onChange={(val) => onChangeSeconds(Number(val))}
      />
    </Group>
  </Group>
);

export default function CallLengthFilter({
  onClear,
  onClose,
  callLengthFilterState: {
    minLength,
    maxLength,
    selectionMode,
    onChangeMinLength,
    onChangeMaxLength,
    onChangeSelectionMode,
  },
}: ComponentProps) {
  const minTime = splitSecondsToMinutesAndSeconds(minLength);
  const maxTime = splitSecondsToMinutesAndSeconds(maxLength);

  const handleMinTimeChange = {
    minutes: (val: number) => {
      onChangeMinLength(
        combineMinutesAndSecondsToSeconds(val, minTime.seconds)
      );
    },
    seconds: (val: number) => {
      onChangeMinLength(
        combineMinutesAndSecondsToSeconds(minTime.minutes, val)
      );
    },
  };

  const handleMaxTimeChange = {
    minutes: (val: number) => {
      onChangeMaxLength(
        combineMinutesAndSecondsToSeconds(val, maxTime.seconds)
      );
    },
    seconds: (val: number) => {
      onChangeMaxLength(
        combineMinutesAndSecondsToSeconds(maxTime.minutes, val)
      );
    },
  };

  const filterLabel = (() => {
    switch (selectionMode) {
      case 'less':
        return `< ${convertSecondsToMinutesAndSeconds(maxLength)}`;
      case 'greater':
        return `> ${convertSecondsToMinutesAndSeconds(minLength)}`;
      case 'between':
        return `${convertSecondsToMinutesAndSeconds(minLength)} - ${convertSecondsToMinutesAndSeconds(maxLength)}`;
    }
  })();

  const handleChangeSelectionMode = (value: string) => {
    const mode = value as 'less' | 'greater' | 'between';
    if (mode === 'less') {
      onChangeMinLength(MIN_LENGTH);
      if (!maxLength) onChangeMaxLength(300);
    } else if (mode === 'greater') {
      if (!minLength) onChangeMinLength(300);
      onChangeMaxLength(MAX_LENGTH);
    }
    onChangeSelectionMode(mode);
  };

  return (
    <FilterPill
      title='Call Length'
      label={filterLabel}
      onClear={onClear}
      onClose={onClose}
    >
      <Stack gap='xs' p='sm'>
        <Radio.Group value={selectionMode} onChange={handleChangeSelectionMode}>
          <Group>
            <Radio
              label='Less than'
              value='less'
              style={{ cursor: 'pointer' }}
            />
            <Radio
              label='Between'
              value='between'
              style={{ cursor: 'pointer' }}
            />
            <Radio
              label='Greater than'
              value='greater'
              style={{ cursor: 'pointer' }}
            />
          </Group>
        </Radio.Group>

        {selectionMode === 'between' ? (
          <>
            <TimeInputGroup
              label='Min Length'
              minutes={minTime.minutes}
              seconds={minTime.seconds}
              onChangeMinutes={handleMinTimeChange.minutes}
              onChangeSeconds={handleMinTimeChange.seconds}
            />
            <TimeInputGroup
              label='Max Length'
              minutes={maxTime.minutes}
              seconds={maxTime.seconds}
              onChangeMinutes={handleMaxTimeChange.minutes}
              onChangeSeconds={handleMaxTimeChange.seconds}
            />
            <RangeSlider
              mb='lg'
              min={MIN_LENGTH}
              max={MAX_LENGTH}
              marks={[
                { value: 900, label: '15m' },
                { value: 1800, label: '30m' },
                { value: 2700, label: '45m' },
              ]}
              value={[minLength, maxLength] as [number, number]}
              onChange={([min, max]) => {
                onChangeMinLength(min);
                onChangeMaxLength(max);
              }}
              label={(value) => `${secondsToMinutes(value)}m`}
            />
          </>
        ) : (
          <>
            {selectionMode === 'greater' ? (
              <>
                <TimeInputGroup
                  label='Min Length'
                  minutes={minTime.minutes}
                  seconds={minTime.seconds}
                  onChangeMinutes={handleMinTimeChange.minutes}
                  onChangeSeconds={handleMinTimeChange.seconds}
                />
                <Slider
                  mb='lg'
                  min={MIN_LENGTH}
                  max={MAX_LENGTH}
                  marks={[
                    { value: 900, label: '15m' },
                    { value: 1800, label: '30m' },
                    { value: 2700, label: '45m' },
                  ]}
                  value={minLength}
                  onChange={(val) => onChangeMinLength(val)}
                  label={(value) => `${secondsToMinutes(value)}m`}
                  inverted
                />
              </>
            ) : (
              <>
                <TimeInputGroup
                  label={selectionMode === 'less' ? 'Max Length' : 'Min Length'}
                  minutes={
                    selectionMode === 'less' ? maxTime.minutes : minTime.minutes
                  }
                  seconds={
                    selectionMode === 'less' ? maxTime.seconds : minTime.seconds
                  }
                  onChangeMinutes={
                    selectionMode === 'less'
                      ? handleMaxTimeChange.minutes
                      : handleMinTimeChange.minutes
                  }
                  onChangeSeconds={
                    selectionMode === 'less'
                      ? handleMaxTimeChange.seconds
                      : handleMinTimeChange.seconds
                  }
                />
                <Slider
                  mb='lg'
                  min={MIN_LENGTH}
                  max={MAX_LENGTH}
                  marks={[
                    { value: 900, label: '15m' },
                    { value: 1800, label: '30m' },
                    { value: 2700, label: '45m' },
                  ]}
                  value={selectionMode === 'less' ? maxLength : minLength}
                  onChange={(val) =>
                    selectionMode === 'less'
                      ? onChangeMaxLength(val)
                      : onChangeMinLength(val)
                  }
                  label={(value) => `${secondsToMinutes(value)}m`}
                />
              </>
            )}
          </>
        )}
      </Stack>
    </FilterPill>
  );
}
