import React, { useState, useEffect } from 'react';
import { notifications } from '@mantine/notifications';
import axios from 'api/axiosConfig';
import env from 'env';
import ATSSyncButton from 'components/campaign/ATSSyncButton';
import { Loader, MultiSelect, Switch } from '@mantine/core';
import './ATSIntegrationsManager.css';
import { IconPlus } from '@tabler/icons-react';

interface AtsIntegration {
  ats_integration_id: string;
  org_id: string;
  ats_provider: string;
  created_at: string;
  last_synced_at: string;
  deleted: boolean;
}

// Map of common ATS providers to their logo images
// Currently all mapped to the same Bullhorn logo
const ATS_PROVIDER_TO_IMAGE_MAP: Record<string, string> = {
  workable: '/imgs/workable_icon.svg',
  greenhouse: '/imgs/greenhouse_icon.svg',
  workday: '/imgs/workday_icon.svg',
  bullhorn: '/imgs/bullhorn_icon.svg',
  // Add any fallback for providers not in the map
  default: '/imgs/kombo_sandbox_icon.svg',
};

const capitalizeAllFirstLetters = (str: string) => {
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const ATSIntegrationsManager = () => {
  const [loadingAtsIntegrations, setLoadingAtsIntegrations] = useState(true);
  const [loadingConnectLink, setLoadingConnectLink] = useState(true);
  const [atsIntegrations, setAtsIntegrations] = useState<AtsIntegration[]>([]);
  const [connectLink, setConnectLink] = useState<string | null>(null);

  useEffect(() => {
    const fetchAtsIntegrations = async () => {
      setLoadingAtsIntegrations(true);
      try {
        const orgId = localStorage.getItem('orgId');
        const response = await axios.post(
          `${env.REACT_APP_SERVER_URL}/kombo/get_ats_integrations`,
          {
            orgId,
          }
        );

        const atsIntegrations = response.data || [];
        setAtsIntegrations(atsIntegrations);
      } catch (error) {
        notifications.show({
          title: 'Error',
          message: 'Failed to fetch ats integrations',
          color: 'red',
        });
      } finally {
        setLoadingAtsIntegrations(false);
      }
    };
    fetchAtsIntegrations();
  }, []);

  useEffect(() => {
    const fetchConnectLink = async () => {
      setLoadingConnectLink(true);
      const response = await axios.post(
        `${env.REACT_APP_SERVER_URL}/kombo/create_connect_link`,
        {
          userId: localStorage.getItem('userId'),
        }
      );
      setConnectLink(response.data);
      setLoadingConnectLink(false);
    };
    fetchConnectLink();
  }, []);

  const loadingAnything = loadingAtsIntegrations || loadingConnectLink;

  return (
    <div style={{ padding: '20px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h3>Active Integrations</h3>
        <div style={{ alignContent: 'center' }}>
          <ATSSyncButton />
        </div>
      </div>
      <div style={{ marginLeft: '10px', width: '600px' }}>
        {!loadingAnything && !atsIntegrations?.length && (
          <div>No active integrations found</div>
        )}
        {loadingAnything && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Loader type='dots' />
          </div>
        )}
        {!loadingAnything &&
          atsIntegrations.map((atsIntegration) => (
            <div
              key={
                atsIntegration.ats_integration_id || atsIntegration.ats_provider
              }
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '30px',
                  width: '100%',
                  border: '1px solid var(--salv-dark-3)',
                  padding: '14px',
                  borderRadius: '12px',
                  marginBottom: '18px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    gap: '30px',
                  }}
                >
                  <img
                    src={
                      ATS_PROVIDER_TO_IMAGE_MAP[atsIntegration.ats_provider] ||
                      ATS_PROVIDER_TO_IMAGE_MAP['default']
                    }
                    alt={`${atsIntegration.ats_provider} Logo`}
                    style={{ height: '30px' }}
                  />
                  <div
                    style={{
                      fontSize: '16px',
                      fontWeight: 'bold',
                      alignContent: 'center',
                    }}
                  >
                    {capitalizeAllFirstLetters(atsIntegration.ats_provider)}
                  </div>
                </div>
                <div style={{ fontSize: '12px', alignContent: 'center' }}>
                  {atsIntegration.last_synced_at ? (
                    <div>
                      <b>Last Synced:</b>{' '}
                      {new Date(atsIntegration.last_synced_at).toLocaleString(
                        undefined,
                        {
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                          hour: 'numeric',
                          minute: '2-digit',
                          hour12: true,
                        }
                      )}
                    </div>
                  ) : (
                    <div>
                      <b>Created:</b>{' '}
                      {new Date(atsIntegration.created_at).toLocaleString(
                        undefined,
                        {
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                          hour: 'numeric',
                          minute: '2-digit',
                          hour12: true,
                        }
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        {!loadingAnything && connectLink && (
          <div
            style={{
              marginTop: '20px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div
              className='connect-ats-button'
              onClick={() =>
                window.open(connectLink, '_blank', 'noopener,noreferrer')
              }
            >
              <IconPlus size={19} />
              Add A New ATS Integration
            </div>
          </div>
        )}
      </div>
      <br />
      <h3>Settings for ATS Jobs</h3>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <div style={{ width: '600px' }}>
          <MultiSelect
            label='Applicant Screening Stage'
            description='Select the stage(s) of the applicant screening process where an interview will be conducted.'
            placeholder='Default: All Stages'
            data={[
              'Phone Screen',
              'Technical Interview',
              'Behavioral Interview',
            ]}
          />
        </div>
        <div
          style={{
            width: '600px',
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
          }}
        >
          <h5 style={{ fontWeight: 'bold', margin: '0px' }}>
            Applicant Stage Transition Settings
          </h5>
          <Switch
            label='Move Applicant Forward After Interview Passed'
            placeholder='Default: All Stages'
            checked={true}
          />
          <Switch
            label='Move Applicant Forward On Thumbs Up'
            placeholder='Default: All Stages'
            checked={true}
          />
        </div>
      </div>
    </div>
  );
};

export default ATSIntegrationsManager;
