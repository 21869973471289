import React, { useEffect, useState } from 'react';
import { ScrollArea, Center, Loader } from '@mantine/core';
import env from 'env';
import { notifications } from '@mantine/notifications';
import { convertUpperSnakeToTitle } from 'utils/formatUtils';
import { formatToLocalTime } from 'utils/dateUtils';

import TranscriptLines from './TranscriptLines';
import { TranscriptMessage } from './types';
import './Transcript.css';
import axios from '../../api/axiosConfig';

/* ----------------------------------------------------------------
   parseCallHistory (for merging into SMS)
----------------------------------------------------------------- */
const parseCallHistory = (callHistory: any[] = []): TranscriptMessage[] => {
  return callHistory
    .map((call) => {
      if (
        !call?.last_updated ||
        call.call_status === 'PENDING' ||
        call.call_status === 'CANCELLED'
      )
        return null;

      const durationSec = call.call_length_sec || 0;
      const minutes = Math.floor(durationSec / 60);
      const seconds = durationSec % 60;

      // Create a Date from last_updated and subtract the duration
      const adjustedDate = new Date(call.last_updated + 'Z'); // needed because was a naiive time before
      adjustedDate.setSeconds(adjustedDate.getSeconds() - durationSec);
      // Hack to add additional offset for incomplete calls to account for the fact that postprocessing usually takes longer than sending the follow-up texts
      const additionalOffset = durationSec > 30 ? 0 : 1 * 60 * 1000;
      return {
        start_ts:
          adjustedDate.getTime() - additionalOffset - durationSec * 1000, // offset hack
        role: 'Call',
        // Pass the adjusted time to formatToLocalTime
        content:
          `${convertUpperSnakeToTitle(call.call_initiated_by)} ` +
          `${formatToLocalTime(adjustedDate, true)} ` +
          `(${minutes}m ${seconds}s) [${convertUpperSnakeToTitle(call.call_status)}]`,
      };
    })
    .filter((item): item is TranscriptMessage => !!item); // remove nulls
};

/* ----------------------------------------------------------------
   Existing parseSmsTranscript
----------------------------------------------------------------- */
const parseSmsTranscript = (smsTranscript: string | undefined) => {
  if (!smsTranscript) return [];
  if (typeof smsTranscript !== 'string') {
    console.error('Invalid type for text:', typeof smsTranscript);
    return [];
  }
  return smsTranscript
    .split('\n')
    .map((line) => {
      const [timestampStr, rest] = line.split('|');
      if (!timestampStr || !rest) {
        return null;
      }

      const timestamp = new Date(timestampStr);
      const [role, ...contentParts] = rest.split(':');
      return {
        start_ts: timestamp.getTime(),
        role: role.trim(),
        content: contentParts.join(':').trim(),
      };
    })
    .filter(Boolean) // remove null entries
    .filter((item): item is TranscriptMessage => !!item);
};

/* ----------------------------------------------------------------
   SMSTab - merges SMS + Call history, displays as one transcript
----------------------------------------------------------------- */
const SMSTab = ({
  candidateId,
  campaignId,
  allCalls,
  userScrolling,
  scrollingProgrammatically,
}: {
  candidateId?: string;
  campaignId?: string;
  allCalls?: any[];
  userScrolling: boolean;
  scrollingProgrammatically: React.MutableRefObject<boolean>;
}) => {
  const [transcriptSMSError, setTranscriptSMSError] = useState(false);
  const [isFetchingSMSTranscript, setIsFetchingSMSTranscript] = useState(false);

  // We'll store both pieces in state
  const [smsTranscript, setSmsTranscript] = useState('');

  useEffect(() => {
    if (!candidateId || !campaignId) return;

    const fetchSMSTranscriptData = async () => {
      setIsFetchingSMSTranscript(true);
      setTranscriptSMSError(false);

      try {
        const response = await axios.get(
          `${env.REACT_APP_SERVER_URL}/candidate/${candidateId}/sms_details/${campaignId}`
        );
        const { sms_transcript } = response.data;

        setSmsTranscript(sms_transcript || '');
      } catch (error) {
        setTranscriptSMSError(true);
        console.error('Error fetching sms/call data:', error);
        notifications.show({
          title: 'There was an error retrieving sms transcript details',
          message: error instanceof Error ? error.message : '',
          color: 'red',
        });
      } finally {
        setIsFetchingSMSTranscript(false);
      }
    };

    fetchSMSTranscriptData();
  }, [campaignId, candidateId]);

  if (isFetchingSMSTranscript) {
    return (
      <Center p='lg'>
        <Loader size='sm' type='dots' />
      </Center>
    );
  }

  if (transcriptSMSError) {
    return (
      <Center style={{ textAlign: 'center', height: '100%' }}>
        <h5>Failed to fetch SMS Transcript/Call History</h5>
      </Center>
    );
  }

  // Parse the SMS lines + calls into the same shape
  const parsedSms = parseSmsTranscript(smsTranscript);
  const parsedCalls = parseCallHistory(allCalls);

  // Merge & sort
  const combined = [...parsedSms, ...parsedCalls].sort(
    (a: TranscriptMessage, b: TranscriptMessage) => a.start_ts - b.start_ts
  );

  if (!combined.length) {
    return (
      <Center style={{ textAlign: 'center', height: '100%' }}>
        <h5>No SMS or Call History Available</h5>
      </Center>
    );
  }

  return (
    <div className='transcript-column'>
      <ScrollArea className='scrollable-content'>
        <div className='scroll-fade-top'></div>
        <div className='inside'>
          <TranscriptLines
            transcript={combined}
            sms={true}
            onTimestampClick={() => {}}
            clickable={false}
            currentTimeMs={undefined}
            userScrolling={userScrolling}
            scrollingProgrammatically={scrollingProgrammatically}
          />
        </div>
        <div className='scroll-fade-bottom'></div>
      </ScrollArea>
    </div>
  );
};

export default SMSTab;
