import React, { Fragment, useEffect, useState } from 'react';
import { Button, LoadingOverlay, Text } from '@mantine/core';
import axios from 'api/axiosConfig';
import env from 'env';
import { notifications } from '@mantine/notifications';
import Template, {
  SequenceSettingsTemplate,
} from 'components/template/Template';
import AddTemplateModal from 'components/template/AddTemplateModal';

const ScriptGenerationManager = () => {
  const [templates, setTemplates] = useState<SequenceSettingsTemplate[]>([]);
  const [openAddTemplateModal, setOpenAddTemplateModal] = useState(false);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        setIsFetching(true);
        const orgId = localStorage.getItem('orgId');
        const response = await axios.get(
          `${env.REACT_APP_SERVER_URL}/org/${orgId}/script_generation_templates`
        );
        const { templates } = response.data;
        setTemplates(templates || []);
      } catch (error) {
        notifications.show({
          title: 'Error',
          message: 'Failed to fetch script generation templates',
          color: 'red',
        });
      } finally {
        setIsFetching(false);
      }
    };

    fetchTemplates();
  }, []);

  const handleDeleteTemplate = async (templateId: string) => {
    try {
      await axios.delete(
        `${env.REACT_APP_SERVER_URL}/script_generation_templates/${templateId}/delete`
      );
      const updatedTemplates = templates.filter(
        (template) => template.id !== templateId
      );
      setTemplates(updatedTemplates);
    } catch (error) {
      throw new Error('Failed to delete template');
    }
  };

  const handleAddTemplate = async (templateData: {
    title: string;
    instructions: string;
  }) => {
    try {
      const orgId = localStorage.getItem('orgId');
      const response = await axios.post(
        `${env.REACT_APP_SERVER_URL}/add_script_generation_templates`,
        {
          orgId,
          title: templateData.title,
          instructions: templateData.instructions,
        }
      );

      const { id } = response.data;
      setTemplates((templates) => {
        return [...templates, { ...templateData, id }];
      });
    } catch (error) {
      throw new Error(String(error));
    }
  };

  const handleEditTemplate = async (template: SequenceSettingsTemplate) => {
    try {
      await axios.patch(
        `${env.REACT_APP_SERVER_URL}/script_generation_templates/${template.id}/edit`,
        {
          orgId: template.org_id,
          title: template.title,
          instructions: template.instructions,
        }
      );
      setTemplates((templates) => {
        return templates.map((t) => {
          if (t.id === template.id) {
            return template;
          }
          return t;
        });
      });
    } catch (error) {
      throw new Error(String(error));
    }
  };

  if (isFetching) {
    return <LoadingOverlay visible />;
  }

  return (
    <div style={{ padding: '20px' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '24px',
        }}
      >
        <div>
          <Text size='lg' fw={500} mb={8}>
            Script Generation Templates
          </Text>
          <Text size='sm' c='dimmed'>
            Manage your organization's script generation templates. Templates
            can be quickly added to any job in the Sequence Editor page.
          </Text>
        </div>
        <Button
          color='blue'
          onClick={() => setOpenAddTemplateModal(true)}
          style={{ alignSelf: 'flex-end' }}
        >
          Add Template
        </Button>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        {templates.length === 0 ? (
          <Text
            size='sm'
            c='dimmed'
            style={{ alignSelf: 'center' }}
            mt={10}
            mb={10}
          >
            No templates found. Add a new template to get started.
          </Text>
        ) : (
          <Fragment>
            {templates.map((template) => (
              <Template
                template={template}
                onEditTemplate={handleEditTemplate}
                onDeleteTemplate={handleDeleteTemplate}
                isAdmin
              />
            ))}
          </Fragment>
        )}
      </div>
      <AddTemplateModal
        open={openAddTemplateModal}
        onClose={() => setOpenAddTemplateModal(false)}
        handleAddTemplate={handleAddTemplate}
      />
    </div>
  );
};

export default ScriptGenerationManager;
