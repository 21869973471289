import React, { useState, useEffect } from 'react';
import { Button, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import axios from 'api/axiosConfig';
import env from 'env';
import { IconDatabasePlus } from '@tabler/icons-react';
import {
  QuestionTable,
  QuestionBankItem,
} from 'components/QuestionTable/QuestionTable';
import { RequirementImportance, QuestionType } from 'pages/editorv2/types';

interface QuestionBankManagerProps {
  onUnsavedChanges: (hasChanges: boolean) => void;
}

const QuestionBankManager = ({
  onUnsavedChanges,
}: QuestionBankManagerProps) => {
  const [questions, setQuestions] = useState<QuestionBankItem[]>([]);
  const [originalQuestions, setOriginalQuestions] = useState<
    QuestionBankItem[]
  >([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const orgId = localStorage.getItem('orgId');
        const response = await axios.get(
          `${env.REACT_APP_SERVER_URL}/org_background_info/${orgId}`
        );
        const questionBank = response.data?.question_bank || [];
        setQuestions(questionBank);
        setOriginalQuestions(questionBank);
      } catch (error) {
        notifications.show({
          title: 'Error',
          message: 'Failed to fetch question bank',
          color: 'red',
        });
      }
    };
    fetchQuestions();
  }, []);

  useEffect(() => {
    setHasUnsavedChanges(
      JSON.stringify(questions) !== JSON.stringify(originalQuestions)
    );
    onUnsavedChanges(
      JSON.stringify(questions) !== JSON.stringify(originalQuestions)
    );
  }, [questions, originalQuestions, onUnsavedChanges]);

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (hasUnsavedChanges) {
        e.preventDefault();
        e.returnValue = ''; // This triggers the confirmation dialog in some browsers.
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [hasUnsavedChanges]);

  const handleSaveChanges = async () => {
    try {
      setIsSaving(true);
      const orgId = localStorage.getItem('orgId');
      await axios.patch(
        `${env.REACT_APP_SERVER_URL}/org_background_info/${orgId}`,
        {
          patch: {
            question_bank: questions,
          },
          org_id: orgId,
        }
      );
      setOriginalQuestions(JSON.parse(JSON.stringify(questions)));
      notifications.show({
        title: 'Success',
        message: 'Question bank saved successfully',
        color: 'green',
      });
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: 'Failed to save question bank',
        color: 'red',
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleAddQuestion = () => {
    setQuestions([
      ...questions,
      {
        bankTitle: '',
        question: '',
        llmGradingInstructions: '',
        importance: RequirementImportance.Normal,
        failIfZero: false,
        questionType: QuestionType.Normal,
      },
    ]);
  };

  return (
    <div style={{ padding: '20px' }}>
      <div style={{ marginBottom: '24px' }}>
        <Text size='lg' fw={500} mb={8}>
          Question Bank
        </Text>
        <Text size='sm' c='dimmed'>
          Manage your organization's commonly used screening questions.
          Questions from the question bank can be quickly added to any job in
          the Sequence Editor page.
        </Text>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '16px',
        }}
      >
        <Button
          onClick={handleSaveChanges}
          disabled={!hasUnsavedChanges}
          color={hasUnsavedChanges ? 'blue' : 'gray'}
          loading={isSaving}
        >
          Save Changes
        </Button>
      </div>

      <QuestionTable
        questions={questions}
        onQuestionsChange={setQuestions}
        showBankTitle={true}
        unsavedChanges={false}
      />

      <div
        style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}
      >
        <Button
          onClick={handleAddQuestion}
          leftSection={<IconDatabasePlus size={20} />}
        >
          Add New Requirement
        </Button>
      </div>
    </div>
  );
};

export default QuestionBankManager;
