import React, { Fragment, useCallback, useEffect, useState } from 'react';
import {
  CampaignFolder,
  CampaignResponse,
  renderCampaignTypeIcon,
} from 'pages/scripts/ScriptsPage';
import env from 'env';
import axios from 'api/axiosConfig';
import {
  ActionIcon,
  Button,
  LoadingOverlay,
  Modal,
  Text,
  Tooltip,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useHover } from '@mantine/hooks';
import {
  IconChevronRight,
  IconFolderFilled,
  IconPlus,
} from '@tabler/icons-react';

import FolderBreadcrumbs from './FolderBreadcrumbs';
import NewFolderModal from './NewFolderModal';

interface MoveToFolderModalProps {
  open: boolean;
  onClose: () => void;
  itemToMove: CampaignFolder | CampaignResponse;
  onMoveItemToFolder: (selectedFolderId: string | null) => Promise<void>;
  onAddFolder: (folder: CampaignFolder) => void;
}

const isCampaign = (item: CampaignFolder | CampaignResponse) => {
  return 'campaign_id' in item;
};

const isFolder = (item: CampaignFolder | CampaignResponse) => {
  return 'folder_name' in item;
};

const MoveToFolderModal = ({
  open,
  onClose,
  itemToMove,
  onMoveItemToFolder,
  onAddFolder,
}: MoveToFolderModalProps) => {
  const [folders, setFolders] = useState<CampaignFolder[]>([]);
  const [isFetching, setIsfetching] = useState(true);
  const [isMoving, setIsMoving] = useState(false);
  const [currentFolderId, setCurrentFolderId] = useState<string | null>(null);
  const [selectedFolderId, setSelectedFolderId] = useState<string | null>(null);
  const [currentFolder, setCurrentFolder] = useState<CampaignFolder | null>(
    null
  );
  const [campaigns, setCampaigns] = useState<CampaignResponse[]>([]);
  const [newFolderModalOpen, setNewFolderModalOpen] = useState(false);

  const fetchFolders = useCallback(async () => {
    try {
      setIsfetching(true);
      const orgId = localStorage.getItem('orgId');
      const response = await axios.get(
        `${env.REACT_APP_SERVER_URL}/org/${orgId}/get_campaign_folders`,
        {
          params: {
            folderId: currentFolderId,
          },
        }
      );
      const { folders, current_folder, campaigns } = response.data.folders;
      setFolders(folders);
      setCurrentFolder(current_folder);
      setCampaigns(campaigns);
    } catch (error) {
      console.error('Error fetching folders:', error);
    } finally {
      setIsfetching(false);
    }
  }, [currentFolderId]);

  useEffect(() => {
    if (!open) {
      return;
    }

    fetchFolders();
  }, [fetchFolders, open]);

  const handleSelectFolder = (folderId) => {
    if (folderId === selectedFolderId) {
      setSelectedFolderId(null);
      return;
    }
    setSelectedFolderId(folderId);
  };

  const goToFolderContents = (e, folderId) => {
    e.stopPropagation();
    setCurrentFolderId(folderId);
    setSelectedFolderId(null);
  };

  const handleMoveToFolder = async () => {
    try {
      setIsMoving(true);
      if (selectedFolderId) {
        await onMoveItemToFolder(selectedFolderId);
      } else {
        await onMoveItemToFolder(currentFolderId);
      }
      handleClose();
    } catch (error) {
      console.error('Error moving item:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to move item',
        color: 'red',
      });
    } finally {
      setIsMoving(false);
    }
  };

  const handleClose = () => {
    onClose();
    setSelectedFolderId(null);
    setCurrentFolderId(null);
  };

  const itemName = isCampaign(itemToMove)
    ? itemToMove.campaign_name
    : isFolder(itemToMove)
      ? itemToMove.folder_name
      : '';

  const selectedFolder = folders.find(
    (folder) => folder.folder_id === selectedFolderId
  );

  const isItemInFolder = () => {
    if (isCampaign(itemToMove)) {
      return selectedFolderId
        ? selectedFolderId === itemToMove.folder_id
        : currentFolderId === itemToMove.folder_id;
    }

    return selectedFolderId
      ? selectedFolderId === itemToMove.parent_folder_id
      : currentFolderId === itemToMove.parent_folder_id;
  };

  const destinationName = selectedFolderId
    ? selectedFolder?.folder_name
    : currentFolder?.folder_name || 'Jobs';

  const handleAddFolder = (folder: CampaignFolder) => {
    setFolders((prev) => [folder, ...prev]);
    setSelectedFolderId(folder.folder_id);
    onAddFolder(folder);
  };

  const handleNavigateBreadCrumbs = (
    breadcrumb: Partial<CampaignFolder> | null
  ) => {
    setCurrentFolderId(breadcrumb?.folder_id ?? null);
    setSelectedFolderId(null);
  };

  return (
    <Modal
      title={`Move ${itemName}`}
      opened={open}
      onClose={handleClose}
      size='lg'
      centered
      withCloseButton={false}
      styles={{
        body: {
          padding: '20px',
          borderRadius: '10px',
          overflowY: 'auto',
          maxHeight: '80vh',
        },
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '40vh',
          overflow: 'hidden',
        }}
      >
        {isFetching ? (
          <LoadingOverlay visible opacity={1} />
        ) : (
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
          >
            <FolderBreadcrumbs
              currentFolder={currentFolder}
              onNavigate={handleNavigateBreadCrumbs}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                minHeight: '40vh',
                maxHeight: 'calc(80vh - 300px)',
                overflow: 'scroll',
              }}
            >
              {folders.length === 0 && campaigns.length === 0 ? (
                <Text
                  size='sm'
                  c='dimmed'
                  style={{
                    padding: '10px',
                    alignSelf: 'center',
                    marginTop: '30px',
                  }}
                >
                  This folder is empty
                </Text>
              ) : (
                <Fragment>
                  {folders.map((folder) => (
                    <MoveToFolderRow
                      key={folder.folder_id}
                      folder={folder}
                      handleSelectFolder={handleSelectFolder}
                      goToFolderContents={goToFolderContents}
                      isSelected={selectedFolderId === folder.folder_id}
                      isSameFolder={
                        isFolder(itemToMove) &&
                        itemToMove.folder_id === folder.folder_id
                      }
                    />
                  ))}
                  {campaigns.map((campaign) => (
                    <CampaignRow
                      key={campaign.campaign_id}
                      campaign={campaign}
                    />
                  ))}
                </Fragment>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Button
                variant='outline'
                onClick={() => setNewFolderModalOpen(true)}
                leftSection={<IconPlus size={14} />}
              >
                New Folder
              </Button>

              <div
                style={{
                  display: 'flex',
                  gap: '10px',
                  alignItems: 'center',
                }}
              >
                <Button variant='outline' onClick={handleClose}>
                  Cancel
                </Button>
                <Tooltip
                  label={'Item is already in this folder'}
                  position='top'
                  withArrow
                  hidden={!isItemInFolder()}
                >
                  <Button
                    color='blue'
                    onClick={handleMoveToFolder}
                    disabled={isItemInFolder()}
                    loading={isMoving}
                  >
                    {`${selectedFolderId ? `Move to ${destinationName} ` : `Move into ${destinationName}`} `}
                  </Button>
                </Tooltip>
              </div>
            </div>
          </div>
        )}
      </div>
      <NewFolderModal
        open={newFolderModalOpen}
        onClose={() => setNewFolderModalOpen(false)}
        folderId={currentFolderId}
        onAddFolder={handleAddFolder}
      />
    </Modal>
  );
};

const MoveToFolderRow = ({
  folder,
  handleSelectFolder,
  isSelected,
  goToFolderContents,
  isSameFolder,
}) => {
  const { hovered, ref } = useHover();
  return (
    <Tooltip
      label="Can't move folder into itself"
      disabled={!isSameFolder}
      position='top'
      withArrow
    >
      <div
        key={folder?.folder_id}
        ref={ref}
        onClick={() => !isSameFolder && handleSelectFolder(folder?.folder_id)}
        style={{
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '10px',
          borderRadius: '5px',
          cursor: 'pointer',
          border: isSelected ? '2px solid #228be6' : '',
          backgroundColor: isSameFolder
            ? 'var(--salv-dark-1)'
            : isSelected
              ? 'var(--mantine-color-blue-0)'
              : hovered
                ? 'var(--mantine-color-blue-0)'
                : 'var(--salv-dark-1)',
          opacity: isSameFolder ? 0.6 : 1,
        }}
      >
        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          <IconFolderFilled
            size={14}
            style={{ color: 'var(--salv-folder-color)' }}
          />
          <Text size='sm'>{folder.folder_name}</Text>
        </div>
        {!isSameFolder && (
          <ActionIcon
            variant='light'
            radius='xl'
            onClick={(e) => goToFolderContents(e, folder?.folder_id)}
          >
            <IconChevronRight size={20} color='#4a564d' />
          </ActionIcon>
        )}
      </div>
    </Tooltip>
  );
};

const CampaignRow = ({ campaign }: { campaign: CampaignResponse }) => (
  <div
    style={{
      display: 'flex',
      gap: '10px',
      alignItems: 'center',
      padding: '10px',
      backgroundColor: 'var(--salv-dark-1)',
      borderRadius: '5px',
      opacity: 0.3,
    }}
  >
    {renderCampaignTypeIcon(campaign)}
    <Text size='sm'>{campaign.campaign_name}</Text>
  </div>
);

export default MoveToFolderModal;
