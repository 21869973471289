import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import axios from 'api/axiosConfig';
import env from 'env';
import { useParams } from 'react-router-dom';
import { RetellWebClient } from 'retell-client-js-sdk';
import { throttle } from 'lodash';
import { CloseButton, Modal, Button, Loader } from '@mantine/core';
import { notifications } from '@mantine/notifications';

import { decodeData } from './utils';
import RetellControlsArea from './components/RetellControlsArea';
import RetellWebCallHandler from './components/RetellWebCallHandler';
import './WebCallPage.css';
import { useIsMobile } from './components/useIsMobile';
import CandidateFormModal from './CandidateForm';
import AutoScrollButton from '../../components/transcript/AutoScrollButton';

// Define the type for the decoded data
interface DecodedData {
  campaignId: string;
  candidateName: string;
  voiceName: string;
  defaultId: string;
  candidateTimezone?: string;
}

const WebCallPage = () => {
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
  const [callId, setCallId] = useState<string | null>(null);
  const [decodedData, setDecodedData] = useState<DecodedData | null>(null);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [campaignId, setCampaignId] = useState<string | null>(null);

  const { encodedData, encodedId } = useParams<{
    encodedData?: string;
    encodedId?: string;
  }>();

  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const retellWebClientRef = useRef<RetellWebClient | null>(null);
  const [startClicked, setStartClicked] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [candidateName, setCandidateName] = useState('');
  const [candidateId, setCandidateId] = useState<string | null>(null);
  const [expired, setExpired] = useState(false);
  const isTestCall = !encodedId;
  const [isTestEmail, setIsTestEmail] = useState(false);
  const [validated, setValidated] = useState<boolean>(isTestCall); // Test call already validated
  const [retryIdx, setRetryIdx] = useState<number | null>(null);
  const [pastCompletionRate, setPastCompletionRate] = useState<
    number | undefined
  >(undefined);
  const [isPartialWarningModalOpen, setIsPartialWarningModalOpen] =
    useState(false);
  const [voiceName, setVoiceName] = useState<string>('');

  const [interviewerMessages, setInterviewerMessages] = useState<string[]>([]);

  const [showMicTestModal, setShowMicTestModal] = useState(false);
  const micTestStreamRef = useRef<MediaStream | null>(null);
  const micTestAnalyserRef = useRef<AnalyserNode | null>(null);

  const [messagesVisible, setMessagesVisible] = useState(true);

  const isMobile = useIsMobile();

  const THROTTLE_S = useMemo(() => {
    return isMobile ? 100 : 50; // request animation frames messes up clicking on mobile
  }, [isMobile]);

  const [showScrollButton, setShowScrollButton] = useState(false);
  const [increasedBuffer, setIncreasedBuffer] = useState(false);
  const [autoScrollEnabled, setAutoScrollEnabled] = useState(true);

  const getAccessTokenSetupCall = async () => {
    // Gets access token for either test call or real call depending on url parameters
    if (encodedId) {
      const payload: {
        webCallEncodedId: string;
        callId?: string;
        retryIdx?: number;
      } = {
        webCallEncodedId: encodedId,
      };
      if (callId) {
        payload.callId = callId;
      }
      if (retryIdx) {
        payload.retryIdx = retryIdx;
      }
      try {
        const response = await axios.post(
          `${env.REACT_APP_SERVER_URL}/candidate_web_call`,
          payload
        );
        setCallId(response.data.data.call_id);
        setAccessToken(response.data.data.access_token);
        setCandidateName(response.data.data.candidate_name);
        setVoiceName(response.data.data.voice_name || '');
      } catch (error) {
        console.error('Error initiating video call:', error);
      }
      return;
    }
    // Else, do test
    if (!decodedData) {
      console.error('Decoded data is not available');
      return;
    }

    const payload: DecodedData = {
      campaignId: decodedData.campaignId,
      candidateName: decodedData.candidateName,
      voiceName: decodedData.voiceName,
      defaultId: decodedData.defaultId,
      candidateTimezone: decodedData.candidateTimezone || '',
    };

    try {
      setVoiceName(decodedData?.voiceName || '');
      const response = await axios.post(
        `${env.REACT_APP_SERVER_URL}/test_web_call`,
        payload
      );
      setCallId(response.data.data.call_id);
      setAccessToken(response.data.data.access_token);
    } catch (error) {
      console.error('Error initiating video call:', error);
    }
  };

  // This is a slighlty hacky version of having a global store to prevent uneeded re-renders when the state changes
  // A better design is a global state store and having micLevels subscribe
  const micBarsUpdateRef = useRef<(level: number) => void>();
  const onMicLevelChange = (newMicLevel: number) => {
    // Instead of setting state here, we call the callback we got from MicBars
    if (micBarsUpdateRef.current) {
      micBarsUpdateRef.current(newMicLevel);
    }
  };

  const updateMicLevelsRef = useRef<number>();

  const throttledSetMicLevel = useMemo(
    () => throttle((volume: number) => onMicLevelChange(volume), THROTTLE_S), // Throttle to 50ms
    [THROTTLE_S]
  );

  useEffect(() => {
    return () => throttledSetMicLevel.cancel();
  }, [throttledSetMicLevel]);

  const startMicTest = useCallback(async () => {
    try {
      if (!micTestStreamRef.current) {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        micTestStreamRef.current = stream;
      }

      const audioContext = new AudioContext({ sampleRate: 48000 });

      if (audioContext.state === 'suspended') {
        await audioContext.resume();
      }

      const analyser = audioContext.createAnalyser();
      analyser.fftSize = 256;
      const microphone = audioContext.createMediaStreamSource(
        micTestStreamRef.current
      );
      microphone.connect(analyser);
      micTestAnalyserRef.current = analyser;
      const updateMicLevels = () => {
        if (!micTestAnalyserRef.current) return;

        const dataArray = new Uint8Array(
          micTestAnalyserRef.current.frequencyBinCount
        );
        micTestAnalyserRef.current.getByteFrequencyData(dataArray);

        const sensitivityBoost = 2.0;
        const rms = Math.sqrt(
          dataArray.reduce((sum, value) => sum + value ** 2, 0) /
            dataArray.length
        );

        const normalizedVolume = Math.min((rms / 255) * sensitivityBoost, 1);

        throttledSetMicLevel(normalizedVolume); // Use throttled function

        updateMicLevelsRef.current = requestAnimationFrame(updateMicLevels);
      };

      updateMicLevelsRef.current = requestAnimationFrame(updateMicLevels);
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  }, [throttledSetMicLevel]);

  const stopMicTest = useCallback(() => {
    if (updateMicLevelsRef.current) {
      cancelAnimationFrame(updateMicLevelsRef.current);
      updateMicLevelsRef.current = undefined;
    }

    if (micTestStreamRef.current) {
      micTestStreamRef.current.getTracks().forEach((track) => track.stop());
      micTestStreamRef.current = null;
    }

    if (micTestAnalyserRef.current) {
      micTestAnalyserRef.current.disconnect();
      micTestAnalyserRef.current = null;
    }

    onMicLevelChange(0);
  }, []);

  const handleOpenMicTest = () => {
    setShowMicTestModal(true);
    startMicTest();
  };

  const handleCloseMicTest = () => {
    setShowMicTestModal(false);
    stopMicTest();
  };

  useEffect(() => {
    if (showMicTestModal) {
      startMicTest();
    } else {
      stopMicTest();
    }
  }, [showMicTestModal, startMicTest, stopMicTest]);

  useEffect(() => {
    // Once we get a candidate id, either from form submission or candidate web link,
    // we do the final validation of that candidate, checking if they completed calls
    // or are able to complete the interview again
    const validateCandidateLink = async () => {
      try {
        const candidateLinkPayload = {
          webCallEncodedId: encodedId,
          candidateId: candidateId,
          callId: callId,
        };
        const response = await axios.post(
          `${env.REACT_APP_SERVER_URL}/validate_encoded_id_plus_candidate`,
          candidateLinkPayload
        );
        const {
          expired,
          past_completion_rate,
          candidate_name,
          any_call_done,
          retry_idx,
        } = response.data.data;
        setPastCompletionRate(past_completion_rate);
        if (expired) {
          setExpired(true);
        } else {
          // otherwise, set as validated
          setCandidateName(candidate_name);
          setValidated(true);

          if (
            !isTestEmail &&
            !isTestCall &&
            any_call_done &&
            past_completion_rate < 1.0
          ) {
            // if recent and partially complete, allow to continue the interview
            setIsPartialWarningModalOpen(true);
            if (retry_idx) {
              setRetryIdx(retry_idx);
            }
          }
        }
      } catch (error: any) {
        console.error('Error validating candidate link', error);
        notifications.show({
          title: 'Starting call failed',
          message:
            error.response?.data?.detail ||
            (error instanceof Error
              ? error.message
              : 'Error validating candidate link'),
          color: 'red',
        });
      }
    };

    if (candidateId && encodedId && callId && !isTestCall && !isTestEmail) {
      validateCandidateLink();
    }
  }, [encodedId, candidateId, callId, isTestEmail, isTestCall]);

  useEffect(() => {
    const fetchDataFromEncodedId = async () => {
      const payload = {
        webCallEncodedId: encodedId,
      };
      try {
        const linkCheckResponse = await axios.post(
          `${env.REACT_APP_SERVER_URL}/check_web_link`,
          payload
        );
        const isSharedLink = linkCheckResponse.data.is_shared_link;
        setCampaignId(linkCheckResponse.data.campaign_id);
        const isExpired = linkCheckResponse.data.expired;
        setExpired(linkCheckResponse.data.expired);
        setIsTestEmail(linkCheckResponse.data.is_test_call);
        if (isSharedLink && !isExpired) {
          // shared link, need to gather candidate info
          setIsFormModalOpen(true);
        } else {
          // link validated and we have retrieved the candidate id
          setCandidateId(linkCheckResponse.data.candidate_id);
          setCallId(linkCheckResponse.data.call_id);
        }
      } catch (error: any) {
        console.error('Error checking video link:', error);
        notifications.show({
          title: 'Call failed',
          message:
            error.response?.data?.detail ||
            (error instanceof Error
              ? error.message
              : 'Error checking video link'),
          color: 'red',
        });
      }
    };
    if (encodedId) {
      fetchDataFromEncodedId();
    }
  }, [encodedId]);

  const isCallReady = useMemo(() => {
    if (encodedId && !expired && validated) {
      return true;
    }

    return (
      !!decodedData?.campaignId && !!decodedData?.candidateName && validated
    );
  }, [decodedData, encodedId, expired, validated]);

  useEffect(() => {
    if (encodedData) {
      try {
        const decoded: DecodedData = decodeData(encodedData);
        setDecodedData(decoded);
        setCandidateName(decoded.candidateName); // redundant but just one place for this
      } catch (error) {
        console.error('Error decoding data:', error);
      }
    }
  }, [encodedData]);

  const [hasMicrophoneAccess, setHasMicrophoneAccess] = useState<
    undefined | boolean
  >(undefined);

  useEffect(() => {
    // Add a delay of 500ms before requesting permissions
    const timer = setTimeout(() => {
      // Check if getUserMedia is supported
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then(() => {
            setHasMicrophoneAccess(true);
            // Do something with the stream, like setting it in state
          })
          .catch((err) => {
            console.error(
              'Microphone access denied or an error occurred:',
              err
            );
            setHasMicrophoneAccess(false);
          });
      } else {
        console.error('getUserMedia is not supported by your browser.');
      }
    }, 50);

    // Clean up the timeout
    return () => clearTimeout(timer);
  }, []); // Empty dependency array to run only on mount

  const messagesContainerRef = useRef<HTMLDivElement>(null); // Ref for the messages container

  // Check if user is at the bottom of the scroll
  const isUserAtBottom = (buffer: number) => {
    const container = messagesContainerRef.current;
    if (!container) return true;

    const actualBuffer = increasedBuffer ? 1000 : buffer;

    return (
      container.scrollHeight - container.scrollTop - actualBuffer <=
      container.clientHeight
    );
  };

  // Scroll to bottom function
  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      setIncreasedBuffer(true);
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
      setAutoScrollEnabled(true);
      setShowScrollButton(false);

      // Reset increased buffer after 3 seconds
      setTimeout(() => {
        setIncreasedBuffer(false);
      }, 2000);
    }
  };

  // Monitor scroll position
  useEffect(() => {
    const container = messagesContainerRef.current;
    if (!container) return;

    const handleScroll = () => {
      const isBottom = isUserAtBottom(50);
      setShowScrollButton(!isBottom);

      // Update auto-scroll state based on position
      if (isBottom) {
        setAutoScrollEnabled(true);
        setShowScrollButton(false);
      } else {
        setAutoScrollEnabled(false);
      }
    };

    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Auto-scroll when new messages are added
  useEffect(() => {
    if (interviewerMessages.length > 0 && autoScrollEnabled) {
      scrollToBottom();
    }
  }, [interviewerMessages, autoScrollEnabled]);

  const handleFormSubmit = async (formData: {
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
  }) => {
    const { firstName, lastName, phone, email } = formData;
    try {
      const response = await axios.post(
        `${env.REACT_APP_SERVER_URL}/create_candidate_call_from_shared_link`,
        {
          first_name: firstName,
          last_name: lastName,
          phone,
          email,
          campaign_id: campaignId,
        }
      );
      if (response.status === 200 && response.data?.data) {
        setIsFormModalOpen(false);
        setCandidateId(response.data.data.candidate_id);
        setCallId(response.data.data.call_id);
      } else {
        // If the response didn't indicate success, try to extract details for the notification
        const detailedError =
          response.data?.error ||
          JSON.stringify(response.data) ||
          'Unexpected error occurred';
        notifications.show({
          title: 'Call failed',
          message: `Status: ${response.status}. Details: ${detailedError}`,
          color: 'yellow',
        });
      }
    } catch (error) {
      notifications.show({
        title: 'Call failed',
        message: 'Failed to initiate a call',
        color: 'red',
      });
    }
  };

  return (
    <>
      {isFormModalOpen ? (
        <CandidateFormModal
          isOpen={isFormModalOpen}
          onClose={() => setIsFormModalOpen(false)}
          onSubmit={handleFormSubmit}
        />
      ) : (
        <div className='web-call-outer-container'>
          {isPartialWarningModalOpen && pastCompletionRate !== undefined && (
            <Modal
              opened={isPartialWarningModalOpen}
              onClose={() => {
                setIsPartialWarningModalOpen(false);
              }}
              title='Partially Complete Interview'
              withCloseButton={true}
              closeOnClickOutside={false}
            >
              <p
                style={{
                  padding: '12px',
                  borderRadius: '12px',
                  backgroundColor: 'var(--salv-dark-0)',
                }}
              >
                {pastCompletionRate
                  ? `You've already completed ${(pastCompletionRate * 100).toFixed(0)}% of this interview`
                  : `You have already begun this interview`}
              </p>
              <p style={{ fontSize: '14px', padding: '10px' }}>
                You may press continue to complete the interview or close the
                browser to exit.
              </p>
              <br />
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button onClick={() => setIsPartialWarningModalOpen(false)}>
                  Continue
                </Button>
              </div>
            </Modal>
          )}
          <div className='web-call-container'>
            <div className='web-call-inner-container'>
              {!validated && !expired && (
                <div className='expired-message'>
                  <Loader type='dots' color='white' />
                </div>
              )}
              {!expired && validated && (
                <div className='call-content-wrapper'>
                  <div className='retell-handler-wrapper'>
                    <RetellWebCallHandler
                      accessToken={accessToken}
                      callId={callId}
                      mediaRecorderRef={mediaRecorderRef}
                      retellWebClientRef={retellWebClientRef}
                      setInterviewerMessages={setInterviewerMessages}
                      startClicked={startClicked}
                      callEnded={callEnded}
                      setCallEnded={setCallEnded}
                      candidateName={candidateName}
                      voiceName={voiceName || ''}
                      showMicTestModal={showMicTestModal}
                      getMicLevelUpdates={(callback) => {
                        micBarsUpdateRef.current = callback;
                      }}
                      handleCloseMicTest={handleCloseMicTest}
                      hasMicrophoneAccess={hasMicrophoneAccess}
                      retryIdx={retryIdx}
                      campaignId={decodedData?.campaignId || ''}
                    />
                  </div>
                  {!isMobile && (
                    <div
                      className={`info-panel ${messagesVisible ? '' : 'panel-hidden'}`}
                    >
                      <div className='white-box'>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            paddingLeft: '16px',
                            paddingRight: '12px',
                          }}
                        >
                          <h4 className='info-header'>Interviewer Messages</h4>
                          <div
                            style={{ marginTop: 'auto', marginBottom: 'auto' }}
                          >
                            <CloseButton
                              onClick={() => setMessagesVisible(false)}
                            />
                          </div>
                        </div>
                        {messagesVisible && (
                          <div
                            className='messages-container'
                            ref={messagesContainerRef}
                          >
                            {interviewerMessages.length === 0 ? (
                              <>
                                <div className='message'>
                                  Interview questions will appear in this chat
                                  window.
                                </div>
                                <div className='message'>
                                  The chat window can be minimized or re-opened
                                  at any time.
                                </div>
                              </>
                            ) : (
                              <div className='messages-wrapper'>
                                {interviewerMessages.map((msg, index) => (
                                  <div key={index} className='message'>
                                    {msg}
                                  </div>
                                ))}
                              </div>
                            )}
                            <AutoScrollButton
                              userScrolling={showScrollButton}
                              onResumeAutoScroll={scrollToBottom}
                              styleOverride={{
                                bottom: '28px',
                                position: 'sticky',
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
              {expired && (
                <div className='expired-message'>
                  {pastCompletionRate || 0 >= 1.0
                    ? 'Interview Completed'
                    : 'Interview Link Expired'}
                </div>
              )}
            </div>
            <div className='web-controls-area'>
              <RetellControlsArea
                getAccessTokenSetupCall={getAccessTokenSetupCall}
                isCallReady={isCallReady}
                mediaRecorderRef={mediaRecorderRef}
                retellWebClientRef={retellWebClientRef}
                startClicked={startClicked}
                setStartClicked={setStartClicked}
                setMessagesVisible={setMessagesVisible}
                callEnded={callEnded}
                handleOpenMicTest={handleOpenMicTest}
                hasMicrophoneAccess={hasMicrophoneAccess}
                retryIdx={retryIdx || 0}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WebCallPage;
