import { Anchor, Badge, Text, Tooltip } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import React, { Fragment } from 'react';
import CampaignQuestionCard from 'components/CampaignQuestionCard';
import { useSearchParams } from 'react-router-dom';

import { CampaignQuestion } from './QuestionsPage';

const UnansweredQuestionsTab = ({
  questions,
  handleUnansweredQuestions,
  setScriptActiveTab,
}: {
  questions: CampaignQuestion[];
  handleUnansweredQuestions: (questionIds: string[]) => void;
  setScriptActiveTab: (tab: string) => void;
}) => {
  const [, setSearchParams] = useSearchParams();
  const handleBackgroundInfoClick = (e) => {
    e.preventDefault();
    setScriptActiveTab('sequence');
    setSearchParams({ step: 'background-info' });
  };

  const handleDismissAll = () => {
    handleUnansweredQuestions(
      questions.map((question) => question.question_id)
    );
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        marginTop: '20px',
      }}
    >
      {questions.length === 0 ? (
        <div
          style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            marginTop: '5%',
          }}
        >
          <Text size='sm' c='dimmed'>
            No questions available
          </Text>
        </div>
      ) : (
        <Fragment>
          <Text size='sm'>
            These are questions the AI didn't have an answer to, we suggest
            adding additional context to the{' '}
            <Anchor onClick={handleBackgroundInfoClick}>
              job background info
            </Anchor>
          </Text>
          <Tooltip label='Dismiss All' position='top'>
            <Badge
              color='gray'
              size='xs'
              rightSection={<IconX size={14} />}
              onClick={handleDismissAll}
            >
              Dismiss All
            </Badge>
          </Tooltip>
          {questions.map((question) => {
            return (
              <CampaignQuestionCard
                key={question.question_id}
                question={question}
                handleUnansweredQuestions={handleUnansweredQuestions}
              />
            );
          })}
        </Fragment>
      )}
    </div>
  );
};

export default UnansweredQuestionsTab;
