import React, { useEffect, useState } from 'react';
import {
  Box,
  Center,
  Grid,
  Group,
  Loader,
  Paper,
  Stack,
  Text,
  Title,
  useMantineTheme,
  Transition,
  RingProgress,
} from '@mantine/core';
import { BarChart, LineChart } from '@mantine/charts';
import '@mantine/charts/styles.css';
import env from 'env';
import axios from 'axios';
import moment from 'moment';
import { MetricTile } from 'components/MetricTile';
import {
  IconChartBar,
  IconClock,
  IconCoin,
  IconHourglass,
  IconHourglassHigh,
  IconPercentage,
  IconPhone,
  IconPhoneCall,
  IconThumbUp,
  IconUsers,
} from '@tabler/icons-react';

interface AnalyticsData {
  answerRate: number | null;
  campaignsLaunched: Array<{ week: string; count: number }>;
  calls: Array<{ week: string; calls: number }>;
  smsSent: Array<{ week: string; sms_sent: number }>;
  timeSaved: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  } | null;
  totalMetrics: {
    total_calls: number;
    cost_per_qualified: number;
  } | null;
  total_thumbs_ups: number;
  total_people_contacted: number;
  passthrough_rate: number;
  weeklyPassthroughRate: Array<{ week: string; passthrough_rate: number }>;
  weeklyCandidates: Array<{ week: string; total_candidates: number }>;
  weeklyCompletedInterviews: Array<{
    week: string;
    completed_interviews: number;
  }>;
  weeklyThumbsUp: Array<{ week: string; thumbs_up: number }>;
  weeklyThumbsDown: Array<{ week: string; thumbs_down: number }>;
  avgTimeToFirstInterview: number;
  avgInterviewLength: number;
}

const initialAnalyticsData: AnalyticsData = {
  answerRate: null,
  campaignsLaunched: [],
  calls: [],
  smsSent: [],
  timeSaved: null,
  totalMetrics: null,
  total_thumbs_ups: 0,
  total_people_contacted: 0,
  passthrough_rate: 0,
  weeklyPassthroughRate: [],
  weeklyCandidates: [],
  weeklyCompletedInterviews: [],
  weeklyThumbsUp: [],
  weeklyThumbsDown: [],
  avgTimeToFirstInterview: 0,
  avgInterviewLength: 0,
};

interface EndpointStatus {
  [key: string]: boolean;
}

interface ComponentLoadingState {
  answerRate: boolean;
  credits: boolean;
  campaigns: boolean;
  calls: boolean;
  sms: boolean;
  timeSaved: boolean;
  totalMetrics: boolean;
  avgInterviewLength: boolean;
  avgTimeToFirstInterview: boolean;
  passthroughRate: boolean;
  weeklyPassthroughRate: boolean;
  totalThumbsUp: boolean;
  weeklyThumbsDown: boolean;
  weeklyThumbsUp: boolean;
  weeklyCompletedInterviews: boolean;
  weeklyCandidates: boolean;
}

const AnalyticsPage: React.FC = () => {
  const theme = useMantineTheme();
  const [analyticsData, setAnalyticsData] =
    useState<AnalyticsData>(initialAnalyticsData);
  const [endpointsCalled, setEndpointsCalled] = useState<EndpointStatus>({});
  const [mountedTiles, setMountedTiles] = useState<boolean[]>(
    Array(9).fill(false)
  );

  useEffect(() => {
    // Animate tiles one by one with a staggered delay
    mountedTiles.forEach((_, index) => {
      setTimeout(
        () => {
          setMountedTiles((prev) => {
            const updated = [...prev];
            updated[index] = true;
            return updated;
          });
        },
        100 + index * 80
      ); // 100ms initial delay, then 80ms between each tile
    });
  }, [mountedTiles]);

  // loading states for individual components
  const [loadingStates, setLoadingStates] = useState<ComponentLoadingState>({
    answerRate: true,
    credits: true,
    campaigns: true,
    calls: true,
    sms: true,
    timeSaved: true,
    totalMetrics: true,
    avgInterviewLength: true,
    avgTimeToFirstInterview: true,
    passthroughRate: true,
    weeklyPassthroughRate: true,
    totalThumbsUp: true,
    weeklyThumbsDown: true,
    weeklyThumbsUp: true,
    weeklyCompletedInterviews: true,
    weeklyCandidates: true,
  });

  const org_id = '5199b8c5-74ef-46dd-8267-0a0e55007dbf';

  function hexToRgb(hex: string) {
    const bigint = parseInt(hex.replace('#', ''), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return `${r}, ${g}, ${b}`;
  }

  const fetchAnalyticsData = async () => {
    const fetchMetric = async (
      endpoint: string,
      component: keyof ComponentLoadingState
    ) => {
      if (endpointsCalled[endpoint]) {
        return null;
      }

      try {
        setLoadingStates((prev) => ({ ...prev, [component]: true }));
        const response = await axios.get(
          `${env.REACT_APP_SERVER_URL}${endpoint}`,
          {
            params: { org_id },
          }
        );
        setEndpointsCalled((prev) => ({
          ...prev,
          [endpoint]: true,
        }));
        return response.data;
      } catch (error) {
        console.error(`Error fetching ${endpoint}:`, error);
        return null;
      } finally {
        setLoadingStates((prev) => ({ ...prev, [component]: false }));
      }
    };

    // Define endpoints in order of priority
    const priorityEndpoints = [
      // High priority - Quick loading metrics for main cards
      {
        url: '/analytics/answer_rate_per_week',
        component: 'answerRate' as const,
        setter: (data: any) =>
          setAnalyticsData((prev) => ({
            ...prev,
            answerRate: data?.answer_rate ?? 0,
          })),
      },
      // Medium priority - Chart data
      {
        url: '/analytics/weekly_candidates',
        component: 'weeklyCandidates' as const,
        setter: (data: any) =>
          setAnalyticsData((prev) => ({
            ...prev,
            weeklyCandidates: data || [],
          })),
      },
      {
        url: '/analytics/weekly_completed_interviews',
        component: 'weeklyCompletedInterviews' as const,
        setter: (data: any) =>
          setAnalyticsData((prev) => ({
            ...prev,
            weeklyCompletedInterviews: data || [],
          })),
      },
      {
        url: '/analytics/weekly_thumbs_up',
        component: 'weeklyThumbsUp' as const,
        setter: (data: any) =>
          setAnalyticsData((prev) => ({
            ...prev,
            weeklyThumbsUp: data || [],
          })),
      },
      {
        url: '/analytics/weekly_thumbs_down',
        component: 'weeklyThumbsDown' as const,
        setter: (data: any) =>
          setAnalyticsData((prev) => ({
            ...prev,
            weeklyThumbsDown: data || [],
          })),
      },
      // {
      //   url: '/analytics/campaigns_launched_per_week',
      //   component: 'campaigns' as const,
      //   setter: (data: any) =>
      //     setAnalyticsData((prev) => ({
      //       ...prev,
      //       campaignsLaunched: data || [],
      //     })),
      // },
      {
        url: '/analytics/calls_per_week',
        component: 'calls' as const,
        setter: (data: any) =>
          setAnalyticsData((prev) => ({ ...prev, calls: data || [] })),
      },
      // // Lower priority - Additional metrics
      {
        url: '/analytics/avg_time_to_first_interview',
        component: 'avgTimeToFirstInterview' as const,
        setter: (data: any) =>
          setAnalyticsData((prev) => ({
            ...prev,
            avgTimeToFirstInterview: data?.avg_time_to_first_interview || 0,
          })),
      },
      {
        url: '/analytics/avg_interview_length',
        component: 'avgInterviewLength' as const,
        setter: (data: any) =>
          setAnalyticsData((prev) => ({
            ...prev,
            avgInterviewLength: data?.avg_interview_length || 0,
          })),
      },
      {
        url: '/analytics/total_time_saved',
        component: 'timeSaved' as const,
        setter: (data: any) =>
          setAnalyticsData((prev) => ({
            ...prev,
            timeSaved: data?.time_saved || null,
          })),
      },
      {
        url: '/analytics/total_metrics',
        component: 'totalMetrics' as const,
        setter: (data: any) =>
          setAnalyticsData((prev) => ({
            ...prev,
            totalMetrics: data || null,
          })),
      },
      {
        url: '/analytics/total_thumbs_ups',
        component: 'totalThumbsUp' as const,
        setter: (data: any) =>
          setAnalyticsData((prev) => ({
            ...prev,
            total_thumbs_ups: data?.total_thumbs_ups || 0,
          })),
      },
      {
        url: '/analytics/total_people_contacted',
        component: 'totalMetrics' as const,
        setter: (data: any) =>
          setAnalyticsData((prev) => ({
            ...prev,
            total_people_contacted: data?.total_people_contacted || 0,
          })),
      },
      {
        url: '/analytics/passthrough_rate',
        component: 'passthroughRate' as const,
        setter: (data: any) =>
          setAnalyticsData((prev) => ({
            ...prev,
            passthrough_rate: data?.passthrough_rate || 0,
          })),
      },
      {
        url: '/analytics/weekly_passthrough_rate',
        component: 'weeklyPassthroughRate' as const,
        setter: (data: any) =>
          setAnalyticsData((prev) => ({
            ...prev,
            weeklyPassthroughRate: data?.weekly_passthrough_rate || [],
          })),
      },
      {
        url: '/analytics/sms_sent_per_week',
        component: 'sms' as const,
        setter: (data: any) =>
          setAnalyticsData((prev) => ({ ...prev, smsSent: data || [] })),
      },
    ];

    const smsEndpoint = priorityEndpoints.find(
      (endpoint) => endpoint.url === '/analytics/sms_sent_per_week'
    );

    // Filter out the SMS endpoint for the initial batch
    const regularEndpoints = priorityEndpoints.filter(
      (endpoint) => endpoint.url !== '/analytics/sms_sent_per_week'
    );

    // First fetch all regular endpoints in parallel
    const endpointsToFetch = regularEndpoints.filter(
      (endpoint) => !endpointsCalled[endpoint.url]
    );

    const promises = endpointsToFetch.map((endpoint) =>
      fetchMetric(endpoint.url, endpoint.component).then((data) => {
        if (data) {
          endpoint.setter(data);
        }
        return { endpoint, data };
      })
    );

    await Promise.all(promises);

    if (smsEndpoint && !endpointsCalled[smsEndpoint.url]) {
      const smsData = await fetchMetric(smsEndpoint.url, smsEndpoint.component);
      if (smsData) {
        smsEndpoint.setter(smsData);
      }
    }
  };

  const weeklyPassthroughRateData = React.useMemo(() => {
    return analyticsData.weeklyPassthroughRate.map((item) => {
      const startDate = moment(item.week);
      const endDate = startDate.clone().add(6, 'days');
      const formattedWeek = `${startDate.format('DD')} - ${endDate.format('DD MMMM')}`;
      return {
        week: formattedWeek,
        'Passthrough Rate (%)': item.passthrough_rate,
      };
    });
  }, [analyticsData.weeklyPassthroughRate]);

  useEffect(() => {
    if (!Object.values(endpointsCalled).some(Boolean)) {
      fetchAnalyticsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endpointsCalled]);

  // Combine metrics data for the bar chart
  const combinedMetricsData = React.useMemo(() => {
    // Create a map of weeks to aggregate data
    const weekMap = new Map();

    const formatWeek = (week: string) => {
      const startDate = moment(week);
      const endDate = startDate.clone().add(6, 'days');
      return `${startDate.format('DD')} - ${endDate.format('DD MMMM')}`;
    };

    analyticsData.campaignsLaunched.forEach((item) => {
      const formattedWeek = formatWeek(item.week);
      if (!weekMap.has(formattedWeek)) {
        weekMap.set(formattedWeek, {
          week: formattedWeek,
          Campaigns: 0,
          Calls: 0,
          SMS: 0,
          Candidates: 0,
          CompletedInterviews: 0,
          ThumbsUp: 0,
          ThumbsDown: 0,
        });
      }
      weekMap.get(formattedWeek).Campaigns = item.count;
    });

    analyticsData.calls.forEach((item) => {
      const formattedWeek = formatWeek(item.week);
      if (!weekMap.has(formattedWeek)) {
        weekMap.set(formattedWeek, {
          week: formattedWeek,
          Campaigns: 0,
          Calls: 0,
          SMS: 0,
          Candidates: 0,
          CompletedInterviews: 0,
          ThumbsUp: 0,
          ThumbsDown: 0,
        });
      }
      weekMap.get(formattedWeek).Calls = item.calls;
    });

    analyticsData.smsSent.forEach((item) => {
      const formattedWeek = formatWeek(item.week);
      if (!weekMap.has(formattedWeek)) {
        weekMap.set(formattedWeek, {
          week: formattedWeek,
          Campaigns: 0,
          Calls: 0,
          SMS: 0,
          Candidates: 0,
          CompletedInterviews: 0,
          ThumbsUp: 0,
          ThumbsDown: 0,
        });
      }
      weekMap.get(formattedWeek).SMS = item.sms_sent;
    });

    analyticsData.weeklyCandidates.forEach((item) => {
      const formattedWeek = formatWeek(item.week);
      if (!weekMap.has(formattedWeek)) {
        weekMap.set(formattedWeek, {
          week: formattedWeek,
          Campaigns: 0,
          Calls: 0,
          SMS: 0,
          Candidates: 0,
          CompletedInterviews: 0,
          ThumbsUp: 0,
          ThumbsDown: 0,
        });
      }
      weekMap.get(formattedWeek).Candidates = item.total_candidates;
    });

    analyticsData.weeklyCompletedInterviews.forEach((item) => {
      const formattedWeek = formatWeek(item.week);
      if (!weekMap.has(formattedWeek)) {
        weekMap.set(formattedWeek, {
          week: formattedWeek,
          Campaigns: 0,
          Calls: 0,
          SMS: 0,
          Candidates: 0,
          CompletedInterviews: 0,
          ThumbsUp: 0,
          ThumbsDown: 0,
        });
      }
      weekMap.get(formattedWeek).CompletedInterviews =
        item.completed_interviews;
    });

    analyticsData.weeklyThumbsUp.forEach((item) => {
      const formattedWeek = formatWeek(item.week);
      if (!weekMap.has(formattedWeek)) {
        weekMap.set(formattedWeek, {
          week: formattedWeek,
          Campaigns: 0,
          Calls: 0,
          SMS: 0,
          Candidates: 0,
          CompletedInterviews: 0,
          ThumbsUp: 0,
          ThumbsDown: 0,
        });
      }
      weekMap.get(formattedWeek).ThumbsUp = item.thumbs_up;
    });

    analyticsData.weeklyThumbsDown.forEach((item) => {
      const formattedWeek = formatWeek(item.week);
      if (!weekMap.has(formattedWeek)) {
        weekMap.set(formattedWeek, {
          week: formattedWeek,
          Campaigns: 0,
          Calls: 0,
          SMS: 0,
          Candidates: 0,
          CompletedInterviews: 0,
          ThumbsUp: 0,
          ThumbsDown: 0,
        });
      }
      weekMap.get(formattedWeek).ThumbsDown = item.thumbs_down;
    });

    return Array.from(weekMap.values()).sort((a, b) =>
      a.week.localeCompare(b.week)
    );
  }, [
    analyticsData.campaignsLaunched,
    analyticsData.calls,
    analyticsData.smsSent,
    analyticsData.weeklyCandidates,
    analyticsData.weeklyCompletedInterviews,
    analyticsData.weeklyThumbsUp,
    analyticsData.weeklyThumbsDown,
  ]);

  // Create time saved trend data
  const timeSavedData = React.useMemo(() => {
    // Estimate time saved based on call volume
    return analyticsData.calls.map((item) => {
      const startDate = moment(item.week);
      const endDate = startDate.clone().add(6, 'days');
      const formattedWeek = `${startDate.format('DD')} - ${endDate.format('DD MMMM')}`;
      return {
        week: formattedWeek,
        'Time Saved (hours)': item.calls * 0.25, // Assuming each call saves 15 minutes
      };
    });
  }, [analyticsData.calls]);

  return (
    <div className='page-container-common'>
      <Stack>
        <Title order={2}>Analytics Dashboard</Title>

        <Grid gutter='md'>
          {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((index) => (
            <Transition
              key={index}
              mounted={mountedTiles[index]}
              transition={{
                in: { opacity: 1, transform: 'translateX(0)' },
                out: { opacity: 0, transform: 'translateX(50px)' },
                transitionProperty: 'transform, opacity',
              }}
              duration={300}
              timingFunction='ease-out'
            >
              {(styles) => (
                <Grid.Col span={4} style={styles}>
                  {/* Your existing MetricTile components, e.g.: */}
                  {index === 0 && (
                    <MetricTile
                      title='Answer Rate'
                      value={
                        <Box
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%',
                          }}
                        >
                          <RingProgress
                            sections={[
                              {
                                value: analyticsData.answerRate || 0,
                                color: theme.colors.blue[6],
                                tooltip: `${analyticsData.answerRate}% Answer Rate`,
                              },
                            ]}
                            label={
                              <Stack gap={0} align='center'>
                                <Text
                                  size='md'
                                  fw={700}
                                  ta='center'
                                  style={{ lineHeight: 1 }}
                                >
                                  {analyticsData.answerRate}%
                                </Text>
                              </Stack>
                            }
                            size={90}
                            thickness={8}
                            roundCaps
                            rootColor={theme.colors.gray[2]}
                          />
                        </Box>
                      }
                      icon={
                        <IconPhone
                          size={24}
                          stroke={1.5}
                          color={theme.colors.blue[6]}
                        />
                      }
                      isLoading={loadingStates.answerRate}
                      color={theme.colors.blue[6]}
                    />
                  )}
                  {index === 1 && (
                    <MetricTile
                      title='Time Saved'
                      value={
                        analyticsData.timeSaved
                          ? `${analyticsData.timeSaved.days}d ${analyticsData.timeSaved.hours}h`
                          : '0h'
                      }
                      icon={
                        <IconClock
                          size={24}
                          stroke={1.5}
                          color={theme.colors.green[6]}
                        />
                      }
                      isLoading={loadingStates.timeSaved}
                      color={theme.colors.green[6]}
                    />
                  )}
                  {index === 2 && (
                    <MetricTile
                      title='Cost per Qualified Candidate'
                      value={`$${analyticsData.totalMetrics?.cost_per_qualified.toFixed(2) || '0.00'}`}
                      icon={
                        <IconCoin
                          size={24}
                          stroke={1.5}
                          color={theme.colors.yellow[6]}
                        />
                      }
                      isLoading={loadingStates.totalThumbsUp}
                      color={theme.colors.yellow[6]}
                    />
                  )}
                  {index === 3 && (
                    <MetricTile
                      title='Total Calls'
                      value={analyticsData.totalMetrics?.total_calls || 0}
                      icon={
                        <IconPhoneCall
                          size={24}
                          stroke={1.5}
                          color={theme.colors.indigo[6]}
                        />
                      }
                      isLoading={loadingStates.totalMetrics}
                      color={theme.colors.indigo[6]}
                    />
                  )}
                  {index === 4 && (
                    <MetricTile
                      title='Total Qualified Candidates'
                      value={analyticsData.total_thumbs_ups || 0}
                      icon={
                        <IconThumbUp
                          size={24}
                          stroke={1.5}
                          color={theme.colors.teal[6]}
                        />
                      }
                      isLoading={loadingStates.totalThumbsUp}
                      color={theme.colors.teal[6]}
                    />
                  )}
                  {index === 5 && (
                    <MetricTile
                      title='Total People Contacted'
                      value={analyticsData.total_people_contacted || 0}
                      icon={
                        <IconUsers
                          size={24}
                          stroke={1.5}
                          color={theme.colors.blue[6]}
                        />
                      }
                      isLoading={loadingStates.totalMetrics}
                      color={theme.colors.blue[6]}
                    />
                  )}
                  {index === 6 && (
                    <MetricTile
                      title='Passthrough Rate'
                      value={
                        <Box
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%',
                          }}
                        >
                          <RingProgress
                            sections={[
                              {
                                value: analyticsData.passthrough_rate || 0,
                                color: theme.colors.cyan[6],
                                tooltip: `${analyticsData.passthrough_rate}% Passthrough Rate`,
                              },
                            ]}
                            label={
                              <Stack gap={0} align='center'>
                                <Text
                                  size='md'
                                  fw={700}
                                  ta='center'
                                  style={{ lineHeight: 1 }}
                                >
                                  {analyticsData.passthrough_rate}%
                                </Text>
                              </Stack>
                            }
                            size={90}
                            thickness={8}
                            roundCaps
                            rootColor={theme.colors.gray[2]}
                          />
                        </Box>
                      }
                      icon={
                        <IconPercentage
                          size={24}
                          stroke={1.5}
                          color={theme.colors.cyan[6]}
                        />
                      }
                      isLoading={loadingStates.passthroughRate}
                      color={theme.colors.cyan[6]}
                    />
                  )}
                  {index === 7 && (
                    <MetricTile
                      title='Avg Time to First Interview'
                      value={`${Math.floor(analyticsData.avgTimeToFirstInterview) || 0} hours`}
                      icon={
                        <IconHourglass
                          size={24}
                          stroke={1.5}
                          color={theme.colors.grape[6]}
                        />
                      }
                      isLoading={loadingStates.avgTimeToFirstInterview}
                      color={theme.colors.grape[6]}
                    />
                  )}
                  {index === 8 && (
                    <MetricTile
                      title='Avg Interview Length'
                      value={`${Math.floor(analyticsData.avgInterviewLength) || 0} minutes`}
                      icon={
                        <IconHourglassHigh
                          size={24}
                          stroke={1.5}
                          color={theme.colors.violet[6]}
                        />
                      }
                      isLoading={loadingStates.avgInterviewLength}
                      color={theme.colors.violet[6]}
                    />
                  )}
                </Grid.Col>
              )}
            </Transition>
          ))}
        </Grid>

        <Paper
          withBorder
          p='md'
          radius='md'
          styles={(theme) => ({
            root: {
              '&:hover': {
                transform: 'translateY(-4px)',
                boxShadow: theme.shadows.md,
                transition: 'transform 0.2s, box-shadow 0.2s',
              },
              background: `linear-gradient(45deg, ${theme.white} 0%, rgba(${hexToRgb(theme.colors.blue[6])}, 0.05) 100%)`,
            },
          })}
        >
          <Stack>
            <Group>
              <IconChartBar
                size={24}
                stroke={1.5}
                color={theme.colors.blue[6]}
              />
              <Title order={3} c={theme.colors.blue[6]}>
                Weekly Passthrough Rate
              </Title>
            </Group>
            {loadingStates.weeklyPassthroughRate ? (
              <Center h={400}>
                <Loader />
              </Center>
            ) : (
              <Box mb={60}>
                <LineChart
                  h={400}
                  data={weeklyPassthroughRateData}
                  dataKey='week'
                  series={[
                    {
                      name: 'Passthrough Rate (%)',
                      color: theme.colors.blue[6],
                    },
                  ]}
                  tickLine='y'
                  gridAxis='xy'
                  withLegend
                  legendProps={{
                    verticalAlign: 'bottom',
                    height: 60,
                    style: { paddingTop: '20px' },
                  }}
                  yAxisProps={{ tickCount: 5 }}
                  curveType='monotone'
                  withDots
                  tooltipProps={{
                    content: ({ payload, label }) => {
                      if (!payload?.[0]) return null;
                      return (
                        <Paper
                          p='sm'
                          withBorder
                          bg='white'
                          style={{
                            border: '1px solid #ddd',
                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                          }}
                        >
                          <Text fw={500} mb={5}>
                            {label}
                          </Text>
                          <Text c={payload[0].color}>
                            {payload[0].name}:{' '}
                            {Number(payload[0].value).toFixed(1)}
                          </Text>
                        </Paper>
                      );
                    },
                  }}
                />
              </Box>
            )}
          </Stack>
        </Paper>

        <Paper
          withBorder
          p='md'
          radius='md'
          styles={(theme) => ({
            root: {
              '&:hover': {
                transform: 'translateY(-4px)',
                boxShadow: theme.shadows.md,
                transition: 'transform 0.2s, box-shadow 0.2s',
              },
              background: `linear-gradient(45deg, ${theme.white} 0%, rgba(${hexToRgb(theme.colors.orange[6])}, 0.05) 100%)`,
            },
          })}
        >
          <Stack>
            <Group>
              <IconPhone
                size={24}
                stroke={1.5}
                color={theme.colors.orange[6]}
              />
              <Title order={3} c={theme.colors.orange[6]}>
                Weekly Communication Metrics
              </Title>
            </Group>
            {
              <Grid>
                <Grid.Col span={6}>
                  <Box mb={60}>
                    <BarChart
                      h={400}
                      data={combinedMetricsData}
                      dataKey='week'
                      series={[
                        { name: 'Candidates', color: theme.colors.blue[6] },
                        { name: 'Calls', color: theme.colors.green[6] },
                        // Only include SMS in the chart when it's not loading
                        ...(!loadingStates.sms
                          ? [{ name: 'SMS', color: theme.colors.orange[6] }]
                          : []),
                      ]}
                      tickLine='y'
                      gridAxis='xy'
                      withLegend
                      legendProps={{
                        verticalAlign: 'bottom',
                        align: 'center',
                        height: 50,
                        wrapperStyle: { paddingTop: '20px' },
                      }}
                      yAxisProps={{ tickCount: 5 }}
                      tooltipProps={{
                        content: ({ payload, label }) => {
                          if (!payload || payload.length === 0) return null;
                          return (
                            <Paper
                              p='sm'
                              withBorder
                              bg='white'
                              style={{
                                border: '1px solid #ddd',
                                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                              }}
                            >
                              <Text fw={500} mb={5} color='black'>
                                {label}
                              </Text>
                              {payload.map((entry) => (
                                <Text key={entry.name} color={entry.color}>
                                  {entry.name}: {entry.value}
                                </Text>
                              ))}
                            </Paper>
                          );
                        },
                      }}
                    />
                    {loadingStates.sms && (
                      <Group justify='flex-end' mt={25}>
                        <Text size='sm' color='dimmed'>
                          Loading SMS data
                        </Text>
                        <Loader size='xs' />
                      </Group>
                    )}
                  </Box>
                </Grid.Col>
                <Grid.Col span={6}>
                  <Box mb={60}>
                    <BarChart
                      h={400}
                      data={combinedMetricsData}
                      dataKey='week'
                      series={[
                        {
                          name: 'Candidates',
                          label: 'Candidates',
                          color: theme.colors.blue[6],
                        },
                        {
                          name: 'CompletedInterviews',
                          label: 'Completed Interviews',
                          color: theme.colors.red[6],
                        },
                        {
                          name: 'ThumbsUp',
                          label: 'Thumbs Up',
                          color: theme.colors.teal[6],
                        },
                        {
                          name: 'ThumbsDown',
                          label: 'Thumbs Down',
                          color: theme.colors.pink[6],
                        },
                      ]}
                      tickLine='y'
                      gridAxis='xy'
                      withLegend
                      legendProps={{
                        verticalAlign: 'bottom',
                        align: 'center',
                        height: 50,
                        wrapperStyle: { paddingTop: '20px' },
                      }}
                      yAxisProps={{ tickCount: 5 }}
                      tooltipProps={{
                        content: ({ payload, label }) => {
                          if (!payload || payload.length === 0) return null;
                          return (
                            <Paper
                              p='sm'
                              withBorder
                              bg='white'
                              style={{
                                border: '1px solid #ddd',
                                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                              }}
                            >
                              <Text fw={500} mb={5} color='black'>
                                {label}
                              </Text>
                              {payload.map((entry) => (
                                <Text key={entry.name} color={entry.color}>
                                  {entry.name}: {entry.value}
                                </Text>
                              ))}
                            </Paper>
                          );
                        },
                      }}
                    />
                  </Box>
                </Grid.Col>
              </Grid>
            }
          </Stack>
        </Paper>

        <Paper
          withBorder
          p='md'
          radius='md'
          styles={(theme) => ({
            root: {
              '&:hover': {
                transform: 'translateY(-4px)',
                boxShadow: theme.shadows.md,
                transition: 'transform 0.2s, box-shadow 0.2s',
              },
              background: `linear-gradient(45deg, ${theme.white} 0%, rgba(${hexToRgb(theme.colors.green[6])}, 0.05) 100%)`,
            },
          })}
        >
          <Stack>
            <Group>
              <IconHourglass
                size={24}
                stroke={1.5}
                color={theme.colors.green[6]}
              />
              <Title order={3} c={theme.colors.green[6]}>
                Time Saved Trend
              </Title>
            </Group>
            {loadingStates.timeSaved || loadingStates.calls ? (
              <Center h={400}>
                <Loader />
              </Center>
            ) : (
              <Box mb={60}>
                <BarChart
                  h={400}
                  data={timeSavedData}
                  dataKey='week'
                  series={[
                    {
                      name: 'Time Saved (hours)',
                      color: theme.colors.violet[6],
                    },
                  ]}
                  tickLine='y'
                  gridAxis='xy'
                  withLegend
                  legendProps={{
                    verticalAlign: 'bottom',
                    height: 60,
                    style: { paddingTop: '20px' },
                  }}
                  yAxisProps={{ tickCount: 5 }}
                  tooltipProps={{
                    content: ({ payload, label }) => {
                      if (!payload?.[0]) return null;
                      return (
                        <Paper
                          p='sm'
                          withBorder
                          bg='white'
                          style={{
                            border: '1px solid #ddd',
                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                          }}
                        >
                          <Text fw={500} mb={5}>
                            {label}
                          </Text>
                          <Text c={payload[0].color}>
                            {payload[0].name}:{' '}
                            {Number(payload[0].value).toFixed(1)}
                          </Text>
                        </Paper>
                      );
                    },
                  }}
                />
              </Box>
            )}
          </Stack>
        </Paper>
      </Stack>
    </div>
  );
};

export default AnalyticsPage;
