import { Button, Text } from '@mantine/core';
import { IconLock } from '@tabler/icons-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const RestrictedAccessPage = ({ isLoggedIn }) => {
  const navigate = useNavigate();
  const handleGoToLogin = () => {
    navigate('/login');
  };

  const handleGoHome = () => {
    navigate('/');
  };
  return (
    <div
      className='page-container-common'
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          alignItems: 'center',
        }}
      >
        <IconLock color='var(--salv-dark-5)' size={70} />
        <Text style={{ fontSize: '24px' }}>
          You do not have access to this resource
        </Text>
        {!isLoggedIn ? (
          <Button onClick={handleGoToLogin}>Login</Button>
        ) : (
          <Button onClick={handleGoHome}>Go Home</Button>
        )}
      </div>
    </div>
  );
};

export default RestrictedAccessPage;
