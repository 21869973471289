import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from 'react';
import {
  Table,
  Button,
  Switch,
  Group,
  Stack,
  Center,
  Loader,
  TextInput,
  ActionIcon,
} from '@mantine/core';
import { useDebouncedCallback } from '@mantine/hooks';
import dayjs from 'dayjs';
import { IconSearch, IconX } from '@tabler/icons-react';
import env from 'env';
import SortableHeader from 'components/common/SortableHeader';

import axios from '../../api/axiosConfig';
import { daysAgo } from '../../utils/dateUtils';
import './JobPostingTipsPage.css';
import FilterBar from './components/FilterBar';
import { GoogleMapsPlaceWithDescription } from '../../components/location-autocomplete/types';
import {
  FilterContext,
  withFilterContextProvider,
} from './components/FilterContext';
import { isState } from '../../components/location-autocomplete/utils';
import {
  AgencyFilterState,
  ClientFilterState,
  DateFilterState,
  LocationFilterState,
  RevenueFilterState,
  ScoreFilterState,
} from './components/types';
import 'css/common.css';

interface SortConfig {
  column: string;
  direction: 'ascending' | 'descending';
}

const JobPostingTipsPage = () => {
  const [loading, setLoading] = useState(false);
  const [jobTips, setJobTips] = useState<any[]>([]);
  const [selectedJobTipId, setSelectedJobTipId] = useState('');
  const [allDataLoaded, setAllDataLoaded] = useState(false);
  const [includeHidden, setIncludeHidden] = useState(false);
  const [hiding, setHiding] = useState(false);
  const observer = useRef<null | IntersectionObserver>(null);

  const [searchValue, setSearchValue] = useState<string>('');

  const filterState = useContext(FilterContext);
  const locationFilterState = filterState?.activeFilters?.find(
    (filter) => filter.type === 'location'
  )?.state as LocationFilterState;
  const clientFilterState = filterState?.activeFilters?.find(
    (filter) => filter.type === 'client'
  )?.state as ClientFilterState;
  const agencyFilterState = filterState?.activeFilters?.find(
    (filter) => filter.type === 'agency'
  )?.state as AgencyFilterState;
  const scoreFilterState = filterState?.activeFilters?.find(
    (filter) => filter.type === 'score'
  )?.state as ScoreFilterState;
  const revenueFilterState = filterState?.activeFilters?.find(
    (filter) => filter.type === 'revenue'
  )?.state as RevenueFilterState;
  const postedDateFilterState = filterState?.activeFilters?.find(
    (filter) => filter.type === 'posted'
  )?.state as DateFilterState;
  const lastSeenFilterState = filterState?.activeFilters?.find(
    (filter) => filter.type === 'seen'
  )?.state as DateFilterState;

  const [sort, setSort] = useState<SortConfig | undefined>();
  const handleSort = (column: string) => () => {
    if (sort && sort.column === column) {
      if (sort.direction === 'descending')
        setSort({ column, direction: 'ascending' });
      else setSort(undefined);
    } else {
      setSort({ column, direction: 'descending' });
    }
  };

  const ITEMS_PER_PAGE = 20;

  const linkifyText = (text) => {
    if (!text) {
      return '';
    }
    const urlPattern =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])|(www\.[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
    return text.replace(urlPattern, function (url) {
      let hyperlink = url;
      if (!hyperlink.match('^https?://')) {
        hyperlink = 'http://' + hyperlink;
      }
      return '<a href="' + hyperlink + '" target="_blank">' + url + '</a>';
    });
  };

  const toggleIncludeHidden = () => {
    setIncludeHidden(!includeHidden);
  };

  const fetchJobTips = useDebouncedCallback(
    async (
      offset: number,
      filters: {
        query: string;
        includeHidden?: boolean;
        selectedLocation?: GoogleMapsPlaceWithDescription;
        radius?: number;
        selectedClientId?: string;
        selectedAgencies?: string[];
        revenueRanges?: number[][];
        score?: number;
        posted?: {
          startDate: Date | null;
          endDate: Date | null;
        };
        seen?: {
          startDate: Date | null;
          endDate: Date | null;
        };
      },
      sort?: SortConfig
    ) => {
      setLoading(true);
      try {
        const payload = {
          query: searchValue || undefined,
          count: ITEMS_PER_PAGE,
          offset: offset,
          includeHidden: filters.includeHidden,
        };
        if (filters.selectedLocation) {
          // check if the location is a state
          if (isState(filters.selectedLocation)) {
            payload['location'] = {
              state: filters.selectedLocation.formatted_address,
            };
          } else if (filters.radius) {
            payload['location'] = {
              location: filters.selectedLocation.geometry?.location,
              radius: filters.radius,
            };
          }
        }
        if (filters.selectedClientId) {
          payload['client'] = filters.selectedClientId;
        }
        if (filters.selectedAgencies && filters.selectedAgencies.length > 0) {
          payload['agencies'] = filters.selectedAgencies;
        }
        if (filters.score) {
          payload['score'] = filters.score;
        }
        if (filters.revenueRanges) {
          payload['revenueRanges'] = filters.revenueRanges;
        }
        if (filters.posted) {
          const { startDate, endDate } = filters.posted;
          payload['posted'] = {
            startDate: startDate ? dayjs(startDate).toISOString() : undefined,
            endDate: endDate
              ? dayjs(endDate).endOf('day').toISOString()
              : undefined,
          };
        }
        if (filters.seen) {
          const { startDate, endDate } = filters.seen;
          payload['seen'] = {
            startDate: startDate ? dayjs(startDate).toISOString() : undefined,
            endDate: endDate
              ? dayjs(endDate).endOf('day').toISOString()
              : undefined,
          };
        }

        if (sort) {
          payload['sort'] = {
            column: sort.column,
            descending: sort.direction === 'descending',
          };
        }

        const response = await axios.post(
          `${env.REACT_APP_SERVER_URL}/get_job_posting_tips`,
          payload
        );
        const newTips = response.data.jobPostingTips;
        setJobTips((prevTips) => {
          const existingJobPostingIds = new Set(
            prevTips.map((tip) => tip.job_posting_tip_id)
          );
          const filteredNewTips = newTips.filter(
            (tip) => !existingJobPostingIds.has(tip.job_posting_tip_id)
          );
          if (filteredNewTips.length === 0 && prevTips.length === offset) {
            setAllDataLoaded(true);
          }
          return [...prevTips, ...filteredNewTips];
        });
      } catch (error) {
        console.error('Error fetching tips:', error);
      }
      setLoading(false);
    },
    300
  );

  useEffect(() => {
    setJobTips([]);
    setAllDataLoaded(false);
    fetchJobTips(
      0,
      {
        query: searchValue,
        includeHidden,
        selectedClientId: clientFilterState?.selectedClient?.client_id,
        selectedAgencies: agencyFilterState?.selectedAgencies,
        selectedLocation: locationFilterState?.selectedLocation,
        radius: locationFilterState?.radius,
        revenueRanges: revenueFilterState?.selectedRevenueRanges?.map(
          (r) => r.Range
        ),
        posted:
          postedDateFilterState && !postedDateFilterState?.isEmpty
            ? {
                startDate: postedDateFilterState.startDate,
                endDate: postedDateFilterState.endDate,
              }
            : undefined,
        seen:
          lastSeenFilterState && !lastSeenFilterState?.isEmpty
            ? {
                startDate: lastSeenFilterState.startDate,
                endDate: lastSeenFilterState.endDate,
              }
            : undefined,
      },
      sort
    );
  }, [
    fetchJobTips,
    searchValue,
    includeHidden,
    locationFilterState?.selectedLocation,
    locationFilterState?.radius,
    revenueFilterState?.selectedRevenueRanges,
    clientFilterState?.selectedClient?.client_id,
    agencyFilterState?.selectedAgencies,
    scoreFilterState?.score,
    lastSeenFilterState,
    postedDateFilterState,
    sort,
  ]);

  const sentinelRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          fetchJobTips(
            jobTips.length,
            {
              query: searchValue,
              includeHidden,
              selectedLocation: locationFilterState?.selectedLocation,
              radius: locationFilterState?.radius,
              selectedClientId: clientFilterState?.selectedClient?.client_id,
              selectedAgencies: agencyFilterState?.selectedAgencies,
              score: scoreFilterState?.score,
              revenueRanges: revenueFilterState?.selectedRevenueRanges?.map(
                (r) => r.Range
              ),
              posted:
                postedDateFilterState && !postedDateFilterState?.isEmpty
                  ? {
                      startDate: postedDateFilterState.startDate,
                      endDate: postedDateFilterState.endDate,
                    }
                  : undefined,
              seen:
                lastSeenFilterState && !lastSeenFilterState?.isEmpty
                  ? {
                      startDate: lastSeenFilterState.startDate,
                      endDate: lastSeenFilterState.endDate,
                    }
                  : undefined,
            },
            sort
          );
        }
      });
      if (node) observer.current.observe(node);
    },
    [
      loading,
      jobTips.length,
      fetchJobTips,
      searchValue,
      includeHidden,
      locationFilterState?.selectedLocation,
      locationFilterState?.radius,
      clientFilterState?.selectedClient?.client_id,
      agencyFilterState?.selectedAgencies,
      scoreFilterState?.score,
      revenueFilterState?.selectedRevenueRanges,
      lastSeenFilterState,
      postedDateFilterState,
      sort,
    ]
  );

  const selectedJobTip = jobTips.find(
    (tip) => tip.job_posting_tip_id === selectedJobTipId
  ); // memoize

  const setJobHidden = async (jobTipId: string, hidden: boolean) => {
    const payload = { job_posting_tip_id: jobTipId, hidden: hidden };
    if (hiding) {
      return;
    }
    setHiding(true);
    try {
      await axios.post(
        `${env.REACT_APP_SERVER_URL}/set_job_tip_hidden`,
        payload
      );
      if (!includeHidden) {
        setJobTips((prevTips) =>
          prevTips.filter((tip) => tip.job_posting_tip_id !== jobTipId)
        );
        setSelectedJobTipId('');
      } else {
        setJobTips((prevTips) =>
          prevTips.map((tip) =>
            tip.job_posting_tip_id === jobTipId
              ? { ...tip, ...{ hidden: hidden } }
              : tip
          )
        );
      }
    } catch (error) {
      console.error('Error hiding tip:', error);
    }
    setHiding(false);
  };

  const renderRevenueText = (r: number): string => {
    switch (true) {
      case r > 1000:
        return '' + (r / 1000).toFixed(0) + ' B';
      case r > 0:
        return '' + r + ' M';
      default:
        return '-';
    }
  };

  const fetchJobTipInfo = async (jobPostingTipId: string) => {
    setSelectedJobTipId(jobPostingTipId);
    const jobTip = jobTips.find(
      (tip) => tip.job_posting_tip_id === jobPostingTipId
    );
    if (jobTip && jobTip.info) {
      return;
    }
    try {
      const payload = { job_posting_tip_id: jobPostingTipId };
      const response = await axios.post(
        `${env.REACT_APP_SERVER_URL}/get_job_posting_tip_info`,
        payload
      );
      const updatedJobTips = jobTips.map((tip) =>
        tip.job_posting_tip_id === jobPostingTipId
          ? { ...tip, info: response.data.jobPostingTipInfo }
          : tip
      );
      setJobTips(updatedJobTips);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    }
  };

  return (
    <div className='page-container-common'>
      <Stack p='md'>
        <Group align='center'>
          <h3 style={{ marginTop: '0px', marginBottom: '0px' }}>
            Job Posting Tips
          </h3>
          <TextInput
            leftSection={<IconSearch size={16} />}
            placeholder='Search jobs by title...'
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            radius='xl'
            w={240}
            rightSection={
              <ActionIcon
                variant='subtle'
                c='gray'
                size='xs'
                onClick={() => setSearchValue('')}
              >
                <IconX size={12} />
              </ActionIcon>
            }
          />
          <Switch
            style={{ marginLeft: '20px' }}
            checked={includeHidden}
            onChange={toggleIncludeHidden}
            label={'Include hidden'}
          />
          <FilterBar context='job-posting' />
        </Group>

        <div style={{ display: 'flex' }}>
          <div
            style={{
              margin: '5px',
              width: '55%',
              maxHeight: 'calc(100vh - 150px)',
              overflowY: 'scroll',
              background: 'white',
              borderRadius: '4px',
              border: '1px solid #8080805c',
            }}
          >
            <Table>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>Job Title</Table.Th>
                  <Table.Th>Location</Table.Th>
                  <Table.Th>Company</Table.Th>
                  <Table.Th>Revenue</Table.Th>
                  <Table.Th>Agency</Table.Th>
                  <SortableHeader
                    sorted={sort?.column === 'score'}
                    reversed={sort?.direction === 'ascending'}
                    onSort={handleSort('score')}
                  >
                    Score
                  </SortableHeader>
                  <SortableHeader
                    sorted={sort?.column === 'created'}
                    reversed={sort?.direction === 'ascending'}
                    onSort={handleSort('created')}
                  >
                    Posted
                  </SortableHeader>
                  <SortableHeader
                    sorted={sort?.column === 'last_seen'}
                    reversed={sort?.direction === 'ascending'}
                    onSort={handleSort('last_seen')}
                  >
                    Last Seen
                  </SortableHeader>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {jobTips.map((tip, index) => {
                  const isSelected =
                    selectedJobTipId === tip.job_posting_tip_id;
                  return (
                    <Table.Tr
                      className={isSelected ? 'selected-row' : 'unseleted-row'}
                      key={index}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        fetchJobTipInfo(tip.job_posting_tip_id);
                      }}
                    >
                      <Table.Td>{tip.job_title}</Table.Td>
                      <Table.Td>
                        {(tip.job_city ? tip.job_city + ', ' : '') +
                          tip.job_state}
                      </Table.Td>
                      <Table.Td>{tip.client?.company_name}</Table.Td>
                      <Table.Td>
                        {renderRevenueText(tip.client?.approximate_revenue)}
                      </Table.Td>
                      <Table.Td>{tip.staffing_agency_name}</Table.Td>
                      <Table.Td>{tip.score}</Table.Td>
                      <Table.Td>{daysAgo(tip.created)}</Table.Td>
                      <Table.Td>{daysAgo(tip.last_seen)}</Table.Td>
                    </Table.Tr>
                  );
                })}
                {!allDataLoaded && (
                  <tr ref={sentinelRef}>
                    <td colSpan={7}>
                      <Center p='lg'>
                        <Loader size='sm' type='dots' />
                      </Center>
                    </td>
                  </tr>
                )}
              </Table.Tbody>
            </Table>
          </div>
          <div
            style={{
              padding: '10px',
              margin: '5px',
              width: '45%',
              maxHeight: 'calc(100vh - 150px)',
              overflowY: 'scroll',
              background: 'white',
              borderRadius: '4px',
              border: '1px solid #8080805c',
            }}
          >
            {selectedJobTip && (
              <div style={{ fontSize: '13px' }}>
                <div style={{ display: 'flex' }}>
                  <div>
                    <h2
                      style={{
                        maxWidth: '500px',
                        marginTop: '10px',
                      }}
                    >
                      {selectedJobTip.job_title}
                    </h2>
                    <h3>{selectedJobTip?.client?.company_name}</h3>
                    <h4 style={{ marginRight: '25px' }}>
                      Posted By: {selectedJobTip.staffing_agency_name}
                    </h4>
                    <a
                      target={'_blank'}
                      rel='noreferrer'
                      href={selectedJobTip?.info?.job_post_url}
                    >
                      Job Post
                    </a>
                  </div>
                  {selectedJobTip.hidden ? (
                    <Button
                      variant='light'
                      onClick={() => {
                        setJobHidden(selectedJobTip.job_posting_tip_id, false);
                      }}
                      style={{
                        marginLeft: 'auto',
                        marginTop: '10px',
                        marginRight: '10px',
                      }}
                    >
                      Un-Hide
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        setJobHidden(selectedJobTip.job_posting_tip_id, true);
                      }}
                      style={{
                        marginLeft: 'auto',
                        marginTop: '10px',
                        marginRight: '10px',
                      }}
                    >
                      Hide
                    </Button>
                  )}
                </div>

                <h4>Location:</h4>
                <p>
                  {(selectedJobTip.job_city
                    ? selectedJobTip.job_city + ', '
                    : '') + selectedJobTip.job_state}
                </p>
                <h4>Notes:</h4>
                <p
                  dangerouslySetInnerHTML={{
                    __html: linkifyText(selectedJobTip?.info?.notes),
                  }}
                />
                <h4>Job Description</h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: selectedJobTip?.info?.job_description,
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </Stack>
    </div>
  );
};

export default withFilterContextProvider(JobPostingTipsPage);
