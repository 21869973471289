import { AdminPortalMemberManagement } from '@stytch/react/b2b/adminPortal';
import React, { useEffect } from 'react';
const styles = { fontFamily: 'Inter, system-ui, sans-serif' };

const roleToName = (role) => {
  if (role.role_id === 'stytch_admin') {
    return 'Admin';
  } else if (role.role_id === 'stytch_member') {
    return 'Viewer';
  } else if (role.role_id === 'editor') {
    return 'Editor';
  }
  return role.role_id;
};

const roleToDescription = (role) => {
  if (role.role_id === 'stytch_admin') {
    return 'All permissions including user management';
  } else if (role.role_id === 'stytch_member') {
    return 'Default application permissions';
  }
  return '';
};

const config = {
  getRoleDisplayName: roleToName,
  getRoleDescription: roleToDescription,
};

const UserSettingsPage = () => {
  useEffect(() => {
    // Hide unsupported UI elements
    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === 'childList') {
          document.querySelectorAll('div').forEach((div) => {
            const h4Arr = div.querySelectorAll(':scope > h4'); // only select direct children
            if (!h4Arr?.length) {
              return;
            }
            const h4 = h4Arr[0];
            if (h4 && h4.textContent.includes('Enable MFA')) {
              div.parentElement.style.display = 'none';
            } else if (
              h4 &&
              h4.textContent.includes('Exempt from primary auth requirements')
            ) {
              div.parentElement.style.display = 'none';
            }
          });
        }
      });
    });

    const targetNode = document.querySelector('.watch'); // Update selector to match the actual container
    if (targetNode) {
      observer.observe(targetNode, { childList: true, subtree: true });
    }

    // Cleanup observer when the component unmounts
    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach(() => {
        document
          .querySelectorAll('.stytch-admin-portal-MuiAutocomplete-root')
          .forEach((autocompleteDiv) => {
            // Traverse up to find the nearest modal
            let parent = autocompleteDiv.parentElement;
            let inviteMemberModal = false;

            let numStepsToFind = 0;
            while (parent && numStepsToFind < 7) {
              const heading = parent.querySelector('h2');
              if (heading && heading.textContent.includes('Invite Member')) {
                inviteMemberModal = true;
                break;
              }
              parent = parent.parentElement; // Keep moving up
              numStepsToFind++; // should take 4 steps, set to 7 to be overly cautious
            }

            if (!inviteMemberModal) {
              return;
            }

            // Target the correct container inside MuiInputBase-root
            const pillsContainer = autocompleteDiv.querySelector(
              'div.stytch-admin-portal-MuiInputBase-root'
            );

            if (pillsContainer) {
              const existingPills = Array.from(
                pillsContainer.querySelectorAll('span[style*="display: flex"]')
              );

              const viewerPill = existingPills.find((span) =>
                span.textContent.includes('Viewer')
              );

              // Add "Viewer" pill if it doesn't exist
              if (!viewerPill) {
                const newPill = document.createElement('span');
                newPill.className =
                  'stytch-admin-portal-MuiChip-root custom-viewer-pill';
                newPill.textContent = 'Viewer';
                newPill.style.display = 'flex';
                newPill.style.alignItems = 'center';
                newPill.style.padding = '4px 20px 4px 12px';
                // newPill.style.border = '1px solid #ccc';
                newPill.style.borderRadius = '4px';
                newPill.style.marginRight = '4px';
                newPill.style.backgroundColor = '#ECFAFF';
                newPill.style.fontSize = '10px';
                newPill.style.color = 'black';

                pillsContainer.prepend(newPill); // Fallback in case there are no other pills
              }
            }
          });
      });
    });

    const targetNode = document.body;
    if (targetNode) {
      observer.observe(targetNode, { childList: true, subtree: true });
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className='page-container-common'>
      <div style={{ overflowY: 'scroll' }} className='watch'>
        <AdminPortalMemberManagement styles={styles} config={config} />
      </div>
    </div>
  );
};

export default UserSettingsPage;
