import React, { useState, useEffect, useRef } from 'react';
import './AudioPlayer.css';
import axios from 'api/axiosConfig';
import { Loader } from '@mantine/core';
import env from 'env';
import { useParams, useSearchParams } from 'react-router-dom';
import { transcriptionCacheAtom } from 'pages/editorv2/atoms';
import { useAtom } from 'jotai';

import PhoneCallRecordingPlayer from './PhoneCallRecordingPlayerV2';

const VideoPlayer = ({
  callId,
  seekTimestamp,
  setFetchTries,
  onTimeUpdate,
  recordingURL,
}) => {
  const { campaignId } = useParams();
  const [searchParams] = useSearchParams();
  const candidateId = searchParams.get('candidateId') || '';
  const transcriptionCacheKey = `${candidateId}_${campaignId}`;
  const [, setTranscriptionCache] = useAtom(transcriptionCacheAtom);

  const [isVideoAvailable, setIsVideoAvailable] = useState(!!recordingURL);
  const [isLoading, setIsLoading] = useState(!recordingURL);
  const [videoUrl, setVideoUrl] = useState(recordingURL || '');
  const [renderDelayPassed, setRenderDelayPassed] = useState(false);

  const videoRef = React.useRef<HTMLVideoElement>(null);
  const [objectFit, setObjectFit] =
    useState<React.CSSProperties['objectFit']>('cover');

  const [dimensions, setDimensions] = useState({
    maxHeight: 0,
    minHeight: 240,
    minWidth: 120,
  });

  const RENDER_DELAY = 150;
  const POLLING_INTERVAL = 3000;

  useEffect(() => {
    if (seekTimestamp !== null && videoRef.current) {
      videoRef.current.currentTime = seekTimestamp;
      videoRef.current.play();
    }
  }, [seekTimestamp]);

  useEffect(() => {
    if (recordingURL) {
      setVideoUrl(recordingURL);
      setIsLoading(false);
    }
  }, [recordingURL]);

  useEffect(() => {
    if (!recordingURL) {
      setVideoUrl('');
    }
  }, [callId, recordingURL]);

  const pollingIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);
  const isMountedRef = useRef<boolean>(true); // To prevent state updates after unmount

  useEffect(() => {
    // Cleanup flag to prevent state updates if the component unmounts
    isMountedRef.current = true;

    const checkVideoAvailability = async () => {
      try {
        const response = await axios.get(
          `${env.REACT_APP_SERVER_URL}/check_web_recording/${callId}`
        );

        if (response.status === 200 && response.data.available) {
          if (isMountedRef.current) {
            setIsVideoAvailable(true);
            setVideoUrl(response.data.video_url);
            setIsLoading(false);

            setTranscriptionCache({
              data: {
                [transcriptionCacheKey]: {
                  recordingURL: response.data.video_url,
                },
              },
              timestamp: Date.now(),
            });
          }
          // Stop polling if it was running
          if (pollingIntervalRef.current) {
            clearInterval(pollingIntervalRef.current);
            pollingIntervalRef.current = null;
          }
        } else {
          throw new Error('Video not available');
        }
      } catch (error) {
        if (isMountedRef.current) {
          setFetchTries((prevTries) => prevTries + 1);
        }

        // Start polling if not already started
        if (!pollingIntervalRef.current) {
          startPolling();
        }
      }
    };

    const startPolling = () => {
      pollingIntervalRef.current = setInterval(
        checkVideoAvailability,
        POLLING_INTERVAL
      );
    };

    // Perform the initial check immediately
    checkVideoAvailability();

    // Cleanup function to clear intervals and timeouts
    return () => {
      isMountedRef.current = false;
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
        pollingIntervalRef.current = null;
      }
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = null;
      }
    };
  }, [callId, setFetchTries, setTranscriptionCache, transcriptionCacheKey]);

  useEffect(() => {
    const handleResize = () => {
      const columnHeight = document.documentElement.clientHeight * 0.6;
      const calculatedMaxHeight = Math.min(columnHeight * 0.4, columnHeight);
      setDimensions((prev) => ({
        ...prev,
        maxHeight: calculatedMaxHeight,
      }));
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!videoUrl) {
      return;
    }

    const videoElement = videoRef.current;
    if (!videoElement) {
      return;
    }

    const handleLoadedMetadata = () => {
      const aspectRatio = videoElement.videoWidth / videoElement.videoHeight;

      if (aspectRatio < 1.2) {
        setObjectFit('contain');
      } else {
        setObjectFit('cover');
      }
    };

    videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);

    return () => {
      videoElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
    };
  }, [videoUrl]);

  // Initial render delay to ensure UI stability
  useEffect(() => {
    const timer = setTimeout(() => {
      setRenderDelayPassed(true);
    }, RENDER_DELAY);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        position: 'relative',
      }}
    >
      {isLoading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            maxHeight: `${dimensions.maxHeight}px`,
            minHeight: `${dimensions.minHeight}px`,
            minWidth: `${dimensions.minWidth}px`,
            flexDirection: 'column',
            borderRadius: '16px',
            border: '1px solid var(--salv-dark-2)',
            aspectRatio: '4/3',
            opacity: '.7',
          }}
        >
          <h4 style={{ marginBottom: '4px' }}>Loading Video</h4>
          <Loader type='dots' size='sm' />
        </div>
      )}
      {isVideoAvailable && renderDelayPassed && (
        <video
          onTimeUpdate={(e) => onTimeUpdate?.(e.currentTarget.currentTime)}
          controls
          ref={videoRef}
          style={{
            borderRadius: '16px',
            maxHeight: `${dimensions.maxHeight}px`,
            minHeight: `${dimensions.minHeight}px`,
            minWidth: `${dimensions.minWidth}px`,
            maxWidth: '100%',
            aspectRatio: '4/3',
            position: 'relative',
            border: '4px solid var(--salv-dark-1)',
            objectFit: objectFit,
            background: 'var(--salv-dark-8)',
          }}
        >
          <source src={videoUrl} type='video/mp4' />
          Your browser does not support the video tag.
        </video>
      )}
    </div>
  );
};

const WebCallRecordingPlayer = ({
  callId,
  seekTimestamp,
  testCall,
  onTimeUpdate,
  recordingURL,
}) => {
  const [fetchTries, setFetchTries] = useState(0);
  if (!testCall && fetchTries > 2) {
    return (
      <PhoneCallRecordingPlayer
        callId={callId}
        seekTimestamp={seekTimestamp}
        webAudio={true}
        onTimeUpdate={onTimeUpdate}
        recordingURL={recordingURL}
      />
    );
  }
  return (
    <div
      style={{
        display: 'flex',
        fontSize: '14px',
        width: '100%',
        maxWidth: '800px',
        margin: '0 auto',
      }}
    >
      {!!callId && (
        <VideoPlayer
          callId={callId}
          seekTimestamp={seekTimestamp}
          setFetchTries={setFetchTries}
          onTimeUpdate={onTimeUpdate}
          recordingURL={recordingURL}
        />
      )}
    </div>
  );
};

export default WebCallRecordingPlayer;
