import { MantineSize, Text } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';
import { CampaignFolder } from 'pages/scripts/ScriptsPage';
import React, { Fragment } from 'react';

interface BreadcrumbsProps {
  currentFolder: CampaignFolder | null;
  onNavigate: (breadcrumb: Partial<CampaignFolder> | null) => void;
  includeLastArrow?: boolean;
  size?: MantineSize;
}

const FolderBreadcrumbs = ({
  currentFolder,
  onNavigate,
  includeLastArrow = false,
  size = 'md',
}: BreadcrumbsProps) => {
  return (
    <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
      {currentFolder ? (
        <Fragment>
          {currentFolder.breadcrumbs.map((breadcrumb, index) => (
            <div
              key={index}
              style={{ display: 'flex', gap: '5px', alignItems: 'center' }}
            >
              <Text
                style={{
                  marginBottom: '0px',
                  marginTop: '0px',
                  cursor: 'pointer',
                }}
                className='nav-link-text'
                onClick={() => onNavigate(breadcrumb)}
                size={size}
              >
                {breadcrumb.folder_name}
              </Text>
              {index < currentFolder.breadcrumbs.length - 1 ? (
                <IconChevronRight size={16} />
              ) : (
                includeLastArrow && <IconChevronRight size={16} />
              )}
            </div>
          ))}
        </Fragment>
      ) : (
        <Fragment>
          <Text
            style={{ marginBottom: '0px', marginTop: '0px', cursor: 'pointer' }}
            className='nav-link-text'
            onClick={() => onNavigate(null)}
            size={size}
          >
            Jobs
          </Text>
          {includeLastArrow && <IconChevronRight size={16} />}
        </Fragment>
      )}
    </div>
  );
};

export default FolderBreadcrumbs;
