import React from 'react';
import { Textarea } from '@mantine/core';

export interface EditableFieldProps {
  value?: string;
  onChange: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
  description?: string;
  shaded?: boolean;
  alwaysShowBorder?: boolean;
}

export const EditableField = ({
  value = '',
  onChange,
  placeholder,
  disabled = false,
  shaded = false,
  alwaysShowBorder = false,
}: EditableFieldProps) => {
  return (
    <Textarea
      autosize
      minRows={1}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className={`editableField tableTextArea editableText ${alwaysShowBorder ? 'editingBorder' : ''}`}
      placeholder={placeholder}
      disabled={disabled}
      styles={{
        input: {
          backgroundColor: disabled
            ? 'var(--salv-dark-0)'
            : shaded
              ? 'var(--salv-dark-0)'
              : 'transparent',
        },
      }}
    />
  );
};
