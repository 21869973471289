import React, { useState, useEffect } from 'react';
import { Tabs, Loader, Text } from '@mantine/core';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconSettings } from '@tabler/icons-react';
import OAuthEmailSetup from 'pages/email-settings/OAuthEmailSetup';
import UserSettingsPage from 'pages/admin/UserSettingsPage';
import QuestionBankManager from 'pages/admin/QuestionBankManager';
import { modals } from '@mantine/modals';

import './AdminTabsPage.css';
import 'css/common.css';
import TemplatesManager from './TemplatesManager';
import ATSIntegrationsManager from './ATSIntegrationsManager';
import CallerSettingsManager from './CallerSettingsManager';
import ScriptGenerationManager from './ScriptGenerationManager';

const TabOptions = {
  USERS: 'users',
  EMAIL: 'email',
  LOADER: 'loader',
  QUESTION_BANK: 'question_bank',
  TEMPLATES: 'templates',
  ATS_INTEGRATIONS: 'ats_integrations',
  CALLER_SETTINGS: 'caller_settings',
  BACKGROUND_INFO: 'background_info',
  SCRIPT_GENERATION: 'script_generation',
} as const;

type Tab = (typeof TabOptions)[keyof typeof TabOptions];

const isTab = (value: any): value is Tab => {
  return Object.values(TabOptions).includes(value);
};

const AdminTabsPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialTab: Tab = isTab(queryParams.get('tab') as Tab)
    ? (queryParams.get('tab') as Tab)
    : TabOptions.USERS;

  const [activeTab, setActiveTab] = useState<Tab>(initialTab);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [callerSettingsRefreshCounter, setCallerSettingsRefreshCounter] =
    useState(0); // NOTE: Hack because keepMounted={false} doesn't work on Tabs.Panel

  const email = localStorage.getItem('email');
  const isInternal = email?.includes('@salv.ai');
  const navigate = useNavigate();

  useEffect(() => {
    if (
      activeTab !== TabOptions.LOADER &&
      location.pathname.startsWith('/settings')
    ) {
      const queryParams = new URLSearchParams(location.search);
      queryParams.set('tab', activeTab);
      navigate(`${location.pathname}?${queryParams.toString()}`, {
        replace: true,
      });
    }
  }, [activeTab, location.pathname, location.search, navigate]);

  const handleTabChange = (value: string) => {
    if (value === activeTab) {
      return;
    }

    if (hasUnsavedChanges) {
      modals.openConfirmModal({
        title: 'Unsaved Changes',
        children: (
          <Text size='sm'>
            You have unsaved changes. Are you sure you want to leave? Your
            changes will be lost.
          </Text>
        ),
        labels: { confirm: 'Leave', cancel: 'Stay' },
        confirmProps: { color: 'red' },
        onCancel: () => {},
        onConfirm: () => {
          setHasUnsavedChanges(false);
          setActiveTab(value as Tab);
        },
      });
      return;
    }
    setActiveTab(value as Tab);
    if (value === TabOptions.CALLER_SETTINGS) {
      setCallerSettingsRefreshCounter((prev) => prev + 1);
    }
  };

  return (
    <div
      className='page-container-common'
      style={{
        display: 'flex',
        flexDirection: 'column',
        // backgroundColor: '#f0f0f0',
      }}
    >
      <Tabs
        keepMounted
        variant='pills'
        value={activeTab}
        onChange={(value) => {
          if (isTab(value)) {
            handleTabChange(value);
          }
        }}
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <div
          style={{
            flex: 1,
            overflow: 'auto',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <IconSettings size={20} color='#4a564d' />
            <h4 style={{ marginBottom: '0px', marginTop: '0px' }}>Settings</h4>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingTop: '14px',
            }}
          >
            <Tabs.List>
              <Tabs.Tab
                value={TabOptions.USERS}
                classNames={{
                  tab: 'header-tab', // Add a custom class name for this tab
                }}
              >
                Manage Users
              </Tabs.Tab>
              <Tabs.Tab
                value={TabOptions.EMAIL}
                classNames={{
                  tab: 'header-tab', // Add a custom class name for this tab
                }}
              >
                Email Settings
              </Tabs.Tab>
              <Tabs.Tab
                value={TabOptions.QUESTION_BANK}
                classNames={{
                  tab: 'header-tab', // Add a custom class name for this tab
                }}
              >
                Question Bank
              </Tabs.Tab>
              <Tabs.Tab
                value={TabOptions.BACKGROUND_INFO}
                classNames={{
                  tab: 'header-tab', // Add a custom class name for this tab
                }}
              >
                Background Info
              </Tabs.Tab>
              <Tabs.Tab
                value={TabOptions.SCRIPT_GENERATION}
                classNames={{
                  tab: 'header-tab', // Add a custom class name for this tab
                }}
              >
                Script Generation
              </Tabs.Tab>
              {isInternal && (
                <Tabs.Tab
                  value={TabOptions.ATS_INTEGRATIONS}
                  classNames={{
                    tab: 'header-tab', // Add a custom class name for this tab
                  }}
                >
                  ATS Integrations
                </Tabs.Tab>
              )}
              <Tabs.Tab
                value={TabOptions.CALLER_SETTINGS}
                classNames={{
                  tab: 'header-tab', // Add a custom class name for this tab
                }}
              >
                AI Caller Settings
              </Tabs.Tab>
            </Tabs.List>
          </div>
          <Tabs.Panel
            value={TabOptions.USERS}
            style={{ height: 'calc(100% - 90px)', padding: 0, margin: 0 }}
          >
            <UserSettingsPage />
          </Tabs.Panel>

          <Tabs.Panel
            value={TabOptions.EMAIL}
            style={{
              height: 'calc(100% - 90px)',
              overflow: 'scroll',
              padding: 0,
              margin: 0,
            }}
          >
            <OAuthEmailSetup />
          </Tabs.Panel>

          <Tabs.Panel
            value={TabOptions.QUESTION_BANK}
            style={{ height: 'calc(100% - 90px)', padding: 0, margin: 0 }}
          >
            <QuestionBankManager onUnsavedChanges={setHasUnsavedChanges} />
          </Tabs.Panel>

          <Tabs.Panel
            value={TabOptions.LOADER}
            style={{
              height: 'calc(100% - 90px)',
              overflow: 'scroll',
              padding: 0,
              margin: 0,
            }}
          >
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Loader type='dots' style={{ marginTop: '0%' }} />
            </div>
          </Tabs.Panel>
          <Tabs.Panel
            value={TabOptions.BACKGROUND_INFO}
            style={{
              height: 'calc(100% - 90px)',
              overflow: 'scroll',
              padding: 0,
              margin: 0,
            }}
          >
            <TemplatesManager />{' '}
            {/* Templaes manager should be renamed to Background Info Templates to differentiate from Script Generation Templates */}
          </Tabs.Panel>
          <Tabs.Panel
            value={TabOptions.ATS_INTEGRATIONS}
            style={{ height: 'calc(100% - 90px)', padding: 0, margin: 0 }}
          >
            <ATSIntegrationsManager />
          </Tabs.Panel>
          <Tabs.Panel
            keepMounted={false}
            value={TabOptions.CALLER_SETTINGS}
            style={{
              height: 'calc(100% - 90px)',
              overflow: 'scroll',
              padding: 0,
              margin: 0,
            }}
          >
            <CallerSettingsManager
              onUnsavedChanges={setHasUnsavedChanges}
              refreshTrigger={callerSettingsRefreshCounter}
            />
          </Tabs.Panel>
          <Tabs.Panel
            value={TabOptions.SCRIPT_GENERATION}
            style={{
              height: 'calc(100% - 90px)',
              overflow: 'scroll',
              padding: 0,
              margin: 0,
            }}
          >
            <ScriptGenerationManager />
          </Tabs.Panel>
        </div>
      </Tabs>
    </div>
  );
};

export default AdminTabsPage;
