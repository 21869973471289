import {
  CountryCode,
  isValidPhoneNumber,
  parsePhoneNumberFromString,
} from 'libphonenumber-js';

export const formatPhoneNumber = (
  phoneNumber: string,
  format?: 'national' | 'international'
): string => {
  if (!phoneNumber) {
    return '';
  }
  const parsedNumber = parsePhoneNumberFromString(phoneNumber, 'US'); // Adjust the country code as needed
  if (parsedNumber) {
    if (format === 'national') {
      return parsedNumber.formatNational();
    }

    return parsedNumber.formatInternational();
  }
  return phoneNumber;
};

export const validatePhoneNumber = (
  phone: string,
  countryCode: CountryCode = 'US'
): boolean => {
  const cleaned = phone.replace(/\D/g, '');
  return isValidPhoneNumber(cleaned, countryCode);
};
