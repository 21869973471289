import React, { useEffect, useRef, useCallback } from 'react';
import { Text, Group } from '@mantine/core';
import { IconPhone } from '@tabler/icons-react';
import { useIsMobile } from 'pages/web-call/components/useIsMobile';

import { MIN_VISIBLE_TRANSCRIPT_HEIGHT } from './constants';
import { TranscriptMessage } from './types';

import './Transcript.css';

interface TranscriptLinesProps {
  transcript: TranscriptMessage[] | string;
  sms: boolean;
  onTimestampClick: (ts: number | null) => void;
  clickable: boolean;
  currentTimeMs: number | undefined;
  userScrolling: boolean;
  scrollingProgrammatically: React.MutableRefObject<boolean>;
  columnHeight?: number;
  outerContainer?: React.RefObject<HTMLDivElement>;
  autoScroll?: boolean;
  isTranslating?: boolean;
}

/* ----------------------------------------------------------------
   TranscriptLines - now can handle either:
     - an array of messages, OR
     - a raw string (like phone transcript)
----------------------------------------------------------------- */
const TranscriptLines = ({
  transcript,
  sms,
  onTimestampClick,
  clickable,
  currentTimeMs,
  userScrolling,
  scrollingProgrammatically,
  columnHeight,
  outerContainer,
  autoScroll,
  isTranslating,
}: TranscriptLinesProps) => {
  const activeMessageRef = useRef<Record<number, HTMLDivElement | null>>({});
  const isMobile = useIsMobile();

  // Add a ref to keep track of the last scrolled element and timestamp
  const lastScrolledRef = useRef<{
    index: number | null;
    time: number;
    columnHeight: number | undefined;
  }>({
    index: null,
    time: 0,
    columnHeight: undefined,
  });

  // Finds the "active" message based on currentTime
  const getActiveIndex = useCallback(
    (messages: TranscriptMessage[]): number | null => {
      if (currentTimeMs === undefined) return null;
      // We'll do a simple approach: loop until we find the message whose start_ts is <= current time,
      // but the next message's start_ts is > current time
      const FORWARD_BUFFER = 0.05;
      // something about the logic is a bit messed up where it briefly highlights the previous message but hack seems to fix it
      const currentSec = currentTimeMs / 1000 + FORWARD_BUFFER;
      for (let i = 0; i < messages.length; i++) {
        const msg = messages[i];
        const next = messages[i + 1];
        if (
          msg.start_ts <= currentSec &&
          (!next || next.start_ts > currentSec)
        ) {
          return i;
        }
      }
      return null;
    },
    [currentTimeMs]
  );

  const scrollToActiveMessage = useCallback(
    (messages: TranscriptMessage[]) => {
      if (!autoScroll) return;

      const index = getActiveIndex(messages);
      if (index === null) return;

      const element = activeMessageRef.current[index];
      if (!element) return;

      // If user is scrolling, don't override
      if (userScrolling) return;

      // If the column is super short, we need custom logic:
      const now = Date.now();
      const hasColumnHeightChanged =
        lastScrolledRef.current.columnHeight !== columnHeight;

      if (
        lastScrolledRef.current.index === index &&
        now - lastScrolledRef.current.time < 1000 &&
        !hasColumnHeightChanged
      ) {
        // Debounce if we scrolled to the same element within 1 sec
        return;
      }

      // Otherwise, record it
      lastScrolledRef.current = { index, time: now, columnHeight };

      scrollingProgrammatically.current = true;

      // We'll handle the short column scenario if columnHeight < MIN_VISIBLE_TRANSCRIPT_HEIGHT
      if (
        columnHeight &&
        outerContainer?.current &&
        columnHeight < MIN_VISIBLE_TRANSCRIPT_HEIGHT
      ) {
        const elementRect = element.getBoundingClientRect();
        const outerRect = outerContainer.current.getBoundingClientRect();
        const scrollOffset =
          elementRect.top -
          outerRect.top +
          outerContainer.current.scrollTop -
          60;
        outerContainer.current.scrollTo({
          top: scrollOffset,
          behavior: hasColumnHeightChanged ? 'auto' : 'smooth',
        });
      } else {
        // Normal scenario: just scroll the element into view
        element.scrollIntoView({
          behavior: hasColumnHeightChanged ? 'auto' : 'smooth',
          block: 'start',
        });
      }
    },
    [
      autoScroll,
      columnHeight,
      getActiveIndex,
      outerContainer,
      scrollingProgrammatically,
      userScrolling,
    ]
  );

  useEffect(() => {
    // Whenever currentTimeMs changes, attempt to scroll if not userScrolling
    if (!userScrolling && typeof transcript !== 'string') {
      scrollToActiveMessage(transcript);
    }
  }, [scrollToActiveMessage, transcript, userScrolling, currentTimeMs]);

  const escapeAngleBrackets = (text: string) =>
    text.replace(/</g, '&lt;').replace(/>/g, '&gt;');

  const highlightGrayText = (text: string) => {
    let updatedText = escapeAngleBrackets(text);
    updatedText = updatedText.replace(
      /(&lt;.*?&gt;|~(.*?)~)/g,
      '<span style="color:gray">$1</span>'
    );
    return updatedText;
  };

  const formatTimestampSMS = (timestampMs: number) => {
    const date = new Date(timestampMs);
    const formatted = date.toLocaleString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });
    return formatted;
  };

  const formatTimestamp = (tsSeconds: number) => {
    const minutes = Math.floor(tsSeconds / 60);
    const seconds = Math.floor(tsSeconds % 60);
    return (
      String(minutes).padStart(2, '0') + ':' + String(seconds).padStart(2, '0')
    );
  };

  // 1) If transcript is a string, just render it like old phone transcript
  if (typeof transcript === 'string') {
    return (
      <Text
        style={{ fontSize: '14px' }}
        dangerouslySetInnerHTML={{
          __html: highlightGrayText(transcript),
        }}
      />
    );
  }

  // 2) Otherwise, transcript is an array of messages
  const messages = transcript as TranscriptMessage[];
  const activeIndex = getActiveIndex(messages);
  return (
    <div>
      {messages.map((msg, i) => {
        // We only highlight if i == activeIndex
        const isActive = i === activeIndex;

        if (msg.role === 'Call') {
          // special call block
          return (
            <Group
              key={i}
              ref={(el) => (activeMessageRef.current[i] = el)}
              style={{
                marginTop: i === 0 ? 0 : 12,
                alignItems: 'center',
                justifyContent: 'center',
                opacity: 0.9,
                width: 'fit-content',
                gap: '8px',
                border: '1px solid var(--salv-dark-2)',
                borderRadius: '6px',
                marginLeft: 'auto',
                marginRight: 'auto',
                padding: '10px 16px',
                whiteSpace: 'nowrap',
                flexWrap: 'nowrap',
                overflow: 'hidden',
              }}
              onClick={() => onTimestampClick(msg.start_ts ?? null)}
              className={clickable ? 'clickable' : ''}
            >
              <IconPhone size={16} />
              <Text
                style={{ fontSize: '11px', fontWeight: '500' }}
                c={isTranslating ? 'dimmed' : ''}
              >
                {msg.content}
              </Text>
            </Group>
          );
        }

        // normal message
        return (
          <Group
            key={i}
            ref={(el) => (activeMessageRef.current[i] = el)}
            style={{
              marginTop: i === 0 ? 0 : 12,
              alignItems: 'flex-start',
              width: '100%',
            }}
            onClick={() => onTimestampClick(msg.start_ts ?? null)}
            className={[
              'transcript-message',
              clickable && msg.start_ts != null ? 'clickable-qa-item' : '',
              isActive ? 'transcript-message-active' : '',
            ]
              .filter(Boolean)
              .join(' ')}
          >
            <div style={{ width: '100%' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: sms ? 'space-between' : 'flex-start',
                  width: '100%',
                }}
              >
                {!sms && msg.start_ts !== null && (
                  <Text
                    size='xs'
                    style={{ color: 'gray', marginRight: 8 }}
                    c={isTranslating ? 'dimmed' : ''}
                  >
                    {formatTimestamp(msg.start_ts)}
                  </Text>
                )}
                <Text
                  size={isMobile ? 'xs' : 'sm'}
                  fw={700}
                  style={{
                    color: isTranslating
                      ? 'var(--mantine-color-dimmed)'
                      : msg.role === 'Candidate'
                        ? 'var(--mantine-color-blue-9)'
                        : '#222324',
                  }}
                >
                  {msg.role}
                </Text>
                {sms && msg.start_ts !== null && (
                  <Text size='xs' style={{ color: 'gray' }}>
                    {formatTimestampSMS(msg.start_ts)}
                  </Text>
                )}
              </div>
              <Text
                style={{
                  paddingLeft: '4px',
                  fontSize: isMobile ? '12px' : '14px',
                }}
                dangerouslySetInnerHTML={{
                  __html: highlightGrayText(msg.content.trim()),
                }}
                c={isTranslating ? 'dimmed' : ''}
              />
            </div>
          </Group>
        );
      })}
    </div>
  );
};

export default TranscriptLines;
