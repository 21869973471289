import React, { useState, useEffect, useCallback, useMemo } from 'react';
import './ImportJobButton.css';
import { ActionIcon, Loader, Modal, Table, TextInput } from '@mantine/core';
import axios from 'api/axiosConfig';
import env from 'env';
import { IconX, IconSearch } from '@tabler/icons-react';
import { debounce } from 'lodash';

interface ImportJobButtonProps {
  onJobSelect?: (job: any) => void;
}

const ImportJobButton: React.FC<ImportJobButtonProps> = ({ onJobSelect }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [fetchingJobs, setFetchingJobs] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const fetchJobs = useCallback(async (query: string) => {
    setFetchingJobs(true);
    try {
      const response = await axios.post(
        `${env.REACT_APP_SERVER_URL}/kombo/get_jobs`,
        {
          integration_id: 'sandbox:DX8Euf2BYexKWwBDbEdgzkuR',
          search_query: query,
        }
      );
      const data = response.data;
      setJobs(data.results);
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    } finally {
      setFetchingJobs(false);
    }
  }, []);

  // Create debounced fetch outside useCallback
  const debouncedFetchJobs = useMemo(
    () =>
      debounce((query: string) => {
        fetchJobs(query);
      }, 500),
    [fetchJobs]
  );

  useEffect(() => {
    // Only fetch jobs when the modal is open
    if (isModalOpen) {
      setFetchingJobs(true);
      debouncedFetchJobs(searchValue);
    }

    // Cleanup debounced function on unmount
    return () => {
      debouncedFetchJobs.cancel();
    };
  }, [isModalOpen, searchValue, debouncedFetchJobs]);

  const handleJobSelect = (job: any) => {
    if (onJobSelect) {
      onJobSelect(job);
    }
    setModalOpen(false);
  };

  const renderSelectJobModal = () => {
    return (
      <Modal
        title={'Import a Job from Your ATS'}
        opened={isModalOpen}
        onClose={() => setModalOpen(false)}
        size={'85%'}
      >
        <TextInput
          leftSection={<IconSearch size={16} />}
          placeholder='Search for specific job title'
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          radius='xl'
          styles={{
            input: {
              fontSize: '12px',
            },
          }}
          w={240}
          rightSection={
            <ActionIcon
              variant='subtle'
              c='gray'
              size='xs'
              onClick={() => setSearchValue('')}
            >
              <IconX size={12} />
            </ActionIcon>
          }
          style={{ marginBottom: '4px' }}
        />
        {fetchingJobs ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <Loader type='dots' />
          </div>
        ) : (
          <Table>
            <Table.Thead>
              <Table.Tr>
                <Table.Th
                  style={{
                    width: '16%',
                  }}
                >
                  Name
                </Table.Th>
                <Table.Th
                  style={{
                    width: '10%',
                  }}
                >
                  Opened
                </Table.Th>
                <Table.Th
                  style={{
                    width: '70%',
                  }}
                >
                  Description
                </Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {jobs.map((job: any) => (
                <Table.Tr
                  key={job.id}
                  className='import-job-row'
                  onClick={() => handleJobSelect(job)}
                  style={{ cursor: 'pointer' }}
                >
                  <Table.Td style={{ fontWeight: 'bold' }}>{job.name}</Table.Td>
                  <Table.Td>
                    {job.opened_at
                      ? (() => {
                          const date = new Date(job.opened_at);
                          const month = String(date.getMonth() + 1).padStart(
                            2,
                            '0'
                          );
                          const day = String(date.getDate()).padStart(2, '0');
                          const year = String(date.getFullYear());
                          return `${month}-${day}-${year}`;
                        })()
                      : ''}
                  </Table.Td>
                  <Table.Td>
                    <div
                      style={{
                        display: 'block',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '100%',
                        maxHeight: '22px',
                      }}
                    >
                      <div
                        className='zero-margin-content'
                        dangerouslySetInnerHTML={{ __html: job.description }}
                      />
                    </div>
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        )}
      </Modal>
    );
  };
  return <></>;

  /* eslint-disable no-unreachable */
  return (
    <>
      {renderSelectJobModal()}
      <div className='import-job-button' onClick={() => setModalOpen(true)}>
        <div style={{ fontWeight: 'bold' }}>Import from Your ATS</div>
        <div>
          <img
            src='/imgs/bullhorn_bull.svg'
            alt='Bullhorn Logo'
            style={{ height: '30px' }}
          />
        </div>
      </div>
    </>
  );
};

export default ImportJobButton;
