import { Text } from '@mantine/core';
import React, { Fragment } from 'react';
import CampaignQuestionCard from 'components/CampaignQuestionCard';

import { CampaignQuestion } from './QuestionsPage';

const AllQuestionsTab = ({ questions }: { questions: CampaignQuestion[] }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        marginTop: '20px',
      }}
    >
      {questions.length === 0 ? (
        <div
          style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            marginTop: '5%',
          }}
        >
          <Text size='sm' c='dimmed'>
            No questions available
          </Text>
        </div>
      ) : (
        <Fragment>
          {questions.map((question) => {
            return (
              <CampaignQuestionCard
                key={question.question_id}
                question={question}
                showActionIcons={false}
              />
            );
          })}
        </Fragment>
      )}
    </div>
  );
};

export default AllQuestionsTab;
