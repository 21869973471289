import React, { useEffect, useRef, useCallback, useMemo } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { debounce } from 'lodash';

import './CustomQuill.css'; // Custom CSS
const parser = new DOMParser();

const SkillsQuillEditor = ({
  editorContent,
  setEditorContent,
  jobSkillsStrListLowered,
  potentialJobSkills,
}) => {
  const initialJobSkillsArr = potentialJobSkills;

  const quillRef = useRef<ReactQuill | null>(null);

  function highlightTextNode(node: Text, wordsToHighlight: string[]): void {
    let text = node.nodeValue || '';
    const regex = new RegExp(`\\b(${wordsToHighlight.join('|')})\\b`, 'gi');

    const newContent = text.replace(regex, (match) => {
      return `<span style="background-color: rgb(0 251 255 / 46%);">${match}</span>`;
    });

    if (newContent !== text) {
      const wrapper = document.createElement('span');
      wrapper.innerHTML = newContent;
      node.replaceWith(...Array.from(wrapper.childNodes));
    }
  }

  const highLightedEditorContent = useMemo(() => {
    function walkDOM(node: Node, wordsToHighlight: string[]): void {
      // Recursively highlights text
      if (node.nodeType === Node.TEXT_NODE) {
        highlightTextNode(node as Text, wordsToHighlight);
      } else {
        Array.from(node.childNodes).forEach((child) =>
          walkDOM(child, wordsToHighlight)
        );
      }
    }
    const doc = parser.parseFromString(editorContent, 'text/html');
    walkDOM(doc.body, jobSkillsStrListLowered);
    // return doc.body.innerHTML;
    // if (
    //   doc.body.innerHTML.includes('rgb(0 251 255 / 46%)') &&
    //   !editorContent.includes('rgba(0, 251, 255, 0.46)')
    // ) {
    return doc.body.innerHTML;
    // }
    // NOTE: will not highlight next text. There's a bug somewhere
    // return editorContent;
  }, [editorContent, jobSkillsStrListLowered]);

  useEffect(() => {
    // Combine all the inputs into one HTML string
    const combinedContent = `
      ${initialJobSkillsArr.map((s) => `<p>${s}</p>`).join('')}
    `;
    setEditorContent(combinedContent);
  }, [initialJobSkillsArr, setEditorContent]);

  const debouncedCallback = useRef(debounce((callback) => callback(), 5000));

  const onEditorChange = useCallback(() => {
    if (!quillRef?.current) {
      return;
    }
    const content = quillRef.current.getEditor().root.innerHTML;
    setEditorContent(content);
  }, [setEditorContent]);

  const handleEditorChange = useCallback(() => {
    debouncedCallback.current(onEditorChange);
  }, [onEditorChange]);

  useEffect(() => {
    if (quillRef.current) {
      const quillEditor = quillRef.current.getEditor();

      quillEditor.on('text-change', handleEditorChange);

      return () => {
        quillEditor.off('text-change', handleEditorChange);
      };
    }
  }, [handleEditorChange]);

  const modules = {
    toolbar: false, // Disable the toolbar
  };

  return (
    <div style={{ height: '80%', overflowY: 'scroll' }}>
      <ReactQuill
        ref={quillRef}
        value={highLightedEditorContent}
        onChange={() => {}}
        modules={modules}
      />
    </div>
  );
};

export default SkillsQuillEditor;
