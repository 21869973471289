import React, { useState, useCallback, useMemo, useEffect } from 'react';
import {
  useStytchB2BClient,
  useStytchMember,
  useStytchMemberSession,
  useStytchOrganization,
} from '@stytch/react/b2b';
import {
  Card,
  TextInput,
  Container,
  PasswordInput,
  Center,
} from '@mantine/core';
import env from 'env';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useNavigate } from 'react-router-dom';

import './LoginCommon.css';
import LoginButton from './LoginButton';
import axios from '../../api/axiosConfig';

function hasAtLeastTwoWords(str) {
  if (!str) {
    return;
  }
  const words = str.trim().split(/\s+/); // Trim and split by one or more spaces
  return words.length >= 2;
}

const FinishInfoPage = () => {
  const { session } = useStytchMemberSession();
  const { member } = useStytchMember();
  const { organization } = useStytchOrganization();
  const stytchClient = useStytchB2BClient();

  const navigate = useNavigate();
  const stytch = useStytchB2BClient();
  const [password, setPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [fullName, setFullName] = useState(member?.name);
  const [isValidPassword, setIsValidPassword] = useState(false);
  const strengthCheck = useCallback(async () => {
    return await stytch.passwords.strengthCheck({
      password: password,
    });
  }, [stytch, password]);
  const [creatingUser, setCreatingUser] = useState(false);

  const [invalidFormSubmitted, setInvalidFormSubmitted] = useState(false);

  useEffect(() => {
    const checkPassword = async () => {
      const resp = await strengthCheck();
      if (resp?.valid_password) {
        setIsValidPassword(true);
      } else {
        setIsValidPassword(false);
      }
    };

    checkPassword();
  }, [strengthCheck]);

  useEffect(() => {
    const checkAuthenticationTime = async () => {
      if (!session) {
        return;
      }
      const mostRecentFactor = session?.authentication_factors?.reduce(
        (latest, current) => {
          return new Date(current.last_authenticated_at) >
            new Date(latest.last_authenticated_at)
            ? current
            : latest;
        }
      );
      const lastAuthDate = new Date(
        mostRecentFactor.last_authenticated_at
      ).getTime();
      const currentTime = new Date().getTime();
      const timeDiffInMinutes = (currentTime - lastAuthDate) / 1000 / 60;
      if (timeDiffInMinutes > 4.2) {
        // The password set part of the endpoint will fail after five minutes
        // Therefore, we close the page
        // Exit the page, redirect to another route
        await stytch.session.revoke();
        navigate('/login');
      }
    };

    // Check immediately when the component mounts
    checkAuthenticationTime();

    // Set an interval to check every second
    const intervalId = setInterval(checkAuthenticationTime, 20000);

    // Cleanup the interval on unmount
    return () => clearInterval(intervalId);
  }, [session, stytch.session, navigate]);

  const navigateHome = useCallback(() => {
    navigate(`/`);
    window.location.reload();
  }, [navigate]);

  const sessionTokens = useMemo(() => {
    return stytchClient.session.getTokens();
  }, [stytchClient.session]);

  const finishInviteAcceptance = async () => {
    if (isCreateDisabled) {
      setInvalidFormSubmitted(true);
      return;
    }
    setInvalidFormSubmitted(false);
    setCreatingUser(true);
    try {
      const response = await axios.post(
        `${env.REACT_APP_SERVER_URL}/finish_invite_acceptance`,
        {
          //   magic_link_token: magicLinkToken,
          session_token: sessionTokens?.session_token,
          full_name: fullName,
          password: password,
        }
      );
      const { stychSessionResponse } = response.data;
      const { session_token } = stychSessionResponse;
      // This should persist the session token to cookies for us
      try {
        stytch.session.updateSession({
          session_token: session_token,
        });
        await stytch.session.authenticate({
          session_duration_minutes: 525600,
        });
        navigateHome();
      } catch {
        alert('error exchanging');
        setCreatingUser(false);
      }
    } catch (error) {
      alert('Error creating user! Please contact support if needed');
      setCreatingUser(false);
    } finally {
    }
  };

  const invalidName = !hasAtLeastTwoWords(fullName);
  const isCreateDisabled = useMemo(() => {
    return !isValidPassword || password !== confirmedPassword || invalidName;
  }, [password, confirmedPassword, invalidName, isValidPassword]);

  const renderFinishInvite = () => {
    // Note: unlikely edge case but still render create if they're in an org
    return (
      <>
        <h2 style={{ marginTop: '5px', marginBottom: '8px' }}>
          Finish Account Creation
        </h2>
        <p style={{ marginTop: '0px', fontSize: '13px', color: 'gray' }}>
          For Organization <b>{organization?.organization_name}</b>
        </p>
        {
          <>
            <TextInput
              label='Full Name'
              placeholder='Enter your first and last name'
              required
              type='email'
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              style={{ width: '100%' }}
              mb='md'
              size='md'
              error={
                invalidFormSubmitted &&
                invalidName &&
                'Enter your first and last name'
              }
            />
            <PasswordInput
              label='Password'
              placeholder='Enter your password'
              required
              value={password}
              style={{ width: '100%' }}
              onChange={(e) => setPassword(e.target.value)}
              mb='md'
              size='md'
              error={
                invalidFormSubmitted &&
                !isValidPassword &&
                'Password must be at least 8 characters'
              }
            />
            <PasswordInput
              label='Confirm Password'
              placeholder='Re-enter your password'
              required
              value={confirmedPassword}
              onChange={(e) => setConfirmedPassword(e.target.value)}
              style={{ width: '100%' }}
              mb='md'
              size='md'
              error={invalidFormSubmitted && password !== confirmedPassword}
            />
            <br />
            <LoginButton
              disabled={creatingUser}
              onClick={finishInviteAcceptance}
            >
              Create Account
            </LoginButton>
          </>
        }
      </>
    );
  };

  return (
    <div className='background-image'>
      <Container style={{ height: '100vh' }}>
        <Center style={{ height: '90%' }}>
          <Card className='login-card'>{renderFinishInvite()}</Card>
        </Center>
      </Container>
    </div>
  );
};

export default FinishInfoPage;
