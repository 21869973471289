import { NumberOfCallsFilterState } from 'pages/job-posting-tips/components/types';
import { useState } from 'react';

const DEFAULT_MIN_CALLS = 0;
const DEFAULT_MAX_CALLS = 20;

export type NumberOfCallsSelectionMode = 'less' | 'greater' | 'between';

export default function useNumberOfCallsFilterState(): NumberOfCallsFilterState {
  const [minCalls, setMinCalls] = useState(DEFAULT_MIN_CALLS);
  const [maxCalls, setMaxCalls] = useState(DEFAULT_MAX_CALLS);
  const [selectionMode, setSelectionMode] =
    useState<NumberOfCallsSelectionMode>('between');

  const clear = () => {
    setMinCalls(DEFAULT_MIN_CALLS);
    setMaxCalls(DEFAULT_MAX_CALLS);
    setSelectionMode('between');
  };

  const isEmpty =
    minCalls === DEFAULT_MIN_CALLS &&
    maxCalls === DEFAULT_MAX_CALLS &&
    selectionMode === 'between';

  return {
    minCalls,
    maxCalls,
    selectionMode,
    onChangeMinCalls: setMinCalls,
    onChangeMaxCalls: setMaxCalls,
    onChangeSelectionMode: setSelectionMode,
    clear,
    isEmpty,
  };
}
