import React from 'react';
import { Indicator } from '@mantine/core';
import { useAtom } from 'jotai';
import { campaignInfoAtomForCandidate } from 'pages/editorv2/atoms';

interface ReviewIndicatorProps {
  campaignId: string;
  candidateId: string;
  review: 'Call' | 'SMS' | 'Email';
}

const reviewKeyMap: Record<string, string> = {
  Call: 'isCallReviewed',
  SMS: 'isSMSReviewed',
  Email: 'isEmailReviewed',
};

const iconColor: Record<string, string> = {
  // Candidate review colors
  Call: 'blue',
  SMS: 'green',
  Email: 'purple',

  // Campaign review colors
  Review: 'blue',
  Questions: 'red',
};

const ReviewIndicator = ({
  campaignId,
  candidateId,
  review,
}: ReviewIndicatorProps) => {
  const candidateCampaignInfoKey = candidateId + '_' + campaignId;
  const [candidateInfo] = useAtom(
    campaignInfoAtomForCandidate(candidateCampaignInfoKey)
  );

  const userId = localStorage.getItem('userId');

  const shouldReview = candidateInfo[reviewKeyMap[review]] === false;

  if (!shouldReview) {
    return null;
  }

  if (!userId) {
    return null;
  }

  return (
    <Indicator
      color={iconColor[review]}
      size='8px'
      style={{
        position: 'absolute',
        left: 12,
        zIndex: 1,
      }}
    />
  );
};

const MultiReviewIndicatorBase = ({ indicatorsToShow }) => {
  if (!indicatorsToShow || indicatorsToShow.length === 0) {
    return null;
  }

  return (
    <div
      style={{
        position: 'relative',
        height: '10px',
        width: '12px',
      }}
    >
      {indicatorsToShow.map((indicator, index) => (
        <div
          key={index}
          style={{
            position: 'absolute',
            border: '2px solid white',
            borderRadius: '50%',
            width: '10px',
            height: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            left: `${index * 5}px`,
            zIndex: 9 - index,
          }}
        >
          <Indicator color={iconColor[indicator]} size='8px' />
        </div>
      ))}
    </div>
  );
};

export const OverallReviewIndicator = ({ campaignId, candidateId }) => {
  const candidateCampaignInfoKey = `${candidateId}_${campaignId}`;
  const [candidateInfo] = useAtom(
    campaignInfoAtomForCandidate(candidateCampaignInfoKey)
  );

  const shouldReviewCall = candidateInfo.isCallReviewed === false;
  const shouldReviewSMS = candidateInfo.isSMSReviewed === false;
  const shouldReviewEmail = candidateInfo.isEmailReviewed === false;

  // If nothing needs review, render nothing
  if (!shouldReviewCall && !shouldReviewSMS && !shouldReviewEmail) {
    return null;
  }

  const indicatorsToShow: string[] = [];

  if (shouldReviewCall) {
    indicatorsToShow.push('Call');
  }

  if (shouldReviewSMS) {
    indicatorsToShow.push('SMS');
  }

  if (shouldReviewEmail) {
    indicatorsToShow.push('Email');
  }

  return <MultiReviewIndicatorBase indicatorsToShow={indicatorsToShow} />;
};

export const CampaignReviewIndicator = ({
  needsReview,
  hasUnansweredQuestions,
}) => {
  if (!needsReview && !hasUnansweredQuestions) {
    return null;
  }

  const indicatorsToShow: string[] = [];

  if (needsReview) {
    indicatorsToShow.push('Review');
  }

  if (hasUnansweredQuestions) {
    indicatorsToShow.push('Questions');
  }

  return <MultiReviewIndicatorBase indicatorsToShow={indicatorsToShow} />;
};

export default ReviewIndicator;
